import React, { useState, useEffect, useContext } from 'react';
import { Col } from "react-bootstrap";
import Chart from "../AV/charts/other-chart";
import DentalChart from "./chart";
import Dashboard from "./../../../../../../api/dashboards";
import { isEmpty, getOr } from "lodash/fp";
import { DashContext } from 'context/Context';

const DentalTotals = () => {

  const [data, setData] = useState({})
  const [charts, setCharts] = useState([])
  const [dentalTotals, setDentalTotals] = useState([])
  const [dentalVisits, setDentalVisits] = useState([])
  const [dentalVisitsPerPatient, setDentalVisitsPerPatient] = useState([])
  const [fiscalYears, setFiscalYears] = useState([])
  const {Updatefilters} = useContext(DashContext)

  const [chartNo, setChartNo] = useState(1)
  const [looping, setLooping] = useState(false)

  const increaseChartNo = (num) => {
    setLooping(true)
    if (num !== 3){
      setChartNo(num + 1)
    }
    else{
      setChartNo(1)
    }
  }

  const decreaseChartNo = (num) => {
    setLooping(true)
    if (num !== 1){
      setChartNo(num - 1)
    }
    else{
      setChartNo(3)
    }
  }

  const cancelLooping = () => {
    setChartNo(1)
    setLooping(false)
  }

  useEffect(() => {
    getChartInfo()
    getChartsData()
  }, []);

  const getChart = (slug) => {
    if (!isEmpty(charts)) {
      if (charts.filter(obj => obj["slug"] === slug)) {
        return charts.filter(obj => obj["slug"] === slug)[0]
      }
    }
  }

  const getChartInfo = () => {
    let slug = window.location.href.split("/")[4]
    let dashboardName = localStorage.getItem('dashboardName')
    Dashboard.getChartsInfoBySlugAndTitle(dashboardName, slug).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setCharts(response.data)
      }
    });
  };

  const getChartsData = () => {
    let dashboardId = localStorage.getItem('dashboardId')
    Dashboard.getDentalsTotal(dashboardId).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setData(response.data)
        setFiscalYears(getOr([], 'data.stats_by_year', response).map(a => a.fiscal_year))
        setDentalTotals(getOr([], 'data.stats_by_year', response).map(a => a.total_dental_patients))
        setDentalVisits(getOr([], 'data.stats_by_year', response).map(a => a.total_dental_visits))
        setDentalVisitsPerPatient(getOr([], 'data.stats_by_year', response).map(a => a.total_dental_visits_per_patient))
      }
    });
  };

  const getSmallestValue = (array) => {
    return Math.min(...array);
  }

  const differenceArray = (array) => {
    let arr = array.slice(1).map((v, i) => v - array[i])
    arr.unshift(0)
    return arr
  }

  useEffect(() => {
    Updatefilters([])
  }, [])

  return (
    <>
    {!isEmpty(data) && !isEmpty(charts) ?
      <div className="container px-0">
        <div className="row">
          <Col lg={12} xl={6} className="chart-col">
            <Chart
              fiscalYears={fiscalYears}
              data={dentalTotals}
              description={getChart('total-dental-patients-by-fiscal-year')["description"]}
              title={getChart('total-dental-patients-by-fiscal-year')["name"]}
              colorHex={"#2A8B8C"}
              min={getSmallestValue(dentalTotals)}
              differenceArray={differenceArray(dentalTotals)}
              increaseChartNo={increaseChartNo}
              decreaseChartNo={decreaseChartNo}
              cancelLooping={cancelLooping}
              chartNo={chartNo}
              looping={looping}
              currentNo={1}
            />
          </Col>
          <Col lg={12} xl={6} className="chart-col">
            <Chart
              fiscalYears={fiscalYears}
              data={dentalVisits}
              description={getChart('total-dental-visits-by-fiscal-year')["description"]}
              title={getChart('total-dental-visits-by-fiscal-year')["name"]}
              colorHex={"#733837"}
              min={getSmallestValue(dentalVisits)}
              differenceArray={differenceArray(dentalVisits)}
              increaseChartNo={increaseChartNo}
              decreaseChartNo={decreaseChartNo}
              cancelLooping={cancelLooping}
              chartNo={chartNo}
              looping={looping}
              currentNo={2}
            />
          </Col>
        </div>
        <div className="row">
          <Col lg={12} xl={6} className="chart-col">
            <DentalChart
              fiscalYears={fiscalYears}
              data={dentalVisitsPerPatient}
              description={getChart('total-dental-visits-per-patient-by-fiscal-year')["description"]}
              title={getChart('total-dental-visits-per-patient-by-fiscal-year')["name"]}
              colorHex={"#e1a95b"}
              min={getSmallestValue(dentalVisitsPerPatient)}
              differenceArray={differenceArray(dentalVisitsPerPatient)}
              increaseChartNo={increaseChartNo}
              decreaseChartNo={decreaseChartNo}
              cancelLooping={cancelLooping}
              chartNo={chartNo}
              looping={looping}
              currentNo={3}
            />
          </Col>
        </div>
      </div>
      : null}
    </>
  );
};

export default DentalTotals;
