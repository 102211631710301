import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { MdAdd } from "react-icons/md";
import UserAvatar from 'assets/img/sp-images/default-avatar-sm.jpg';
import User from "./../../../../api/users";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from 'react-toastify';
import Defender from 'helpers/defender';
import AvatarEditor from 'react-avatar-editor'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Dropdown } from 'react-bootstrap';
import InviteModal from './ModalInvite';

function ModalUser({ getUsers, dashboards, selectedUser, showModal, closeModal, getDashboards, type, extraType }) {
	const [zoom, setZoom] = useState(1.2);
	const [showRemove, setShowRemove] = useState(false);
	const [showOptions, setShowOptions] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [invitedEmail, setInvitedEmail] = useState('');
	const [existUserModalShow, setExistUserModalShow] = useState(false);
	const [selectUser, setSelectUser] = useState(null);
	const editor = useRef(null);
	const values = [true];
	const [fullscreen, setFullscreen] = useState(true);
	const handleClose = () => setShow(false);
	const [avatar, setAvatar] = useState(null);
	const [imgSrc, setImgSrc] = useState(null);
	const [show, setShow] = useState(false);
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		roleAdder: '',
	});

	const handleSlider = (event) => {
		setZoom(event)
	};

	useEffect(() => {
		setImgSrc(null)
		setAvatar(null)
		setShowRemove(false)
		if (showModal === true) {
			let user = selectedUser
			setSelectUser(user)
			setFullscreen(true);
			setShow(true);
			if (!isEmpty(getOr('', 'main_image_link', user))) {
				setShowRemove(true)
			}
			if (getOr([], '_r', user).includes("executive")) {
				setFormData({
					...formData,
					firstName: getOr('', 'first_name', user),
					lastName: getOr('', 'last_name', user),
					phone: getOr('', 'phone', user),
					email: getOr('', 'email', user),
					roleAdder: 2,
				});
				setImgSrc(!isEmpty(getOr('', 'main_image_link', user)) ? getOr('', 'main_image_link', user) : UserAvatar)
			}
			else if (getOr([], '_r', user).includes("management")) {
				setFormData({
					...formData,
					firstName: getOr('', 'first_name', user),
					lastName: getOr('', 'last_name', user),
					phone: getOr('', 'phone', user),
					email: getOr('', 'email', user),
					roleAdder: 3,
				});
				setImgSrc(!isEmpty(getOr('', 'main_image_link', user)) ? getOr('', 'main_image_link', user) : UserAvatar)
			}
			else if (getOr([], '_r', user).includes("admin")) {
				setFormData({
					...formData,
					firstName: getOr('', 'first_name', user),
					lastName: getOr('', 'last_name', user),
					email: getOr('', 'email', user),
					roleAdder: 1,
				});
				setImgSrc(!isEmpty(getOr('', 'main_image_link', user)) ? getOr('', 'main_image_link', user) : UserAvatar)
			}
			else if (getOr([], '_r', user).includes("sub_admin")) {
				setFormData({
					...formData,
					firstName: getOr('', 'first_name', user),
					lastName: getOr('', 'last_name', user),
					email: getOr('', 'email', user),
					roleAdder: 4,
				});
				setImgSrc(!isEmpty(getOr('', 'main_image_link', user)) ? getOr('', 'main_image_link', user) : UserAvatar)
			}
			else {
				setImgSrc(UserAvatar)
			}
		}
		else {
			setFormData({
				...formData,
				firstName: '',
				lastName: '',
				phone: '',
				email: '',
				roleAdder: '',
			});
			setImgSrc(UserAvatar)
		}
	}, [showModal]);

	function handleShow(breakpoint) {
		setFullscreen(breakpoint);
		setShow(true);
	}

	const ModalClose = (e) => {
		setShow(false);
		closeModal(e)
	}

	const b64toBlob = (b64Data, contentType, sliceSize) => {
		contentType = contentType || "";
		sliceSize = sliceSize || 512;

		var byteCharacters = atob(b64Data);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	const handleSubmit = e => {
		e.preventDefault();
		// if (type === "SuperAdmin") {
		// 	formData.roleAdder = 1
		// }
		if ((formData.roleAdder !== "Select" && formData.roleAdder !== "") && formData.email !== "") {
			let formDataUser = {}
			formDataUser["first_name"] = formData.firstName
			formDataUser["last_name"] = formData.lastName
			formDataUser["phone"] = formData.phone
			formDataUser["email"] = formData.email
			formDataUser["role_adder"] = formData.roleAdder
			if (parseInt(formData.roleAdder) === 2 || parseInt(formData.roleAdder) === 3) {
				if (Defender.currentUser() && (Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin"))) {
					formDataUser["organization_id"] = window.location.href.split("/")[4]
				}
				else {
					formDataUser["organization_id"] = Defender.currentUser().organization_id
				}
			}
			if ((isEmpty(selectedUser))) {
				User.create({ user: formDataUser }).then((response) => {
					if (
						(getOr("", "status", response) === 200 ||
							getOr("", "status", response) === 204) &&
						isEmpty(getOr({}, "data.errors", response))
					) {
						let createdUser = response.data.user
						if (!isEmpty(editor) && avatar !== null) {
							let block = editor.current.getImageScaledToCanvas().toDataURL().split(";");
							let contentType = block[0].split(":")[1];
							let realData = block[1].split(",")[1];
							let blob = b64toBlob(realData, contentType);
							let imageFormData = new FormData();
							imageFormData.append("main_image", blob);
							User.updateImage(imageFormData, getOr(0, 'id', createdUser)).then((response) => {
								toast.success(`User created`, {
									theme: 'colored'
								});
								setFormData({
									...formData,
									firstName: '',
									lastName: '',
									phone: '',
									email: '',
									roleAdder: '',
								});
								setAvatar(null)
								setImgSrc(null)
								if (Defender.currentUser().organization_id) {
									getUsers(Defender.currentUser().organization_id)
									if (type !== "SuperAdmin") {
										getDashboards(Defender.currentUser().organization_id)
									}
								}
								else {
									getUsers()
									if (type !== "SuperAdmin") {
										getDashboards()
									}
								}
								handleClose()
							})
						}
						else {
							toast.success(`User created`, {
								theme: 'colored'
							});
							setFormData({
								...formData,
								firstName: '',
								lastName: '',
								phone: '',
								email: '',
								roleAdder: '',
							});
							setAvatar(null)
							setImgSrc(null)
							if (Defender.currentUser().organization_id) {
								getUsers(Defender.currentUser().organization_id)
								if (type !== "SuperAdmin") {
									getDashboards(Defender.currentUser().organization_id)
								}
							}
							else {
								getUsers()
								if (type !== "SuperAdmin") {
									getDashboards()
								}
							}
							handleClose()
						}
					}
					else {
						setShow(false)
						if (type !== "SuperAdmin") {
							setExistUserModalShow(true)
							setFormData({
								...formData,
								firstName: '',
								lastName: '',
								phone: '',
								email: '',
								roleAdder: '',
							});
							setInvitedEmail(formData["email"])
							setAvatar(null)
						}
						else {
							toast.error(`User already exist`, {
								theme: 'colored'
							});
							setFormData({
								...formData,
								firstName: '',
								lastName: '',
								phone: '',
								email: '',
								roleAdder: '',
							});
							setAvatar(null)
						}
					}
				})
			}
			else {
				User.updateUser({ user: formDataUser }, getOr(0, 'id', selectedUser)).then((response) => {
					if (
						(getOr("", "status", response) === 200 ||
							getOr("", "status", response) === 204) &&
						isEmpty(getOr({}, "data.errors", response))
					) {
						if (!isEmpty(editor) && avatar !== null) {
							let block = editor.current.getImageScaledToCanvas().toDataURL().split(";");
							let contentType = block[0].split(":")[1];
							let realData = block[1].split(",")[1];
							let blob = b64toBlob(realData, contentType);
							let imageFormData = new FormData();
							imageFormData.append("main_image", blob);
							User.updateImage(imageFormData, getOr(0, 'id', selectedUser)).then((response) => {
								toast.success(`User updated`, {
									theme: 'colored'
								});
								setFormData({
									...formData,
									firstName: '',
									lastName: '',
									phone: '',
									email: '',
									roleAdder: '',
								});
								setAvatar(null)
								setImgSrc(null)
								if (Defender.currentUser().organization_id) {
									getUsers(Defender.currentUser().organization_id)
									if (type !== "SuperAdmin") {
										getDashboards(Defender.currentUser().organization_id)
									}
								}
								else {
									getUsers()
									if (type !== "SuperAdmin") {
										getDashboards()
									}
								}
								handleClose()
								setShow(false);
								closeModal(e)
							})
						}
						else {
							toast.success(`User updated`, {
								theme: 'colored'
							});
							setFormData({
								...formData,
								firstName: '',
								lastName: '',
								phone: '',
								email: '',
								roleAdder: '',
							});
							setAvatar(null)
							setImgSrc(null)
							if (Defender.currentUser().organization_id) {
								getUsers(Defender.currentUser().organization_id)
								if (type !== "SuperAdmin") {
									getDashboards(Defender.currentUser().organization_id)
								}
							}
							else {
								getUsers()
								if (type !== "SuperAdmin") {
									getDashboards()
								}
							}
							handleClose()
							setShow(false);
							closeModal(e)
						}
					}
					else {
						toast.error(getOr("", "response.data.message", response), {
							theme: 'colored'
						});
					}
				})
			}
		}
		else {
			toast.error("Please enter email and select user role", {
				theme: 'colored'
			});
		}
	}

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const fileUpload = (e) => {
		e.preventDefault()
		document.getElementById('fileid').click();
	}

	const fileUploaded = (e) => {
		setShowRemove(true)
		setImgSrc(URL.createObjectURL(e.target.files[0]))
		setAvatar(e.target.files[0])
		toast.success(`File Uploaded Successfully`, {
			theme: 'colored'
		});
	}


	const removeAvatar = (e) => {
		e.preventDefault();
		User.removeImage(0).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				Defender.setCurrentUser(response.data.user)
				toast.success(`User profile image removed`, {
					theme: 'colored'
				});
				setShowRemove(false)
				setImgSrc(UserAvatar)
				setAvatar(null)
			}
		})
	}

	function isValidEmail(email) {
		return /\S+@\S+\.\S+/.test(email);
	}

	const handleInviteUser = () => {
		if (isValidEmail(!isEmpty(formData["email"]) ? formData["email"] : invitedEmail)) {
			let data = {}
			data["organization_id"] = window.location.href.split("/")[4]
			data["email"] = !isEmpty(formData["email"]) ? formData["email"] : invitedEmail
			User.inviteUser({ user: data }).then((response) => {
				if (
					(getOr("", "status", response) === 200 ||
						getOr("", "status", response) === 204) &&
					isEmpty(getOr({}, "data.errors", response))
				) {
					setExistUserModalShow(false)
					toast.success(`User Invited Successfully`, {
						theme: 'colored'
					});
				}
			})
		} else {
			toast.error(`Invalid Email`, {
				theme: 'colored'
			});
		}
	}

	const ExistUserModal = () => {
		return (
			<>
				<Modal
					show={existUserModalShow}
					onHide={() => setExistUserModalShow(false)}
					size="md"
					aria-labelledby="exist-user-modal"
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>User Already Exists</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>The email of this user already exists, would you like to invite them to join your organization?</div>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => {
							handleInviteUser()
						}}>Invite User</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	const handleUsers = (e, type) => {
		getUsers(type)
	}


	return (
		<>
			{values.map((v, idx) => (
				<>
					{extraType === "AllUsers" ?
						<Dropdown>
							<Dropdown.Toggle
								as={Button}
								variant="btn-primary"
								size="md"
								iconClassName="d-none"
								className="btn btn-primary"
							>
								<span className="d-none d-sm-inline-block ms-1">Filter</span>
								{typeof v === 'string' && `below ${v.split('-')[0]}`}
							</Dropdown.Toggle>
							<Dropdown.Menu align={'start'}>
								<Dropdown.Item onClick={(e) => handleUsers(v, "all")}>View All</Dropdown.Item>
								<Dropdown.Item onClick={(e) => handleUsers(v, "executive")}>Executive</Dropdown.Item>
								<Dropdown.Item onClick={(e) => handleUsers(v, "management")}>Management</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						:
						<>
							<Button key={idx} className="btn btn-primary" onClick={() => handleShow(v)}>
								<MdAdd />
								<span className="d-none d-sm-inline-block ms-1">New</span>
								{typeof v === 'string' && `below ${v.split('-')[0]}`}
							</Button>
						</>
					}
				</>
			))}
			<InviteModal modalShow={modalShow} setModalShow={setModalShow} />
			<Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
				<Modal.Header>
					<Modal.Title>{type === 'SuperAdmin' ? "New SuperAdmin User" : "New User"}</Modal.Title>
					<div className="modal-header__actions">
						<Button variant="falcon-default" onClick={ModalClose}>
							Cancel
						</Button>
						<Button variant="primary" onClick={handleSubmit}>
							Save
						</Button>
					</div>
				</Modal.Header>
				<Modal.Body>
					<Form className="modal-form max-800">
						<div className="form-divider">Contact Information</div>
						<div className="row mb-4">
							<div className="modal-form__avatar">
								{avatar !== null ?
									<AvatarEditor
										ref={editor}
										image={imgSrc}
										width={250}
										height={250}
										border={50}
										color={[255, 255, 255, 0.6]} // RGBA
										scale={zoom}
										rotate={0}
									/>
									: <img src={imgSrc} />
								}
								<div className="modal-form__avatar-actions">
									<input id='fileid' type='file' hidden onChange={fileUploaded} accept="image/x-png,image/gif,image/jpeg" />
									<button className="btn btn-falcon-default btn-sm" onClick={fileUpload}>Add Avatar</button>
									<button className="btn btn-default btn-sm" onClick={removeAvatar}>Remove Avatar</button>
									{avatar !== null ?
										<Slider min={1}
											max={10}
											value={zoom}
											onChange={handleSlider}
											step={0.1} />
										: null}
								</div>
							</div>
						</div>
						<div className="row">
							<Form.Group className="mb-3 col-sm-6" controlId="ContactFirstName">
								<Form.Label>First Name</Form.Label>
								<Form.Control type="text" placeholder="" name="firstName" onChange={handleFieldChange} value={formData.firstName} />
							</Form.Group>
							<Form.Group className="mb-3 col-sm-6" controlId="ContactLastName">
								<Form.Label>Last Name</Form.Label>
								<Form.Control type="text" placeholder="" name="lastName" onChange={handleFieldChange} value={formData.lastName} />
							</Form.Group>
						</div>
						<div className="row">
							<Form.Group className="mb-3 col-sm-6" controlId="ContactEmail">
								<Form.Label>Email Address</Form.Label>
								<Form.Control type="email" placeholder="name@domain.com" name="email" onChange={handleFieldChange} value={formData.email} />
							</Form.Group>
							{type === 'SuperAdmin' ? (
								<>
								</>
							) : (
								<Form.Group className="mb-3 col-sm-6" controlId="ContactPhoneNumber">
									<Form.Label>Phone Number</Form.Label>
									<Form.Control type="tel" placeholder="(000) 000-0000" name="phone" onChange={handleFieldChange} value={formData.phone} />
								</Form.Group>
							)}
						</div>
						{type === 'SuperAdmin' ? (
							<>
								<div className="form-divider">User Permissions</div>
								<div className="row">
									{extraType === "AllUsers" ?
										<Form.Group className="mb-3 col-sm-6" controlId="UserRole">
											<Form.Label>User Role</Form.Label>
											<Form.Select aria-label="User Role" name="roleAdder" onChange={handleFieldChange} value={formData.roleAdder}>
												<option value={null}>Select</option>
												<option value={2}>Executive</option>
												<option value={3}>Management</option>
											</Form.Select>
										</Form.Group>
										:
										<Form.Group className="mb-3 col-sm-6" controlId="UserRole">
											<Form.Label>User Role</Form.Label>
											<Form.Select aria-label="User Role" name="roleAdder" onChange={handleFieldChange} value={formData.roleAdder}>
												<option value={null}>Select</option>
												<option value={1}>Admin</option>
												<option value={4}>Manager</option>
											</Form.Select>
										</Form.Group>
									}
								</div>
							</>
						) : (
							<>
								<div className="form-divider">User Permissions</div>
								<div className="row">
									<Form.Group className="mb-3 col-sm-6" controlId="UserRole">
										<Form.Label>User Role</Form.Label>
										<Form.Select aria-label="User Role" name="roleAdder" onChange={handleFieldChange} value={formData.roleAdder}>
											<option value={null}>Select</option>
											<option value={2}>Executive</option>
											<option value={3}>Management</option>
										</Form.Select>
									</Form.Group>
								</div>
							</>
						)}
						{type === 'SuperAdmin' ? (
							<>
								{null}
							</>
						) : (
							<>
								<div className="form-divider">Assigned Organization</div>
								<div className="row">
									{!isEmpty(getOr('', 'organization.name', selectUser)) ?
										<div>{getOr('', 'organization.name', selectUser)}</div>
										: <div>{'None'}</div>
									}
								</div>
							</>
						)}
					</Form>
				</Modal.Body>
			</Modal>
			<ExistUserModal />
		</>
	);
}

export default ModalUser;