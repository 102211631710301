import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router";
import logo from 'assets/img/byc-logo.png';
import slogo from 'assets/img/spearpoint-logo_1A.png';

const Logo = ({ at, width, className, textClass, ...rest }) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.toLowerCase().includes("users") || pathname.toLowerCase().includes("dash") || pathname.toLowerCase().includes("organization") || pathname.toLowerCase().includes("admin") || pathname.toLowerCase().includes("operations-summary")  || pathname.toLowerCase().includes("summary-of-accounts") || pathname.toLowerCase().includes("upload") || pathname.toLowerCase().includes("plans")  || pathname.toLowerCase().includes("invoice-reports") || pathname.toLowerCase().includes("activity-log")
  const isAdminDashboard = pathname.toLowerCase().includes("users") || pathname.includes("admin") || pathname.toLowerCase().includes("upload")|| pathname.toLowerCase().includes("plans") || pathname.toLowerCase().includes("invoice-reports") || pathname.toLowerCase().includes("activity-log") || pathname.toLowerCase().includes("empty-dash");

  return (
    <Link 
      to="/"
      className={classNames(
        'text-decoration-none',
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        <img className={isAdminDashboard ? 'me-2 admin-logo mh-100' : 'me-2' } src={isDashboard ? slogo : logo} alt="Logo" width={width} />
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
