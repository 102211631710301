import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Organization from "./../../../../api/organizations";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from 'react-toastify';
import Defender from 'helpers/defender';
import { Col, Row, Table } from 'react-bootstrap';

import IRTable from '../invoice-recipents-table/IRTable';
import LogsTable from '../logs-table/LogsTable';
import ModalOrgInvoice from './ModalOrgInvoice';

function ModalOrgEditPlan({ getOrganization, getUsers }) {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [organization, setOrganization] = useState({});
  const [show, setShow] = useState(false);
  const [showInvoice, setShowInvoice] = useState(true)
  const handleClose = () => setShow(false);
  const [formData, setFormData] = useState({
    name: '',
    organizationPhone: '',
    primaryAddress: '',
    secondaryAddress: '',
    city: '',
    state: '',
    zipcode: '',
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userPhone: '',
  });

  useEffect(() => {
    let id = window.location.href.split("/")[4]
    Organization.get(id).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        let organization = response.data.organization
        setOrganization(organization)
        setFormData({
          ...formData,
          name: getOr('', 'name', organization),
          website: getOr('', 'website', organization),
          organizationPhone: getOr('', 'phone', organization),
          primaryAddress: getOr('', 'primary_address', organization),
          secondaryAddress: getOr('', 'secondary_address', organization),
          state: getOr('', 'state', organization),
          zipcode: getOr('', 'zipcode', organization),
          city: getOr('', 'city', organization),
          userFirstName: getOr('', 'user_first_name', organization),
          userLastName: getOr('', 'user_last_name', organization),
          userEmail: getOr('', 'user_email', organization),
          userPhone: getOr('', 'user_phone', organization),
        });
      }
    })
  }, []);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const handleSubmit = e => {
    e.preventDefault();
    toast.success(`Organization Plan Changed`, {
      theme: 'colored'
    });
    handleClose()
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const users = [{
    "name": "Youssef Hassan",
  },
  {
    "name": "Steve Reed"
  },
  {
    "name": "Usman"
  },
  {
    "name": "Farasat"
  }]

  const logs = [{
    "date": "08/20/2023",
    "text": "They approved the new amount",
    "by": "Youssef Hassan",
  },{
    "date": "08/25/2023",
    "text": "Plan set up with custom reports for $500",
    "by": "Steve Reed",
  },
  {
    "date": "08/20/2023",
    "text": "They approved the new amount",
    "by": "Youssef Hassan",
  },
]



const [showConfirm, setShowConfirm] = useState(false)


const confirmPlanChange = () => {
  return (
    <Modal
      show={showConfirm}
      onHide={() => setShowConfirm(false)}
      size="md"
      centered
      backdropClassName="custom-modal-backdrop"
    >
      <Modal.Header>
        <Modal.Title>Resend invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>You have chosen the Standard Annual Plan at $60,000, do you wish to proceed with this plan?</div>
        <div className='mt-2 d-flex gap-2 justify-content-end align-items-center'>
        <Button onClick={() => setShowConfirm(false)} variant="secondary">Cancel</Button>
        <Button onClick={() => {
          setShowConfirm(false);
          toast.success(`Plan Has Been Changed`, {
            theme: 'colored'
          });
        }}>Confirm</Button>
        </div>
      </Modal.Body>

    </Modal>
  );
}


  return (
    <>
      {values.map((v, idx) => (
        <Button key={idx} variant="falcon-default" className="" onClick={() => handleShow(v)}>
           Edit Default Pricing 
          {typeof v === 'string' && `below ${v.split('-')[0]}`}
        </Button>
      ))}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>Default Plan Pricing</Modal.Title>
          <div className="modal-header__actions">
            <Button variant="falcon-default" onClick={handleClose}>
              Cancel
            </Button>

            <ModalOrgInvoice preview={true} previewHide={handleClose} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form className="modal-form max-800 mx-auto">
            <div className="form-divider">Select Plan</div>
            <div className="row plans max-800 mx-auto mb-4">  
              <div className='plans__plan d-flex justify-content-between'>
                <div className='plans__plan__name d-flex gap-2 align-items-center justify-content-center table__checkbox'>
                <Form.Check
                    type="radio"
                    id="plans__plan__name"
                    name="plans__plan__name"
                    checked={true}
                  />
                 Standard Monthly
                </div>
                <div className='d-flex align-items-center gap-2'>
                  <div className='h5'>$</div>
                  <Form.Group controlId="standard">
                    <Form.Control type="text"  name="standard__cost" className="plan__cost__checkbox" onChange={handleFieldChange} value={"5,500"} />
                  </Form.Group>
                </div>
              </div>
              <div className='plans__plan d-flex justify-content-between'>
                <div className='plans__plan__name d-flex gap-2 align-items-center justify-content-center table__checkbox'>
                <Form.Check
                    type="radio"
                    id="plans__plan__name"
                    name="plans__plan__name"
                />
                 Standard Annual
                </div>
                <div className='d-flex align-items-center gap-2'>
                  <div className='h5'>$</div>
                  <Form.Group controlId="standard">
                    <Form.Control type="text"  name="standard__cost" className="plan__cost__checkbox" onChange={handleFieldChange} value={"60,000"} />
                  </Form.Group>
                </div>
              </div>
              <div className='plans__plan d-flex justify-content-between'>
                <div className='plans__plan__name d-flex gap-2 align-items-center justify-content-center table__checkbox'>
                <Form.Check
                    type="radio"
                    id="plans__plan__name"
                    name="plans__plan__name"
                />
                 Premium Monthly
                </div>
                <div className='d-flex align-items-center gap-2'>
                  <div className='h5'>$</div>
                  <Form.Group controlId="standard">
                    <Form.Control type="text"  name="standard__cost" className="plan__cost__checkbox" onChange={handleFieldChange} value={"7,500"} />
                  </Form.Group>
                </div>
              </div>
              <div className='plans__plan d-flex justify-content-between'>
                <div className='plans__plan__name d-flex gap-2 align-items-center justify-content-center table__checkbox'>
                <Form.Check
                    type="radio"
                    id="plans__plan__name"
                    name="plans__plan__name"
                  />
                 Premium Annual
                </div>
                <div className='d-flex align-items-center gap-2'>
                  <div className='h5'>$</div>
                  <Form.Group controlId="standard">
                    <Form.Control type="text"  name="standard__cost" className="plan__cost__checkbox" onChange={handleFieldChange} value={"80,000"} />
                  </Form.Group>
                </div>
              </div> 
            </div>
            <div className="row">
              <Form.Group className="mb-3 col-sm-6" controlId="overhead__fee">
                <Form.Label>Overhead Fee</Form.Label>
                <Form.Control type="number" step="any" placeholder="" name="overhead__fee" onChange={handleFieldChange} value={formData.overhead__fee} />
              </Form.Group>
              <Form.Group className="mb-3 col-sm-6" controlId="custom__report__fee" >
                <Form.Label>Custom Report Fee</Form.Label>
                <Form.Control type="number" placeholder="" step="any"  name="custom__report__fee" onChange={handleFieldChange} value={formData.custom__report__fee} />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {showConfirm && confirmPlanChange()}
    </>
  );
}

export default ModalOrgEditPlan;