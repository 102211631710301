import React, { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import axiosInstance from "./axiosInstance"
import { ProgressBar } from "react-bootstrap"
import Defender from "./../../../helpers/defender";
import { isEmpty, getOr } from "lodash/fp";

function Dropzone() {
  const navigate = useNavigate();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ multiple: true });
  const [progress, setProgress] = useState([])
  const [progressValue, setProgressValue] = useState(0)
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  useEffect(() => {
    if (getOr([], "_r", Defender.currentUser()).includes("admin")){
    }
    else{
      window.location.href = '/'
    }
  }, []);

  const handleSubmit = (e) => {
    if (acceptedFiles.length > 0) {
      for (let i = 0; i < acceptedFiles.length; i++) {
        if (acceptedFiles[i].name.split(".")[1] === "xlsx") {
          let formData = new FormData();
          formData.append('file', acceptedFiles[i]);
          axiosInstance.post("/api/v1/upload_csvs.json", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: data => {
              let total = Math.round((100 * data.loaded) / data.total)
              let progressArray = progress
              let index = progressArray.findIndex(item => item["id"] === acceptedFiles[i].path)
              setProgress([])
              if (index === -1) {
                let data = {}
                data["id"] = acceptedFiles[i].path
                data["total"] = total
                progressArray.push(data)
              }
              else {
                progressArray[i]["total"] = total
              }
              setProgress(progressArray)
              if (i === acceptedFiles.length - 1 && total === 100) {
                setTimeout(() => {
                  toast.success(`Files Uploaded`, {
                    theme: 'colored'
                  });
                  navigate(`/admin`);
                }, 3000);
              }
            },
          })
        }
        else{
          toast.error(`Please upload files of xlsx format only`, {
            theme: 'colored'
          });
        }
      }
    }
  }

  return (
    <>
      <div className="card mt-3">
        <div className="card-body">
          <div {...getRootProps({ className: 'dropzone-area py-6' })}>
            <input {...getInputProps()} />
            <Flex justifyContent="center">
              <img src={cloudUpload} alt="" width={25} className="me-2" />
              <p className="fs-0 mb-0 text-700">Drop client Excel files here</p>
            </Flex>
          </div>
          <div>
            {acceptedFiles.length > 0 && (
              <>
                <h5 className="mt-3 mb-2">Uploaded Files</h5>
                <ul className="mb-0">{files}</ul>
              </>
            )}
            {acceptedFiles.length > 0 ?
              <div className="mt-3">
                {progress.map((p, index) => (
                  <div className="mt-1">
                    {console.log(p)}
                    <>
                      {p["total"] && <ProgressBar now={p["total"]} label={`${p["total"]}%`} />}
                    </>
                  </div>
                ))}
              </div>
              : null}
            <div className="mt-3">
              <Button className="primary" onClick={handleSubmit}>Confirm Upload</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dropzone;