import React from 'react';
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { SlQuestion } from 'react-icons/sl';
import { isEmpty, getOr } from "lodash/fp";
import { HiOutlineArrowsExpand } from 'react-icons/hi';

const AverageExportAge = ({ locationData, serviceData, payerData, locationDescription, serviceDescription, payerDescription }) => {
  let lData = []
  let sData = []
  let pData = []
  if (!isEmpty(locationData)) {
    for (let l = 0; l < (locationData.length); l++) {
      let obj = {}
      if (!isEmpty(locationData[l]["location_type"])) {
        obj["location_type"] = !isEmpty(locationData[l]["location_type"]) ? locationData[l]["location_type"] : "(BLANK)"
        obj["export_age"] = locationData[l]["export_age"]
        lData.push(obj)
      }
    }
  }

  if (!isEmpty(serviceData)) {
    for (let l = 0; l < (serviceData.length); l++) {
      let obj = {}
      if (!isEmpty(serviceData[l]["service"])) {
        obj["service"] = !isEmpty(serviceData[l]["service"]) ? serviceData[l]["service"] : "(BLANK)"
        obj["export_age"] = serviceData[l]["export_age"]
        sData.push(obj)
      }
    }
  }

  if (!isEmpty(payerData)) {
    for (let l = 0; l < (payerData.length); l++) {
      let obj = {}
      if (!isEmpty(payerData[l]["payer"])) {
        obj["payer"] = !isEmpty(payerData[l]["payer"]) ? payerData[l]["payer"] : "(BLANK)"
        obj["export_age"] = payerData[l]["export_age"]
        pData.push(obj)
      }
    }
  }

  function ModalVersion({ title }) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
      <>
        <HiOutlineArrowsExpand onClick={() => setModalShow(true)} className="card-header__question-mark card-header__expand" />
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mini-stat__modal solid-color">
              {title === "Location" ?
                <>
                  {locationData.map((obj, index) => (
                    <>
                      <div className={`mini-stat__item--modal mini-stat__item--modal--${index} col-3`}>
                        <div className="mini-stat__title">{obj["location_type"]}</div>
                        <div className="mini-stat__value">
                          {obj["export_age"] ? obj["export_age"] : 0}
                        </div>
                      </div>
                    </>
                  ))}
                </>
                : null}
              {title === "Service" ?
                <>
                  {serviceData.map((obj, index) => (
                    <>
                      <div className={`mini-stat__item--modal mini-stat__item--modal--${index} col-3`}>
                        <div className="mini-stat__title">{obj["service"]}</div>
                        <div className="mini-stat__value">
                          {obj["export_age"] ? obj["export_age"] : 0}
                        </div>
                      </div>
                    </>
                  ))}
                </>
                : null}
              {title === "Payer" ?
                <>
                  {payerData.map((obj, index) => (
                    <>
                      <div className={`mini-stat__item--modal mini-stat__item--modal--${index} col-3`}>
                        <div className="mini-stat__title">{obj["payer"]}</div>
                        <div className="mini-stat__value">
                          {obj["export_age"] ? obj["export_age"] : 0}
                        </div>
                      </div>
                    </>
                  ))}
                </>
                : null}

            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  return (
    <>
      <div className="mini-stat chart-col">
        <div className="col-12">
          <div className="card card--chart">
            <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
              <h5>Location</h5>
              <div className="d-flex align-items-center gap-2">
                <ModalVersion title={"Location"} />
                <OverlayTrigger
                  trigger="hover"
                  placement="bottom-end"
                  overlay={
                    <Popover
                      id={"popover-positioned-" + "bottom-end"}
                      arrow={false}
                      arrowProps={false}
                      style={{
                        marginTop: "0",
                        transition: "all 0.2s ease-in-out, transform 0s;",
                      }}
                    >
                      <Popover.Body>
                        {locationDescription}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div>
                    <SlQuestion className="card-header__question-mark" />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
            <div className="card-body">
              {!isEmpty(lData[0]) ?
                <div className="mini-stat__item mini-stat__item--1 col-4">
                  <div className="mini-stat__title">{lData[0]["location_type"]}</div>
                  <div className="mini-stat__value">
                    {lData[0]["export_age"] ? lData[0]["export_age"] : 0}
                  </div>
                </div>
                :
                <div className="mini-stat__item mini-stat__item--1 col-4">
                  <div className="mini-stat__title">{"BLANK"}</div>
                  <div className="mini-stat__value">
                    {0}
                  </div>
                </div>
              }
              {!isEmpty(lData[1]) ?
                <div className="mini-stat__item mini-stat__item--2 col-4">
                  <div className="mini-stat__title">{lData[1]["location_type"]}</div>
                  <div className="mini-stat__value">
                    {lData[1]["export_age"] ? lData[1]["export_age"] : 0}
                  </div>
                </div>
                :
                <div className="mini-stat__item mini-stat__item--2 col-4">
                  <div className="mini-stat__title">{"BLANK"}</div>
                  <div className="mini-stat__value">
                    {0}
                  </div>
                </div>
              }
              {!isEmpty(lData[2]) ?
                <div className="mini-stat__item mini-stat__item--3 col-4">
                  <div className="mini-stat__title">{lData[2]["location_type"]}</div>
                  <div className="mini-stat__value">
                    {lData[2]["export_age"] ? lData[2]["export_age"] : 0}
                  </div>
                </div>
                :
                <div className="mini-stat__item mini-stat__item--3 col-4">
                  <div className="mini-stat__title">{"BLANK"}</div>
                  <div className="mini-stat__value">
                    {0}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="card card--chart">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5>Service</h5>
              <div className="d-flex align-items-center gap-2">
                <ModalVersion title={"Service"} />
                <OverlayTrigger
                  trigger="hover"
                  placement="bottom-end"
                  overlay={
                    <Popover
                      id={"popover-positioned-" + "bottom-end"}
                      arrow={false}
                      arrowProps={false}
                      style={{
                        marginTop: "0",
                        transition: "all 0.2s ease-in-out, transform 0s;",
                      }}
                    >
                      <Popover.Body>
                        {serviceDescription}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div>
                    <SlQuestion className="card-header__question-mark" />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
            <div className="card-body">
              {!isEmpty(sData[0]) ?
                <div className="mini-stat__item mini-stat__item--1 col-4">
                  <div className="mini-stat__title">{sData[0]["service"]}</div>
                  <div className="mini-stat__value">
                    {sData[0]["export_age"] ? sData[0]["export_age"] : 0}
                  </div>
                </div>
                :
                <div className="mini-stat__item mini-stat__item--1 col-4">
                  <div className="mini-stat__title">{"BLANK"}</div>
                  <div className="mini-stat__value">
                    {0}
                  </div>
                </div>
              }
              {!isEmpty(sData[1]) ?
                <div className="mini-stat__item mini-stat__item--2 col-4">
                  <div className="mini-stat__title">{sData[1]["service"]}</div>
                  <div className="mini-stat__value">
                    {sData[1]["export_age"] ? sData[1]["export_age"] : 0}
                  </div>
                </div>
                :
                <div className="mini-stat__item mini-stat__item--2 col-4">
                  <div className="mini-stat__title">{"BLANK"}</div>
                  <div className="mini-stat__value">
                    {0}
                  </div>
                </div>
              }
              {!isEmpty(sData[2]) ?
                <div className="mini-stat__item mini-stat__item--3 col-4">
                  <div className="mini-stat__title">{sData[2]["service"]}</div>
                  <div className="mini-stat__value">
                    {sData[2]["export_age"] ? sData[2]["export_age"] : 0}
                  </div>
                </div>
                :
                <div className="mini-stat__item mini-stat__item--3 col-4">
                  <div className="mini-stat__title">{"BLANK"}</div>
                  <div className="mini-stat__value">
                    {0}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="card card--chart">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5>Payer</h5>
              <div className="d-flex align-items-center gap-2">
                <ModalVersion title={"Payer"} />
                <OverlayTrigger
                  trigger="hover"
                  placement="bottom-end"
                  overlay={
                    <Popover
                      id={"popover-positioned-" + "bottom-end"}
                      arrow={false}
                      arrowProps={false}
                      style={{
                        marginTop: "0",
                        transition: "all 0.2s ease-in-out, transform 0s;",
                      }}
                    >
                      <Popover.Body>
                        {payerDescription}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div>
                    <SlQuestion className="card-header__question-mark" />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
            <div className="card-body">
              {pData.filter(obj => obj["payer"] === "MEDICAID")[0] ?
                <div className="mini-stat__item mini-stat__item--1 col-4">
                  <div className="mini-stat__title">{pData.filter(obj => obj["payer"] === "MEDICAID")[0]["payer"]}</div>
                  <div className="mini-stat__value">
                    {pData.filter(obj => obj["payer"] === "MEDICAID")[0]["export_age"] ? pData.filter(obj => obj["payer"] === "MEDICAID")[0]["export_age"] : 0}
                  </div>
                </div>
                :
                <div className="mini-stat__item mini-stat__item--1 col-4">
                  <div className="mini-stat__title">{"BLANK"}</div>
                  <div className="mini-stat__value">
                    {0}
                  </div>
                </div>
              }
              {pData.filter(obj => obj["payer"] === "MEDICARE")[0] ?
                <div className="mini-stat__item mini-stat__item--2 col-4">
                  <div className="mini-stat__title">{pData.filter(obj => obj["payer"] === "MEDICARE")[0]["payer"]}</div>
                  <div className="mini-stat__value">
                    {pData.filter(obj => obj["payer"] === "MEDICARE")[0]["export_age"] ? pData.filter(obj => obj["payer"] === "MEDICARE")[0]["export_age"] : 0}
                  </div>
                </div>
                :
                <div className="mini-stat__item mini-stat__item--2 col-4">
                  <div className="mini-stat__title">{"BLANK"}</div>
                  <div className="mini-stat__value">
                    {0}
                  </div>
                </div>
              }
              {pData.filter(obj => obj["payer"] === "PI")[0] ?
                <div className="mini-stat__item mini-stat__item--3 col-4">
                  <div className="mini-stat__title">{pData.filter(obj => obj["payer"] === "PI")[0]["payer"] === "PI" ? "PRIVATE INS" : null}</div>
                  <div className="mini-stat__value">
                    {pData.filter(obj => obj["payer"] === "PI")[0]["export_age"] ? pData.filter(obj => obj["payer"] === "PI")[0]["export_age"] : 0}
                  </div>
                </div>
                :
                <div className="mini-stat__item mini-stat__item--3 col-4">
                  <div className="mini-stat__title">{"BLANK"}</div>
                  <div className="mini-stat__value">
                    {0}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AverageExportAge;