import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import ModalUser from 'components/site/admin/modals/ModalUser';
import User from "./../../../../api/users";
import { isEmpty, getOr } from "lodash/fp";

const UserTableHeader = ({ selectedRowIds, getUsers, dashboards, data, selectedUser, showModal, closeModal, getDashboards, title, type, extraType }) => {

  const [optionSelected, setOptionSelected] = useState('');
  
  const handleChange = (e) => {
    setOptionSelected(e.target.value)
  }

  const bulkOperate = e => {
    e.preventDefault();
    let formData = {}
    formData["action"] = optionSelected

    let keys = Object.keys(selectedRowIds);

    let ids = []

    for (let i = 0; i < keys.length; i++){
      ids.push(data[parseInt(keys[i])]["id"])
    }
    formData["ids"] = ids
    User.bulkOperate({ user: formData }).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        getUsers()
      }
    })
  }

  return (
    <Row className="flex-between-center">
      <Col xs={6} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="settings__title">{title ? title : 'Users'}</h5>
      </Col>
      <Col xs={6} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select size="sm" aria-label="Bulk actions" onChange={handleChange}>
              <option>Bulk Actions</option>
              <option value="active">Access On</option>
              <option value="inactive">Access Off</option>
              <option value="delete">Delete</option>
            </Form.Select>
            <Button
              type="button"
              variant="primary"
              size="sm"
              className="ms-2"
              onClick={bulkOperate}
            >
              Apply
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
            <ModalUser getUsers={getUsers} dashboards={dashboards} selectedUser={selectedUser} showModal={showModal} closeModal={closeModal} getDashboards={getDashboards} type={type} extraType={extraType} />
          </div>
        )}
      </Col>
    </Row>
  );
};

UserTableHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default UserTableHeader;
