import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from 'assets/img/spearpoint-logo_1A.png';

const LogoutContent = ({ layout, titleTag: TitleTag }) => {
  return (
    <>
      <div className="access__logo">
        <img className="me-2" src={logo} alt="Logo" />
      </div>
      <h3>See you again!</h3>
      <p>You are now successfully signed out.</p>
      <p>
        Thank you for being a part of the Spearpoint vision to enhance Tribal communities by empowering healthcare providers like you.
      </p>
      <div className="access-forgot-actions mt-4">
        <Link className="fs--1 text-600" to="/login">
          Return to Login
        </Link>
        <span>|</span>
        <Link className="fs--1 text-600" to="/">
          Back to Home
        </Link>
      </div>
    </>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

LogoutContent.defaultProps = {
  layout: 'simple',
  titleTag: 'h4'
};

export default LogoutContent;
