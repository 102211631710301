import React, { useState, useEffect, useContext } from 'react';
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DistributionChart from "./charts/distribution-chart";
import InjuryChart from "./charts/injury-type-chart";
import Chart from "./charts/other-chart";
import Dashboard from "./../../../../../../api/dashboards";
import { isEmpty, getOr } from "lodash/fp";
import { colorScheme } from "helpers/colors";
import { DashContext } from 'context/Context';

const TOP7DentalApptTypes = () => {
  const [data, setData] = useState({})
  const [opt, setOpt] = useState("")
  const [fiscalYears, setFiscalYears] = useState([])
  const [dentalAppointmentsType, setDentalAppointmentsType] = useState([])
  const [dentalAppointmentsTypeByYear, setDentalAppointmentsTypeByYear] = useState([])
  const [dentalAppointmentsTypeByYearAndOption, setDentalAppointmentsTypeByYearAndOption] = useState([])
  const [chartDentalAppointmentsTypeByYearAndOption, setChartDentalAppointmentsTypeByYearAndOption] = useState([])
  const [totalByDentalAppointmentsType, setTotalByDentalAppointmentsType] = useState(0)
  const [pieSelect, setPieSelect] = useState(false)
  const [stackingOption, setStackingOption] = useState([])
  const [charts, setCharts] = useState([])
  const {Updatefilters} = useContext(DashContext)

  const [chartNo, setChartNo] = useState(1)
  const [looping, setLooping] = useState(false)

  const increaseChartNo = (num) => {
    setLooping(true)
    if (num !== 3){
      setChartNo(num + 1)
    }
    else{
      setChartNo(1)
    }
  }

  const decreaseChartNo = (num) => {
    setLooping(true)
    if (num !== 1){
      setChartNo(num - 1)
    }
    else{
      setChartNo(3)
    }
  }

  const cancelLooping = () => {
    setChartNo(1)
    setLooping(false)
  }


  useEffect(() => {
    getChartInfo()
    getChartsData(opt, true)
  }, []);

  const getChart = (slug) => {
    if (!isEmpty(charts)) {
      if (charts.filter(obj => obj["slug"] === slug)) {
        return charts.filter(obj => obj["slug"] === slug)[0]
      }
    }
  }

  const getChartInfo = () => {
    let slug = window.location.href.split("/")[4]
    let dashboardName = localStorage.getItem('dashboardName')
    Dashboard.getChartsInfoBySlugAndTitle(dashboardName, slug).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setCharts(response.data)
      }
    });
  };

  useEffect(() => {
    if (opt === "") {
      getChartsData(opt, true)
    }
    else {
      getChartsData(opt, false)
    }
  }, [opt]);

  function hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;

    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;

    return s;
  }

  function color(r, g, b) {
    return "#" + hex2(r) + hex2(g) + hex2(b);
  }

  function shade(col, light) {

    // TODO: Assert that col is good and that -1 < light < 1

    var r = parseInt(col.substr(1, 2), 16);
    var g = parseInt(col.substr(3, 2), 16);
    var b = parseInt(col.substr(5, 2), 16);

    if (light < 0) {
      r = (1 + light) * r;
      g = (1 + light) * g;
      b = (1 + light) * b;
    } else {
      r = (1 - light) * r + light * 255;
      g = (1 - light) * g + light * 255;
      b = (1 - light) * b + light * 255;
    }

    return color(r, g, b);
  }


  const getChartsData = (option, pageLoad) => {
    let dashboardId = localStorage.getItem('dashboardId')
    Dashboard.getDentalAppointmentsType(dashboardId, option, pageLoad).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setData(response.data)
        setDentalAppointmentsType(getOr([], 'data.type_of_dental_appointments', response).sort((a, b) => a.localeCompare(b)))
        setFiscalYears(getOr([], 'data.fiscal_years', response))
        setDentalAppointmentsTypeByYear(getOr([], 'data.stats_by_year', response).map(a => a.total_dental_appointment_types))
        setDentalAppointmentsTypeByYearAndOption(getOr([], 'data.stats_by_year_and_option', response).map(a => a.total_dental_appointment_types))
        let tByDAT = getOr(0, 'data.total_by_dental_appointment_types', response)
        setTotalByDentalAppointmentsType(tByDAT)
        let statsByDentalAppointmentsType = getOr([], 'data.stats_by_dental_appointments_type', response)
        if (!isEmpty(statsByDentalAppointmentsType)) {
          let statsByDentalAppointmentsTypeArray = []
          for (let j = 0; j < statsByDentalAppointmentsType.length; j++) {
            let obj = {}
            obj["name"] = statsByDentalAppointmentsType[j].appointment_type
            if (!isEmpty(opt)) {
              if (opt !== statsByDentalAppointmentsType[j].appointment_type) {
                obj["color"] = shade(colorScheme[j], 0.731)
              }
              else {
                obj["color"] = colorScheme[j]
              }
            }
            else {
              obj["color"] = colorScheme[j]
            }
            obj["y"] = Math.round(((statsByDentalAppointmentsType[j].total_dental_appointment_types / tByDAT) * 100) * 10) / 10
            if (statsByDentalAppointmentsType[j].total_dental_appointment_types > 1000) {
              obj["x"] = (Math.round(((statsByDentalAppointmentsType[j].total_dental_appointment_types) / 1000) * 10) / 10) + "K"
              obj["a"] = (Math.round(((statsByDentalAppointmentsType[j].total_dental_appointment_types) / 1) * 1) / 1).toLocaleString()
            }
            else {
              obj["x"] = Math.round(((statsByDentalAppointmentsType[j].total_dental_appointment_types) / 1) * 1) / 1
              obj["a"] = (Math.round(((statsByDentalAppointmentsType[j].total_dental_appointment_types) / 1) * 1) / 1).toLocaleString()
            }
            statsByDentalAppointmentsTypeArray.push(obj)
          }
          statsByDentalAppointmentsTypeArray = statsByDentalAppointmentsTypeArray.sort((a, b) => a.name.localeCompare(b.name))

          
           statsByDentalAppointmentsTypeArray.map((obj, i) => {
             obj["color"] = colorScheme[i]
           });

          setChartDentalAppointmentsTypeByYearAndOption(statsByDentalAppointmentsTypeArray)
        }
      }
    });
  };

  const chartColorData = chartDentalAppointmentsTypeByYearAndOption;

  useEffect(() => {
    const listenStorageChange = () => {
      const option = localStorage.getItem('option')
      const temp = chartColorData
      const optionSelected = temp.filter(obj => obj["name"] === option)[0]
      if (option === '') {
        setOpt(option)
        setStackingOption([])
        setPieSelect(false)
      }
      else {
        setOpt(option)
        if (!isEmpty(optionSelected)) {
          setStackingOption(optionSelected)
        }
        setPieSelect(true)
      }
    };
    window.addEventListener("storage", listenStorageChange);
    return () => window.removeEventListener("storage", listenStorageChange);
  }, [chartDentalAppointmentsTypeByYearAndOption]);

  const getSmallestValue = (array) => {
    return Math.min(...array);
  }

  const differenceArray = (array) => {
    let arr = array.slice(1).map((v, i) => v - array[i])
    arr.unshift(0)
    return arr
  }

  const selectOption = (option) => {
    setOpt(option.name)
    setStackingOption(option)
    setPieSelect(true)
    localStorage.setItem('option', option.name)
    window.dispatchEvent(new Event("storage"));
  }


  useEffect(() => {
    Updatefilters(dentalAppointmentsType);
  }, [dentalAppointmentsType]);

  return (
    <>
    {!isEmpty(data) && !isEmpty(charts) ?
      <div className="container px-0">
        <>
          <div className="container px-0">
            <>
              <div className="row">
                <Col lg={12} xl={6} className="chart-col">
                  <Chart
                    fiscalYears={fiscalYears}
                    data={dentalAppointmentsTypeByYear}
                    description={getChart('total-for-ambulatory-care-visits-by-fiscal-year')["description"]}
                    title={getChart('total-for-ambulatory-care-visits-by-fiscal-year')["name"]}
                    colorHex={"#2A8B8C"}
                    min={getSmallestValue(dentalAppointmentsTypeByYear)}
                    differenceArray={differenceArray(dentalAppointmentsTypeByYear)}
                    increaseChartNo={increaseChartNo}
                    decreaseChartNo={decreaseChartNo}
                    cancelLooping={cancelLooping}
                    chartNo={chartNo}
                    looping={looping}
                    currentNo={1}
                  />
                </Col>
                <Col lg={12} xl={6} className="chart-col">
                  <Chart
                    fiscalYears={fiscalYears}
                    data={dentalAppointmentsTypeByYearAndOption}
                    description={getChart('dental-appointment-types')["description"]}
                    title={getChart('dental-appointment-types')["name"]}
                    colorHex={"#733837"}
                    min={getSmallestValue(dentalAppointmentsTypeByYearAndOption)}
                    differenceArray={differenceArray(dentalAppointmentsTypeByYearAndOption)}
                    stacking={pieSelect === true ? 'normal': ''}
                    totalData={dentalAppointmentsTypeByYear}
                    lightColor={shade("#733837", 0.731)}
                    stackingColor={pieSelect === true ? stackingOption.color : '#fff'}
                    increaseChartNo={increaseChartNo}
                    decreaseChartNo={decreaseChartNo}
                    cancelLooping={cancelLooping}
                    chartNo={chartNo}
                    looping={looping}
                    currentNo={2}
                  />
                </Col>
              </div>
              <div className="row">
                <Col lg={12} xl={12} xxl={12} className="chart-col">
                  <DistributionChart
                    type="Type"
                    total={totalByDentalAppointmentsType}
                    typeData={chartDentalAppointmentsTypeByYearAndOption}
                    description={getChart('distribution-of-ambulatory-care-visits-by-type-and-top-7-dental-appointment-types')["description"]}
                    title={getChart('distribution-of-ambulatory-care-visits-by-type-and-top-7-dental-appointment-types')["name"]}
                    showInLegendEnable={false}
                    selectOption={selectOption}
                    chartColorData={chartColorData}
                    increaseChartNo={increaseChartNo}
                    decreaseChartNo={decreaseChartNo}
                    cancelLooping={cancelLooping}
                    chartNo={chartNo}
                    looping={looping}
                    currentNo={3}
                  />
                </Col>
              </div>
            </>
          </div>
        </>
      </div>
      : null}
    </>
  );
};

export default TOP7DentalApptTypes;
