import React, { useState, useEffect } from 'react';
import NavbarAdmin from './../admin/NavbarAdmin';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import Dashboard from "./../../../api/dashboards";
import SignOut from "./../../../api/auth/sign_out";
import { isEmpty, getOr } from "lodash/fp";
import Defender from 'helpers/defender';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const UserDashboard = ({ }) => {
	const navigate = useNavigate();
	const [dashboard, setDashboard] = useState({});

	const getDashboard = (id) => {
		Dashboard.get(id).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				setDashboard(response.data.dashboard)
			}
		})
	}

	useEffect(() => {
		if (!isEmpty(Defender.currentUser()) && (getOr([], "_r", Defender.currentUser()).includes("executive") || getOr([], "_r", Defender.currentUser()).includes("management"))) {
			getDashboard(Defender.currentUser().dashboard_id)
		}
		else {
			SignOut.destroy().then((response) => {
				if (
					(getOr("", "status", response) === 200 ||
						getOr("", "status", response) === 201) &&
					isEmpty(getOr({}, "data.errors", response))
				) {
          localStorage.removeItem('dashboardName')
          localStorage.removeItem('dashboardId')
          localStorage.removeItem('dashboardSubPage')
          localStorage.removeItem('dashboardCsvUploaded')
          localStorage.removeItem('dashboardStartDate')
          localStorage.removeItem('dashboardEndDate')
					localStorage.removeItem('dashboardStatic')
					localStorage.removeItem('option')
					Defender.logout();
					toast.error(`Signed out`, {
						theme: 'colored'
					});
					navigate("/logout");
				}
			})
		}
	}, []);

	return (
		<>
			<NavbarAdmin />
			<div className="content">
				<section className="py-0">
					<div className="container">
						{!isEmpty(dashboard) ?
							<PowerBIEmbed
								embedConfig={{
									type: 'report',   // Supported types: report, dashboard, tile, visual and qna
									id: getOr('', 'report_id', dashboard),
									embedUrl: getOr('', 'embed_code', dashboard),
									accessToken: getOr('', 'access_token', dashboard),
									tokenType: models.TokenType.Embed,
									settings: {
										panes: {
											filters: {
												expanded: false,
												visible: true
											}
										},
									}
								}}

								eventHandlers={
									new Map([
										['loaded', function () { console.log('Report loaded'); }],
										['rendered', function () { console.log('Report rendered'); }],
										['error', function (event) { console.log(event.detail); }]
									])
								}

								cssClassName={"embed-container"}

								getEmbeddedComponent={(embeddedReport) => {
									window.report = embeddedReport;
								}}
							/> : null}
					</div>
				</section>
			</div>
		</>
	);
};

export default UserDashboard;