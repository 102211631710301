import bg1 from 'assets/img/sp-images/widget-bg-1.png';
import bg2 from 'assets/img/sp-images/widget-bg-2.png';
import bg3 from 'assets/img/sp-images/widget-bg-3.png';

export const activeUser = [3, 7, 6, 8, 5, 12, 11];

export const statsData = [
  {
    title: 'Organizations',
    value: 12,
    decimal: false,
    suffix: '',
    prefix: '',
    valueClassName: 'text--primary',
    badgeBg: 'warning',
    badgeText: '',
    image: bg1
  },
  {
    title: 'Users',
    value: 175,
    decimal: false,
    suffix: '',
    prefix: '',
    valueClassName: 'text--primary',
    badgeBg: 'info',
    badgeText: '',
    image: bg2
  },
  {
    title: 'Total Subscriptions',
    value: 43594,
    decimal: true,
    suffix: '',
    prefix: '$',
    valueClassName: 'text--primary',
    badgeBg: 'success',
    badgeText: '',
    image: bg3
  }
];