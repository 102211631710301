import React, { useState, useEffect, useRef } from 'react';
import Dashboard from "./../../../../api/dashboards";
import User from "./../../../../api/users";
import AvatarImage from 'assets/img/sp-images/default-avatar-sm.jpg';
import Defender from "./../../../../helpers/defender";
import SignOut from "./../../../../api/auth/sign_out";
import UserTable from './../user-table/UserTable';
import { isEmpty, getOr } from "lodash/fp";
import { toast } from 'react-toastify';
import AvatarEditor from 'react-avatar-editor'
import { useNavigate } from "react-router-dom";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const UserSettings = ({ }) => {
	const navigate = useNavigate();
	const [showRemove, setShowRemove] = useState(false);
	const [zoom, setZoom] = useState(1.2);
	const [avatar, setAvatar] = useState(null);
	const [imgSrc, setImgSrc] = useState(null);
	const [users, setUsers] = useState([]);
	const [dashboards, setDashboards] = useState([]);
	const [userStatusList, setUserStatusList] = useState([]);
	const editor = useRef(null);
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		password: '',
		newPassword: '',
		confirmPassword: ''
	});

	const handleSlider = (event) => {
		setZoom(event)
	};

	const getDashboards = (id) => {
		Dashboard.getAll(id).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				setDashboards(getOr([], 'data', response))
			}
		})
	}

	const getUsers = (id) => {
		User.getOrganizationUsers(id ? id : getOr(0, "organization_id", Defender.currentUser())).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				let userList = getOr([], 'data.users', response)
				userList = userList.filter(user => parseInt(user.id) !== Defender.currentUser().id)
				let statusListArray = []
				setUsers(userList)
				for (let i = 0; i < userList.length; i++) {
					let obj = {}
					obj["id"] = userList[i]["id"]
					obj["status"] = userList[i]["status"]
					statusListArray.push(obj)
				}
				setUserStatusList(statusListArray)
			}
		})
	}


	useEffect(() => {
		if (!isEmpty(Defender.currentUser())) {
			let user = Defender.currentUser();
			User.fetch(getOr(0, 'id', user)).then((response) => {
				if (
					(getOr("", "status", response) === 200 ||
						getOr("", "status", response) === 204) &&
					isEmpty(getOr({}, "data.errors", response))) {
					Defender.setCurrentUser(getOr({}, 'data.user', response))
					setShowRemove(false)
					if (getOr([], "_r", Defender.currentUser()).includes("executive")) {
						getDashboards(getOr(0, "organization_id", Defender.currentUser()))
						getUsers(getOr(0, "organization_id", Defender.currentUser()))
					}
					if (!isEmpty(getOr('', 'main_image_link', user))) {
						setShowRemove(true)
					}
					setImgSrc(null)
					setAvatar(null)
					setFormData({
						...formData,
						firstName: '',
						lastName: '',
						phone: '',
						email: '',
					});
					setFormData({
						...formData,
						firstName: getOr('', 'first_name', user),
						lastName: getOr('', 'last_name', user),
						phone: getOr('', 'phone', user),
						email: getOr('', 'email', user),
					});
					setImgSrc(!isEmpty(getOr('', 'main_image_link', user)) ? getOr('', 'main_image_link', user) : AvatarImage)
				}
			})
		}
		else {
			SignOut.destroy().then((response) => {
				if (
					(getOr("", "status", response) === 200 ||
						getOr("", "status", response) === 201) &&
					isEmpty(getOr({}, "data.errors", response))
				) {
					localStorage.removeItem('dashboardName')
					localStorage.removeItem('dashboardId')
					localStorage.removeItem('dashboardSubPage')
					localStorage.removeItem('dashboardCsvUploaded')
					localStorage.removeItem('dashboardStartDate')
					localStorage.removeItem('dashboardEndDate')
					localStorage.removeItem('dashboardStatic')
					localStorage.removeItem('option')
					Defender.logout();
					toast.error(`Signed out`, {
						theme: 'colored'
					});
					navigate("/");
				}
			})
		}
	}, []);

	const b64toBlob = (b64Data, contentType, sliceSize) => {
		contentType = contentType || "";
		sliceSize = sliceSize || 512;

		var byteCharacters = atob(b64Data);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	const handleSubmit = e => {
		e.preventDefault();
		if (formData.newPassword === formData.confirmPassword) {
			let formDataUser = {}
			formDataUser["first_name"] = formData.firstName
			formDataUser["last_name"] = formData.lastName
			formDataUser["phone"] = formData.phone
			formDataUser["email"] = formData.email
			formDataUser["password"] = formData.password
			formDataUser["new_password"] = formData.newPassword
			formDataUser["confirm_password"] = formData.confirmPassword
			User.update({ user: formDataUser }).then((response) => {
				if (
					(getOr("", "status", response) === 200 ||
						getOr("", "status", response) === 204) &&
					isEmpty(getOr({}, "data.errors", response))
				) {
					Defender.setCurrentUser(response.data.user)
					if (!isEmpty(editor) && avatar !== null) {
						let block = editor.current.getImageScaledToCanvas().toDataURL().split(";");
						let contentType = block[0].split(":")[1];
						let realData = block[1].split(",")[1];
						let blob = b64toBlob(realData, contentType);
						let imageFormData = new FormData();
						imageFormData.append("main_image", blob);
						User.updateImage(imageFormData, 0).then((response) => {
							Defender.setCurrentUser(response.data.user)
							toast.success(`User settings updated`, {
								theme: 'colored'
							});
							setFormData({
								...formData,
								firstName: '',
								lastName: '',
								phone: '',
								email: '',
							});
							if (getOr([], "_r", Defender.currentUser()).includes("admin")) {
								navigate("/admin");
							}
							else if (getOr([], "_r", Defender.currentUser()).includes("executive") || getOr([], "_r", Defender.currentUser()).includes("management")) {
								navigate("/dash");
							}
							else {
								navigate("/");
							}
						})
					}
					else {
						toast.success(`User settings updated`, {
							theme: 'colored'
						});
						setFormData({
							...formData,
							firstName: '',
							lastName: '',
							phone: '',
							email: '',
						});
						if (getOr([], "_r", Defender.currentUser()).includes("admin")) {
							navigate("/admin");
						}
						else if (getOr([], "_r", Defender.currentUser()).includes("executive") || getOr([], "_r", Defender.currentUser()).includes("management")) {
							navigate("/dash");
						}
						else {
							navigate("/");
						}
					}
				}
				else {
					toast.error(getOr("", "response.data.message", response), {
						theme: 'colored'
					});
				}
			})
		}
		else {
			toast.error(`Password and Confirm Password do not match`, {
				theme: 'colored'
			});
		}
	}

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const fileUpload = (e) => {
		e.preventDefault()
		document.getElementById('fileid').click();
	}

	const fileUploaded = (e) => {
		setShowRemove(true)
		setImgSrc(URL.createObjectURL(e.target.files[0]))
		setAvatar(e.target.files[0])
		toast.success(`File Uploaded Successfully`, {
			theme: 'colored'
		});
	}

	const removeAvatar = (e) => {
		e.preventDefault();
		User.removeImage(0).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				Defender.setCurrentUser(response.data.user)
				toast.success(`User profile image removed`, {
					theme: 'colored'
				});
				setImgSrc(AvatarImage)
				setAvatar(null)
				setShowRemove(false)
			}
		})
	}

	const removeUser = (id, e) => {
		e.preventDefault();
		User.remove(id).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				toast.success(`User removed`, {
					theme: 'colored'
				});
				getUsers(getOr('', "organization_id", Defender.currentUser()))
			}
		})
	}

	const removeUserOrganization = (e) => {
		if (e) {
			e.preventDefault();
			User.removeUserOrganization().then((response) => {
				if (
					(getOr("", "status", response) === 200 ||
						getOr("", "status", response) === 204) &&
					isEmpty(getOr({}, "data.errors", response))
				) {
					Defender.setCurrentUser(response.data.user)
					toast.success(`Organization successfully left`, {
						theme: 'colored'
					});
					setModalShow(false)
					if (getOr([], "_r", Defender.currentUser()).includes("executive") || getOr([], "_r", Defender.currentUser()).includes("management")) {
						navigate("/empty-dash");
					}
				}
			})
		}
	}

	const updateUserStatus = (id, status, e) => {
		e.preventDefault();
		let data = {}
		data["status"] = status
		User.updateUser({ user: data }, id).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				toast.success(`User status updated`, {
					theme: 'colored'
				});
				const newStatusList = [...userStatusList];
				let statusIndex = newStatusList.findIndex(x => x["id"] === id)
				newStatusList[statusIndex]["status"] = newStatusList[statusIndex]["status"] === true ? false : true
				setUserStatusList(newStatusList);
			}
		})
	}

	const [modalShow, setModalShow] = useState(false);

	const LeaveOrganizationModal = () => {
		return (
			<>
				<Modal
					show={modalShow}
					onHide={() => setModalShow(false)}
					size="md"
					aria-labelledby="remove-modal"
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>Leave Organization</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{(getOr([], "_r", Defender.currentUser()).includes("executive") ||
							getOr([], "_r", Defender.currentUser()).includes("management")) ?
							<div>You are choosing to leave this organization. This action can not be undone and in order to be added back, you will need to contact an administrator from your organization</div>
							: <div>Are you sure you want to leave your current organization <strong>{getOr('', 'organization.name', Defender.currentUser())}</strong>?</div>
						}
					</Modal.Body>
					<Modal.Footer>
						{(getOr([], "_r", Defender.currentUser()).includes("executive") ||
							getOr([], "_r", Defender.currentUser()).includes("management")) ?
							<Button onClick={(e) => {
								removeUserOrganization(removeUserOrganization(e))
							}
							} variant="btn-danger" className="btn-danger">Yes, Leave Organization</Button>
							:
							<Button onClick={(e) => {
								removeUserOrganization(removeUserOrganization(e))
							}
							} variant="btn-danger" className="btn-danger">Leave</Button>
						}
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	const handleRedirection = () => {
		if (getOr([], "_r", Defender.currentUser()).includes("admin") || getOr([], "_r", Defender.currentUser()).includes("sub_admin")) {
			navigate("/admin")
		}
		else if (getOr([], "_r", Defender.currentUser()).includes("executive") || getOr([], "_r", Defender.currentUser()).includes("management")) {
			navigate("/dash")
		}
	}

	return (
		<>
			<LeaveOrganizationModal />
			<div className="content">
				<section className="py-0">
					<div className="admin__org-header modal-header mb-3">
						<div className="modal-title w700 h4">Settings</div>
						<div className="form-actions">
							<a onClick={handleRedirection} className="btn btn-falcon-default">
								Cancel
							</a>
							<a onClick={handleSubmit} className="btn btn-primary">
								Save & Close
							</a>
						</div>
					</div>
					<div className="container container--800">
						<div className="card card-settings mb-3">
							<div className="card-header">
								<h5 className="settings__title">Profile</h5>
							</div>
							<div className="card-body">
								<div className="settings-list">
									<div className="row">
										<div className="col-12">
											<div className="modal-form__avatar">
												{avatar !== null ?
													<>
														<AvatarEditor
															ref={editor}
															image={imgSrc}
															width={250}
															height={250}
															border={50}
															color={[255, 255, 255, 0.6]} // RGBA
															scale={zoom}
															rotate={0}
														/>
													</>
													: <img src={imgSrc} />
												}
												<div className="modal-form__avatar-actions">
													<input id='fileid' type='file' hidden onChange={fileUploaded} accept="image/x-png,image/gif,image/jpeg" />
													<button className="btn btn-falcon-default btn-sm" onClick={fileUpload}>
														Add Avatar
													</button>
													<button className="btn btn-default btn-sm" onClick={removeAvatar}>
														Remove Avatar
													</button>
													{avatar !== null ?
														<Slider min={1}
															max={10}
															value={zoom}
															onChange={handleSlider}
															step={0.1} />
														: null}
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="form__item">
												<label className="form-label">First Name</label>
												<input className="form-control" type="text" name="firstName" onChange={handleFieldChange} value={formData.firstName} />
											</div>
										</div>
										<div className="col-sm-6">
											<div className="form__item">
												<label className="form-label">Last Name</label>
												<input className="form-control" type="text" name="lastName" onChange={handleFieldChange} value={formData.lastName} />
											</div>
										</div>
										<div className="col-sm-6">
											<div className="form__item">
												<label className="form-label">Email</label>
												<input className="form-control" type="text" placeholder="you@email.com" name="email" onChange={handleFieldChange} value={formData.email} />
											</div>
										</div>
										<div className="col-sm-6">
											<div className="form__item">
												<label className="form-label">Phone</label>
												<input className="form-control" type="text" placeholder="(000) 000-0000" name="phone" onChange={handleFieldChange} value={formData.phone} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card card-settings mb-3">
							<div className="card-header">
								<h5 className="settings__title">Security</h5>
							</div>
							<div className="card-body">
								<div className="settings-list">
									<div className="row">
										<div className="col-sm-6">
											<div className="form__item">
												<label className="form-label">Current Password</label>
												<input className="form-control" type="password" placeholder="••••••••" name="password" onChange={handleFieldChange} />
											</div>
											<div className="form__item">
												<label className="form-label">New Password</label>
												<input className="form-control" type="password" placeholder="••••••••" name="newPassword" onChange={handleFieldChange} />
											</div>
											<div className="form__item">
												<label className="form-label">Confirm Password</label>
												<input className="form-control" type="password" placeholder="••••••••" name="confirmPassword" onChange={handleFieldChange} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{getOr([], "_r", Defender.currentUser()).includes("executive") ?
							<div className="org-table">
								<UserTable users={users} getUsers={getUsers} dashboards={dashboards} removeUser={removeUser} updateUserStatus={updateUserStatus} userStatusList={userStatusList} getDashboards={getDashboards} />
							</div>
							: null}
						{(getOr([], "_r", Defender.currentUser()).includes("executive") || getOr([], "_r", Defender.currentUser()).includes("management")) ?
							<>
								{!isEmpty(getOr('', 'organization.name', Defender.currentUser())) ?
									<div className="card card-settings mb-3">
										<div className="card-header">
											<h5 className="settings__title">Assigned Organization</h5>
										</div>
										<div className="card-body">
											<div className='mb-3'>{getOr('', 'organization.name', Defender.currentUser())}</div>
											<Link to="#" onClick={() => {
												setModalShow(true)
											}}>Leave Organization</Link>
										</div>
									</div>
									: <div className="card card-settings mb-3">
										<div className="card-header">
											<h5 className="settings__title">Assigned Organization</h5>
										</div>
										<div className="card-body">
											{!isEmpty(getOr('', 'organization.name', Defender.currentUser())) ?
												<div className='mb-3'>{getOr('', 'organization.name', Defender.currentUser())}</div>
												: <div className='mb-3'>{'None'}</div>
											}
										</div>
									</div>}
							</>
							: null}
					</div>
				</section>
			</div>
		</>
	);
};

export default UserSettings;