import React, { useState, useEffect, useContext } from 'react';
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DistributionChart from "./charts/distribution-chart/";
import InjuryChart from "./charts/injury-type-chart";
import Chart from "./charts/other-chart/";
import Dashboard from "./../../../../../../api/dashboards";
import { isEmpty, getOr } from "lodash/fp";
import { colorScheme } from "helpers/colors";
import { DashContext } from 'context/Context';

const TOP5InjuriesTypes = () => {

  const [data, setData] = useState({})
  const [opt, setOpt] = useState("")
  const [fiscalYears, setFiscalYears] = useState([])
  const [injuriesType, setInjuriesType] = useState([])
  const [injuriesTypeByYear, setInjuriesTypeByYear] = useState([])
  const [injuriesTypeByYearAndOption, setInjuriesTypeByYearAndOption] = useState([])
  const [initialInjuriesTypeByYearAndOption, setInitialInjuriesTypeByYearAndOption] = useState([])
  const [chartInuriesTypeByYearAndOption, setChartInuriesTypeByYearAndOption] = useState([])
  const [totalByInjuriesType, setTotalByInjuriesType] = useState(0)
  const [stackingOption, setStackingOption] = useState([])
  const [pieSelect, setPieSelect] = useState(false)

  const [charts, setCharts] = useState([])
  const {Updatefilters} = useContext(DashContext    )

  const [chartNo, setChartNo] = useState(1)
  const [looping, setLooping] = useState(false)

  const increaseChartNo = (num) => {
    setLooping(true)
    if (num !== 3){
      setChartNo(num + 1)
    }
    else{
      setChartNo(1)
    }
  }

  const decreaseChartNo = (num) => {
    setLooping(true)
    if (num !== 1){
      setChartNo(num - 1)
    }
    else{
      setChartNo(3)
    }
  }

  const cancelLooping = () => {
    setChartNo(1)
    setLooping(false)
  }


  useEffect(() => {
    getChartInfo()
    getChartsData(opt, true)
  }, []);

  const getChart = (slug) => {
    if (!isEmpty(charts)) {
      if (charts.filter(obj => obj["slug"] === slug)) {
        return charts.filter(obj => obj["slug"] === slug)[0]
      }
    }
  }

  const getChartInfo = () => {
    let slug = window.location.href.split("/")[4]
    let dashboardName = localStorage.getItem('dashboardName')
    Dashboard.getChartsInfoBySlugAndTitle(dashboardName, slug).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setCharts(response.data)
      }
    });
  };

  useEffect(() => {
    if (opt === "") {
      getChartsData(opt, true)
    }
    else {
      getChartsData(opt, false)
    }
  }, [opt]);

  function hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;

    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;

    return s;
  }

  function color(r, g, b) {
    return "#" + hex2(r) + hex2(g) + hex2(b);
  }

  function shade(col, light) {

    // TODO: Assert that col is good and that -1 < light < 1

    var r = parseInt(col.substr(1, 2), 16);
    var g = parseInt(col.substr(3, 2), 16);
    var b = parseInt(col.substr(5, 2), 16);

    if (light < 0) {
      r = (1 + light) * r;
      g = (1 + light) * g;
      b = (1 + light) * b;
    } else {
      r = (1 - light) * r + light * 255;
      g = (1 - light) * g + light * 255;
      b = (1 - light) * b + light * 255;
    }

    return color(r, g, b);
  }

  const getChartsData = (option, pageLoad) => {
    let dashboardId = localStorage.getItem('dashboardId')
    Dashboard.getInjuriesType(dashboardId, option, pageLoad).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setData(response.data)
        if(isEmpty(option)){
          setInitialInjuriesTypeByYearAndOption(getOr([], 'data.stats_by_year_and_option', response).map(a => a.total_injuries_type))
        }
        setInjuriesType(getOr([], 'data.type_of_injuries', response).sort((a, b) => a.localeCompare(b)))
        setFiscalYears(getOr([], 'data.fiscal_years', response))
        setInjuriesTypeByYear(getOr([], 'data.stats_by_year', response).map(a => a.total_injuries_type))
        setInjuriesTypeByYearAndOption(getOr([], 'data.stats_by_year_and_option', response).map(a => a.total_injuries_type))
        let tByIT = getOr(0, 'data.total_by_injuries_type', response)
        setTotalByInjuriesType(tByIT)
        let statsByInjuriesType = getOr([], 'data.stats_by_injuries_type', response)
        if (!isEmpty(statsByInjuriesType)) {
          let statsByInjuriesTypeArray = []
          for (let j = 0; j < statsByInjuriesType.length; j++) {
            let obj = {}
            obj["name"] = statsByInjuriesType[j].injury_type
            if (!isEmpty(opt)) {
              if (opt !== statsByInjuriesType[j].injury_type) {
                obj["color"] = shade(colorScheme[j], 0.731)
              }
              else {
                obj["color"] = colorScheme[j]
              }
            }
            else {
              obj["color"] = colorScheme[j]
            }
            obj["y"] = Math.round(((statsByInjuriesType[j].total_injuries_type / tByIT) * 100) * 10) / 10
            if (statsByInjuriesType[j].total_injuries_type > 1000) {
              obj["x"] = (Math.round(((statsByInjuriesType[j].total_injuries_type) / 1000) * 10) / 10) + "K"
              obj["a"] = (Math.round(((statsByInjuriesType[j].total_injuries_type) / 1) * 1) / 1).toLocaleString()
            }
            else {
              obj["x"] = Math.round(((statsByInjuriesType[j].total_injuries_type) / 1) * 1) / 1
              obj["a"] = (Math.round(((statsByInjuriesType[j].total_injuries_type) / 1) * 1) / 1).toLocaleString()
            }
            statsByInjuriesTypeArray.push(obj)
          }
          statsByInjuriesTypeArray = statsByInjuriesTypeArray.sort((a, b) => a.name.localeCompare(b.name))
          
          statsByInjuriesTypeArray.map((obj, i) => {
             obj["color"] = colorScheme[i]
           });
          
          setChartInuriesTypeByYearAndOption(statsByInjuriesTypeArray)
        }
      }
    });
  };

  const chartColorData = chartInuriesTypeByYearAndOption;

  useEffect(() => {
    const listenStorageChange = () => {
      const option = localStorage.getItem('option')
      const temp = chartColorData
      const optionSelected = temp.filter(obj => obj["name"] === option)[0]
      if (option === '') {
        setOpt(option)
        setStackingOption([])
        setPieSelect(false)
      }
      else {
        setOpt(option)
        if (!isEmpty(optionSelected)) {
          setStackingOption(optionSelected)
        }
        setPieSelect(true)
      }
    };
    window.addEventListener("storage", listenStorageChange);
    return () => window.removeEventListener("storage", listenStorageChange);
  }, [chartInuriesTypeByYearAndOption]);

  const getSmallestValue = (array) => {
    return Math.min(...array);
  }

  const differenceArray = (array) => {
    let arr = array.slice(1).map((v, i) => v - array[i])
    arr.unshift(0)
    return arr
  }

  const selectOption = (option) => {
    setOpt(option.name)
    setStackingOption(option)
    setPieSelect(true)
    localStorage.setItem('option', option.name)
    window.dispatchEvent(new Event("storage"));
  }
 
  useEffect(() => {
    Updatefilters(injuriesType);
  }, [injuriesType]);

  return (
    <>
    {!isEmpty(data) && !isEmpty(charts) ?
      <div className="container px-0">
        <>
          <div className="row">
            <Col lg={12} xl={6} className="chart-col">
              <Chart
                fiscalYears={fiscalYears}
                data={injuriesTypeByYear}
                description={getChart('total-for-ambulatory-care-visits-by-top-5-injuries-types')["description"]}
                title={getChart('total-for-ambulatory-care-visits-by-top-5-injuries-types')["name"]}
                colorHex={"#2A8B8C"}
                min={getSmallestValue(injuriesTypeByYear)}
                differenceArray={differenceArray(injuriesTypeByYear)}
                increaseChartNo={increaseChartNo}
                decreaseChartNo={decreaseChartNo}
                cancelLooping={cancelLooping}
                chartNo={chartNo}
                looping={looping}
                currentNo={1}
              />
            </Col>
            <Col lg={12} xl={6} className="chart-col">
              <InjuryChart
                fiscalYears={fiscalYears}
                data={injuriesTypeByYearAndOption}
                description={getChart('injury-types-by-fiscal-year')["description"]}
                title={getChart('injury-types-by-fiscal-year')["name"]}
                colorHex={"#733837"}
                min={getSmallestValue(injuriesTypeByYearAndOption)}
                differenceArray={differenceArray(injuriesTypeByYearAndOption)}
                stacking={pieSelect === true ? 'normal': ''}
                totalData={initialInjuriesTypeByYearAndOption}
                lightColor={shade("#733837", 0.731)}
                stackingColor={pieSelect === true ? stackingOption.color : '#fff'}
                increaseChartNo={increaseChartNo}
                decreaseChartNo={decreaseChartNo}
                cancelLooping={cancelLooping}
                chartNo={chartNo}
                looping={looping}
                currentNo={2}
              />
            </Col>
          </div>
          <div className="row">
            <Col lg={12} xl={12} xxl={12} className="chart-col">
              <DistributionChart
                type="Type"
                total={totalByInjuriesType}
                typeData={chartInuriesTypeByYearAndOption}
                showInLegendEnable={false}
                description={getChart('distribution-of-ambulatory-care-visits-by-type-and-top-5-injuries-types')["description"]}
                title={getChart('distribution-of-ambulatory-care-visits-by-type-and-top-5-injuries-types')["name"]}
                selectOption={selectOption}
                chartColorData={chartColorData}
                increaseChartNo={increaseChartNo}
                decreaseChartNo={decreaseChartNo}
                cancelLooping={cancelLooping}
                chartNo={chartNo}
                looping={looping}
                currentNo={3}
              />
            </Col>
          </div>
        </>
      </div>
      : null}
    </>
  );
};

export default TOP5InjuriesTypes;
