import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { isEmpty, getOr } from "lodash/fp";
import Dashboard from "./../../../../api/dashboards";
import { toast } from 'react-toastify';

const ModalTiles = ({ sections, setShowModal, ModalData, getChartData }) => {
  const [show, setShow] = useState(true);
  const [chartState, setChartState] = useState([])
  const [formData, setFormData] = useState({
  });
  const handleCancelClick = () => {
    setShow(false);
    setShowModal(false);
  };

  useEffect(() => {

    let charts = []

    ModalData.children.map((child, index) => {
      if (child.charts != null) {
        child.charts.map((chart) => {
          charts.push({
            id: chart.id,
            name: chart.name,
            description: chart.description
          })
        })
      } else {
        charts.push({
          id: child.id,
          name: child.name,
          description: child.description
        })
      }
    })
    setChartState(charts)
  }, [ModalData])


  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formDataDashboard = {}
    let keys = Object.keys(formData)
    let chartsData = []
    for (let j = 0; j < keys.length; j++) {
      let chartObj = {}
      if (keys[j].includes("name")) {
        let chartId = parseInt(keys[j].split("-")[1])
        chartObj["id"] = chartId
        chartObj["name"] = formData[keys[j]]
      }
      if (keys[j+1] && keys[j+1].includes("description")) {
        let chartId = parseInt(keys[j+1].split("-")[1])
        chartObj["id"] = chartId
        chartObj["description"] = formData[keys[j+1]]
      }
      if (!keys[j].includes("name") && keys[j].includes("description")){
        let chartId = parseInt(keys[j].split("-")[1])
        chartObj["id"] = chartId
        chartObj["description"] = formData[keys[j]]
      }
      chartsData.push(chartObj)
    }
    if (!isEmpty(chartsData)) {
      formDataDashboard["charts_data"] = chartsData
    }
    Dashboard.updateChartInfo({ dashboard: formDataDashboard }).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))) {
        toast.success(`Descriptions updated`, {
          theme: 'colored'
        });
        getChartData()
        setShow(false);
        setShowModal(false);
      }
      else {
        toast.error(`Descriptions not updated`, {
          theme: 'colored'
        });
      }
    })
  }

  return (
    <>
      {show && (
        <Modal show={show} fullscreen={1200} onHide={() => setShow(false)}>
          <div className="modal-header mb-3">
            <div className="modal-title w700 h4">
              Edit {ModalData.name} Tiles
            </div>
            <div className="form-actions">
              <a
                onClick={() => handleCancelClick()}
                className="btn btn-falcon-default"
              >
                Cancel
              </a>
              <a
                onClick={handleSubmit}
                className="btn btn-primary"
              >
                Save
              </a>
            </div>
          </div>
          <Modal.Body>
            <Form className="modal-form max-800">
              <div className="row">
                {ModalData && chartState && ModalData.children.map((child, index) => {
                  if (child.charts != null) {
                    return (
                      <Form.Group className="mb-3 tiles-form">
                        <Form.Label className="tiles-form__title">{child.name}</Form.Label>

                        {child.charts.map((chart, ind) => {
                          return (
                            <>
                              <Form.Control
                                as="input"
                                rows={1}
                                placeholder=""
                                onChange={handleFieldChange}
                                name={`name-${getOr('', 'id', chart)}`}
                                defaultValue={getOr('', 'name', chart)}
                                className="mb-2 tiles-form__input"
                              />
                              <Form.Control
                                as="textarea"
                                rows={4}
                                placeholder=""
                                onChange={handleFieldChange}
                                className="mb-3"
                                name={`description-${getOr('', 'id', chart)}`}
                                defaultValue={getOr('', 'description', chart)}
                              />
                            </>
                          )
                        })}

                      </Form.Group>
                    )
                  } else {
                    return (
                      <Form.Group className="mb-3 tiles-form">
                        <Form.Control
                          as="input"
                          rows={1}
                          placeholder=""
                          onChange={handleFieldChange}
                          name={`name-${getOr('', 'id', child)}`}
                          defaultValue={getOr('', 'name', child)}
                          className="mb-2 tiles-form__input"
                        />
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder=""
                          onChange={handleFieldChange}
                          className="mb-3"
                          name={`description-${getOr('', 'id', child)}`}
                          defaultValue={getOr('', 'description', child)}
                        />
                      </Form.Group>
                    )
                  }
                })}
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ModalTiles;
