import React, { useEffect } from 'react';
import NavbarStandard from './front/NavbarStandard';
import Footer from './front/Footer';

// Images
import bg1 from 'assets/img/sp-images/home/bg1.png';

const Demo = ({}) => {

	const CalendlyWidget = () => {
		useEffect(() => {
		  const script = document.createElement("script");
		  script.src = "https://assets.calendly.com/assets/external/widget.js";
		  script.async = true;
		  document.body.appendChild(script);
	  
		  return () => {
			document.body.removeChild(script);
		  }
		}, []);
	  
		return (
			<div
			  className="calendly-inline-widget"
			  data-url="https://calendly.com/spearpointhealth/demo"
			  style={{ height: '100vh' }}
			/>
		);
	  }

  return (
	<>
		<NavbarStandard />
		
		<div className="content content--front">
			<section className="py-0">
				<div 
					className="heading bar1"
					style={{ backgroundImage:`url(${bg1})` }} >
					<div className="container">
						<div className="heading__overline">Before You buy</div>
						<h1 className="page-title">Request a Spearpoint Demo</h1>
						<div className="subtitle">See Spearpoint in action and learn about what we can do for your health system.</div>
					</div>
				</div>
                <div className="container">
				<CalendlyWidget />
				</div>
			</section>
		</div>
		
		<Footer />
	</>
  );
};

export default Demo;