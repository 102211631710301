import React from 'react';
import { Col, Row } from 'react-bootstrap';
import StatisticsCard from './StatisticsCard';
import { statsData } from './data/saas';

const StatisticsCards = ({organizationsCount, usersCount, totalSubscriptionAmount}) => {
  let stats = statsData
  let organizationIndex =  stats.findIndex(x => x["title"] === 'Organizations')
  stats[organizationIndex]["value"] = organizationsCount
  let userIndex =  stats.findIndex(x => x["title"] === 'Users')
  stats[userIndex]["value"] = usersCount
  let amountIndex =  stats.findIndex(x => x["title"] === 'Total Subscriptions')
  stats[amountIndex]["value"] = totalSubscriptionAmount
  return (
    <Row className="g-3 mb-3">
      {stats.map((stat, index) => (
        <Col key={stat.title} sm={index === 2 ? 12 : 6} md={4}>
          <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
        </Col>
      ))}
    </Row>
  );
};

export default StatisticsCards;
