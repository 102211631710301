import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Organization from "./../../../../api/organizations";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from 'react-toastify';
import Defender from 'helpers/defender';
import { useNavigate } from "react-router-dom";

function ModalOrgInfo({ getOrganization, getUsers }) {
  const navigate = useNavigate();
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [organization, setOrganization] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [formData, setFormData] = useState({
    name: '',
    organizationPhone: '',
    primaryAddress: '',
    secondaryAddress: '',
    city: '',
    state: '',
    zipcode: '',
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userPhone: '',
  });

  useEffect(() => {
    let id = window.location.href.split("/")[4]
    if (id) {
      Organization.get(id).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          let organization = response.data.organization
          setOrganization(organization)
          setFormData({
            ...formData,
            name: getOr('', 'name', organization),
            website: getOr('', 'website', organization),
            organizationPhone: getOr('', 'phone', organization),
            primaryAddress: getOr('', 'primary_address', organization),
            secondaryAddress: getOr('', 'secondary_address', organization),
            state: getOr('', 'state', organization),
            zipcode: getOr('', 'zipcode', organization),
            city: getOr('', 'city', organization),
            userFirstName: getOr('', 'user_first_name', organization),
            userLastName: getOr('', 'user_last_name', organization),
            userEmail: getOr('', 'user_email', organization),
            userPhone: getOr('', 'user_phone', organization),
          });
        }
      })
    }
  }, []);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const handleSubmit = e => {
    e.preventDefault();
    let formDataOrganization = {}
    formDataOrganization["name"] = formData.name
    formDataOrganization["phone"] = formData.organizationPhone
    formDataOrganization["primary_address"] = formData.primaryAddress
    formDataOrganization["secondary_address"] = formData.secondaryAddress
    formDataOrganization["city"] = formData.city
    formDataOrganization["website"] = formData.website
    formDataOrganization["state"] = formData.state
    formDataOrganization["zipcode"] = formData.zipcode
    formDataOrganization["user_first_name"] = formData.userFirstName
    formDataOrganization["user_last_name"] = formData.userLastName
    formDataOrganization["user_email"] = formData.userEmail
    formDataOrganization["user_phone"] = formData.userPhone
    let id = window.location.href.split("/")[4]
    if (id) {
      Organization.update({ organization: formDataOrganization }, id).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          toast.success(`Organization info saved`, {
            theme: 'colored'
          });
          getOrganization()
          getUsers(id)
          handleClose()
        }
        else {
          toast.error(`Organization Info not saved`, {
            theme: 'colored'
          });
        }
      })
    }
    else {
      formDataOrganization["status"] = true
      Organization.create({ organization: formDataOrganization }).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {

          toast.success(`Organization created`, {
            theme: 'colored'
          });
          navigate(`/organization/${response.data.id}`);
          getOrganization()
          handleClose()
        }
        else {
          toast.error(`Organization not created`, {
            theme: 'colored'
          });
        }
      })
    }
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      {window.location.href.split("/")[4] ?
        <>
          {values.map((v, idx) => (
            <Button key={idx} className="" onClick={() => handleShow(v)}>
              Edit
              {typeof v === 'string' && `below ${v.split('-')[0]}`}
            </Button>
          ))}
        </>
        :
        <>
          {values.map((v, idx) => (
            <Button key={idx} className="" onClick={() => handleShow(v)}>
              New
              {typeof v === 'string' && `below ${v.split('-')[0]}`}
            </Button>
          ))}
        </>
      }
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>Edit Org<span>anization</span> Info</Modal.Title>
          <div className="modal-header__actions">
            <Button variant="falcon-default" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form className="modal-form max-800">
            <div className="form-divider">Contact Information</div>
            <div className="row">
              <Form.Group className="mb-3 col-sm-6" controlId="ContactFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" placeholder="" name="userFirstName" onChange={handleFieldChange} value={formData.userFirstName} />
              </Form.Group>
              <Form.Group className="mb-3 col-sm-6" controlId="ContactLastName" >
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" placeholder="" name="userLastName" onChange={handleFieldChange} value={formData.userLastName} />
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="mb-3 col-sm-6" controlId="ContactEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" placeholder="" name="userEmail" onChange={handleFieldChange} value={formData.userEmail} />
              </Form.Group>
              <Form.Group className="mb-3 col-sm-6" controlId="ContactPhoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="tel" placeholder="(000) 000-0000" name="userPhone" onChange={handleFieldChange} value={formData.userPhone} />
              </Form.Group>
            </div>
            {getOr([], "_r", Defender.currentUser()).includes("admin") ?
              <>
                <div className="form-divider">Organization Information</div>
                <div className="row">
                  <Form.Group className="mb-3" controlId="OrganizationName">
                    <Form.Label>Name of Organization</Form.Label>
                    <Form.Control type="text" placeholder="" name="name" onChange={handleFieldChange} value={formData.name} />
                  </Form.Group>
                  <Form.Group className="mb-3 col-sm-6" controlId="OrganizationPhoneNumber">
                    <Form.Label>Organization Phone</Form.Label>
                    <Form.Control type="tel" placeholder="(000) 000-0000" name="organizationPhone" onChange={handleFieldChange} value={formData.organizationPhone} />
                  </Form.Group>
                  <Form.Group className="mb-3 col-sm-6" controlId="OrganizationWebsite">
                    <Form.Label>Organization Website</Form.Label>
                    <Form.Control type="text" placeholder="" onChange={handleFieldChange} name="website" value={formData.website} />
                  </Form.Group>
                  <Form.Group className="mb-3 col-sm-6" controlId="OrganizationAddress1">
                    <Form.Label>Address 1</Form.Label>
                    <Form.Control type="text" placeholder="" name="primaryAddress" onChange={handleFieldChange} value={formData.primaryAddress} />
                  </Form.Group>
                  <Form.Group className="mb-3 col-sm-6" controlId="OrganizationAddress2">
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control type="text" placeholder="" name="secondaryAddress" onChange={handleFieldChange} value={formData.secondaryAddress} />
                  </Form.Group>
                  <Form.Group className="mb-3 col-sm-6" controlId="OrganizationCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" placeholder="" name="city" onChange={handleFieldChange} value={formData.city} />
                  </Form.Group>
                  <Form.Group className="mb-3 col-xs-6 col-sm-6 col-md-3" controlId="OrganizationState">
                    <Form.Label>State</Form.Label>
                    <Form.Select aria-label="Select State" name="state" onChange={handleFieldChange} value={formData.state}>
                      <option>Select State</option>
                      <option value="AL">AL</option>
                      <option value="AK">AK</option>
                      <option value="AZ">AZ</option>
                      <option value="AR">AR</option>
                      <option value="CA">CA</option>
                      <option value="CO">CO</option>
                      <option value="CT">CT</option>
                      <option value="DE">DE</option>
                      <option value="DC">DC</option>
                      <option value="FL">FL</option>
                      <option value="GA">GA</option>
                      <option value="HI">HI</option>
                      <option value="ID">ID</option>
                      <option value="IL">IL</option>
                      <option value="IN">IN</option>
                      <option value="IA">IA</option>
                      <option value="KS">KS</option>
                      <option value="KY">KY</option>
                      <option value="LA">LA</option>
                      <option value="ME">ME</option>
                      <option value="MD">MD</option>
                      <option value="MA">MA</option>
                      <option value="MI">MI</option>
                      <option value="MN">MN</option>
                      <option value="MS">MS</option>
                      <option value="MO">MO</option>
                      <option value="MT">MT</option>
                      <option value="NE">NE</option>
                      <option value="NV">NV</option>
                      <option value="NH">NH</option>
                      <option value="NJ">NJ</option>
                      <option value="NM">NM</option>
                      <option value="NY">NY</option>
                      <option value="NC">NC</option>
                      <option value="ND">ND</option>
                      <option value="OH">OH</option>
                      <option value="OK">OK</option>
                      <option value="OR">OR</option>
                      <option value="PA">PA</option>
                      <option value="RI">RI</option>
                      <option value="SC">SC</option>
                      <option value="SD">SD</option>
                      <option value="TN">TN</option>
                      <option value="TX">TX</option>
                      <option value="UT">UT</option>
                      <option value="VT">VT</option>
                      <option value="VA">VA</option>
                      <option value="WA">WA</option>
                      <option value="WV">WV</option>
                      <option value="WI">WI</option>
                      <option value="WY">WY</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3 col-xs-6 col-sm-6 col-md-3" controlId="OrganizationZipCode">
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control type="text" placeholder="" name="zipcode" onChange={handleFieldChange} value={formData.zipcode} />
                  </Form.Group>
                  <Form.Group className="mb-3 col-sm-6" controlId="OrganizationCity">
                    <Form.Label>PO</Form.Label>
                    <Form.Control type="text" placeholder="" name="PO" onChange={handleFieldChange} value={formData.PO} />
                  </Form.Group>
                  <Form.Group className="mb-3 col-xs-6 col-sm-6 col-md-3" controlId="OrganizationZipCode">
                    <Form.Label>POP Start Date</Form.Label>
                    <Form.Control type="text" placeholder="" name="pop_start" onChange={handleFieldChange} value={formData.pop_start} />
                  </Form.Group>
                  <Form.Group className="mb-3 col-xs-6 col-sm-6 col-md-3" controlId="OrganizationZipCode">
                    <Form.Label>POP End Date</Form.Label>
                    <Form.Control type="text" placeholder="" name="pop_end" onChange={handleFieldChange} value={formData.pop_end} />
                  </Form.Group>
                </div>
              </>
              : null}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalOrgInfo;