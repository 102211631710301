import { useState } from "react";
import { Button, Col, Table } from "react-bootstrap";
import { MultiSelect } from "primereact/multiselect";
import { GrPowerReset } from "react-icons/gr";
import Form from "react-bootstrap/Form";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import DataTable from "./table";
import Chart from "./chart";

const ByVisitType = () => {
  const [opt, setOpt] = useState("");

  const [optProvider, setOptProvider] = useState("");

  const [optClinic, setOptClinic] = useState("");

  const [optVisit, setOptVisit] = useState("");

  const [optPayer, setOptPayer] = useState("");

  const [optVisitL, setOptVisitL] = useState("");

  const handleFieldChange = (e) => {
    setOpt(e.target.value);
  };
  const handleProviderFieldChange = (e) => {
    setOptProvider(e.target.value);
  };

  const providers = [
    {
      name: "ABRAHAM, FRED D",
    },
    {
      name: "Provider 2",
    },
    {
      name: "Provider 3",
    },
    {
      name: "Provider 4",
    },
    {
      name: "Provider 5",
    },
    {
      name: "Provider 6",
    },
    {
      name: "Provider 7",
    },
  ];
  const clinics = [
    {
      name: "Clinic 1",
    },
    {
      name: "Clinic 2",
    },
    {
      name: "Clinic 3",
    },
    {
      name: "Clinic 4",
    },
    {
      name: "Clinic 5",
    },
    {
      name: "Clinic 6",
    },
    {
      name: "Clinic 7",
    },
  ];
  const visitType = [
    {
      name: "Visit Type 1",
    },
    {
      name: "Visit Type 2",
    },
    {
      name: "Visit Type 3",
    },
    {
      name: "Visit Type 4",
    },
    {
      name: "Visit Type 5",
    },
    {
      name: "Visit Type 6",
    },
    {
      name: "Visit Type 7",
    },
  ];

  const payer = [
    {
      name: "Payer Type 1",
    },
    {
      name: "Payer Type 2",
    },
    {
      name: "Payer Type 3",
    },
    {
      name: "Payer Type 4",
    },
    {
      name: "Payer Type 5",
    },
    {
      name: "Payer Type 6",
    },
    {
      name: "Payer Type 7",
    },
  ];

  return (
    <>
      <div className="container px-0">
        <div className="card mb-3">
          <div className="card-body d-flex justify-content-between align-items-center gap-2">
            <h5 className="m-0 w500">Filters</h5>
            <div className="d-flex gap-2 align-items-center flex-wrap">
              <MultiSelect
                value={optVisitL}
                onChange={(e) => setOptVisitL(e.value)}
                options={visitType}
                optionLabel="name"
                filter
                placeholder="Visit Location"
                display="chip"
                maxSelectedLabels={1337}
                className="multi-selection__filter"
              />
              <MultiSelect
                value={optClinic}
                onChange={(e) => setOptClinic(e.value)}
                options={clinics}
                optionLabel="name"
                filter
                placeholder="Clinic"
                display="chip"
                maxSelectedLabels={1337}
                className="multi-selection__filter"
              />
              <Form>
                <Form.Select
                  size="sm"
                  onChange={(e) => handleFieldChange(e)}
                  value={opt}
                >
                  <option value={"All FYs"} key={"View-All"}>
                    All FYs
                  </option>
                  <option value={"FY2022"} key={"2022"}>
                    FY2022
                  </option>
                  <option value={"FY2023"} key={"2023"}>
                    FY2023
                  </option>
                  <option value={"FY2024"} key={"2024"}>
                    FY2024
                  </option>
                </Form.Select>
              </Form>
              <Button variant="transparent" className="filter__reset_button">
                <GrPowerReset />
              </Button>
            </div>
          </div>
        </div>
        <>
          <div className="row">
            <Col lg={12} xl={12} className="chart-col">
              <DataTable title={"Visit Type"} />
            </Col>
          </div>
          <div className="row">
            <Col lg={12} xl={12} className="chart-col">
            <Chart
                row1={1290000}
                row2={1070000}
                row3={1300000}
                row4={1130000}
                row5={1380000}
                row6={810000}
                row7={320000}
                row8={1290000}
                row9={1070000}
                row10={1300000}
                row11={1130000}
                row12={1380000}
                row13={810000}
                row14={320000}
                row15={1300000}
                row16={1130000}
                row17={1380000}
                row18={810000}
                row19={320000}
              />
              </Col>
          </div>
        </>
      </div>
    </>
  );
};

export default ByVisitType;
