import React from 'react';
import PasswordResetForm from './PasswordResetForm';
import bgImg from 'assets/img/generic/20.jpg';
import AuthSplitLayout from './AuthSplitLayout';
import logo from 'assets/img/spearpoint-logo_1A.png';

const PasswordReset = () => {
  return (
    <AuthSplitLayout bgProps={{ image: bgImg }}>
      <div className="text-center">
        <div className="access__logo">
          <img className="me-2" src={logo} alt="Logo" />
        </div>
        <h3>Reset password</h3>
      </div>
      <PasswordResetForm layout="split" hasLabel />
    </AuthSplitLayout>
  );
};

export default PasswordReset;
