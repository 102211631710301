import React, { useContext } from 'react';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ProfileDropdown from './ProfileDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import Defender from "./../../../helpers/defender";
import { isEmpty, getOr } from "lodash/fp";

const TopNavRightSideNavItem = () => {
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="div"
    >
      {!isEmpty(Defender.currentUser()) ?
        <li className="admin-user-name">
          Welcome, {!isEmpty(getOr("", "first_name", Defender.currentUser())) ? getOr("", "first_name", Defender.currentUser()) : getOr("", "email", Defender.currentUser())}
        </li>
        : null}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
