import React from 'react';
import PropTypes from 'prop-types';
import Background from 'components/common/Background';
import { Card, Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from 'assets/img/spearpoint-logo_1A.png';

const AuthSplitLayout = ({ children, bgProps }) => {
  return (
  <Container fluid>
    <Row className="min-vh-100 bg-100">
    <Col sm={10} md={6} className="px-sm-0 align-self-center mx-auto py-5">
      <Row className="g-0 justify-content-center">
      <Col lg={9} xl={8} className="col-xxl-6">
        <Card>
        <Card.Header className="p-2">
          <Link
            className="access__logo"
            to="/"
          >
            <img className="me-2" src={logo} alt="Logo" />
          </Link>
        </Card.Header>
        <Card.Body className="py-0">{children}</Card.Body>
        </Card>
      </Col>
      </Row>
    </Col>
    <Col xs={6} className="d-none d-lg-block position-relative">
      {bgProps && <Background {...bgProps} />}
    </Col>
    </Row>
  </Container>
  );
};

AuthSplitLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bgProps: PropTypes.shape(Background.propTypes).isRequired
};

export default AuthSplitLayout;
