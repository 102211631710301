import React, { useEffect, useState } from 'react';
import ModalOrgPlan from './modals/ModalOrgPlan';
import ModalOrgInfo from './modals/ModalOrgInfo';
import ModalNotes from './modals/ModalNotes';
import UserTable from './user-table/UserTable';
import DashboardTable from './dashboard-table/DashboardTable';
import Organization from "./../../../api/organizations";
import Dashboard from "./../../../api/dashboards";
import User from "./../../../api/users";
import SignOut from "./../../../api/auth/sign_out";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from 'react-toastify';
import Defender from 'helpers/defender';
import { useNavigate } from "react-router-dom";
import bg2 from 'assets/img/sp-images/widget-bg-2.png';

// Images
import adminBg from 'assets/img/sp-images/adminBg.png';

// Icons
import { HiShieldCheck } from "react-icons/hi";
import { Button } from 'react-bootstrap';
import ModalOrgInvoice from './modals/ModalOrgInvoice';

const NewOrganization = ({ }) => {
  const navigate = useNavigate();
  const [organization, setOrganization] = useState({});
  const [dashboards, setDashboards] = useState([]);
  const [staticDashboards, setStaticDashboards] = useState([]);
  const [nonStaticDashboards, setNonStaticDashboards] = useState([]);
  const [users, setUsers] = useState([]);
  const [userStatusList, setUserStatusList] = useState([]);
  const [dashboardStatusList, setDashboardStatusList] = useState([]);
  const [organizationNote, setOrganizationNote] = useState({});

  useEffect(() => {
    if (Defender.currentUser() && (Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin"))) {
      let id = window.location.href.split("/")[4]
      if (id) {
        getOrganization()
        getDashboards()
        getUsers()
      }
    }
    else {
      SignOut.destroy().then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 201) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          localStorage.removeItem('dashboardName')
          localStorage.removeItem('dashboardId')
          localStorage.removeItem('dashboardSubPage')
          localStorage.removeItem('dashboardCsvUploaded')
          localStorage.removeItem('dashboardStartDate')
          localStorage.removeItem('dashboardEndDate')
          localStorage.removeItem('dashboardStatic')
          localStorage.removeItem('option')
          Defender.logout();
          toast.error(`Signed out`, {
            theme: "colored",
          });
          navigate("/logout");
        }
      });
    }
  }, []);

  const getOrganization = () => {
    let id = window.location.href.split("/")[4]
    if (id) {
      Organization.get(id).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          setOrganization(getOr({}, 'data.organization', response))
          setOrganizationNote(getOr({}, 'data.organization.organization_notes[0]', response))
        }
      })
    }
  }

  function currencyFormat(num) {
    if (num) {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    else {
      return '$' + '0'
    }
  }

  const getDashboards = () => {
    let id = window.location.href.split("/")[4]
    if (id) {
      Dashboard.getAll(id).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          let statusListArray = []
          let dashboards = getOr([], 'data', response)
          setDashboards(dashboards)
          let sDashboards = dashboards.filter(obj => obj["static"] === true)
          const ssDashboards = sDashboards.sort(function (a, b) { return a.id - b.id; });
          setStaticDashboards(ssDashboards)
          setNonStaticDashboards(dashboards.filter(obj => obj["static"] === null))
          for (let i = 0; i < dashboards.length; i++) {
            let obj = {}
            obj["id"] = dashboards[i]["id"]
            obj["status"] = dashboards[i]["status"]
            statusListArray.push(obj)
          }
          setDashboardStatusList(statusListArray)
        }
      })
    }
  }

  const getUsers = () => {
    let id = window.location.href.split("/")[4]
    if (id) {
      User.getOrganizationUsers(id).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          let statusListArray = []
          setUsers(getOr([], 'data.users', response))
          for (let i = 0; i < response.data.users.length; i++) {
            let obj = {}
            obj["id"] = response.data.users[i]["id"]
            obj["status"] = response.data.users[i]["status"]
            statusListArray.push(obj)
          }
          setUserStatusList(statusListArray)
        }
      })
    }
  }

  const removeDashboard = (id, e) => {
    e.preventDefault();
    Dashboard.remove(id).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        toast.success(`Dashboard removed`, {
          theme: 'colored'
        });
        getDashboards()
        getUsers()
      }
    })
  }


  const removeUser = (id, e) => {
    e.preventDefault();
    User.remove(id).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        toast.error(`User removed`, {
          theme: 'colored'
        });
        getUsers()
      }
    })
  }

  const updateDashboardStatus = (id, status, e) => {
    e.preventDefault();
    let data = {}
    data["status"] = status
    Dashboard.update({ dashboard: data }, id).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        const newStatusList = [...dashboardStatusList];
        let statusIndex = newStatusList.findIndex(x => x["id"] === id)
        newStatusList[statusIndex]["status"] = newStatusList[statusIndex]["status"] === true ? false : true
        setDashboardStatusList(newStatusList);
        toast.success(`Dashboard status updated`, {
          theme: 'colored'
        });
        getUsers()
      }
    })
  }

  const updateUserStatus = (id, status, e) => {
    e.preventDefault();
    let data = {}
    data["status"] = status
    User.updateUser({ user: data }, id).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        toast.success(`User status updated`, {
          theme: 'colored'
        });
        const newStatusList = [...userStatusList];
        let statusIndex = newStatusList.findIndex(x => x["id"] === id)
        newStatusList[statusIndex]["status"] = newStatusList[statusIndex]["status"] === true ? false : true
        setUserStatusList(newStatusList);
      }
    })
  }

  return (
    <>
      <div className="content admin admin-bg"
        style={{ backgroundImage: `url(${adminBg})` }} >
        <section className="p-0">
          <div className="admin__org-header modal-header mb-3">
            <div className="modal-title w700 h4">Org<span>anization</span></div>
            <div className="form-actions">
              <a href="/admin" className="btn btn-falcon-default">
                Cancel
              </a>
              <a href="/admin" className="btn btn-primary">
                Save & Close
              </a>
            </div>
          </div>
          <div className="container container--800">
            <div className="card card-settings mb-3">
              <div className="card-header">
                <h5 className="settings__title">Organization Info</h5>
                <ModalOrgInfo getOrganization={getOrganization} getUsers={getUsers} />
              </div>
              <div className="card-body">
                <div className="settings-list">
                  <div className="row">
                    <div className="col-sm-6 mb-3">
                      <div className="settings-list__divider">Contact Information</div>
                      <div className="settings-list__item">
                        <label>Name</label>
                        <div className="settings-list__content">{getOr('', 'user_full_name', organization)}</div>
                      </div>
                      <div className="settings-list__item">
                        <label>Email</label>
                        <div className="settings-list__content">{getOr('', 'user_email', organization)}</div>
                      </div>
                      <div className="settings-list__item">
                        <label>Phone</label>
                        <div className="settings-list__content">{getOr('', 'user_phone', organization)}</div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="settings-list__divider">Organization Information</div>
                      <div className="settings-list__item">
                        <label>Organization Code</label>
                        <div className="settings-list__content">{getOr('', 'csv_code', organization)}</div>
                      </div>

                      {/* {!isEmpty(getOr('', 'csv_name', organization)) ?
                        <div className="settings-list__item">
                          <label>CSV Status</label>
                          <div className="settings-list__content"><HiShieldCheck className="csv-confirm" />{getOr('', 'csv_name', organization)}<br /><span className="form-text-small">{getOr('', 'csv_uploaded_format', organization)}, {getOr('', 'csv_size', organization)}MB</span></div>
                        </div>
                        : <div className="settings-list__item">
                          <label>CSV Status</label>
                          <div className="settings-list__content">None</div>
                        </div>} */}
                      <div className="settings-list__item">
                        <label>Name of Organization</label>
                        <div className="settings-list__content">{getOr('', 'name', organization)}</div>
                      </div>
                      <div className="settings-list__item">
                        <label>Phone</label>
                        <div className="settings-list__content">{getOr('', 'phone', organization)}</div>
                      </div>
                      <div className="settings-list__item">
                        <label>Website</label>
                        <div className="settings-list__content"><a href={getOr('', 'website', organization)} target="_blank">{getOr('', 'website', organization)}</a></div>
                      </div>
                      <div className="settings-list__item">
                        <label>Address</label>
                        <div className="settings-list__content">
                          {getOr('', 'primary_address', organization)}
                        </div>
                        <div className="settings-list__content">
                          {getOr('', 'secondary_address', organization)}
                        </div>
                        <div className="settings-list__content">
                          {getOr('', 'city', organization)}, {getOr('', 'state', organization)}, {getOr('', 'zipcode', organization)}
                        </div>
                      </div>
                      <div className="settings-list__item">
                        <label>PO#</label>
                        <div className="settings-list__content">2349832105840321432578492534</div>
                      </div>
                      <div className="settings-list__item">
                        <label>Period of Performance</label>
                        <div className="settings-list__content">Jan 1, 2023 - Dec 31, 2024</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="org-table">
              <UserTable users={users} getUsers={getUsers} dashboards={dashboards} removeUser={removeUser} updateUserStatus={updateUserStatus} userStatusList={userStatusList} getDashboards={getDashboards} />
            </div>

            <div className="org-table">
              <DashboardTable dashboards={staticDashboards} getDashboards={getDashboards} removeDashboard={removeDashboard} updateDashboardStatus={updateDashboardStatus} getUsers={getUsers} dashboardStatusList={dashboardStatusList} title={"Spearpoint Dashboards"} showNew={false} />
            </div>

            <div className="org-table">
              <DashboardTable dashboards={nonStaticDashboards} getDashboards={getDashboards} removeDashboard={removeDashboard} updateDashboardStatus={updateDashboardStatus} getUsers={getUsers} dashboardStatusList={dashboardStatusList} title={"PowerBi Dashboards"} showNew={true} />
            </div>

            <div className="card card-settings mb-3">
              <div className="card-header">
                <h5 className="settings__title">Notes</h5>
                <ModalNotes organizationNote={organizationNote} getOrganization={getOrganization} />
              </div>

              <div className="card-body">
                <div className="settings-list">
                  <div className="row">
                    <div className="settings-list__item">
                      <label>Notes</label>
                      {isEmpty(getOr('', 'note', organizationNote)) ?
                        <div className="settings-list__placeholder"><i>No notes</i></div>
                        : <div className="settings-list__content">{getOr('', 'note', organizationNote)}</div>
                      }
                    </div>
                    <div className="settings-list__item">
                      <label>Subscription Amount</label>
                      {getOr(0, 'subscription_amount', organizationNote) !== 0 ?
                        <div className="settings-list__content">{currencyFormat(getOr(0, 'subscription_amount', organizationNote))}</div>
                        : <div className="settings-list__content">$0</div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-settings mb-3">
              <div className="card-header">
                <h5 className="settings__title">Organization Plan</h5>
                <div className="d-flex gap-3">
                  <ModalOrgInvoice getOrganization={getOrganization} getUsers={getUsers} />
                  <ModalOrgPlan getOrganization={getOrganization} getUsers={getUsers} />
                </div>
              </div>
              <div className="card-body">
                <div className="settings-list">
                  <div className="row plan-change" style={{ backgroundImage: `url('${bg2}')` }}>
                    <div className="settings-list__item plan-change__item">
                      <label>Previous Plan</label>
                      <div className="settings-list__content">Premium Annual</div>
                    </div>
                    <div className="settings-list__item plan-change__item">
                      <label>Expires</label>
                      <div className="settings-list__content">01/02/2023</div>
                    </div>
                    <div className="settings-list__item plan-change__item">
                      <label>Annual Fee</label>
                      <div className="settings-list__content">$60,000</div>
                    </div>
                    <div className="settings-list__item plan-change__item">
                      <label>Custom Report Fee</label>
                      <div className="settings-list__content">$700</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 mb-3">
                      <div className="settings-list__divider">Active Plan</div>
                      <div className="settings-list__item">
                        <label>Plan Name</label>
                        <div className="settings-list__content">Standard Annual</div>
                      </div>
                      <div className="settings-list__item">
                        <label>Annual Fee</label>
                        <div className="settings-list__content">$20,000</div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="settings-list__divider">Plan Details</div>
                      <div className="settings-list__item">
                        <label>Custom Report Fee</label>
                        <div className="settings-list__content">$1,000</div>
                      </div>
                      <div className="settings-list__item">
                        <label>Contract Start Date</label>
                        <div className="settings-list__content">01/02/2023</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NewOrganization;