import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Row, Col } from 'react-bootstrap';
import SignIn from "./../../../api/auth/sign_in";
import Organization from "./../../../api/organizations";
import { isEmpty, getOr } from "lodash/fp";
import { useNavigate } from "react-router-dom";
import ReactCodeInput from 'react-verification-code-input';
import Defender from 'helpers/defender';

const TwoFactorForm = ({ hasLabel, layout }) => {
  const navigate = useNavigate();
  // State
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false)
  const [iteration, setIteration] = useState(0);

  const codeRef = useRef(undefined)

  // Handler

  const handleSubmit = e => {
    let formData = {}
    formData["otp"] = e
    formData["id"] = window.location.href.split("?")[1].split("=")[1];
    setLoading(true)
    SignIn.verify({ user: formData }).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 201) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        const token = response.headers["authorization"];
        let signedIn = {};
        signedIn = response.data;
        Defender.loggedIn(signedIn, token);
        setLoading(false)
        toast.success(`Logged in as ${signedIn.email}`, {
          theme: 'colored'
        });
        if (getOr([], "_r", signedIn).includes("admin") || getOr([], "_r", signedIn).includes("sub_admin")) {
          navigate("/admin");
        }
        else if (getOr([], "_r", signedIn).includes("executive") || getOr([], "_r", signedIn).includes("management")) {
          Organization.getFirstOrganizationDashboard(getOr("", "organization_id", signedIn)).then((res) => {
            if (
              (getOr("", "status", res) === 200 ||
                getOr("", "status", res) === 204) &&
              isEmpty(getOr({}, "data.errors", res))
            ) {
              if (!isEmpty(res.data)) {
                localStorage.setItem('dashboardId', getOr({}, "data.dashboard.id", res))
                localStorage.setItem('dashboardName', getOr({}, "data.dashboard.name", res))
                localStorage.setItem('dashboardCsvUploaded', getOr({}, "data.dashboard.csv_uploaded_format", res))
                localStorage.setItem('dashboardStartDate', getOr({}, "data.dashboard.starting_date_format", res))
                localStorage.setItem('dashboardEndDate', getOr({}, "data.dashboard.ending_date_format", res))
                navigate("/dash");
              }
              else {
                navigate("/empty-dash");
              }
            }
          })
        }
        else {
          navigate("/");
        }
      }
      else {
        if (getOr("", "response.data.message", response) === "One Time Code is incorrect, 2 more attempts allowed" ||
          getOr("", "response.data.message", response) === "One Time Code is incorrect, 1 more attempt allowed") {
          clear()
          setLoading(false)
          toast.error(getOr("", "response.data.message", response), {
            theme: 'colored'
          });
        }
        else {
          setLoading(false)
          toast.error(getOr("", "response.data.message", response), {
            theme: 'colored'
          });
          navigate("/login");
        }
      }
    })
  };

  const resendCode = e => {
    e.preventDefault()
    let iter = iteration + 1
    if (iter <= 3) {
      setIteration(iter)
      let formData = {}
      formData["resend"] = true
      formData["id"] = window.location.href.split("?")[1].split("=")[1];
      SignIn.verify({ user: formData }).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 201) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          toast.success(`One-time login code resent`, {
            theme: 'colored'
          });
        }
        else {
          toast.error(`One-time login code not resent`, {
            theme: 'colored'
          });
        }
      })
    }
    else {
      navigate("/login");
    }
  }

  function clear() {
    codeRef.current.state.values[0] = ""
    codeRef.current.state.values[1] = ""
    codeRef.current.state.values[2] = ""
    codeRef.current.state.values[3] = ""
    codeRef.current.state.values[4] = ""
    codeRef.current.state.values[5] = ""
    codeRef.current.iRefs[0].current.focus()
  }

  return (
    <Form className="two-factor mt-4">
      <Form.Group className="mb-3">
        <ReactCodeInput onComplete={handleSubmit} onChange={setCode} className='react-input' ref={codeRef} />
      </Form.Group>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <p
            className="fs--1 mb-0" style={{ color: '#2c7be5', cursor: 'pointer' }}
            onClick={resendCode}
          >
            Resend Code
          </p>
        </Col>
      </Row>
    </Form>
  );
};

TwoFactorForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

TwoFactorForm.defaultProps = { layout: 'simple', hasLabel: false };

export default TwoFactorForm;
