import { useState } from "react";
import { Button, Col, Table } from "react-bootstrap";
import { MultiSelect  } from 'primereact/multiselect';
import { FaCalendarAlt } from "react-icons/fa";
import { GrPowerReset } from "react-icons/gr";
import Form from "react-bootstrap/Form";
import DataTable from "./table";
import SDataTable from "./sTable";

import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";                                         
import Chart from "./chart";
import DChart from "./distrbution-chart";

const SumByVisitTypeClinic = () => {

  const [opt, setOpt] = useState("")

  const [optProvider, setOptProvider] = useState("")

  const handleFieldChange = (e) => {
    setOpt(e.target.value)
  }
  const handleProviderFieldChange = (e) => {
    setOptProvider(e.target.value)
  }

  const visits = [
    {
      name: "ABRAHAM, FRED D"
    },
    {
      name: "VISIT 2"
    },
    {
      name: "VISIT 3"
    },
    {
      name: "VISIT 4"
    },
    {
      name: "VISIT 5"
    },
    {
      name: "VISIT 6"
    },
    {
      name: "VISIT 7"
    },
  ] 
  
  const locations = [
    {
      name: "RBHC"
    },
    {
      name: "School"
    }
  ]

  const typeData = [
    {
      name: "School",
      y: Math.round(80 * 10) / 10,
      x: Math.round((40000 / 1000) * 10) / 10,
      a: Math.round((40000 / 1000) * 10) / 10,
      color: "#2A8B8C",
      },
      {
      name: "ALCOHOL AND SUBSTANCE",
      y: Math.round(20 * 10) / 10,
      x: Math.round((10000 / 1000) * 10) / 10,
      a: Math.round((10000 / 1000) * 10) / 10,
      color: "#733837",
      },
      {
      name: "DENTAL",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#e1a95b",
      },
      {
      name: "PUBLIC HEALTH EMERGENCY",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#6A6E71",
      },
      {
      name: "PHN CLINIC VISIT",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#bc6541",
      },
      {
      name: "FAMILY PRACTICE",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#7c5280",
      },
      {
      name: "NURSE CLINIC",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#61724f",
      },
      {
      name: "LABORATORY SERVICES",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#9a3982",
      },
      {
      name: "BEHAVIORAL HEALTH",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#293747",
      },
      {
      name: "CHIROPRACTIC",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#523a3a",
      },
      {
      name: "OPTOMETRY",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#2A8B8C",
      },
      {
      name: "Other",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#47232d",
      },
      {
      name: "URGENT CARE",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#a08148",
      },
      {
      name: "IMMUNIZATION",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#46484a",
      },
      {
      name: "PHYSICAL THERAPY",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#8e5f4c",
      },
      {
      name: "DIABETIC RETINOPATHY",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#562f85",
      },
      {
      name: "MENTAL HEALTH",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#7ba94a",
      },
      {
      name: "ANTICOAGULATION THERAPY",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#633858",
      },
      {
      name: "RADIOLOGY",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#2d598b",
      },
      {
      name: "TELEMEDICINE",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#724040",
      },
      {
      name: "OBSTETRICS",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#194048",
      },
      {
      name: "NEPHROLOGY ",
      y: Math.round(0 * 10) / 10,
      x: Math.round((0 / 1000) * 10) / 10,
      a: Math.round((0 / 1000) * 10) / 10,
      color: "#a94442",
      }
  ]


  return (
    <>
      <div className="container px-0">
        <div className="card mb-3">
          <div className="card-body d-flex justify-content-between align-items-center gap-2">
            <h5 className="m-0 w500">Filters</h5>
            <div className="d-flex gap-2 align-items-center" >
            <MultiSelect value={opt} onChange={(e) => setOpt(e.value)}  options={locations} optionLabel="name" filter placeholder="Visit Location" display="chip" maxSelectedLabels={1337} className="multi-selection__filter" />
            <MultiSelect value={optProvider} onChange={(e) => setOptProvider(e.value)}  options={visits} optionLabel="name" filter placeholder="Visit Type" display="chip" maxSelectedLabels={1337} className="multi-selection__filter" />
            <Form>
              <Form.Select 
                size="sm" 
                onChange={(e) => handleFieldChange(e)} 
                value={opt}
              >
                <option value={"All FYs"} key={"View-All"}>
                    All FYs
                </option>
                <option value={"FY2017"} key={"2017"}>
                  FY2017
                </option>
                <option value={"FY2018"} key={"2018"}>
                  FY2018
                </option>
                <option value={"FY2019"} key={"2019"}>
                  FY2019
                </option>
                <option value={"FY2020"} key={"2020"}>
                  FY2020
                </option>
                <option value={"FY2021"} key={"2021"}>
                  FY2021
                </option>
                <option value={"FY2022"} key={"2022"}>
                  FY2022
                </option>
                <option value={"FY2023"} key={"2023"}>
                  FY2023
                </option>
              </Form.Select>
            </Form>
            <Button variant="transparent" className="filter__reset_button">
            <GrPowerReset />
            </Button>
            </div>
          </div>
        </div>
      <>
        <div className="row">
          <Col lg={12} xl={12} className="chart-col">
            <DataTable
            title={"Summary By Clinic & Visit Type"}
             />            
          </Col>
        </div>
        <div className="row">
            <Col lg={12} xl={12} className="chart-col">
            <Chart
                row1={1290000}
                row2={1070000}
                row3={1300000}
                row4={1130000}
                row5={1380000}
                row6={810000}
                row7={320000}
                row8={1290000}
                row9={1070000}
                row10={1300000}
                row11={1130000}
                row12={1380000}
                row13={810000}
                row14={320000}
                row15={1300000}
                row16={1130000}
                row17={1380000}
                row18={810000}
                row19={320000}
              />
              </Col>
          </div>
          <div className="row">
          <Col lg={5} xl={5} className="chart-col">
            <SDataTable
            title={"Summary By Clinic & Visit Type"}
             />            
          </Col>
          <Col lg={7} xl={7} className="chart-col">
            <DChart
            type="Type"
              total = {50000}
              typeData={typeData}
              showInLegendEnable={true}
             />            
          </Col>
        </div>
        
      </>
      </div>
    </>
  );
};

export default SumByVisitTypeClinic;