import React, { useState, useEffect, useContext } from "react";
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Chart from "./chart";
import Dashboards from "./../../../../../../api/dashboards";
import { isEmpty, getOr } from "lodash/fp";
import { colorScheme } from "helpers/colors";
import { DashContext } from "context/Context";

const AtbSumByPayer = () => {
  
  const [loading, setLoading] = useState(true)
  const [charts, setCharts] = useState([])
  const [payersData, setPayersData] = useState([])
  const [insurersData, setInsurersData] = useState([])
  const [visitTypeData, setVisitTypeData] = useState([])
  const [visitLocationData, setVisitLocationData] = useState([])
  const {Updatefilters} = useContext(DashContext)

  const getChartData = (id) => {
    setLoading(true)
    Dashboards.getAtbSummaryByPayerAndInsurerAndVisitTypeAndVisitLocation(id).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setPayersData(getOr([], 'data.atb_amount_by_atb_age_group_and_payer', response))
        setInsurersData(getOr([], 'data.atb_amount_by_atb_age_group_and_insurer', response))
        setVisitTypeData(getOr([], 'data.atb_amount_by_atb_age_group_and_visit_type', response))
        setVisitLocationData(getOr([], 'data.atb_amount_by_atb_age_group_and_visit_location', response))
        setLoading(false)
      }
    })
  }

  const getChartsInfo = (title) => {
    Dashboards.getChartsInfo(title).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setCharts(getOr([], 'data.charts', response))
      }
    })
  }

  const getChartDescription = (name) => {
    if (!isEmpty(charts)) {
      if (charts.filter(obj => obj["name"] === name)) {
        if (charts.filter(obj => obj["name"] === name)[0]) {
          return charts.filter(obj => obj["name"] === name)[0]["description"]
        }
        else {
          return ""
        }
      }
      else {
        return ""
      }
    }
    else {
      return ""
    }
  }


  useEffect(() => {
    getChartData(localStorage.dashboardId)
    getChartsInfo(localStorage.dashboardName)
  }, [localStorage.getItem('dashboardId')]);

  useEffect(() => {
    Updatefilters([]);
  }, []);

  return (
    <>
      <div className="container px-0">
      <>
        <div className="row">
          <Col lg={12} xl={6} className="chart-col">
            <Chart 
            type={"Payer"}
            data={payersData}
            />
          </Col>
          <Col lg={12} xl={6} className="chart-col">
            <Chart 
            type={"Insurer"}
            data={insurersData}
            />
          </Col>
        </div>
        <div className="row">
          <Col lg={12} xl={6} className="chart-col">
            <Chart 
            type={"Visit Type"}
            data={visitTypeData}
            />
          </Col>
          <Col lg={12} xl={6} className="chart-col">
            <Chart 
            type={"Visit Location"}
            data={visitLocationData}
            />
          </Col>
        </div>
        {/* <div className="row">
          <Col lg={12} xl={6} className="chart-col">
            <Chart 
            type={"Visit Type"}
            data={VisitTypeRBHCData}
            />
          </Col>
        </div> */}
      </>
      </div>
    </>
  );
};

export default AtbSumByPayer;
