import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import User from "./../../../../api/users";
import { isEmpty, getOr } from "lodash/fp";

const InviteModal = ({ modalShow, setModalShow }) => {
  const [invitedEmail, setInvitedEmail] = useState('')

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleEmailFieldChange = (e) => {
    setInvitedEmail(e.target.value)
  }

  const handleInviteUser = () => {
    if (isValidEmail(invitedEmail)) {
      let data = {}
      data["organization_id"] = window.location.href.split("/")[4]
      data["email"] = invitedEmail
      User.inviteUser({ user: data }).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          setModalShow(false)
          setInvitedEmail('')
          toast.success(`User Invited Successfully`, {
            theme: 'colored'
          });
        }
      })
    } else {
      toast.error(`Invalid Email`, {
        theme: 'colored'
      });
    }
  }

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="invite-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Invite existing user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Enter the email of the existing user that you wish to invite.</div>
          <Form.Group className="mt-3 mb-3" controlId="InviteEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="name@domain.com" name="email" onChange={handleEmailFieldChange} value={invitedEmail} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleInviteUser()}>Invite User</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InviteModal;