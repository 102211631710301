import { React, useEffect, useState } from "react";
import { Button, Form, InputGroup, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";
import { SlQuestion } from "react-icons/sl";
import { isEmpty, getOr } from "lodash/fp";

// import AdvanceTable from "components/common/advance-table/AdvanceTable";
// import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";

const ParentRow = ({ data, isActiveRow, activeRow, setActiveRow, selectOption }) => {
  const handleRowChange = (e, primaryCategory, secondaryCategory) => {
    selectOption(primaryCategory, secondaryCategory)
    const selectedRowId = parseInt(e.target.id);
    setActiveRow((prevActiveRow) => {
      // Toggle the active row if it is already selected
      return prevActiveRow === selectedRowId ? null : selectedRowId;
    });
  };

  return (
    <>
      <tr
        className={isActiveRow ? "summary-accounts__table__row__active" : ""}
      >
        <td className="px-0 pe-2 d-flex align-items-center">
          <Form.Check
            checked={isActiveRow}
            className={`px-2 table__checkbox`}
            id={data.id}
            onChange={(e) => handleRowChange(e, data.category, data.clinic)}
          />

          {data.category}
        </td>
        <td>{data.clinic}</td>
      </tr>
    </>
  );
};

const DataTable = ({ title, description, data, selectOption }) => {

  const [tableRowData, setTableRowData] = useState([])
  const [activeRow, setActiveRow] = useState(1)

  useEffect(() => {
    if (!isEmpty(data)) {
      let tableData = []
      for (let i = 0; i < data.length; i++) {
        if (data[i]["primary_category"] === "AV By Clinic" ||
          data[i]["primary_category"] === "Contract Health Services" ||
          data[i]["primary_category"] === "Dental Totals" ||
          data[i]["primary_category"] === "Injuries Total" ||
          data[i]["primary_category"] === "Pharmacy" ||
          data[i]["primary_category"] === "Ambulatory Care Visits (AV)" ) {
          let obj = {}
          obj["id"] = i+1
          obj["category"] = data[i]["primary_category"]
          obj["clinic"] = data[i]["secondary_category"]
          tableData.push(obj)
        }
      }
      setTableRowData(tableData)
    }
  }, [data]);
  return (
    <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
              <Popover
                id={"popover-positioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                  marginTop: "0",
                  transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                  {description}
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
        </div>
        <div className="card-body av-chart__card-body">
          <Table responsive bordered className="summary-accounts__data-table">
            <thead className="summary-accounts__table__head">
              <tr>
                <th>category</th>
                <th>name</th>
              </tr>
            </thead>
            <tbody className="summary-accounts__table__body">
              {tableRowData.map((parentRow) => (
                <ParentRow 
                  key={parentRow.id} 
                  data={parentRow} 
                  isActiveRow={activeRow === parentRow.id} 
                  activeRow={activeRow} 
                  setActiveRow={setActiveRow} 
                  selectOption={selectOption}/>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default DataTable;