import React, { useState, useEffect } from 'react';
import NavbarAdmin from './NavbarAdmin';
import ActivityLogTable from './tables/activity-log-table/ActivityLogTable';
import Activity from "./../../../api/activity_logs";
import SignOut from "./../../../api/auth/sign_out";
import { isEmpty, getOr } from "lodash/fp";
import Defender from 'helpers/defender';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const ActivityLog = ({ }) => {

	const navigate = useNavigate();
	const [logs, setLogs] = useState([]);
	const [tags, setTags] = useState([]);
	const [suggestions, setSuggestions] = useState([]);

	const pickOneResultPerSecond = (results) => {
		// Group results by second
		const resultsBySecond = results.reduce((acc, result) => {
			const secondKey = result.created_at.slice(0, 19); // Include milliseconds for precision
			acc[secondKey] = acc[secondKey] || [];
			acc[secondKey].push(result);
			return acc;
		}, {});
	
		// Pick one result from each second
		const selectedResults = Object.values(resultsBySecond).map(resultsInSecond => resultsInSecond[0]);
	
		return selectedResults;
	};

	const getLogs = (body) => {
		Activity.get(body).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				setLogs(pickOneResultPerSecond(response.data))
			}
		})
	}
	const getSuggestions = (value) => {
		let body = {}
		body["value"] = value
		Activity.getSuggestions(body).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				setSuggestions(response.data.suggestions)
			}
		})
	}

	useEffect(() => {
		if (!isEmpty(Defender.currentUser()) && (getOr([], "_r", Defender.currentUser()).includes("admin") || getOr([], "_r", Defender.currentUser()).includes("sub_admin"))) {
			let body = {}
			body["value"] = ""
			getLogs(body)
		}
		else {
			SignOut.destroy().then((response) => {
				if (
					(getOr("", "status", response) === 200 ||
						getOr("", "status", response) === 201) &&
					isEmpty(getOr({}, "data.errors", response))
				) {
					localStorage.removeItem('dashboardName')
					localStorage.removeItem('dashboardId')
					localStorage.removeItem('dashboardSubPage')
					localStorage.removeItem('dashboardCsvUploaded')
					localStorage.removeItem('dashboardStartDate')
					localStorage.removeItem('dashboardEndDate')
					localStorage.removeItem('dashboardStatic')
					localStorage.removeItem('option')
					Defender.logout();
					toast.error(`Signed out`, {
						theme: 'colored'
					});
					navigate("/logout");
				}
			})
		}
	}, []);

	return (
		<>
			<NavbarAdmin />
			<div className="content">
				<section className="py-3">
					<div className="container container--1100">
							<ActivityLogTable 
								log={logs} 
								getLogs={getLogs}
								setSuggestions={setSuggestions}
								suggestions={suggestions}
								getSuggestions={getSuggestions}
							/>
					</div>
				</section>
			</div>
		</>
	);
};

export default ActivityLog;