/* eslint-disable */
import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const InsurerChart = ({ year1, year2, year3, year4, colorHex }) => {
  const options = {
  
  credits: {
        enabled: false
    },

    title: {
      text: "",
    },
    chart: {
      type: "column",
      zoomType: "x",
      height: '80%',
      resetZoomButton: {
        position: {
          x: 0,
          y: 0,
        },
      },
    },
    xAxis: {
      categories: ["MEDICAID", "MEDICARE", "PI", "MCR PART D"],
      crosshair: true,
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
       title: {
        text: ""
    },
      labels: {
        format: '{value}%'
    },
      },
    tooltip: {
      headerFormat:
        `<span style="font-size:10px">Insurer</span><table>`,
      pointFormat:
        '<tr><td style="color:{series.color};padding:0"></td>' +
        '<td style="padding:0"><b>{point.a:,.f}%</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.a}%",
        },
      },
    },
    series: [
      {
        name: "CHS InsurerChart",
        colorByPoint: true,
        data: [
          {
            name: "CHS InsurerChart",
            a: year1,
            y: year1,
            color: colorHex || "#2A8B8C",
          },
          {
            name: "Safari",
            a: year2,
            y: year2,
            color: colorHex || "#2A8B8C",
          },
          {
            name: "Firefox",
            a: year3,
            y: year3,
            color: colorHex || "#2A8B8C",
          },
          {
            name: "Edge",
            a: year4,
            y: year4,
            color: colorHex || "#2A8B8C",
          } 
        ],
      },
    ],
  };
  return (
    <>
      <div className="card card--chart">
	  <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>Payment & Adjustment as a percent of Billed Amount by Payer</h5>
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
              <Popover
                id={"popover-pos  itioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                  marginTop: "0",
                  transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                  Description of what this chart means and definitions of any
                  unfamililar or technical terms
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </>
  );
};

export default InsurerChart;
