import React from 'react';
import TwoFactorForm from './TwoFactorForm';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/img/sp-images/access/access-twofactor.jpg';

const TwoFactor = () => (
  <div className="access">
    <AuthSplitLayout
      bgProps={{ image: bgImg, position: '50% 76%', overlay: true }}
    >
      <div className="text-center access-card-content">
        <h3 className="">Enter Your Code</h3>
        <p>A code has been emailed to you, copy and paste that code here below to access your account.</p>
          <TwoFactorForm />
      </div>
    </AuthSplitLayout>
  </div>
);

export default TwoFactor;
