import React, { useState, useEffect } from 'react';
import NavbarStandard from './front/NavbarStandard';
import Footer from './front/Footer';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import Organization from "./../../api/organizations";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from 'react-toastify';

// Images
import bg3 from 'assets/img/sp-images/home/bg3.png';

const About = ({ }) => {
	const navigate = useNavigate();
	const [interest, setInterest] = useState([]);
	const [validated, setValidated] = useState(false);
	const [checkValid, setCheckValid] = useState(true);
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		organizationName: '',
		organizationType: '',
		email: '',
		phone: '',
		message: '',
	});

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleCheck = e => {
		let interestArray = interest
		if (interestArray.includes(e.target.value)) {
			interestArray = interestArray.filter((u) => u !== e.target.value)
		}
		else {
			interestArray.push(e.target.value)
			setCheckValid(false)
		}
		setInterest(interestArray)
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (formData.firstName !== "" && formData.lastName !== "" && formData.organizationType !== "" && formData.organizationName !== "" && formData.email !== "" && formData.phone !== "" && interest.length > 0) {
			let data = {}
			data["organization_name"] = formData.organizationName
			data["organization_type"] = formData.organizationType
			data["first_name"] = formData.firstName
			data["last_name"] = formData.lastName
			data["email"] = formData.email
			data["phone"] = formData.phone
			data["message"] = formData.message
			if (!isEmpty(interest)) {
				data["interest"] = interest.toString()
			}
			setCheckValid(false)
			Organization.sendContactEmail(data).then((response) => {
				if (
					(getOr("", "status", response) === 200 ||
						getOr("", "status", response) === 201) &&
					isEmpty(getOr({}, "data.errors", response))
				) {
					toast.success(`Email has been sent`, {
						theme: 'colored'
					});
					setFormData({
						...formData,
						firstName: '',
						lastName: '',
						phone: '',
						email: '',
						message: '',
						organizationType: '',
						organizationName: '',
					});
					setInterest([])
					navigate("/");
				}
				else {
					setFormData({
						...formData,
						firstName: '',
						lastName: '',
						phone: '',
						email: '',
						message: '',
						organizationType: '',
						organizationName: '',
					});
					toast.error(`Email not sent`, {
						theme: 'colored'
					});
				}
			})
		}
		else {

			if (interest.length < 0) {
				setCheckValid(true)
			}

			toast.error(`Please fill the  fields`, {
				theme: 'colored'
			});

			setValidated(true)
		
		}
	};

	return (
		<>
			<NavbarStandard />

			<div className="content content--front">
				<section className="py-0">
					<div 
						className="heading bar3"
						style={{ backgroundImage:`url(${bg3})` }} >
						<div className="container">
							<div className="heading__overline">Contact Bernie Yazzie Consulting</div>
							<h1 className="page-title">Get a Quote</h1>
							<div className="subtitle">Contact us to learn more about our pricing and our solutions for your organization.</div>
						</div>
					</div>
					<div className="container max-800">
						<Form className="contact-form" noValidate validated={validated} onSubmit={handleSubmit}>
							<div className="intro">Bernie Yazzie Consulting is a team of credentialed experts that utilize our proprietary cloud platform to  provide key insights that empower leadership to guide their businesses to a higher standard of care and profitability.</div>

							<p>Connect with us to learn how Bernie Yazzie Consulting can help your teams make wiser, better decisions by understanding the story the data is telling.</p>

							Call us at: <a href="tel:+19282066550"><b>(928) 206-6550</b></a> or fill out the form below:

							<div className="spacer mb-5"></div>

							<Form.Group className="row">
								<div className="col-sm-6 mb-4">
									<Form.Label>Organization Name</Form.Label>
									<Form.Control type="text" placeholder="" name="organizationName" onChange={handleFieldChange} value={formData.organizationName}  required />
								</div>
								<div className="col-sm-6 mb-4">
									<Form.Label>Organization Type</Form.Label>
									<Form.Select aria-label="Organization Type example" name="organizationType" onChange={handleFieldChange} value={formData.organizationType} required >
										<option value="">Select</option>
										<option value="IHS">IHS</option>
										<option value="638">638</option>
										<option value="Title 5<">Title 5</option>
									</Form.Select>
								</div>
							</Form.Group>
							<Form.Group className="row">
								<div className="col-sm-6 mb-4">
									<Form.Label>First Name</Form.Label>
									<Form.Control type="text" placeholder="" name="firstName" onChange={handleFieldChange} value={formData.firstName} required />
								</div>
								<div className="col-sm-6 mb-4">
									<Form.Label>Last Name</Form.Label>
									<Form.Control type="text" placeholder="" name="lastName" onChange={handleFieldChange} value={formData.lastName} required />
								</div>
							</Form.Group>
							<Form.Group className="row">
								<div className="col-sm-6 mb-4">
									<Form.Label>Email address</Form.Label>
									<Form.Control type="email" placeholder="name@example.com" name="email" onChange={handleFieldChange} value={formData.email} required />
								</div>
								<div className="col-sm-6 mb-4">
									<Form.Label>Phone</Form.Label>
									<Form.Control type="tel" placeholder="(000) 000-0000" name="phone" onChange={handleFieldChange} value={formData.phone} required />
								</div>
							</Form.Group>
							<Form.Group className="mb-4">
								<Form.Label>Area of interest</Form.Label>
								<div key="checkboxes" className="mb-4">
									<Form.Check
										type="checkbox"
										id="check1"
										label="Reporting Analytics"
										onChange={handleCheck}
										value="Reporting Analytics"
										required={checkValid}
									/>
									<Form.Check
										type="checkbox"
										id="check2"
										label="Training"
										onChange={handleCheck}
										value="Training"
										required={checkValid}
									/>
									<Form.Check
										type="checkbox"
										id="check3"
										label="Consulting"
										onChange={handleCheck}
										value="Consulting"
										required={checkValid}
									/>
									<Form.Check
										type="checkbox"
										id="check4"
										label="Support services"
										onChange={handleCheck}
										value="Support services"
										required={checkValid}
									/>
								</div>
							</Form.Group>
							<Form.Group className="mb-5">
								<Form.Label>Your Message</Form.Label>
								<Form.Control as="textarea" rows={3} name="message" onChange={handleFieldChange} value={formData.message}  required />
							</Form.Group>
							<Button className="btn--rg" variant="primary" type="submit">
								Submit
							</Button>
						</Form>
					</div>
				</section>
			</div>

			<Footer />
		</>
	);
};

export default About;