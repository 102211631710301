import React, { useState, useEffect, useRef } from "react";
import Dashboard from "./../../../../api/dashboards";
import Defender from "./../../../../helpers/defender";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import TilesTable from "../tiles-table/TilesTable";
import ModalTiles from "../modals/ModalTiles";
import Dashboards from "./../../../../api/dashboards";

const Tiles = ({}) => {
  const [showModal, setShowModal] = useState(false);
  const [ModalData, setModalData] = useState([]);
  const [chartsData, setChartData] = useState([]);

  const getChartData = () => {
    Dashboards.getChartsByDashboardTitle().then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setChartData(response.data)
      }
    })
  }

  useEffect(() => {
    if (getOr([], "_r", Defender.currentUser()).includes("admin") || getOr([], "_r", Defender.currentUser()).includes("sub_admin") ){
      getChartData()
    }
    else{
      window.location.href = '/'
    }
  }, []);

  const handleSubmit = () => {
    if (getOr([], "_r", Defender.currentUser()).includes("admin") || getOr([], "_r", Defender.currentUser()).includes("sub_admin")){
      window.location.href = '/admin'
    }

  };

  return (
    <>
      <div className="content">
        <section className="py-0">
          <div className="admin__org-header modal-header mb-3">
            <div className="modal-title w700 h4">Tiles</div>
            <div className="form-actions">
              {getOr([], "_r", Defender.currentUser()).includes("admin") || getOr([], "_r", Defender.currentUser()).includes("sub_admin")  ? (
                <a href="/admin" className="btn btn-falcon-default">
                  Cancel
                </a>
              ) : null}
              <a onClick={handleSubmit} className="btn btn-primary">
                Save & Close
              </a>
            </div>
          </div>
          {showModal ? (
            <ModalTiles
              sections={chartsData}
              setShowModal={setShowModal}
              ModalData={ModalData}
              getChartData={getChartData}
            />
          ) : (
            <div className="container container--800">
              <div className="org-table">
                <TilesTable
                  sections={chartsData}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  setModalData={setModalData}
                  getChartData={getChartData}
                />
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default Tiles;
