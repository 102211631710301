import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isEmpty } from "lodash/fp";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";
import { HiOutlineArrowsExpand, HiZoomOut } from "react-icons/hi";
import CardModal from "../../Modals/CardModal";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const AmountsByMonth = ({
  data,
  description,
  increaseChartNo,
  decreaseChartNo,
  cancelLooping,
  chartNo,
  looping,
  currentNo,
  title
}) => {
	let monthsWithFiscal = Array(data.length).fill('')
	let rByMonthWithFiscal = Array(data.length).fill(0)
	let tByMonthWithFiscal = Array(data.length).fill(0)
  let adjByMonthWithFiscal = Array(data.length).fill(0)
	let aByMonthWithFiscal = Array(data.length).fill(0)
	if (!isEmpty(data)) {
		for (let l = 0; l < (data.length); l++) {
			for (let m = 0; m < 4; m++) {
				monthsWithFiscal[l] = !isEmpty(data[l]["month_year"]) ? data[l]["month_year"] : "(BLANK)"
				rByMonthWithFiscal[l] = data[l]["tsr_pay_amt"]
				tByMonthWithFiscal[l] = data[l]["billed_amt"]
        adjByMonthWithFiscal[l] = data[l]["tsr_adj_amt"]
				aByMonthWithFiscal[l] = data[l]["atb_amt"]
			}
		}
	}
	const chartRef = useRef();

	const handleResetZoom = () => {
	  const chart = chartRef.current.chart;
	  chart.zoom();
	};

  
  const options = { 
  
  credits: {
        enabled: false
    },

    chart: {
      type: "line",
      zoomType: "x",
      resetZoomButton: {
        position: {
          x: -3000,
          y: 0,
        },
      },
    },

		plotOptions: {
			areaspline: {
				fillOpacity: 0.1
			},
			series: {
				groupPadding: .15,
        animation: false,
				dataLabels: {
					rotation: 360,
					x: 0,
          y: -10,
					align: 'left',
          verticalAlign: 'top',
					enabled: true,
					color: '#000',
					formatter: function() {
						if (this.y > 1000000) {
						  return Highcharts.numberFormat(this.y / 1000000, 1) + "M"
						} else if (this.y > 1000) {
						  return Highcharts.numberFormat(this.y / 1000, 1) + "K";
						} else {
						  return this.y
						}
					  }
				},
			}
		},

    title: {
      text: "",
    },

    xAxis: {
      categories: monthsWithFiscal,
    },

    yAxis: {
      title: {
        text: "",
      },
    },

    series: [
      {
        name: "Charges",
        data: tByMonthWithFiscal,
        color: "#bc6541",
      },
      {
        name: "Revenue",
        data: rByMonthWithFiscal,
        color: "#2A8B8C",
      },
      {
        name: "Adjustments",
        data: adjByMonthWithFiscal,
        color: "#e1a95b",
      },
      {
        name: "ATB",
        data: aByMonthWithFiscal,
        color: "#90a9ac",
      },
    ],
  };


	useEffect(() => {
		if (chartNo === 10 && looping){
			setShow(true)
		}
		if (chartNo !== 10){
			setShow(false)
		}
	  }, [chartNo]);

	const [show, setShow] = useState(false);

	const handleClose = () => {
    setShow(false);
    cancelLooping()
  }
  
	const ChartCard = () => {
  
	  return (
      <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <div className="d-flex gap-2">
          <div>
            <HiZoomOut onClick={() => handleResetZoom()} className="card-header__question-mark" />
          </div>
          <HiOutlineArrowsExpand onClick={() => setShow(true)} className="card-header__question-mark card-header__expand" />
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
            <Popover
              id={"popover-pos  itioned-" + "bottom-end"}
              arrow={false}
              arrowProps={false}
              style={{
              marginTop: "0",
              transition: "all 0.2s ease-in-out, transform 0s;",
              }}
            >
              <Popover.Body>
              {description}
              </Popover.Body>
            </Popover>
            }
          >
            <div>
            <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
          </div>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
        </div>
      </div>
      </>
	  )
	};

	return (
	  <>
	  <ChartCard />
	  <CardModal 
      title={"Amounts by Month"} 
      subTitle={""} 
      highcharts={Highcharts} 
      options={options} 
      show={show} 
      handleClose={handleClose} 
      zoom={true} 
      increaseChartNo={increaseChartNo}
      decreaseChartNo={decreaseChartNo}
      currentNo={currentNo}
      />
	  </>
	);

};

export default AmountsByMonth;
