import React, { useState, useEffect, useContext } from 'react';
import { Col } from "react-bootstrap";
import Chart from "../AV/charts/other-chart";
import PharmacyChart from "./chart";
import Dashboard from "./../../../../../../api/dashboards";
import { isEmpty, getOr } from "lodash/fp";
import { DashContext } from 'context/Context';

const PharmacyTotals = () => {

  const [data, setData] = useState({})
  const [charts, setCharts] = useState([])
  const [pharmacyPrescriptions, setPharmacyPrescriptions] = useState([])
  const [pharmacyRefills, setPharmacyRefills] = useState([])
  const [pharmacyRefillsPerPrescription, setPharmacyRefillsPerPrescription] = useState([])
  const [fiscalYears, setFiscalYears] = useState([])
  const {Updatefilters} = useContext(DashContext)

  useEffect(() => {
    Updatefilters([])
  }, [])

  const [chartNo, setChartNo] = useState(1)
  const [looping, setLooping] = useState(false)

  const increaseChartNo = (num) => {
    setLooping(true)
    if (num !== 3){
      setChartNo(num + 1)
    }
    else{
      setChartNo(1)
    }
  }

  const decreaseChartNo = (num) => {
    setLooping(true)
    if (num !== 1){
      setChartNo(num - 1)
    }
    else{
      setChartNo(3)
    }
  }

  const cancelLooping = () => {
    setChartNo(1)
    setLooping(false)
  }
  useEffect(() => {
    getChartInfo()
    getChartsData()
  }, []);

  const getChart = (slug) => {
    if (!isEmpty(charts)) {
      if (charts.filter(obj => obj["slug"] === slug)) {
        return charts.filter(obj => obj["slug"] === slug)[0]
      }
    }
  }

  const getChartInfo = () => {
    let slug = window.location.href.split("/")[4]
    let dashboardName = localStorage.getItem('dashboardName')
    Dashboard.getChartsInfoBySlugAndTitle(dashboardName, slug).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setCharts(response.data)
      }
    });
  };

  const getChartsData = () => {
    let dashboardId = localStorage.getItem('dashboardId')
    Dashboard.getPharmacyTotals(dashboardId).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setData(response.data)
        setFiscalYears(getOr([], 'data.stats_by_year', response).map(a => a.fiscal_year))
        setPharmacyPrescriptions(getOr([], 'data.stats_by_year', response).map(a => a.pharmacy_prescriptions))
        setPharmacyRefills(getOr([], 'data.stats_by_year', response).map(a => a.pharmacy_refills))
        setPharmacyRefillsPerPrescription(getOr([], 'data.stats_by_year', response).map(a => a.pharmacy_refills_per_prescription))
      }
    });
  };

  const getSmallestValue = (array) => {
    return Math.min(...array);
  }

  const differenceArray = (array) => {
    let arr = array.slice(1).map((v, i) => v - array[i])
    arr.unshift(0)
    return arr
  }

  return (
    <>
    {!isEmpty(data) && !isEmpty(charts) ?
      <div className="container px-0">
        <div className="row">
        <Col lg={4} xl={6} xxl={4} className="chart-col">
          <Chart
                fiscalYears={fiscalYears}
                data={pharmacyPrescriptions}
                description={getChart('total-pharmacy-prescriptions-by-fiscal-year')["description"]}
                title={getChart('total-pharmacy-prescriptions-by-fiscal-year')["name"]}
                colorHex={"#2A8B8C"}
                min={getSmallestValue(pharmacyPrescriptions)}
                differenceArray={differenceArray(pharmacyPrescriptions)}
                increaseChartNo={increaseChartNo}
                decreaseChartNo={decreaseChartNo}
                cancelLooping={cancelLooping}
                chartNo={chartNo}
                looping={looping}
                currentNo={1}
               />
          </Col>
          <Col lg={4} xl={6} xxl={4} className="chart-col">
          <Chart
                fiscalYears={fiscalYears}
                data={pharmacyRefills}
                description={getChart('total-pharmacy-refills-by-fiscal-year')["description"]}
                title={getChart('total-pharmacy-refills-by-fiscal-year')["name"]}
                colorHex={"#733837"}
                min={getSmallestValue(pharmacyRefills)}
                differenceArray={differenceArray(pharmacyRefills)}
                increaseChartNo={increaseChartNo}
                decreaseChartNo={decreaseChartNo}
                cancelLooping={cancelLooping}
                chartNo={chartNo}
                looping={looping}
                currentNo={2}
               />
          </Col>          
          <Col lg={4} xl={6} xxl={4} className="chart-col">
          <PharmacyChart
                fiscalYears={fiscalYears}
                data={pharmacyRefillsPerPrescription}
                description={getChart('total-pharmacy-refills-per-prescription-by-fiscal-year')["description"]}
                title={getChart('total-pharmacy-refills-per-prescription-by-fiscal-year')["name"]}
                colorHex={"#e1a95b"}
                min={getSmallestValue(pharmacyRefillsPerPrescription)}
                differenceArray={differenceArray(pharmacyRefillsPerPrescription)}
                increaseChartNo={increaseChartNo}
                decreaseChartNo={decreaseChartNo}
                cancelLooping={cancelLooping}
                chartNo={chartNo}
                looping={looping}
                currentNo={3}
               />
          </Col>
        </div>
      </div>
      : null}
    </>
  );
};

export default PharmacyTotals;
