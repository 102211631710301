import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';
import AuthSplitLayout from './AuthSplitLayout';
import bgImg from 'assets/img/sp-images/access/access-login.jpg';
import Flex from 'components/common/Flex';
import logo from 'assets/img/spearpoint-logo_1A.png';

const Login = () => {
  return (
	<div className="access">
		<AuthSplitLayout bgProps={{ image: bgImg, position: '50% 20%' }}>
			<div className="access__logo">
				<img className="me-2" src={logo} alt="Logo" />
			</div>
	  		<Flex alignItems="center" justifyContent="between">
				<h3>Login</h3>
	  		</Flex>
	  		<LoginForm layout="split" hasLabel />
		</AuthSplitLayout>
	</div>
  );
};

export default Login;
