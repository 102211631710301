import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Form, Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import CardDropdown from 'components/common/CardDropdown';
import UserTableWrapper from './UserTableWrapper';
import UserTableHeader from './UserTableHeader';
import UserTableMain from './UserTableMain';
import { recentPurchaseTableData } from 'data/dashboard/ecom';
import UserTableFooter from './UserTableFooter';
import { getOr, isEmpty } from "lodash/fp";
import Defender from 'helpers/defender';
import { AutoComplete } from "primereact/autocomplete";
// import User from "./../../../../api/users";
// import { toast } from 'react-toastify';
// Icons

const UserTable = ({ users, getUsers, dashboards, removeUser, updateUserStatus, userStatusList, getDashboards, title, type, extraType, suggestions, getUsersSuggestions, searchUsers }) => {
	const [data] = useState(recentPurchaseTableData);
	const [showModal, setShowModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState({});

	const [value, setValue] = useState("");

	const openModal = (user, e) => {
		e.preventDefault()
		setSelectedUser(user)
		setShowModal(true)
	}
	const closeModal = (e) => {
		e.preventDefault()
		setSelectedUser({})
		setShowModal(false)
	}

	const [modalShow, setModalShow] = useState(false);

	const [RemoveUserData, setRemoveUserData] = useState({})

	const RemoveModal = ({ removeUser, RemoveUserData }) => {
		return (
			<>
				<Modal
					show={modalShow}
					onHide={() => setModalShow(false)}
					size="md"
					aria-labelledby="remove-modal"
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>Delete User</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>Are you sure you want to delete user <strong>{RemoveUserData['full_name']}</strong> from your organization.</div>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => {
							setModalShow(false)
							removeUser(RemoveUserData['id'], RemoveUserData['e'])
						}
						} variant="btn-danger" className="btn-danger">Remove</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	const [superModalShow, setSuperModalShow] = useState(false);

	const SuperRemoveModal = ({ removeUser, RemoveUserData }) => {
		const [userEmail, setUserEmail] = useState('');
		const [confirmEmail, setConfirmEmail] = useState('');
		const [isEmailConfirmed, setisEmailConfirmed] = useState(false);

		useEffect(() => {
			setUserEmail(RemoveUserData['email']);
		}, [RemoveUserData['email']]);


		const handleFieldChange = (e) => {
			setConfirmEmail(e.target.value)
			if (e.target.value === userEmail) {
				setisEmailConfirmed(true)
			} else {
				setisEmailConfirmed(false)
			}
		}

		return (
			<>
				<Modal
					show={superModalShow}
					onHide={() => setSuperModalShow(false)}
					size="md"
					aria-labelledby="remove-modal"
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>{extraType === "AllUsers" ? "Delete user" : "Delete Superadmin user"}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{extraType === "AllUsers" ?
							<div>Type the email of this user:<br /><strong>{RemoveUserData['email']}</strong> to delete them. This action is<br /> permanent and cannot be undone.</div>
							: <div>Type the email of this SuperAdmin user:<br /><strong>{RemoveUserData['email']}</strong> to delete them. This action is<br /> permanent and cannot be undone.</div>
						}
						<Form.Group className="mt-4">
							<Form.Label>Type email of account to delete</Form.Label>
							<Form.Control
								type="text"
								value={confirmEmail}
								onChange={(e) => handleFieldChange(e)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => {
							setSuperModalShow(false)
							removeUser(RemoveUserData['id'], RemoveUserData['e'])
						}
						} variant="btn-danger" className='btn-danger' disabled={!isEmailConfirmed}>{extraType === "AllUsers" ? 'Delete User' : 'Delete SuperAdmin User'}</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}


	const columns = [
		{
			accessor: 'user',
			Header: 'User',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'fw-semi-bold'
			},
			Cell: rowData => {
				const { full_name } = rowData.row.original;
				const { id } = rowData.row.original;
				const { user_role } = rowData.row.original;
				let statusIndex = userStatusList.findIndex(x => x["id"] === id)
				return <div>
					<div className="org-table__name" onClick={(e) => openModal(rowData.row.original, e)}>{full_name}</div>
					{extraType === "AllUsers" && !isEmpty(user_role) ?
						<>
							{user_role[0].toUpperCase() + user_role.slice(1).toLowerCase()}
						</>
						: null}
					<div className="org-table__meta org-table__meta--1">
						{Defender.currentUser()._r.includes("admin") ? getOr(false, "status", userStatusList[statusIndex]) ? <div className="badge badge-soft-success"  onClick={(e) => updateUserStatus(getOr(0, 'id', rowData.row.original), false, e)}>Active</div> :	<div className="badge badge-soft-danger"   onClick={(e) => updateUserStatus(getOr(0, 'id', rowData.row.original), true, e)}>Inactive</div> : null}</div>
				</div>;
			}
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end py-2'
			},
			Cell: rowData => {
				const { id, full_name, email } = rowData.row.original;
				return (
					<CardDropdown iconClassName="fs--1" drop="start">
						<div className="py-2">
							<Dropdown.Item onClick={(e) => openModal(rowData.row.original, e)}>Edit</Dropdown.Item>
							<Dropdown.Item
								onClick={(e) => {
									type === 'SuperAdmin'
										? (
											setRemoveUserData({ id, e, full_name, email }),
											setSuperModalShow(true)
										)
										: (
											setRemoveUserData({ id, e, full_name, email }),
											setModalShow(true)
										);
								}}
								className="text-danger"
							>
								Delete
							</Dropdown.Item>

						</div>
					</CardDropdown>
				);
			}
		}
	];

	const handleSearchValue = (e) => {
		setValue(e.target.value)
		if (e.target.value === ""){
			let body = {}
			body["value"] = e.target.value
			getUsers("all")
		}
	}

	const handleSearchValueKey = (e) => {
		setValue(e.target.value)
		if (e.target.value === "" && e.key === "Backspace"){
			let body = {}
			body["value"] = e.target.value
			getUsers("all")
		}
	}

	const search = (event) => {
		if (event.query.length >= 2){
			getUsersSuggestions(event.query)
		}
	}

	const searchUsersOnClick = () => {
		let body = {}
		body["value"] = value
		searchUsers(body)
	}

	return (
		<UserTableWrapper
			columns={columns}
			data={users}
			selection
			sortable
			pagination
			perPage={7}
			rowCount={users.length}
		>
			<Card className="mb-3">
				<Card.Header>
					<UserTableHeader table getUsers={getUsers} dashboards={dashboards} data={users} selectedUser={selectedUser} showModal={showModal} closeModal={closeModal} getDashboards={getDashboards} title={title} type={type} extraType={extraType} />
					{type === 'SuperAdmin' ? (
						<SuperRemoveModal removeUser={removeUser} RemoveUserData={RemoveUserData} />
					) : (
						<RemoveModal removeUser={removeUser} RemoveUserData={RemoveUserData} />
					)}
					<div className='reports p-fluid'>
						<AutoComplete value={value} suggestions={suggestions} completeMethod={search} className="reports__auto-complete" placeholder="Search" onChange={(e) => handleSearchValue(e)} onKeyUp={(e) => handleSearchValueKey(e)}/>
						<Button className='reports__cta' onClick={() => searchUsersOnClick()}>Search</Button>
					</div>
				</Card.Header>
				<Card.Body className="p-0">
					<UserTableMain
						table
						headerClassName="org-table__header text-900 text-nowrap align-middle"
						rowClassName="btn-reveal-trigger text-nowrap pe-0 align-middle"
						tableProps={{
							size: 'sm',
							className: 'fs--1 mb-0 overflow-hidden'
						}}
					/>
				</Card.Body>
				<Card.Footer>
					<UserTableFooter rowCount={users.length} table rowInfo navButtons extraType={extraType}/>
				</Card.Footer>
			</Card>
		</UserTableWrapper>
	);
};

export default UserTable;
