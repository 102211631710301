import React, { useState, useEffect, useRef } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { isEmpty, groupBy } from "lodash/fp";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { SlQuestion } from 'react-icons/sl';
import { HiOutlineArrowsExpand, HiZoomOut } from "react-icons/hi";
import CardModal from "../../Modals/CardModal";

Highcharts.setOptions({
	lang: {
		thousandsSep: ','
	}
});



const AllocationOfCharges = ({ data, description, title, increaseChartNo, decreaseChartNo, cancelLooping, chartNo, looping, currentNo }) => {
	let slicedProviders = Array(data.length).fill('')
	let slicedRevenue = Array(data.length).fill(0)
	let slicedAdjustment = Array(data.length).fill(0)
	let slicedAtb = Array(data.length).fill(0)
	let slicedTotal = Array(data.length).fill(0)
	if (!isEmpty(data)) {
		for (let l = 0; l < (data.length); l++) {
			for (let m = 0; m < 4; m++) {
				slicedProviders[l] = !isEmpty(data[l]["provider"]) ? data[l]["provider"] : "(BLANK)"
				slicedRevenue[l] = data[l]["tsr_pay_amt"]
				slicedAdjustment[l] = data[l]["tsr_adj_amt"]
				slicedAtb[l] = data[l]["atb_amt"]
				slicedTotal[l] = data[l]["billed_amt"]
			}
		}
	}


	const chartRef = useRef();

	const handleResetZoom = () => {
	  const chart = chartRef.current.chart;
	  chart.zoom();
	};


	const options = {
		
		credits: {
			enabled: false
		},

		chart: {
			type: 'bar',
			zoomType: 'y',
			resetZoomButton: {
				position: {
					x: -3000,
					y: 0
				}
			}
		},

		plotOptions: {
			areaspline: {
				fillOpacity: 0.1
			},
			series: {
				stacking: 'normal',
				groupPadding: .15,
				animation: false,
				dataLabels: {
					rotation: 360,
					verticalAlign: 'top',
					y: 12,
					x: 0,
					align: 'left',
					enabled: true,
					color: '#000',
					formatter: function() {
						if (this.y > 1000000) {
						  return Highcharts.numberFormat(this.y / 1000000, 1) + "M"
						} else if (this.y > 1000) {
						  return Highcharts.numberFormat(this.y / 1000, 1) + "K";
						} else {
						  return this.y
						}
					  }
				},
			}
		},


		title: {
			text: ''
		},

		xAxis: {
			categories: slicedProviders
		},
		yAxis: {
			min: 0,
			title: {
				text: ''
			}
		},

		series: [{
			name: 'Charges',
			data: slicedTotal,
			color: '#bc6541'
		}, {
			name: 'Revenue',
			data: slicedRevenue,
			color: '#2A8B8C'
		}, {
			name: 'Adjustments',
			data: slicedAdjustment,
			color: '#e1a95b'
		}, {
			name: 'ATB',
			data: slicedAtb,
			color: '#90a9ac'
		}]
	};



	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
		cancelLooping()
	  }
	
		useEffect(() => {
			if (chartNo === 4 && looping){
				setShow(true)
			}
			if (chartNo !== 4){
				setShow(false)
			}
		  }, [chartNo]);
  
	const ChartCard = () => {
  
	  return (
		<>
		<div className="card card--chart">
			<div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
				<h5>{title}</h5>
				<div className="d-flex gap-2">
				<div>
					<HiZoomOut onClick={() => handleResetZoom()} className="card-header__question-mark" />
				</div>
				<HiOutlineArrowsExpand onClick={() => setShow(true)} className="card-header__question-mark card-header__expand" />
				<OverlayTrigger
				trigger="hover"
				placement="bottom-end"
				overlay={
					<Popover
					id={"popover-pos  itioned-" + "bottom-end"}
					arrow={false}
					arrowProps={false}
					style={{
						marginTop: "0",
						transition: "all 0.2s ease-in-out, transform 0s;",
					}}
					>
					<Popover.Body>
						{description}
					</Popover.Body>
					</Popover>
				}
				>
				<div>
					<SlQuestion className="card-header__question-mark" />
				</div>
				</OverlayTrigger>
				</div>
			</div>
			<div className="card-body">
				{!isEmpty(data) ?
					<HighchartsReact highcharts={Highcharts} options={options}  ref={chartRef} />
					: null}
			</div>
		</div>
		</>
	  )
	};
		return (
	  <>
	  <ChartCard />
	  <CardModal 
			title={"Allocation of Charges by Top 10 Providers"} 
			subTitle={""} 
			highcharts={Highcharts} 
			options={options} 
			show={show} 
			handleClose={handleClose} 
			zoom={true}
      increaseChartNo={increaseChartNo}
      decreaseChartNo={decreaseChartNo} 
			currentNo={currentNo}
		/>
	  </>
	);


};

export default AllocationOfCharges;