
export const summaryRoutes = [ {
  label: 'Core Analytics',
  children: [
    {
      name: 'Executive Summary',
      icon: 'layer-group',
      to: '/dash',
      children: false
    },
    {
      name: 'Operations Summary',
      to: '/',
      children: [
          { 
            label: 'Patient Registration',
            to: '/operations-summary/patient-registration',
           },
           { 
            label: 'Third Party Eligibility',
            to: '/operations-summary/third-party-eligibility',
          },
          { 
            label: 'Purchase Referred Care',
            to: '/operations-summary/purchase-referred-care',
          },
          { 
            label: 'AV by Type',
            to: '/operations-summary/av-by-type',
          },
          { 
            label: 'AV by Location',
            to: '/operations-summary/av-by-location',
          },
          { 
            label: 'AV by Service Category',
            to: '/operations-summary/av-by-service-category',
          },
          { 
            label: 'AV by Clinic',
            to: '/operations-summary/av-by-clinic',
          },
          { 
            label: 'AV by Provider Type',
            to: '/operations-summary/av-by-provider-type',
          },
          { 
            label: 'AV by TOP 10 Diagnosis (DX)',
            to: '/operations-summary/av-by-top-10-diagnosis-dx',
          },
          { 
            label: 'Injuries Total',
            to: '/operations-summary/injuries-total',
          },
          { 
            label: 'TOP 5 Injuries Types',
            to: '/operations-summary/top-5-injuries-types',
          },
          { 
            label: 'Dental Totals',
            to: '/operations-summary/dental-totals',
          },
          { 
            label: 'TOP 7 Dental Appt. Types',
            to: '/operations-summary/top-7-dental-appt-types',
          },
          { 
            label: 'Pharmacy Totals',
            to: '/operations-summary/pharmacy-totals',
          },
      ]
    }
  ]
}, {
  label: 'Ad Hoc Analytics',
} ];



export default [
  summaryRoutes,
];
