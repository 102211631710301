/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";
import { HiZoomOut } from "react-icons/hi";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const InjuryChart = ({ title, year1, year2, year3, year4, year5, year6, year7 }) => {
  
  const chartRef = useRef();

  const handleResetZoom = () => {
    const chart = chartRef.current.chart;
    chart.zoom();
  };
  
  const options = { 
  
  credits: {
        enabled: false
    },

    title: {
      text: "",
    },
    chart: {
      type: "column",
      zoomType: "y",
      resetZoomButton: {
        position: {
          x: -3000,
          y: 0,
        },
      },
    },
    xAxis: {
      categories: ["2017", "2018", "2019", "2020", "2021", "2022", "2023"],
      crosshair: true,
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
      title: {
        useHTML: true,
        text: "",
      },
    },
    tooltip: {
      headerFormat:
        `<span style="font-size:10px">${title}</span><table>`,
      pointFormat:
        '<tr><td style="color:{series.color};padding:0"></td>' +
        '<td style="padding:0"><b>{point.a:,.f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.a:,.f}",
        },
      },
    },
    series: [
      {
        name: title,
        colorByPoint: true,
        data: [
          {
            name: title,
            a: year1,
            y: year1,
            color: "#2A8B8C",
          },
          {
            name: "Safari",
            a: year2,
            y: year3,
            color: "#2A8B8C",
          },
          {
            name: "Firefox",
            a: year3,
            y: year3,
            color: "#2A8B8C",
          },
          {
            name: "Edge",
            a: year4,
            y: year4,
            color: "#2A8B8C",
          },
          {
            name: "Opera",
            a: year5,
            y: year5,
            color: "#2A8B8C",
          },
          {
            name: "Opera",
            a: year6,
            y: year6,
            color: "#2A8B8C",
          },
          {
            name: "Opera",
            a: year7,
            y: year7,
            color: "#2A8B8C",
          },
        ],
      },
    ],
  };
  return (
    <>
      <div className="card card--chart">
	  <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <div>
              <HiZoomOut onClick={() => handleResetZoom()} className="card-header__question-mark" />
          </div>
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
              <Popover
                id={"popover-positioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                  marginTop: "0",
                  transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                  Description of what this chart means and definitions of any
                  unfamililar or technical terms
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </>
  );
};

export default InjuryChart;
