import React, { useState, useEffect } from 'react';
import NavbarAdmin from './NavbarAdmin';
import InvoiceReportsTable from './tables/invoice-reports-table/InvoiceReportsTable';

const InvoiceReports = ({ }) => {

	const invoices = [
		{
			name:"Lorem Ipsum Health Center",
			po: "0032",
			invoice_id: "0032",
			invoice_date: "01/02/2023",
			invoice_amount: "$7,500",
			status: "Paid",
		},
		{
			name:"Lorem Ipsum Health Center",
			po: "0032",
			invoice_id: "0032",
			invoice_date: "01/02/2023",
			invoice_amount: "$7,500",
			status: "Unpaid",
		},
		{
			name:"Lorem Ipsum Health Center",
			po: "0032",
			invoice_id: "0032",
			invoice_date: "01/02/2023",
			invoice_amount: "$7,500",
			status: "Paid",
		},
		{
			name:"Lorem Ipsum Health Center",
			po: "0032",
			invoice_id: "0032",
			invoice_date: "01/02/2023",
			invoice_amount: "$7,500",
			status: "Unpaid",
		},
		{
			name:"Lorem Ipsum Health Center",
			po: "0032",
			invoice_id: "0032",
			invoice_date: "01/02/2023",
			invoice_amount: "$7,500",
			status: "Paid",
		},
	]

	return (
		<>
			<NavbarAdmin />
			<div className="content">
				<section className="py-3">
					<div className="container container--1100">
                        <InvoiceReportsTable  invoices={invoices} />
					</div>
				</section>
			</div>
		</>
	);
};

export default InvoiceReports;