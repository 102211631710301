import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TfiAngleRight } from "react-icons/tfi";
import { FaAngleLeft } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { BsChevronDown } from "react-icons/bs";
import { isEmpty, getOr } from "lodash/fp";


import Defender from "helpers/defender";
import PropTypes from 'prop-types';
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import Logo from "components/common/Logo";
import summaryRoutes from "./summaryRoutes";
import Organization from "./../../../api/organizations";
import SignOut from "./../../../api/auth/sign_out";
import User from "./../../../api/users";
import ProfileDropdown from "components/site/admin/ProfileDropdown";

const SummaryNavbarVertical = ({ parent }) => {

  const HTMLClassList = document.getElementsByTagName("html")[0].classList;

  const {
    config: { showBurgerMenu, isNavbarVerticalCollapsed },
    setConfig,
  } = useContext(AppContext);

  const [dashboards, setDashboards] = useState([]);
  const [listItems, setListItems] = useState([])
  const [mainHeading, setMainHeading] = useState('');
  const [organizationName, setOrganizationName] = useState("");
  const [activeRoute, setActiveRoute] = useState(null);
  const [activeSecondryRoute, setActiveSecondryRoute] = useState(null);
  const [activePrimaryNav, setActivePrimaryNav] = useState(true);
  const [isPrimaryAnimated, setIsPrimaryAnimated] = useState(false);
  const [isSecondryAnimated, setIsSecondryAnimated] = useState(false);
  const [isOutAnimated, setIsOutAnimated] = useState(false);
  const [subNavOpen, setSubNavOpen] = useState(false);
  const navigate = useNavigate();

  const getOrganizationDashboards = (id) => {
    Organization.getOrganizationDashboards(id).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        let dashArray = [];
        for (let i = 0; i < getOr([], "data", response).length; i++) {
          let data = getOr([], "data", response)[i]
          let dash = {};
          //if (data["name"] !== "Summary of Accounts" || data["static"] !== true) {
            dash["id"] = data["id"]
            dash["name"] = data["name"];
            if (!isEmpty(data["csv_uploaded_format"])) {
              dash["csvUploaded"] = data["csv_uploaded_format"];
            }
            if (!isEmpty(data["starting_date_format"])) {
              dash["startDate"] = data["starting_date_format"];
            }
            if (!isEmpty(data["ending_date_format"])) {
              dash["endDate"] = data["ending_date_format"];
            }
            dash["icon"] = "poll";
            dash["static"] = data["static"]
            if (data["static"] === true) {
              dash["to"] = `/dash`;
            }
            else {
              dash["to"] = `/dash/details/${data["id"]}`;
            }
            dash["active"] = false;
            dash["isLink"] = true;
            if (!isEmpty(getOr([], "dashboard_items", data))) {
              let dashboardItems = getOr([], "dashboard_items", data)
              let itemArray = []
              for (let j = 0; j < dashboardItems.length; j++) {
                let item = {}
                item["id"] = dashboardItems[j]["dashboard_item_id"]
                item["label"] = dashboardItems[j]["dashboard_item_name"]
                if (data["name"] === "Operations Summary") {
                  item["to"] = `/operations-summary/${dashboardItems[j]["dashboard_item_slug"]}`;
                }
                if (data["name"] === "Summary of Accounts") {
                  item["to"] = `/summary-of-accounts/${dashboardItems[j]["dashboard_item_slug"]}`;
                }
                if (j === 0) {
                  item["active"] = true;
                }
                else {
                  item["active"] = false;
                }
                itemArray.push(item)
              }
              dash["children"] = itemArray
            }
            else {
              dash["children"] = false
            }
            dashArray.push(dash);
          //}
        }
        //   console.log(dashArray[2] = {
        //     "id": 81,
        //     "name": "Summary Of Accounts",
        //     "icon": "poll",
        //     "static": true,
        //     "to": "/dash",
        //     "active": false,
        //     "isLink": true,
        //     "children": [
        //       {
        //           "id": 449,
        //           "label": "ATB Summary By Payer",
        //           "to": "/summary-of-accounts/atb-sum-by-payer",
        //           "active": true
        //       },
        //       {
        //           "id": 450,
        //           "label": "ATB Summary By insurer",
        //           "to": "/summary-of-accounts/atb-sum-by-insurers",
        //           "active": true
        //       },
        //       {
        //           "id": 451,
        //           "label": "ATB Summary By Visit Type",
        //           "to": "/summary-of-accounts/atb-sum-by-visit-type",
        //           "active": true
        //       },
        //       {
        //         "id": 452,
        //         "label": "Sum By Clinic & Visit Type",
        //         "to": "/summary-of-accounts/sum-by-clinic-visit-type",
        //         "active": false
        //       },
        //     {
        //         "id": 453,
        //         "label": "Sum By Clinic & Provider",
        //         "to": "/summary-of-accounts/sum-by-clinic-provider",
        //         "active": false
        //     },
        //     {
        //         "id": 454,
        //         "label": "Sum By Payer & insurer",
        //         "to": "/summary-of-accounts/sum-by-payer-insurer",
        //         "active": false
        //     },
        //     {
        //         "id": 455,
        //         "label": "Sum By insurer & Visit Type",
        //         "to": "/summary-of-accounts/sum-by-insurer-visit-type",
        //         "active": false
        //     },
        //     {
        //         "id": 456,
        //         "label": "Sum By MOS & Accts",
        //         "to": "/summary-of-accounts/sum-by-mos-accts",
        //         "active": false
        //     },
        //       {
        //         "id": 457,
        //         "label": "Sum By MOS & Clinic",
        //         "to": "/summary-of-accounts/sum-by-mos-clinic",
        //         "active": false
        //     },
        //     {
        //         "id": 458,
        //         "label": "Sum By MOS & Visit Type",
        //         "to": "/summary-of-accounts/sum-by-mos-visit-type",
        //         "active": false
        //     },
        //     {
        //         "id": 459,
        //         "label": "Sum By Visit Type & Payer",
        //         "to": "/summary-of-accounts/sum-by-visit-type-payer",
        //         "active": false
        //     },
        //       {
        //           "id": 460,
        //           "label": "Sum By Payer",
        //           "to": "/Summary-of-accounts/sum-by-payer",
        //           "active": false
        //       },
        //       { 
        //           "id": 461,
        //           "label": "Summary By Location",
        //           "to": "/summary-of-accounts/sum-by-location",
        //           "active": false
        //       },
        //       {
        //           "id": 462,
        //           "label": "Summary By Medical Center",
        //           "to": "/summary-of-accounts/sum-by-medical-center",
        //           "active": false
        //       },
        //       {
        //           "id": 463,
        //           "label": "HRN Counts",
        //           "to": "/summary-of-accounts/hrn-counts",
        //           "active": false
        //       },
        //       {
        //           "id": 464,
        //           "label": "Payer Charges & Revenue",
        //           "to": "/summary-of-accounts/payer-charges-revenue",
        //           "active": false
        //       },
        //   ]
        // })
        setDashboards(dashArray);
      }
    });
  };

  const getOperationsSummaryDashboard = () => {
    if (!isEmpty(dashboards)) {
      if (dashboards.filter(obj => obj["name"] === "Operations Summary")) {
        if (dashboards.filter(obj => obj["name"] === "Operations Summary")[0]) {
          return dashboards.filter(obj => obj["name"] === "Operations Summary")[0]["children"][0]
        }
        else {
          return {}
        }
      }
      else {
        return {}
      }
    }
    else {
      return {}
    }
  }

  const getSummaryOfAccountsDashboard = () => {
    if (!isEmpty(dashboards)) {
      if (dashboards.filter(obj => obj["name"] === "Summary of Accounts")) {
        if (dashboards.filter(obj => obj["name"] === "Summary of Accounts")[0]) {
          return dashboards.filter(obj => obj["name"] === "Summary of Accounts")[0]["children"][0]
        }
        else {
          return {}
        }
      }
      else {
        return {}
      }
    }
    else {
      return {}
    }
  }



  useEffect(() => {
    if (!isEmpty(Defender.currentUser())) {
      User.fetch(getOr(0, 'id', Defender.currentUser())).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))) {
          if (
            (getOr([], "_r", Defender.currentUser()).includes("executive") ||
              getOr([], "_r", Defender.currentUser()).includes("management"))) {
            Defender.setCurrentUser(getOr({}, 'data.user', response))
          }
          if (
            !isEmpty(Defender.currentUser()) &&
            (getOr([], "_r", Defender.currentUser()).includes("executive") ||
              getOr([], "_r", Defender.currentUser()).includes("management") ||
              getOr([], "_r", Defender.currentUser()).includes("admin") || getOr([], "_r", Defender.currentUser()).includes("sub_admin")) &&
            !isEmpty(Defender.currentUser().organization)) {
            setOrganizationName(!isEmpty(Defender.currentUser().organization.name) ? Defender.currentUser().organization.name : Defender.currentUser().organization_name);
            getOrganizationDashboards(
              Defender.currentUser().organization_id
                ? Defender.currentUser().organization_id
                : Defender.currentUser().organization
            );
            Organization.getFirstOrganizationDashboard(Defender.currentUser().organization_id
              ? Defender.currentUser().organization_id
              : Defender.currentUser().organization).then((response) => {
                if (
                  (getOr("", "status", response) === 200 ||
                    getOr("", "status", response) === 204) &&
                  isEmpty(getOr({}, "data.errors", response))
                ) {
                  localStorage.removeItem('dashboardSubPage')
                  localStorage.removeItem('dashboardStatic')
                  localStorage.removeItem('option')
                  if (!isEmpty(response.data)) {
                    localStorage.setItem('dashboardId', getOr({}, "data.dashboard.id", response))
                    localStorage.setItem('dashboardName', getOr({}, "data.dashboard.name", response))
                    localStorage.setItem('dashboardCsvUploaded', getOr({}, "data.dashboard.csv_uploaded_format", response))
                    localStorage.setItem('dashboardStartDate', getOr({}, "data.dashboard.starting_date_format", response))
                    localStorage.setItem('dashboardEndDate', getOr({}, "data.dashboard.ending_date_format", response))
                    localStorage.setItem('dashboardStatic', true)
                  }
                  if (window.location.href.split("/")[4] === "details") {
                    window.location.href = "/dash"
                  }
                  else if (window.location.href.split("/")[3] === "operations-summary") {
                    //    window.location.href = "/dash"
                  }
                }
              })

          }
          else if (
            !isEmpty(Defender.currentUser()) &&
            ((getOr([], "_r", Defender.currentUser()).includes("executive") ||
              getOr([], "_r", Defender.currentUser()).includes("management")) &&
              isEmpty(Defender.currentUser().organization))) {
            localStorage.removeItem('dashboardName')
            localStorage.removeItem('dashboardId')
            localStorage.removeItem('dashboardSubPage')
            localStorage.removeItem('dashboardCsvUploaded')
            localStorage.removeItem('dashboardStartDate')
            localStorage.removeItem('dashboardEndDate')
          }
        }
      })
    }
    else {
      SignOut.destroy().then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 201) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          localStorage.removeItem('dashboardName')
          localStorage.removeItem('dashboardId')
          localStorage.removeItem('dashboardSubPage')
          localStorage.removeItem('dashboardCsvUploaded')
          localStorage.removeItem('dashboardStartDate')
          localStorage.removeItem('dashboardEndDate')
          Defender.logout();
          toast.error(`Signed out`, {
            theme: "colored",
          });
          navigate("/logout");
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(Defender.currentUser())) {
      setTimeout(() => {
        if (!isEmpty(localStorage.dashboardId)) {
        }
        else {
          navigate("/empty-dash");
        }
      }, 500);
    }
    else {
      navigate("/");
    }
  }, [localStorage.getItem('dashboardId')]);


  useEffect(() => {
    if (isNavbarVerticalCollapsed || showBurgerMenu) {
      setActivePrimaryNav(false);
      setSubNavOpen(false);
    } else {
      setActivePrimaryNav(true);
      setIsPrimaryAnimated(true);
      if (activeRoute) {
        if (activeRoute.children) {
          setIsSecondryAnimated(true);
          setSubNavOpen(true)
          HTMLClassList.add("content-sub-nav-active");
        }
      }
    }
  }, [isNavbarVerticalCollapsed, showBurgerMenu]);


  useEffect(() => {
    if (isOutAnimated) {
      HTMLClassList.remove("dash-sidebar--active");
    } else {
      activePrimaryNav
        ? HTMLClassList.add("dash-sidebar--active")
        : HTMLClassList.remove("dash-sidebar--active");
    }
  }, [activePrimaryNav, isOutAnimated]);



  useEffect(() => {
    subNavOpen
      ? HTMLClassList.add("dash-sidebar--active")
      : HTMLClassList.remove("content-sub-nav-active");
  }, [subNavOpen]);


  useEffect(() => {
    summaryRoutes[0][0].children.map((route) =>
      route.name == parent && setActiveRoute(route)
    );
  }, [summaryRoutes]);


  useEffect(() => {
    setMainHeading(localStorage.getItem('dashboardName'))
  }, [localStorage.getItem('dashboardName')]);

  const handlePrimaryNavClick = (route) => {
    getOperationsSummaryDashboard()
    getSummaryOfAccountsDashboard()
    localStorage.removeItem('dashboardName');
    localStorage.removeItem('dashboardId');
    localStorage.removeItem('dashboardCsvUploaded');
    localStorage.removeItem('dashboardStartDate');
    localStorage.removeItem('dashboardEndDate');
    localStorage.removeItem('option')
    localStorage.removeItem('dashboardStatic')
    if (route.static) {
      localStorage.setItem('dashboardStatic', route.static)
    }
    else {
      localStorage.removeItem('dashboardStatic')
    }
    if (route.children) {
      setSubNavOpen(true);
      setIsSecondryAnimated(true);
      HTMLClassList.add("content-sub-nav-active");
      setActiveRoute(route);
      setActiveSecondryRoute(route.children);
      setIsSecondryAnimated(false);
      localStorage.setItem('dashboardId', route.id)
      localStorage.setItem('dashboardName', route.name)
      if (!isEmpty(route.csvUploaded)) {
        localStorage.setItem('dashboardCsvUploaded', route.csvUploaded)
      }
      if (!isEmpty(route.startDate)) {
        localStorage.setItem('dashboardStartDate', route.startDate)
      }
      if (!isEmpty(route.endDate)) {
        localStorage.setItem('dashboardEndDate', route.endDate)
      }
      localStorage.setItem('dashboardSubPage', "")
      handleSecondryNavClick("")
    } else {
      setSubNavOpen(false);
      HTMLClassList.remove("content-sub-nav-active");
      setIsPrimaryAnimated(false)
      setActiveRoute(route);
      navigate(route.to);
      localStorage.setItem('dashboardId', route.id)
      localStorage.setItem('dashboardName', route.name)
      if (!isEmpty(route.csvUploaded)) {
        localStorage.setItem('dashboardCsvUploaded', route.csvUploaded)
      }
      if (!isEmpty(route.startDate)) {
        localStorage.setItem('dashboardStartDate', route.startDate)
      }
      if (!isEmpty(route.endDate)) {
        localStorage.setItem('dashboardEndDate', route.endDate)
      }
      localStorage.setItem('dashboardSubPage', "")
    }
  };


  const handleSecondryReturnClick = () => {
    setSubNavOpen(false);
    setIsPrimaryAnimated(false)
    HTMLClassList.remove("content-sub-nav-active");

  };


  const handleSecondryNavClick = (route) => {
    localStorage.removeItem('option')
    if (!isEmpty(route)) {
      setActiveSecondryRoute(route.label);
      navigate(route.to);
      localStorage.setItem('dashboardSubPage', route.label)
    }
    else {
      let dashboardName = localStorage.getItem("dashboardName")
      handleSecondryNavClick(dashboardName === "Operations Summary" ? getOperationsSummaryDashboard() : getSummaryOfAccountsDashboard())
    }
  };
  const handleCloseButtonClick = () => {
    setIsPrimaryAnimated(false)
    setIsSecondryAnimated(false)
    setIsOutAnimated(true)
    const timeOut = setTimeout(() => {
      setConfig("isNavbarVerticalCollapsed", true)
      setIsOutAnimated(false)
      clearTimeout(timeOut)
    }, 300);
  };

  useEffect(() => {
    summaryRoutes[0][0].children.map((route) =>
      route.name == parent && setActiveRoute(route)
    );
  }, [summaryRoutes]);


  useEffect(() => {

    const newitemsList = [];

    summaryRoutes[0].map((route, index) => {

      newitemsList.push(route)

      dashboards.map((dashboard) => {
        if (route.label === 'Core Analytics' & dashboard.to.includes('details') === false) {
          newitemsList.push(dashboard)
        } else if (route.label === 'Ad Hoc Analytics' & dashboard.to.includes('details')) {
          newitemsList.push(dashboard)
        }
      });
    })
    if (newitemsList.some(obj => obj.to && obj.to.includes('details') === true) === false) {
      newitemsList.splice(newitemsList.length - 1, 1)
    }

    setListItems(newitemsList)

  }, [summaryRoutes, dashboards]);

  return (
    <>
      {activePrimaryNav && (
        <div
          className={`dash-sidebar ${subNavOpen ? "sub-sidebar--active" : ""} ${isPrimaryAnimated ? "ease-in" : ''
            } ${isOutAnimated ? 'ease-out' : ''}`}
        >
          <div className="dash-sidebar__content">
            <div className="dash-sidebar__content__header">
              <Flex alignItems="center" justifyContent="between">
                <Logo at="dash-sidebar" width={180} />
                <div className="dash-sidebar__close-button" onClick={handleCloseButtonClick}>
                  <MdClose />
                </div>
              </Flex>
            </div>
            <div className="dash-sidebar__content__menu">
              <h4 className="dash-sidebar__content__menu__title">
                {organizationName}
              </h4>
              {subNavOpen && activeRoute ? (
                <div
                  className="dash-sidebar__content__menu__switch-button"
                  onClick={() => handleSecondryReturnClick()}
                >
                  <FaAngleLeft
                    style={{
                      marginTop: "0.17em",
                      height: "1.2em",
                      paddingRight: "0.3em",
                    }}
                  />
                  <div>Back</div>
                </div>
              ) : ''}
              <ul className="dash-sidebar__content__menu__items">
                {subNavOpen && activeRoute ? (
                  <>
                    <li className="dash-sidebar__content__menu__separator">{mainHeading}</li>
                    {activeRoute && activeRoute.children.map((item, index) => (
                      <li
                        key={item.label}
                        onClick={() => handleSecondryNavClick(item)}
                        className={`dash-sidebar__content__menu__items__item
                      ${activeSecondryRoute && activeSecondryRoute === item.label
                            ? "dash-sidebar__content__menu__items__item--active"
                            : ""}`
                        }
                      >
                        {item.label}
                      </li>
                    ))}
                  </>
                ) : (
                  listItems.map((item, index) => (
                    item.label ? (
                      <li key={index} className="dash-sidebar__content__menu__separator mt-3">
                        {item.label}
                      </li>
                    ) : (
                      <li
                        key={index}
                        className={`dash-sidebar__content__menu__items__item
                      ${activeRoute && activeRoute.name === item.name
                            ? "dash-sidebar__content__menu__items__item--active"
                            : ""}`
                        }
                        onClick={() => handlePrimaryNavClick(item)}
                      >
                        {item.name}
                        {item.children && <TfiAngleRight />}
                      </li>
                    )

                  ))
                )}
              </ul>
            </div>
            <ProfileDropdown isCustomerDash={true} />
          </div>
        </div>
      )}

    </>
  );
};

SummaryNavbarVertical.propTypes = { parent: PropTypes.string };


export default SummaryNavbarVertical;