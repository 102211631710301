import React, { useEffect } from 'react';
import NavbarAdmin from '../../NavbarAdmin'
import Defender from "helpers/defender";
import User from "api/users";
import { isEmpty, getOr } from "lodash/fp";
import { useNavigate } from "react-router-dom";

const EmptyDash = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(Defender.currentUser())) {
      User.fetch(getOr(0, 'id', Defender.currentUser())).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))) {
          Defender.setCurrentUser(getOr({}, 'data.user', response))
          if (Defender.currentUser().organization_id){
            window.location.href = "/dash"
          }
        }
      })
    }
    else{
      window.location.href = "/"
    }
  }, []);

  return (
    <>
      <NavbarAdmin />
      <div className="content">
        <section className="py-6">
          <div className="container empty-dash__container">
            <div className='empty-dash__container__text'>Your dashboard is empty because you currently do not belong to any organizations. You either removed yourself from an organization or you were removed by an administrator.</div>

            <div className='empty-dash__container__text'>If you removed yourself by accident, or think you were removed in error, please  request an invitation to be added back to your existing organization or a new organization from a senior member of your organization with Spearpoint access by providing them with your Spearpoint email address.</div>
          </div>
        </section>
      </div>
    </>
  )
}

export default EmptyDash