import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isEmpty } from "lodash/fp";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";
import { HiOutlineArrowsExpand, HiZoomOut } from "react-icons/hi";
import CardModal from "../../Modals/CardModal";

const maxLimit = 10000000
const minLimit = -1000000

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});
const AmountsByService = ({
  data,
  description,
  increaseChartNo,
  decreaseChartNo,
  cancelLooping,
  chartNo,
  looping,
  currentNo,
  title
}) => {
  let services = Array(data.length).fill('')
  let revenueByService = Array(data.length).fill(0)
  let adjustmentByService = Array(data.length).fill(0)
  let atbByService = Array(data.length).fill(0)
  let totalByService = Array(data.length).fill(0)
  if (!isEmpty(data)) {
		for (let l = 0; l < (data.length); l++) {
			for (let m = 0; m < 4; m++) {
				services[l] = !isEmpty(data[l]["service"]) ? data[l]["service"] : "(BLANK)"
				revenueByService[l] = data[l]["tsr_pay_amt"]
				adjustmentByService[l] = data[l]["tsr_adj_amt"]
				atbByService[l] = data[l]["atb_amt"]
				totalByService[l] = data[l]["billed_amt"]
			}
		}
    services.reverse()
    revenueByService.reverse()
    adjustmentByService.reverse()
    atbByService.reverse()
    totalByService.reverse()
  }

  const chartRef = useRef();

	const handleResetZoom = () => {
	  const chart = chartRef.current.chart;
	  chart.zoom();
	};

  const options = { 
  
  credits: {
        enabled: false
    },

    chart: {
      type: "bar",
      zoomType: "y",
      height: "600px",
      resetZoomButton: {
        position: {
          x: -3000,
          y: 0,
        },
      },
    },

		plotOptions: {
			areaspline: {
				fillOpacity: 0.1
			},
			series: {
				groupPadding: .15,
        animation: false,
				dataLabels: {
					rotation: 360,
					verticalAlign: 'top',
					y: 10,
					x: 0,
					align: 'left',
					enabled: true,
					color: '#000',
					formatter: function() {
						if (this.y > 1000000) {
						  return Highcharts.numberFormat(this.y / 1000000, 1) + "M"
						} else if (this.y > 1000) {
						  return Highcharts.numberFormat(this.y / 1000, 1) + "K";
						} else {
						  return this.y
						}
					  }
				},
			}
		},

    title: {
      text: "",
    },

    xAxis: {
      categories: services,
    },

    yAxis: {
      title: {
        text: "",
      },
      max: Math.max(...totalByService) + maxLimit,
      min: minLimit
    },

    series: [
      {
        name: "Charges",
        data: totalByService,
        color: "#bc6541",
      },
      {
        name: "Revenue",
        data: revenueByService,
        color: "#2A8B8C",
      },
      {
        name: "Adjustments",
        data: adjustmentByService,
        color: "#e1a95b",
      },
      {
        name: "ATB",
        data: atbByService,
        color: "#90a9ac",
      },
    ],
  };

	useEffect(() => {
		if (chartNo === 9 && looping){
			setShow(true)
		}
		if (chartNo !== 9){
			setShow(false)
		}
	  }, [chartNo]);

	const [show, setShow] = useState(false);

	const handleClose = () => {
    setShow(false);
    cancelLooping()
  }
  
	const ChartCard = () => {
  
	  return (
      <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <div className="d-flex gap-2">
          <div>
            <HiZoomOut onClick={() => handleResetZoom()} className="card-header__question-mark" />
          </div>
          <HiOutlineArrowsExpand onClick={() => setShow(true)} className="card-header__question-mark card-header__expand" />
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
            <Popover
              id={"popover-pos  itioned-" + "bottom-end"}
              arrow={false}
              arrowProps={false}
              style={{
              marginTop: "0",
              transition: "all 0.2s ease-in-out, transform 0s;",
              }}
            >
              <Popover.Body>
              {description}
              </Popover.Body>
            </Popover>
            }
          >
            <div>
            <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
          </div>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
        </div>
      </div>
      </>
	  )
	};

	return (
	  <>
	  <ChartCard />
	  <CardModal 
      title={"Amounts by Service"} 
      subTitle={""} 
      highcharts={Highcharts} 
      options={options} 
      show={show} 
      handleClose={handleClose} 
      zoom={true} 
      increaseChartNo={increaseChartNo}
      decreaseChartNo={decreaseChartNo}
      currentNo={currentNo}
    />
	  </>
	);
};

export default AmountsByService;
