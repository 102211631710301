import React, { useContext, useEffect, useState } from 'react';
import { Col } from "react-bootstrap";
import Dashboard from "./../../../../../../api/dashboards";
import { isEmpty, getOr } from "lodash/fp";
import { DashContext } from 'context/Context';
import StackingChart from './charts/stacking-chart';
import DistributionChart from './distribution-chart';
import DataTable from './charts/table';

const AmbulatoryCareVisits = () => {

  const [opt, setOpt] = useState("")
  const [charts, setCharts] = useState([])
  const [fiscalYears, setFiscalYears] = useState([])
  const [data, setData] = useState([])
  const [dataByOption, setDataByOption] = useState([])

  const colors = ['#2A8B8C', '#733837', '#e1a95b', '#6A6E71', '#bc6541', '#7c5280', '#61724f']

  const { Updatefilters } = useContext(DashContext)

  const [chartNo, setChartNo] = useState(1)
  const [looping, setLooping] = useState(false)

  const increaseChartNo = (num) => {
    setLooping(true)
    if (num !== 2) {
      setChartNo(num + 1)
    }
    else {
      setChartNo(1)
    }
  }

  const decreaseChartNo = (num) => {
    setLooping(true)
    if (num !== 1) {
      setChartNo(num - 1)
    }
    else {
      setChartNo(2)
    }
  }

  const cancelLooping = () => {
    setChartNo(1)
    setLooping(false)
  }

  useEffect(() => {
    getChartInfo()
    getChartsData(opt, true)
  }, []);

  useEffect(() => {
    let dashboardId = localStorage.getItem('dashboardId')
    Dashboard.getYearsComparisonByOption(dashboardId, opt).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setDataByOption(getOr([], 'data.stats_by_fiscal_year', response))
      }
    })
  }, [opt]);

  const getChartDescription = (name) => {

    if (!isEmpty(charts)) {
      if (charts.filter(obj => obj["name"] === name)) {
        if (charts.filter(obj => obj["name"] === name)[0]) {
          return charts.filter(obj => obj["name"] === name)[0]["description"]
        }
        else {
          return ""
        }
      }
      else {
        return ""
      }
    }
    else {
      return ""
    }
  }

  const getChartInfo = () => {
    let slug = window.location.href.split("/")[4]
    let dashboardName = localStorage.getItem('dashboardName')
    Dashboard.getChartsInfoBySlugAndTitle(dashboardName, slug).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setCharts(response.data)
      }
    });
  };

  const getChart = (slug) => {
    if (!isEmpty(charts)) {
      if (charts.filter(obj => obj["slug"] === slug)) {
        return charts.filter(obj => obj["slug"] === slug)[0]
      }
    }
  }

  const getChartsData = (option, pageLoad) => {
    let dashboardId = localStorage.getItem('dashboardId')
    Dashboard.getYearsComparison(dashboardId).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setFiscalYears(getOr([], 'data.fiscal_years', response))
        let chartData = getOr([], 'data.chart_data', response)
        setData(chartData)
        setOpt(chartData[0])
      }
    });
  };

  const selectOption = (primaryCategory, secondaryCategory) => {
    let data = {}
    data["primary_category"] = primaryCategory
    data["secondary_category"] = secondaryCategory
    setOpt(data)
  }

  useEffect(() => {
    Updatefilters([]);
  }, []);

  return (
    <>
      {!isEmpty(data) && !isEmpty(charts) ?
        <div className="container px-0">
          <>
            <div className="row">
              <Col lg={6} xl={6} className="chart-col">
                <DataTable
                  description={getChart('av')["description"]}
                  title={getChart('av')["name"]}
                  data={data}
                  selectOption={selectOption}
                />
              </Col>
              <Col lg={6} xl={6} className="chart-col">
                <StackingChart
                  description={getChart('ambulatory-care-visits-by-stack-bar')["description"]}
                  title={getChart('ambulatory-care-visits-by-stack-bar')["name"]}
                  fiscalYears={fiscalYears}
                  dataByOption={dataByOption}
                  increaseChartNo={increaseChartNo}
                  decreaseChartNo={decreaseChartNo}
                  cancelLooping={cancelLooping}
                  chartNo={chartNo}
                  looping={looping}
                  currentNo={1}
                  colors={colors}
                />
              </Col>
            </div>
            <div className="row">
              <Col lg={12} xl={12} className="chart-col">
                <DistributionChart
                  description={getChart('ambulatory-care-visits-by-pie-chart')["description"]}
                  title={getChart('ambulatory-care-visits-by-pie-chart')["name"]}
                  type={"Total"}
                  fiscalYears={fiscalYears}
                  dataByOption={dataByOption}
                  colors={colors}
                  increaseChartNo={increaseChartNo}
                  decreaseChartNo={decreaseChartNo}
                  cancelLooping={cancelLooping}
                  chartNo={chartNo}
                  looping={looping}
                  currentNo={2}
                  showInLegendEnable={true}
                  hideCustomList={true}
                />
              </Col>
            </div>
          </>
        </div>
        : null}
    </>
  );
};

export default AmbulatoryCareVisits;