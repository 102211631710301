import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import SocialAuthButtons from './SocialAuthButtons';

import SignIn from "./../../../api/auth/sign_in";
import SignOut from "./../../../api/auth/sign_out";
import User from "./../../../api/users";
import { isEmpty, getOr } from "lodash/fp";
import Defender from 'helpers/defender';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';

const LoginForm = ({ hasLabel, layout }) => {
  const navigate = useNavigate();
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  const [loading, setLoading] = useState(false)

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true)
    SignIn.verify({ user: formData }).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 201) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setLoading(false)
        toast.success(`One-time login code`, {
          theme: 'colored'
        });
        navigate(`/two-factor?id=${getOr(0, "data.id", response)}`);
      }
      else {
        setLoading(false)
        toast.error(`${getOr("", "response.data.message", response)}`, {
          theme: 'colored'
        });
      }
    })
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

	useEffect(() => {
		const parsed = queryString.parse(location.search);
		let data = {}
		data["user_id"] = parsed['user']
		if (!isEmpty(parsed['user'])) {
      User.invalidateExistingSessions(parsed['user']).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 201) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          localStorage.removeItem('dashboardName')
          localStorage.removeItem('dashboardId')
          localStorage.removeItem('dashboardSubPage')
          localStorage.removeItem('dashboardCsvUploaded')
          localStorage.removeItem('dashboardStartDate')
          localStorage.removeItem('dashboardEndDate')
          localStorage.removeItem('dashboardStatic')
          localStorage.removeItem('option')
          Defender.logout();
          navigate("/login");
        }
      })
		}
	}, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        {/* <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col> */}

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        {!loading ?
          <Button
            type="submit"
            color="primary"
            className="mt-3 btn--rg w-100"
            disabled={!formData.email || !formData.password}
          >
            Log in
          </Button>
          :
          <Button
            type="submit"
            color="primary"
            className="mt-3 btn--rg w-100"
            disabled={true}
          >
            Log in
          </Button>
        }
      </Form.Group>

      {/* <Divider className="mt-4">or log in with</Divider>

      <SocialAuthButtons /> */}

      <div className="access-forgot-actions mt-4">
        <Link className="fs--1 text-600" to="/">
          Back to Home
        </Link>
      </div>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
