import React, { useState, useEffect } from "react";
import Defender from "./../../../../helpers/defender";
import { isEmpty, getOr } from "lodash/fp";
import UserTable from "../user-table/UserTable";
import NavbarAdmin from "../NavbarAdmin";
import User from "./../../../../api/users";
import { toast } from 'react-toastify';

const AllUsers = ({ }) => {
  const [users, setUsers] = useState([]);
  const [userStatusList, setUserStatusList] = useState([]);
  const [suggestions, setSuggestions]= useState([])

  useEffect(() => {
    if (getOr([], "_r", Defender.currentUser()).includes("admin")) {
      getUsers("all")
    }
    else {
      window.location.href = '/'
    }
  }, []);

  const handleSubmit = () => {
    if (getOr([], "_r", Defender.currentUser()).includes("admin")) {
      window.location.href = '/admin'
    }
  };

	const getUsersSuggestions = (value) => {
		let body = {}
		body["value"] = value
		User.getUsersSuggestions(body).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				setSuggestions(response.data.suggestions)
			}
		})
	}

	const searchUsers = (body) => {
		User.searchUsers(body).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				setUsers(response.data.users)
        let statusListArray = []
        for (let i = 0; i < response.data.users.length; i++) {
          let obj = {}
          obj["id"] = response.data.users[i]["id"]
          obj["status"] = response.data.users[i]["status"]
          statusListArray.push(obj)
        }
        setUserStatusList(statusListArray)
			}
		})
	}


  const getUsers = (type) => {
      User.getAllUsers(type).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          let statusListArray = []
          setUsers(getOr([], 'data.users', response))
          for (let i = 0; i < response.data.users.length; i++) {
            let obj = {}
            obj["id"] = response.data.users[i]["id"]
            obj["status"] = response.data.users[i]["status"]
            statusListArray.push(obj)
          }
          setUserStatusList(statusListArray)
        }
      })
  }

  const removeUser = (id, e) => {
    e.preventDefault();
    User.remove(id).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        toast.success(`User removed`, {
          theme: 'colored'
        });
        getUsers("all")
      }
    })
  }

  const updateUserStatus = (id, status, e) => {
    e.preventDefault();
    let data = {}
    data["status"] = status
    User.updateUser({ user: data }, id).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        toast.success(`User status updated`, {
          theme: 'colored'
        });
        const newStatusList = [...userStatusList];
        let statusIndex = newStatusList.findIndex(x => x["id"] === id)
        newStatusList[statusIndex]["status"] = newStatusList[statusIndex]["status"] === true ? false : true
        setUserStatusList(newStatusList);
      }
    })
  }


  return (
    <>
      <NavbarAdmin />
      <div className="content">
        <section className="py-3">
          <div className="container container--800">
            <div className="org-table">
              <UserTable 
                title={"Users"}
                type={"SuperAdmin"}
                users={users}
                getUsers={getUsers}
                removeUser={removeUser}
                userStatusList={userStatusList}
                updateUserStatus={updateUserStatus}
                extraType={"AllUsers"}
                getUsersSuggestions={getUsersSuggestions}
                suggestions={suggestions}
                searchUsers={searchUsers}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AllUsers;
