import React, { useState, useEffect, useContext, useRef } from "react";
import { Col, Row, Navbar } from "react-bootstrap";
import { isEmpty, getOr } from "lodash/fp";
import Defender from 'helpers/defender';
import Dashboards from "./../../../../api/dashboards";
import SignOut from "./../../../../api/auth/sign_out";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
// Navigation
import NavbarAdmin from "../NavbarAdmin";
import VerticalNav from "../VerticalNav";

// Form
import Form from "react-bootstrap/Form";
import Spinner from 'react-bootstrap/Spinner';


// Charts
import AverageExportAge from "./charts/mini-stats/AverageExportAge";
import AverageATBAge from "./charts/mini-stats/AverageATBAge";
import AllocationOfAmounts from "./charts/allocation-of-amounts";
import AmountsByVisitLocation from "./charts/amounts-by-visit-location";
import AgedTrialBalances from "./charts/aged-trial-balances";
import ChargesAndRevenue from "./charts/charges-and-revenue";
import AmountsByFiscalYear from "./charts/amounts-by-fiscal-year";
import AmountsByPayer from "./charts/amounts-by-payer";
import AmountsByTop5 from "./charts/amounts-by-top5";
import AmountsByService from "./charts/amounts-by-service";
import AmountsByMonth from "./charts/amounts-by-month";
import AllocationOfCharges from "./charts/allocation-of-charges";
import { GiAncientColumns } from "react-icons/gi";
import { DashContext } from "context/Context";

const Summary = ({ }) => {

  const ref = useRef();

  const navigate = useNavigate();

  const [opt, setOpt] = useState("");

  const [data, setData] = useState({})

  const [loading, setLoading] = useState(true)

  const [charts, setCharts] = useState([])

  const { Updatefilters } = useContext(DashContext)
  const [chartNo, setChartNo] = useState(1)

  const [looping, setLooping] = useState(false)

  const increaseChartNo = (num) => {
    setLooping(true)
    if (num !== 10) {
      setChartNo(num + 1)
    }
    else {
      setChartNo(1)
    }
  }

  const decreaseChartNo = (num) => {
    setLooping(true)
    if (num !== 1) {
      setChartNo(num - 1)
    }
    else {
      setChartNo(10)
    }
  }

  const cancelLooping = () => {
    setChartNo(1)
    setLooping(false)
  }

  const getChartData = (id, option, pageLoad) => {
    setLoading(true)
    Dashboards.getData(id, option, pageLoad).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setData(response.data)
        setLoading(false)
      }
    })
  }

  const getChartsInfo = (title) => {
    Dashboards.getChartsInfo(title).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setCharts(getOr([], 'data.charts', response))
      }
    })
  }

  useEffect(() => {
    setTimeout(() => {
      if (!isEmpty(localStorage.dashboardId)) {
        getChartData(localStorage.dashboardId, opt, true)
        getChartsInfo(localStorage.dashboardName)
      }
    }, 500);
  }, [localStorage.getItem('dashboardId')]);

  useEffect(() => {
    if (!isEmpty(Defender.currentUser()) && (getOr([], "_r", Defender.currentUser()).includes("executive") || getOr([], "_r", Defender.currentUser()).includes("management") || getOr([], "_r", Defender.currentUser()).includes("admin") || getOr([], "_r", Defender.currentUser()).includes("sub_admin"))) {
      getChartsInfo(localStorage.dashboardName)
      getChartData(localStorage.dashboardId, opt, true)
    }
    else {
      SignOut.destroy().then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 201) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          localStorage.removeItem('dashboardName')
          localStorage.removeItem('dashboardId')
          localStorage.removeItem('dashboardSubPage')
          localStorage.removeItem('dashboardCsvUploaded')
          localStorage.removeItem('dashboardStartDate')
          localStorage.removeItem('dashboardEndDate')
          localStorage.removeItem('dashboardStatic')
          localStorage.removeItem('option')
          Defender.logout();
          toast.error(`Signed out`, {
            theme: 'colored'
          });
          navigate("/logout");
        }
      })
    }
  }, []);

  const handleFieldChange = (e) => {
    setOpt(e.target.value)
    if (e.target.value === "") {
      getChartData(localStorage.dashboardId, e.target.value, true)
    }
    else {
      getChartData(localStorage.dashboardId, e.target.value, false)
    }
  }

  useEffect(() => {
    const listenStorageChange = () => {
      const option = localStorage.getItem('option')
      if (option === '') {
        getChartData(localStorage.dashboardId, option, true)
      }
      else {
        getChartData(localStorage.dashboardId, option, false)
      }
    };
    window.addEventListener("storage", listenStorageChange);
    return () => window.removeEventListener("storage", listenStorageChange);
  }, []);


  const getChart = (slug) => {
    if (!isEmpty(charts)) {
      if (charts.filter(obj => obj["slug"] === slug)) {
        return charts.filter(obj => obj["slug"] === slug)[0]
      }
    }
  }

  useEffect(() => {
    const updatedFilters = [...new Set(getOr([], "fiscal_years", data))];
    Updatefilters(updatedFilters);
  }, [data]);

  return (
    <>
      <div className="container px-0">
        {!isEmpty(data) && !isEmpty(charts) ?
          <>
            <div className="row">
              <Col lg={12} xl={6} className="chart-col">
                <AllocationOfAmounts
                  total={!isEmpty(getOr([], "stats_by_year", data)[0]) ? getOr([], "stats_by_year", data)[0]["billed_amt"] : 0}
                  rev={!isEmpty(getOr([], "stats_by_year", data)[0]) ? getOr([], "stats_by_year", data)[0]["tsr_pay_amt"] : 0}
                  adj={!isEmpty(getOr([], "stats_by_year", data)[0]) ? getOr([], "stats_by_year", data)[0]["tsr_adj_amt"] : 0}
                  at={!isEmpty(getOr([], "stats_by_year", data)[0]) ? getOr([], "stats_by_year", data)[0]["atb_amt"] : 0}
                  revPercent={!isEmpty(getOr([], "stats_by_year", data)[0]) ? parseFloat(getOr([], "stats_by_year", data)[0]["tsr_pay_amt_percent"]) : 0}
                  adjPercent={!isEmpty(getOr([], "stats_by_year", data)[0]) ? parseFloat(getOr([], "stats_by_year", data)[0]["tsr_adj_amt_percent"]) : 0}
                  atPercent={!isEmpty(getOr([], "stats_by_year", data)[0]) ? parseFloat(getOr([], "stats_by_year", data)[0]["atb_amt_percent"]) : 0}
                  description={getChart('allocation-of-amounts')["description"]}
                  increaseChartNo={increaseChartNo}
                  decreaseChartNo={decreaseChartNo}
                  cancelLooping={cancelLooping}
                  chartNo={chartNo}
                  looping={looping}
                  currentNo={1}
                  title={getChart('allocation-of-amounts')["name"]}
                />
              </Col>
              <Col lg={12} xl={6} className="chart-col">
                <AmountsByVisitLocation
                  data={getOr([], "stats_by_location", data)}
                  description={getChart('amounts-by-visit-location')["description"]}
                  increaseChartNo={increaseChartNo}
                  decreaseChartNo={decreaseChartNo}
                  cancelLooping={cancelLooping}
                  chartNo={chartNo}
                  looping={looping}
                  currentNo={2}
                  title={getChart('amounts-by-visit-location')["name"]}
                />
              </Col>
            </div>
            <>
              <h4 className="mt-1 mb-3">Average Export Age</h4>
              <AverageExportAge
                locationData={getOr([], "export_age_by_location", data)}
                serviceData={getOr([], "export_age_by_service", data)}
                payerData={getOr([], "export_age_by_payer", data)}
                locationDescription={getChart('average-export-age-by-location')["description"]}
                serviceDescription={getChart('average-export-age-by-service')["description"]}
                payerDescription={getChart('average-export-age-by-payer')["description"]}
              />
            </>
            <div className="row">
              <Col lg={12} xl={6} className="chart-col">
                <AgedTrialBalances
                  till30={!isEmpty(getOr([], "atb_amount_by_atb_age_group", data).filter(obj => obj["atb_age_group"] === "0 - 30")[0]) ? getOr([], "atb_amount_by_atb_age_group", data).filter(obj => obj["atb_age_group"] === "0 - 30")[0]["atb_amt"] : null}
                  till60={!isEmpty(getOr([], "atb_amount_by_atb_age_group", data).filter(obj => obj["atb_age_group"] === "31 - 60")[0]) ? getOr([], "atb_amount_by_atb_age_group", data).filter(obj => obj["atb_age_group"] === "31 - 60")[0]["atb_amt"] : null}
                  till90={!isEmpty(getOr([], "atb_amount_by_atb_age_group", data).filter(obj => obj["atb_age_group"] === "61 - 90")[0]) ? getOr([], "atb_amount_by_atb_age_group", data).filter(obj => obj["atb_age_group"] === "61 - 90")[0]["atb_amt"] : null}
                  till120={!isEmpty(getOr([], "atb_amount_by_atb_age_group", data).filter(obj => obj["atb_age_group"] === "91 - 120")[0]) ? getOr([], "atb_amount_by_atb_age_group", data).filter(obj => obj["atb_age_group"] === "91 - 120")[0]["atb_amt"] : null}
                  over120={!isEmpty(getOr([], "atb_amount_by_atb_age_group", data).filter(obj => obj["atb_age_group"] === "120+")[0]) ? getOr([], "atb_amount_by_atb_age_group", data).filter(obj => obj["atb_age_group"] === "120+")[0]["atb_amt"] : null}
                  increaseChartNo={increaseChartNo}
                  decreaseChartNo={decreaseChartNo}
                  cancelLooping={cancelLooping}
                  chartNo={chartNo}
                  looping={looping}
                  currentNo={3}
                  description={getChart('aged-trial-balances')["description"]}
                  title={getChart('aged-trial-balances')["name"]}
                />
              </Col>
              <Col lg={12} xl={6} className="chart-col">
                <AllocationOfCharges
                  data={getOr([], "amounts_by_provider", data)}
                  increaseChartNo={increaseChartNo}
                  decreaseChartNo={decreaseChartNo}
                  cancelLooping={cancelLooping}
                  chartNo={chartNo}
                  looping={looping}
                  currentNo={4}
                  description={getChart('allocation-of-charges-by-top-10-providers')["description"]}
                  title={getChart('allocation-of-charges-by-top-10-providers')["name"]}
                />
              </Col>
            </div>
            <div className="row">
              <Col lg={12} xl={12} className="chart-col">
                <ChargesAndRevenue
                  data={getOr([], "stats_by_month", data)}
                  increaseChartNo={increaseChartNo}
                  decreaseChartNo={decreaseChartNo}
                  cancelLooping={cancelLooping}
                  chartNo={chartNo}
                  looping={looping}
                  currentNo={5}
                  description={getChart('charges-and-revenue-by-fiscal-year')["description"]}
                  title={getChart('charges-and-revenue-by-fiscal-year')["name"]}
                />
              </Col>
            </div>
            <>
              <h4 className="mt-1 mb-3">Average ATB Age</h4>
              <AverageATBAge
                locationData={getOr([], "atb_age_by_location", data)}
                serviceData={getOr([], "atb_age_by_service", data)}
                payerData={getOr([], "atb_age_by_payer", data)}
                locationDescription={getChart('average-atb-age-by-location')["description"]}
                serviceDescription={getChart('average-atb-age-by-service')["description"]}
                payerDescription={getChart('average-atb-age-by-payer')["description"]}
              />
            </>
            <div className="row">
              <Col lg={12} xl={6} className="chart-col">
                <AmountsByFiscalYear
                  data={getOr([], "stats_by_fiscal_year", data)}
                  increaseChartNo={increaseChartNo}
                  decreaseChartNo={decreaseChartNo}
                  cancelLooping={cancelLooping}
                  chartNo={chartNo}
                  looping={looping}
                  currentNo={6}
                  description={getChart('amounts-by-fiscal-year')["description"]}
                  title={getChart('amounts-by-fiscal-year')["name"]}
                />
              </Col>
              <Col lg={12} xl={6} className="chart-col">
                <AmountsByPayer
                  data={getOr([], "amounts_by_payer", data)}
                  increaseChartNo={increaseChartNo}
                  decreaseChartNo={decreaseChartNo}
                  cancelLooping={cancelLooping}
                  chartNo={chartNo}
                  looping={looping}
                  currentNo={7}
                  description={getChart('amounts-by-payer')["description"]}
                  title={getChart('amounts-by-payer')["name"]}
                />
              </Col>
            </div>
            <div className="row">
              <Col lg={12} xl={6} className="chart-col">
                <AmountsByTop5
                  data={getOr([], "amounts_by_insurer", data)}
                  increaseChartNo={increaseChartNo}
                  decreaseChartNo={decreaseChartNo}
                  cancelLooping={cancelLooping}
                  chartNo={chartNo}
                  looping={looping}
                  currentNo={8}
                  description={getChart('amounts-by-top-5-insurers')["description"]}
                  title={getChart('amounts-by-top-5-insurers')["name"]}
                />
              </Col>
              <Col lg={12} xl={6} className="chart-col">
                <AmountsByService
                  data={getOr([], "amounts_by_service", data)}
                  increaseChartNo={increaseChartNo}
                  decreaseChartNo={decreaseChartNo}
                  cancelLooping={cancelLooping}
                  chartNo={chartNo}
                  looping={looping}
                  currentNo={9}
                  description={getChart('amounts-by-service')["description"]}
                  title={getChart('amounts-by-service')["name"]}
                />
              </Col>
            </div>
            <div className="row">
              <Col lg={12} xl={12} className="chart-col">
                <AmountsByMonth
                  data={getOr([], "stats_by_month", data)}
                  increaseChartNo={increaseChartNo}
                  decreaseChartNo={decreaseChartNo}
                  cancelLooping={cancelLooping}
                  chartNo={chartNo}
                  looping={looping}
                  currentNo={10}
                  description={getChart('amounts-by-month')["description"]}
                  title={getChart('amounts-by-month')["name"]}
                />
              </Col>

            </div>
          </>
          : null}
      </div>
    </>
  );
};

export default Summary;
