import React, { useContext, useEffect, useState } from 'react';
import { Col } from "react-bootstrap";
import PatientChart from "./charts/patient-chart";
import Dashboard from "./../../../../../../api/dashboards";
import { isEmpty, getOr } from "lodash/fp";
import { DashContext } from 'context/Context';

const PatientRegistration = () => {
  const [data, setData] = useState({})
  const [charts, setCharts] = useState([])
  const [livingPatients, setLivingPatients] = useState([])
  const [newPatients, setNewPatients] = useState([])
  const [births, setBirths] = useState([])
  const [deaths, setDeaths] = useState([])
  const [fiscalYears, setFiscalYears] = useState([])
  const [fiscalYearsByLivingPatients, setFiscalYearsByLivingPatients] = useState([])
  const { Updatefilters } = useContext(DashContext)

  const [chartNo, setChartNo] = useState(1)
  const [looping, setLooping] = useState(false)

  const increaseChartNo = (num) => {
    setLooping(true)
    if (num !== 4) {
      setChartNo(num + 1)
    }
    else {
      setChartNo(1)
    }
  }

  const decreaseChartNo = (num) => {
    setLooping(true)
    if (num !== 1) {
      setChartNo(num - 1)
    }
    else {
      setChartNo(4)
    }
  }

  const cancelLooping = () => {
    setChartNo(1)
    setLooping(false)
  }

  useEffect(() => {
    getChartInfo()
    getChartsData()
  }, []);

  const getChart = (slug) => {
    if (!isEmpty(charts)) {
      if (charts.filter(obj => obj["slug"] === slug)) {
        return charts.filter(obj => obj["slug"] === slug)[0]
      }
    }
  }

  const getChartInfo = () => {
    let slug = window.location.href.split("/")[4]
    let dashboardName = localStorage.getItem('dashboardName')
    Dashboard.getChartsInfoBySlugAndTitle(dashboardName, slug).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setCharts(response.data)
      }
    });
  };

  const getChartsData = () => {
    let dashboardId = localStorage.getItem('dashboardId')
    Dashboard.getPatientRegistrationData(dashboardId).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setData(response.data)
        const stat = getOr([], 'data.stats_by_year', response)
        setFiscalYears(stat.map(a => a.fiscal_year))
        let statByLivingPatients = stat
        setLivingPatients(statByLivingPatients.slice().map(a => a.living_patients))
        setFiscalYearsByLivingPatients(statByLivingPatients.map(a => a.fiscal_year))
        setNewPatients(stat.map(a => a.new_patients))
        setBirths(stat.map(a => a.births))
        setDeaths(stat.map(a => a.deaths))
      }
    });
  };

  const differenceBetweenLargestAndSmallestElement = (array) => {
    return Math.round(Math.max(...array) - Math.min(...array));
  }

  const differenceFirstAndLastElement = (array) => {
    return Math.round(array[array.length - 1] - array[0]);
  }

  const getSmallestValue = (array) => {
    return Math.round(Math.min(...array));
  }

  const differenceArray = (array) => {
    let arr = array.slice(1).map((v, i) => v - array[i])
    arr.unshift(0)
    return arr
  }

  useEffect(() => {
    Updatefilters([])
  }, [])



  return (
    <>
      {!isEmpty(data) && !isEmpty(charts) ?
      <div className="container px-0">
        <div className="row">
          <Col lg={12} xl={6} className="chart-col">
            <PatientChart
              subTitle={(!isEmpty(livingPatients) ? differenceFirstAndLastElement(livingPatients).toLocaleString() : 0) + ' Added'}
              fiscalYears={fiscalYearsByLivingPatients}
              data={livingPatients}
              description={getChart('living-patients')["description"]}
              title={getChart('living-patients')["name"]}
              colorHex={"#2A8B8C"}
              min={getSmallestValue(livingPatients)}
              differenceArray={differenceArray(livingPatients)}
              increaseChartNo={increaseChartNo}
              decreaseChartNo={decreaseChartNo}
              cancelLooping={cancelLooping}
              chartNo={chartNo}
              looping={looping}
              currentNo={1}
            />
          </Col>
          <Col lg={12} xl={6} className="chart-col">
            <PatientChart
              subTitle={(!isEmpty(newPatients) ? Math.round(newPatients.slice(1).reduce((a, b) => a + b, 0)) : 0).toLocaleString() + ' Added'}
              fiscalYears={fiscalYears}
              data={newPatients}
              description={getChart('new-patients')["description"]}
              title={getChart('new-patients')["name"]}
              colorHex={"#733837"}
              min={getSmallestValue(newPatients)}
              differenceArray={differenceArray(newPatients)}
              increaseChartNo={increaseChartNo}
              decreaseChartNo={decreaseChartNo}
              cancelLooping={cancelLooping}
              chartNo={chartNo}
              looping={looping}
              currentNo={2}
            />
          </Col>
        </div>
        <div className="row">
          <Col lg={12} xl={6} className="chart-col">
            <PatientChart
              subTitle={!isEmpty(births) ? Math.round(births.reduce((a, b) => a + b, 0)).toLocaleString() : 0}
              fiscalYears={fiscalYears}
              data={births}
              description={getChart('births')["description"]}
              title={getChart('births')["name"]}
              colorHex={"#e1a95b"}
              min={getSmallestValue(births)}
              differenceArray={differenceArray(births)}
              increaseChartNo={increaseChartNo}
              decreaseChartNo={decreaseChartNo}
              cancelLooping={cancelLooping}
              chartNo={chartNo}
              looping={looping}
              currentNo={3}
            />
          </Col>
          <Col lg={12} xl={6} className="chart-col">
            <PatientChart
              subTitle={!isEmpty(deaths) ? Math.round(deaths.reduce((a, b) => a + b, 0)).toLocaleString() : 0}
              fiscalYears={fiscalYears}
              data={deaths}
              description={getChart('deaths')["description"]}
              title={getChart('deaths')["name"]}
              colorHex={"#6A6E71"}
              min={getSmallestValue(deaths)}
              differenceArray={differenceArray(deaths)}
              increaseChartNo={increaseChartNo}
              decreaseChartNo={decreaseChartNo}
              cancelLooping={cancelLooping}
              chartNo={chartNo}
              looping={looping}
              currentNo={4}
            />
          </Col>
        </div>
      </div>
      : null}
    </>
  )
}

export default PatientRegistration