import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import team3 from "assets/img/sp-images/default-avatar-sm.jpg";
import Avatar from "components/common/Avatar";
import Defender from "./../../../helpers/defender";
import SignOut from "./../../../api/auth/sign_out";
import User from "./../../../api/users";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
const ProfileDropdown = ({ isCustomerDash }) => {

  useEffect(() => {
    // const handleBeforeUnload = (event) => {
    //   const confirmationMessage = "Are you sure you want to leave?";
    //   event.returnValue = confirmationMessage; // Standard for most browsers
    //   logOut()
    //   return confirmationMessage;
    // };

    // window.addEventListener('beforeunload', handleBeforeUnload);

    // return () => {
    //   // Cleanup: Remove the event listener when the component is unmounted
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // };
  }, []);

  const checkSessionTime = (e) => {
    User.checkSessionTime().then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 201) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        const flag = getOr(false, "data.flag", response)
        if (flag) {
          setModalShow(false)
          setModalShow(true)
        }
      }
    });
  }

  const increaseSessionTime = () => {
    if (!isEmpty(Defender.currentUser())) {
      User.fetch(Defender.currentUser().id).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 201) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          setModalShow(false)
        }
      });
    }
  }

  useEffect(() => {
    checkSessionTime();
    increaseSessionTime()

    // Set up the interval for subsequent API calls
    const intervalId = setInterval(() => {
      checkSessionTime();
    }, 60000); // 60000 milliseconds = 1 minute

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false)

  const logOut = (e) => {
    SignOut.destroy().then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 201) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        localStorage.removeItem('dashboardName')
        localStorage.removeItem('dashboardId')
        localStorage.removeItem('dashboardSubPage')
        localStorage.removeItem('dashboardCsvUploaded')
        localStorage.removeItem('dashboardStartDate')
        localStorage.removeItem('dashboardEndDate')
        localStorage.removeItem('dashboardStatic')
        localStorage.removeItem('option')
        Defender.logout();
        toast.success(`Successfully signed out`, {
          theme: "colored",
        });
        navigate("/logout");
      }
    });
  };

  return isCustomerDash ? (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="remove-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Your session will be logged out in less than 5 minutes.</div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => {
            increaseSessionTime()
          }
          } variant="btn-primary" className="btn btn-primary">STAY LOGGED IN</Button>
        </Modal.Footer>
      </Modal>
      <Dropdown>
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={`div`}
          className="dash-sidebar__footer"
        >
          {!isEmpty(Defender.currentUser()) ? (
            <>
              {!isEmpty(Defender.currentUser().main_image_link) ? (
                <Avatar src={Defender.currentUser().main_image_link} size={`2xl`} />
              ) : (
                <Avatar src={team3} size={`2xl`} />
              )}
            </>
          ) : null}
          {!isEmpty(Defender.currentUser()) ?
            <div className="dash-sidebar__footer__user-details">
              <div className="dash-sidebar__footer__user-details__name">
                {!isEmpty(getOr("", "full_name", Defender.currentUser())) ? getOr("", "full_name", Defender.currentUser()) : getOr("", "email", Defender.currentUser())}
              </div>

              <BsChevronDown />
            </div>
            : null}
        </Dropdown.Toggle>


        {!isEmpty(Defender.currentUser()) ? (
          <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
            <div className="bg-white rounded-2 py-2  dark__bg-1000">
              {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                <Dropdown.Item as={Link} to="/admin">
                  Dashboard
                </Dropdown.Item>
              ) : (
                null
              )}
              {Defender.currentUser()._r.includes("admin") ? (
                <Dropdown.Item as={Link} to="/upload">
                  Upload
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item as={Link} to="/settings">
                Settings
              </Dropdown.Item>
              {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                <Dropdown.Item as={Link} to="/tiles">
                  Tiles
                </Dropdown.Item>
              ) : null}
              {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                <Dropdown.Item as={Link} to="/plans">
                  Plans
                </Dropdown.Item>
              ) : null}
              {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                <Dropdown.Item as={Link} to="/invoice-reports">
                  Invoices Reports
                </Dropdown.Item>
              ) : null}
              {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                <Dropdown.Item as={Link} to="/activity-log">
                  Activity Log
                </Dropdown.Item>
              ) : null}
              {Defender.currentUser()._r.includes("admin") ? (
                <Dropdown.Item as={Link} to="/superadmin">
                  SuperAdmin
                </Dropdown.Item>
              ) : null}
              {Defender.currentUser()._r.includes("admin") ? (
                <Dropdown.Item as={Link} to="/users">
                  Users
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
            </div>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
            <div className="bg-white rounded-2 py-2 dark__bg-1000">
              <Dropdown.Item as={Link} to="/admin">
                Dashboard
              </Dropdown.Item>
              {Defender.currentUser() &&
                Defender.currentUser()._r.includes("admin") ? (
                <Dropdown.Item as={Link} to="/upload">
                  Upload
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item as={Link} to="/settings">
                Settings
              </Dropdown.Item>
              <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
            </div>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  ) : (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="remove-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Your session will be logged out in less than 5 minutes.</div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => {
            setModalShow(false)
            increaseSessionTime()
          }
          } variant="btn-primary" className="btn btn-primary">STAY LOGGED IN</Button>
        </Modal.Footer>
      </Modal>
      <Dropdown>
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={`div`}
          // to=""
          className="profile-dropdown pe-0 ps-3 nav-link"
        >
          {!isEmpty(Defender.currentUser()) ? (
            <>
              {!isEmpty(Defender.currentUser().main_image_link) ? (
                <Avatar src={Defender.currentUser().main_image_link} size={`${location.pathname.includes('dash') ? '2xl' : 'xl'}`} />
              ) : (
                <Avatar src={team3} size={`${location.pathname.includes('dash') ? '2xl' : 'xl'}`} />
              )}
            </>
          ) : null}
        </Dropdown.Toggle>

        {!isEmpty(Defender.currentUser()) ? (
          <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
            <div className="bg-white rounded-2 py-2 dark__bg-1000">
              {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                <Dropdown.Item as={Link} to="/admin">
                  Dashboard
                </Dropdown.Item>
              ) : (
                null
              )}
              {Defender.currentUser()._r.includes("admin") ? (
                <Dropdown.Item as={Link} to="/upload">
                  Upload
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item as={Link} to="/settings">
                Settings
              </Dropdown.Item>
              {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                <Dropdown.Item as={Link} to="/tiles">
                  Tiles
                </Dropdown.Item>
              ) : null}
              {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                <Dropdown.Item as={Link} to="/plans">
                  Plans
                </Dropdown.Item>
              ) : null}
              {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                <Dropdown.Item as={Link} to="/invoice-reports">
                  Invoices Reports
                </Dropdown.Item>
              ) : null}
              {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                <Dropdown.Item as={Link} to="/activity-log">
                  Activity Log
                </Dropdown.Item>
              ) : null}
              {Defender.currentUser()._r.includes("admin") ? (
                <Dropdown.Item as={Link} to="/superadmin">
                  SuperAdmin
                </Dropdown.Item>
              ) : null}
              {Defender.currentUser()._r.includes("admin") ? (
                <Dropdown.Item as={Link} to="/users">
                  Users
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
            </div>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
            <div className="bg-white rounded-2 py-2 dark__bg-1000">
              <Dropdown.Item as={Link} to="/admin">
                Dashboard
              </Dropdown.Item>
              {Defender.currentUser() &&
                Defender.currentUser()._r.includes("admin") ? (
                <Dropdown.Item as={Link} to="/upload">
                  Upload
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item as={Link} to="/settings">
                Settings
              </Dropdown.Item>
              <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
            </div>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};

export default ProfileDropdown;