import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isEmpty } from "lodash/fp";
import { SlQuestion } from "react-icons/sl";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { HiOutlineArrowsExpand } from "react-icons/hi";
import CardModal from "../../Modals/CardModal";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const AllocationOfAmounts = ({
  total,
  rev,
  adj,
  at,
  revPercent,
  adjPercent,
  atPercent,
  description,
  increaseChartNo,
  decreaseChartNo,
  cancelLooping,
  chartNo,
  looping,
  currentNo,
  title,
}) => {
  
  const options = {

    credits: {
      enabled: false
    },

  chart: {
    type: "pie",
    },

    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: "%",
      },
    },

    title: {
      text: total > 1000000 ? (Math.round((total / 1000000) * 10) / 10 + "M") : (total > 1000 ? (Math.round((total / 1000) * 10) / 10 + "K") : total),
    },

    subtitle: {
      text: "Charges",
    },

    plotOptions: {
      pie: {
        shadow: false,
        center: ["50%", "50%"],
        showInLegend: true,
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:10px"></span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0"></td>' +
        '<td style="padding:0">{point.name} <b>{point.a:.1f} {point.y:.1f}%</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },

    series: [
      {
        name: "Total",
        colorByPoint: true,
        innerSize: "50%",
        animation: false,
        dataLabels: {
          formatter: function () {
            return this.y > 1
              ? "<b>" + this.point.x + "M</b> " + this.y + "%"
              : null;
          },
        },
        data: [
          {
            name: "Revenue",
            y: Math.round(revPercent * 10) / 10,
            x: Math.round((rev / 1000000) * 10) / 10,
            a: rev.toLocaleString(),
            color: "#2A8B8C",
          },
          {
            name: "Adjustments",
            y: Math.round(adjPercent * 10) / 10,
            x: Math.round((adj / 1000000) * 10) / 10,
            a: adj.toLocaleString(),
            color: "#e1a95b",
          },
          {
            name: "Aged Trial Balance",
            y: Math.round(atPercent * 10) / 10,
            x: Math.round((at / 1000000) * 10) / 10,
            a: at.toLocaleString(),
            color: "#90a9ac",
          },
        ],
      },
    ],
  };

	useEffect(() => {
		if (chartNo === 1 && looping){
			setShow(true)
		}
		if (chartNo !== 1){
			setShow(false)
		}
	  }, [chartNo]);

	const [show, setShow] = useState(false);

	const handleClose = () => {
    setShow(false);
    cancelLooping()
  }
  
	const ChartCard = () => {
  
	  return (
      <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <div className="d-flex gap-2">
          <HiOutlineArrowsExpand onClick={() => setShow(true)} className="card-header__question-mark card-header__expand" />
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
            <Popover
              id={"popover-pos  itioned-" + "bottom-end"}
              arrow={false}
              arrowProps={false}
              style={{
              marginTop: "0",
              transition: "all 0.2s ease-in-out, transform 0s;",
              }}
            >
              <Popover.Body>
              {description}
              </Popover.Body>
            </Popover>
            }
          >
            <div>
            <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
          </div>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
      </>
	  )
	};

	return (
	  <>
	  <ChartCard />
	  <CardModal 
      title={"Allocation of Amounts (Total Amount = Charges)"} 
      subTitle={""} 
      highcharts={Highcharts} 
      options={options} 
      show={show} 
      handleClose={handleClose} 
      increaseChartNo={increaseChartNo}
      decreaseChartNo={decreaseChartNo}
      currentNo={currentNo}
      />
	  </>
	);

};

export default AllocationOfAmounts;
