import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isEmpty } from "lodash/fp";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";
Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const Chart  = ({
  revenueByMonthWithFiscal,
  totalByMonthWithFiscal,
  adjustmentByMonthWithFiscal,
  atbByMonthWithFiscal,
  monthsWithFiscal,
  description
}) => {

  const options = {
    credits: {
      enabled: false,
    },

    chart: {
      type: "line",
      zoomType: "x",
      resetZoomButton: {
        position: {
          x: 0,
          y: 0,
        },
      },
    },

    credits: {
      enabled: false,
    },

    title: {
      text: "",
    },

    xAxis: {
      categories: [
        "OCT 2019",
        "NOV 2019",
        "DEC 2019",
        "JAN 2020",
        "FEB 2020",
        "MAR 2020",
        "APR 2020",
        "MAY 2020",
        "JUN 2020",
        "JUL 2020",
        "AUG 2020",
        "SEP 2020",
        "OCT 2020",
        "NOV 2020",
        "DEC 2020",
        "JAN 2021",
        "FEB 2021",
        "MAR 2021",
        "APR 2021",
        "MAY 2021",
        "JUN 2021",
        "JUL 2021",
        "AUG 2021",
        "SEP 2021",
        "OCT 2021",
        "NOV 2021",
        "DEC 2021",
        "JAN 2022",
        "FEB 2022",
        "MAR 2022",
        "APR 2022",
        "MAY 2022",
        "JUN 2022",
        "JUL 2022",
        "AUG 2022",
        "SEP 2022",
      ],
    },

    yAxis: {
      title: {
        text: "",
      },
    },

    series: [
      {
        name: "Charges",
        data: [
          1325199,
          1093281,
          1233809,
          1004606,
          1416281,
          934621,
          671640,
          881642,
          872308,
          910488,
          831509,
          891706,
          859384,
          771261,
          580766,
          690375,
          987462,
          1061081,
          914897,
          796032,
          635112,
          5122281,
          691539,
          617615,
          1418799,
          742418,
          733535,
          868906,
          722873,
          701529,
          423301,
          394687,
          745442,
          477457,
          84439,
          0
      ],
        color: "#bc6541",
      },
      {
        name: "Revenue",
        data: [
          1635933, 1370240, 1550565, 1351649, 1693656, 1236962, 987162, 1192322, 1186231, 1197564, 1148110, 1193716, 1241598, 1100401, 1055533, 1068756, 1322209, 1506113, 1291337, 1222790, 1109722, 5599058, 1328286, 1205785, 1812525, 1207924, 1249889, 1405653, 1232561, 1458560, 1297959, 1373404, 1565830, 1017879, 842855, 486321,
        ],
                color: "#2A8B8C",
      },
      {
        name: "Adjustments",
        data: [
          231287,
          216766,
          248845,
          203494,
          169993,
          210347,
          150618,
          133938,
          203309,
          175182,
          171134,
          168081,
          146858,
          100549,
          131689,
          120406,
          124280,
          140503,
          116827,
          137526,
          92188,
          62211,
          21665,
          7933,
          152078,
          164772,
          183166,
          70075,
          22065,
          107819,
          95114,
          105958,
          176195,
          76345,
          80636,
          0
      ],

      color: "#e1a95b",
      },
      {
        name: "ATB",
        data: [
          79419,
          60149,
          67870,
          143510,
          107360,
          91992,
          164915,
          176747,
          110573,
          111893,
          145435,
          133880,
          235252,
          228525,
          343077,
          257997,
          210486,
          304539,
          259554,
          289187,
          382396,
          414549,
          615087,
          580241,
          241664,
          300771,
          333175,
          466646,
          487670,
          649225,
          779587,
          872779,
          644175,
          464140,
          677755,
          486278
      ],
        color: "#90a9ac",
      },
    ],
  };
  return (
    <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>Billed Amount , Payment Amount , Adjustment Amount and Aged Trial Balance Amount By Month_Year</h5>
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
              <Popover
                id={"popover-positioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                  marginTop: "0",
                  transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                Description
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </>
  );
};

export default Chart  ;
