import React from 'react';
import ConfirmMailContent from './ConfirmMailContent';
import bgImg from 'assets/img/sp-images/access/access-check.jpg';
import AuthSplitLayout from './AuthSplitLayout';

const ConfirmMail = () => {
  return (
    <AuthSplitLayout bgProps={{ image: bgImg, position: '50% 30%' }}>
      <div className="text-center">
        <ConfirmMailContent email="your email" layout="split" titleTag="h3" />
      </div>
    </AuthSplitLayout>
  );
};

export default ConfirmMail;
