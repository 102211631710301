import React from 'react';
import NavbarStandard from './front/NavbarStandard';
import Footer from './front/Footer';

// Images
import bg2 from 'assets/img/sp-images/home/bg2.png';
import ppRocky from 'assets/img/sp-images/pp-rocky.png';
import ppKayenta from 'assets/img/sp-images/pp-kayenta.png';
import ppMMM from 'assets/img/sp-images/pp-mmm.png';
import ppCHC from 'assets/img/sp-images/canoncito-health-center-inc.jpg';
import ppPOJ from 'assets/img/sp-images/pueblo-of-jemez.jpg';
import ppRP from 'assets/img/sp-images/river-people-health-center.jpg';
import ppFMIT from 'assets/img/sp-images/fort-mojave-indian-tribe.png';

const About = ({}) => {
  return (
	<>
		<NavbarStandard />
		
		<div className="content content--front">
			<section className="py-0">
				<div 
					className="heading bar2"
					style={{ backgroundImage:`url(${bg2})` }} >
					<div className="container">
						<div className="heading__overline">About Bernie Yazzie Consulting</div>
						<h1 className="page-title">Our Purpose & Mission</h1>
						<div className="subtitle">
							Who we are, what we do, and who we do it for.
						</div>
					</div>
				</div>
				<div className="container max-800">
					<div className="intro">At Bernie Yazzie Consulting, we are dedicated to enhancing the operational efficiency and financial health of tribal healthcare facilities.</div>
					<p>Founded in 2018, our mission began with a focus on Healthcare Financial Management, and quickly evolved to fill the gaps left by others in the industry. We now offer a comprehensive suite of services tailored specifically for Indian Healthcare providers.</p>


					<h4>Our services include:</h4>

					<ul className="mb-4">
						<li><b>Revenue Cycle Management:</b> Optimizing the flow from patient intake to payment processing.</li>
						<li><b>Coding and Billing:</b> Ensuring accuracy and compliance to maximize revenue.</li>
						<li><b>Accounts Receivable Services:</b> Streamlining processes to improve cash flow.</li>
						<li><b>Technical Support:</b> Providing the tools and technology necessary for modern healthcare.</li>
						<li><b>Training and Education:</b> Empowering your staff with the knowledge to succeed.</li>
						<li><b>Management and Consulting Services:</b> Offering expert guidance on operations and strategic planning.</li>
					</ul>
					
					<p>Imagine partnering with a team whose mission is to fully optimize your facility in every possible way. With Bernie Yazzie Consulting, you gain an unlimited resource committed to understanding and improving every aspect of your healthcare system for long-term success.</p> 
					
					<p>We are here to help your management staff master the processes, systems, and cycles they manage, driving your facility towards greater effectiveness and sustainability.</p>
					
					<p>Call us today and start the conversation about how we can nourish your facility together.</p>
					
					<h4>We have all the necessary certifications</h4>
					
					<p>We are currently registered and continue to maintain certifications as a government contractor in the System for Award Management (SAM), in which we are assigned a CAGE code, DUNS number, and NAICS codes describing the management and consulting services we provide as a company.</p>
					
					<div className="row mb-4">
						<div className="col-sm-4">
							<b>DUNS</b>: 081017343
						</div>
						<div className="col-sm-4">
							<b>CAGE</b>: 83RT8
						</div>
						<div className="col-sm-4">
							<b>UEI</b>: VYBZSpp8P2G5
						</div>
					</div>
					
					<h4>NAICS Codes</h4>
					
					<p>Bernie Yazzie Consulting provides a variety of exemplary services under the following North American Industry Classification System Codes (NAICS):</p>
					
					<div className="row">
						<div className="col-sm-6">
							<ul className="mb-0">
								<li><b>541611</b>: Administrative Management and General Management Consulting Services (Primary)</li>
								<li><b>541511</b>: Custom Computer Programming Services</li>
								<li><b>541513</b>: Computer Facilities Management Services</li>
								<li><b>238990</b>: All Other Specialty Trade Contactors</li>
								<li><b>561990</b>: All Other Support Services</li>
								<li><b>513210</b>: Software Publishers</li>
								<li><b>541219</b>: Other Accounting Services</li>
							</ul>
						</div>
						<div className="col-sm-6">
							<ul>
								<li><b>541690</b>: Other Scientific and Consulting Services</li>
								<li><b>541512</b>: Computer Design Services</li>
								<li><b>561440</b>: Debt Collection – Accounts Receivables</li>
								<li><b>541618</b>: Other Management Consulting Services</li>
								<li><b>541990</b>: All Other Professional and Scientific and Technical Services</li>
								<li><b>541219</b>: Other Accounting Services</li>
							</ul>
						</div>
					</div>
					
					<h4 className="mt-3">Certifications</h4>
					
					<div className="row">
						<div className="col-sm-6">
							<ul className="mb-0">
								<li><b>27</b>: Self-Certified Small Disadvantaged Business</li>
								<li><b>2X</b>: For Profit Organization</li>
								<li><b>LI</b>: Limited Liability Company</li>
								<li><b>IEE</b>: Indian Economic Enterprise</li>
							</ul>
						</div>
						<div className="col-sm-6">
							<ul>
								<li><b>NB</b>: Native American Owned</li>
								<li><b>OW</b>: American Indian Owned</li>
								<li><b>XY</b>: Indian Tribe (Federally Recognized)</li>
							</ul>
						</div>
					</div>
					
					<h4 className="mt-4">Past Performance</h4>
					
					<div className="pp-items">
						<img src={ppRocky} alt="Rocky Boy Health Center" />
						<img src={ppKayenta} alt="Rayenta Health Center" />
						<img src={ppMMM} alt="McManis & Monsalve Associates" />
						<img src={ppPOJ} alt="McManis & Monsalve Associates" />
						<img src={ppRP} alt="River People Health Center" />
						<img src={ppFMIT} alt="McManis & Monsalve Associates" />
						<img src={ppCHC} alt="Canoncito Health Clinic" />
					</div>
				</div>
			</section>
		</div>
		
		<Footer />
	</>
  );
};

export default About;