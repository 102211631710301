export const colorScheme = [
    "#2A8B8C",
    "#733837",
    "#e1a95b",
    "#6A6E71",
    "#bc6541",
    "#7c5280",
    "#61724f",
    "#9a3982",
    "#293747",
    "#523a3a",
    "#2A8B8C",
    "#47232d",
    "#a08148",
    "#46484a",
    "#8e5f4c",
    "#562f85",
    "#7ba94a",
    "#633858",
    "#2d598b",
    "#724040",
    "#194048",
    '#a94442',
    '#a3906d',
    '#93979b',
    '#c87655',
    '#6d5789',
    '#539210',
    '#8a547c',
    '#619fb0',
    '#4d3939',
    '#208296',
    '#65201e',
    '#d5a958',
    '#2b2d2e',
    '#8a572e',
    '#5c6286',
    '#33471e',
    '#432c3d',
    '#353584',
    '#413333',
    "#2A8B8C",
    "#733837",
    "#e1a95b",
    "#6A6E71",
    "#bc6541",
    "#7c5280",
    "#61724f",
    "#9a3982",
    "#293747",
    "#523a3a",
    "#2A8B8C",
    "#47232d",
    "#a08148",
    "#46484a",
    "#8e5f4c",
    "#562f85",
    "#7ba94a",
    "#633858",
    "#2d598b",
    "#724040",
    "#194048",
    '#a94442',
    '#a3906d',
    '#93979b',
    '#c87655',
    '#6d5789',
    '#539210',
    '#8a547c',
    '#619fb0',
    '#4d3939',
    '#208296',
    '#65201e',
    '#d5a958',
    '#2b2d2e',
    '#8a572e',
    '#5c6286',
    '#33471e',
    '#432c3d',
    '#353584',
    '#413333',
    "#2A8B8C",
    "#733837",
    "#e1a95b",
    "#6A6E71",
    "#bc6541",
    "#7c5280",
    "#61724f",
    "#9a3982",
    "#293747",
    "#523a3a",
    "#2A8B8C",
    "#47232d",
    "#a08148",
    "#46484a",
    "#8e5f4c",
    "#562f85",
    "#7ba94a",
    "#633858",
    "#2d598b",
    "#724040",
    "#194048",
    '#a94442',
    '#a3906d',
    '#93979b',
    '#c87655',
    '#6d5789',
    '#539210',
    '#8a547c',
    '#619fb0',
    '#4d3939',
    '#208296',
    '#65201e',
    '#d5a958',
    '#2b2d2e',
    '#8a572e',
    '#5c6286',
    '#33471e',
    '#432c3d',
    '#353584',
    '#413333',
];