/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";
import { isEmpty, getOr } from "lodash/fp";
import { HiOutlineArrowsExpand, HiZoomOut } from "react-icons/hi";
import CardModal from "components/site/admin/dash/Modals/CardModal";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const StackingChart = ({ fiscalYears, dataByOption, title, description, increaseChartNo, decreaseChartNo, cancelLooping, chartNo, looping, currentNo, colors }) => {
  let dataArray = []
  if (!isEmpty(dataByOption)) {
    for (let i = 0; i < fiscalYears.length; i++) {
        let obj = {}
        obj["data"] = [dataByOption[i]["percentage_by_year"]]
        obj["color"] = colors[i]
        obj["name"] = dataByOption[i]["fiscal_year"]
        dataArray.push(obj)
    }
  }

  const chartRef = useRef();

  const handleResetZoom = () => {
    const chart = chartRef.current.chart;
    chart.zoom();
  };

  const options = {
    chart: {
      type: 'column',
      height: '500px'
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: ['Fiscal Years']
    },
    yAxis: {
      title: {
        text: ''
      },
      stackLabels: {
        enabled: false,
      }
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<b>FY{series.name}</b><br/>',
      pointFormat: '{point.y}%'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointWidth: 130,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
          pointFormatter: function () {
            return 'FY' + this.series.name;
          },
        }
      }
    },
    series: dataArray
  }
  useEffect(() => {
    let x = currentNo
    if (chartNo === x && looping) {
      setShow(true)
    }
    if (chartNo !== x) {
      setShow(false)
    }
  }, [chartNo]);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    cancelLooping()
  }

  const ChartCard = () => {

    return (
      <>
        <div className="card card--chart">
          <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
            <h5>{title}</h5>
            <div className="d-flex gap-2">
              <div>
                <HiZoomOut onClick={() => handleResetZoom()} className="card-header__question-mark" />
              </div>
              <div>
                <HiOutlineArrowsExpand onClick={() => setShow(true)} className="card-header__question-mark card-header__expand" />
              </div>
              <OverlayTrigger
                trigger="hover"
                placement="bottom-end"
                overlay={
                  <Popover
                    id={"popover-positioned-" + "bottom-end"}
                    arrow={false}
                    arrowProps={false}
                    style={{
                      marginTop: "0",
                      transition: "all 0.2s ease-in-out, transform 0s;",
                    }}
                  >
                    <Popover.Body>
                      {description}
                    </Popover.Body>
                  </Popover>
                }
              >
                <div>
                  <SlQuestion className="card-header__question-mark" />
                </div>
              </OverlayTrigger>
            </div>
          </div>
          <div className="card-body" style={{ height: '500px', }}>
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <ChartCard />
      <CardModal
        title={"Ambulatory Care Visits"}
        subTitle={""}
        highcharts={Highcharts}
        options={options}
        show={show}
        handleClose={handleClose}
        zoom={true}
        increaseChartNo={increaseChartNo}
        decreaseChartNo={decreaseChartNo}
        currentNo={currentNo}
      />
    </>
  );
};

export default StackingChart;