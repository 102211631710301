import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { SlQuestion } from 'react-icons/sl';
import Dashboard from "./../../../../../../../api/dashboards";
import { isEmpty, getOr } from "lodash/fp";
import { HiOutlineArrowsExpand, HiZoomOut } from "react-icons/hi";
import CardModal from "components/site/admin/dash/Modals/CardModal";


Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

const InjuriesChart = ({ }) => {

  const [data, setData] = useState({})
  const [charts, setCharts] = useState([])
  const [injuriesTotal, setInjuriesTotal] = useState([])
  const [newInjuries, setNewInjuries] = useState([])
  const [fiscalYears, setFiscalYears] = useState([])
  useEffect(() => {
    getChartInfo()
    getChartsData()
  }, []);

  const getChart = (slug) => {
    if (!isEmpty(charts)) {
      if (charts.filter(obj => obj["slug"] === slug)) {
        return charts.filter(obj => obj["slug"] === slug)[0]
      }
    }
  }

  const getChartInfo = () => {
    let slug = window.location.href.split("/")[4]
    let dashboardName = localStorage.getItem('dashboardName')
    Dashboard.getChartsInfoBySlugAndTitle(dashboardName, slug).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setCharts(response.data)
      }
    });
  };

  const getChartsData = () => {
    let dashboardId = localStorage.getItem('dashboardId')
    Dashboard.getInjuriesTotal(dashboardId).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setData(response.data)
        let fY = getOr([], 'data.stats_by_year', response).map(a => a.fiscal_year)
        setFiscalYears(fY)
        let iT = getOr([], 'data.stats_by_year', response).map(a => a.injuries_total)
        let iTD = differenceArray(iT)
        let dataArrayOne = []
        if (!isEmpty(iT)) {
          for (let i = 0; i < fY.length; i++) {
            let obj = {}
            obj["y"] = Math.round(iT[i])
            obj["color"] = '#bc6541'
            obj["name"] = "Not New Injuries"
            if (iTD[i] > 0) {
              obj["percentage"] = "+" + (Math.round(iTD[i]*1)/1).toLocaleString()
            }
            else if (iTD[i] < 0) {
              obj["percentage"] = "" + (Math.round(iTD[i]*1)/1).toLocaleString()
            }
            dataArrayOne.push(obj)
          }
        }
        setInjuriesTotal(dataArrayOne)

        let nI = getOr([], 'data.stats_by_year', response).map(a => a.new_injuries)
        let nID = differenceArray(nI)
        let dataArrayTwo = []
        if (!isEmpty(nI)) {
          for (let i = 0; i < fY.length; i++) {
            let obj = {}
            obj["y"] = Math.round(nI[i])
            obj["color"] = '#2A8B8C'
            obj["name"] = "New Injuries"
            if (nID[i] > 0) {
              obj["percentage"] = "+" + (Math.round(nID[i]*1)/1).toLocaleString()
            }
            else if (nID[i] < 0) {
              obj["percentage"] = "" + (Math.round(nID[i]*1)/1).toLocaleString()
            }
            dataArrayTwo.push(obj)
          }
        }
        setNewInjuries(dataArrayTwo)
      }
    });
  };

  const getSmallestValue = (array) => {
    return Math.min(...array);
  }

  const differenceArray = (array) => {
    let arr = array.slice(1).map((v, i) => v - array[i])
    arr.unshift(0)
    return arr
  }

  const chartRef = useRef();

  const handleResetZoom = () => {
    const chart = chartRef.current.chart;
    chart.zoom();
  };

  const options = {
    credits: {
      enabled: false
    },
    title: {
      text: "",
    },
    chart: {
      type: "column",
      zoomType: "y",
      resetZoomButton: {
        position: {
          x: -3000,
          y: 0,
        },
      },
    },
    xAxis: {
      categories: fiscalYears,
      crosshair: true,
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        var points = this.points,
          result = '';

        points.forEach(p => {
          p.series.percentage ?
            (result += '<tr><td style="color:' + p.series.color + ';padding:0">' + p.series.name + ': </td>' +
              '<td style="padding:0"><b>' + (p.y) + '</b><br/>' + p.series.percentage + '<br/></td></tr>') :
            (
              (result += '<tr><td style="color:' + p.series.color + ';padding:0">' + p.series.name + ': </td>' +
                '<td style="padding:0"><b>' + (p.y) + '</b><br/></td></tr>')
            )
        });

        result += '</table>';

        return result;
      }
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
				stacking: 'normal',
      },
      column: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y:,.f}",
        },
      },
    },
    series: [
      {
        name: 'Not New Injuries',
        colorByPoint: true,
        data: injuriesTotal
      },
      {
        name: 'New Injuries',
        colorByPoint: true,
        data: newInjuries
      },
    ],
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);


  const ChartCard = () => {

    return (
      <>
      {!isEmpty(data) && !isEmpty(charts) ?
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{getChart('total-injuries-by-fiscal-year')["name"]}</h5>
          <div className="d-flex gap-2">
          <div>
              <HiZoomOut onClick={() => handleResetZoom()} className="card-header__question-mark" />
          </div>
          <HiOutlineArrowsExpand onClick={() => setShow(true)} className="card-header__question-mark card-header__expand" />
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
              <Popover
                id={"popover-pos  itioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                  marginTop: "0",
                  transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                  {getChart('total-injuries-by-fiscal-year')["description"]}
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
          </div>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
        </div>
      </div>
      : null}
      </>
    )
  };

  return (
    <>
    <ChartCard />
    <CardModal title={"Total Injuries by Fiscal Year"} subTitle={""} highcharts={Highcharts} options={options} show={show} handleClose={handleClose} zoom={true} />
    </>
  );
};

export default InjuriesChart;