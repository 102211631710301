import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import NewPassword from "./../../../api/auth/new_password";
import { isEmpty, getOr } from "lodash/fp";
import { useNavigate } from "react-router-dom";

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  // Handler
  const handleSubmit = e => {
    let formData = {}
    formData["email"] = email
    e.preventDefault();
    setLoading(true)
    NewPassword.verify({ user: formData }).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 201) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setLoading(false)
        toast.success(`An email is sent to ${email} with password reset link`, {
          theme: 'colored'
        });
        navigate("/confirm-mail");
      }
      else {
        setLoading(false)
        toast.error(`Email not present`, {
          theme: 'colored'
        });
      }
    })
  };


  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {!loading ?
          <Button className="w-100 btn--rg" type="submit" disabled={!email}>
            Send reset link
          </Button>
          :
          <Button className="w-100 btn--rg" type="submit" disabled={true}>
            Send reset link
          </Button>
        }
      </Form.Group>

      <a href="mailto:info@bernieyazzieconsulting.com">
        I can't recover my account using this page
        <span className="d-inline-block ms-1"> &rarr;</span>
      </a>

      <div className="access-forgot-actions mt-4">
        <Link className="fs--1 text-600" to="/login">
          Return to Login
        </Link>
        <span>|</span>
        <Link className="fs--1 text-600" to="/">
          Back to Home
        </Link>
      </div>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
