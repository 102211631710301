import React, { useContext, useEffect, useState } from 'react';
import { Col } from "react-bootstrap";
import ThirdChart from "./charts/third-chart";
import Dashboard from "./../../../../../../api/dashboards";
import { isEmpty, getOr } from "lodash/fp";
import { DashContext } from 'context/Context';
const ThirdPartyEligibility = () => {
  
  const [data, setData] = useState({})
  const [charts, setCharts] = useState([])
  const [medicarePartA, setMedicarePartA] = useState([])
  const [medicarePartB, setMedicarePartB] = useState([])
  const [medicarePartD, setMedicarePartD] = useState([])
  const [medicaid, setMedicaid] = useState([])
  const [privateInsurance, setPrivateInsurance] = useState([])
  const [fiscalYears, setFiscalYears] = useState([])
  const [fiscalYearsByMedicarePartA, setFiscalYearsByMedicarePartA] = useState([])
  const { Updatefilters } = useContext(DashContext)


  const [chartNo, setChartNo] = useState(1)
  const [looping, setLooping] = useState(false)

  const increaseChartNo = (num) => {
    setLooping(true)
    if (num !== 5) {
      setChartNo(num + 1)
    }
    else {
      setChartNo(1)
    }
  }

  const decreaseChartNo = (num) => {
    setLooping(true)
    if (num !== 1) {
      setChartNo(num - 1)
    }
    else {
      setChartNo(5)
    }
  }

  const cancelLooping = () => {
    setChartNo(1)
    setLooping(false)
  }

  useEffect(() => {
    getChartInfo()
    getChartsData()
  }, []);

  const getChart = (slug) => {
    if (!isEmpty(charts)) {
      if (charts.filter(obj => obj["slug"] === slug)) {
        return charts.filter(obj => obj["slug"] === slug)[0]
      }
    }
  }

  const getChartInfo = () => {
    let slug = window.location.href.split("/")[4]
    let dashboardName = localStorage.getItem('dashboardName')
    Dashboard.getChartsInfoBySlugAndTitle(dashboardName, slug).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setCharts(response.data)
      }
    });
  };

  const getChartsData = () => {
    let dashboardId = localStorage.getItem('dashboardId')
    Dashboard.getThirdPartyEligibilityData(dashboardId).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setData(response.data)
        const stat = getOr([], 'data.stats_by_year', response)
        setFiscalYears(stat.map(a => a.fiscal_year))
        let statByMedicarePartA = stat
        setMedicarePartA(statByMedicarePartA.map(a => a.medicare_part_a))
        setFiscalYearsByMedicarePartA(statByMedicarePartA.map(a => a.fiscal_year))
        setMedicarePartB(stat.map(a => a.medicare_part_b))
        setMedicarePartD(stat.map(a => a.medicare_part_d))
        setMedicaid(stat.map(a => a.medicaid))
        setPrivateInsurance(stat.map(a => a.private_insurance))
      }
    });
  };

  const differenceBetweenFirstAndLastElement = (array) => {
    let firstElement = array[0];
    let lastElement = array[array.length - 1];
    return Math.round(lastElement - firstElement);
  }

  const differenceBetweenLargestAndSmallestElement = (array) => {
    return Math.round(Math.max(...array) - Math.min(...array));
  }


  const getSmallestValue = (array) => {
    return Math.round(Math.min(...array));
  }

  const differenceArray = (array) => {
    let arr = array.slice(1).map((v, i) => v - array[i])
    arr.unshift(0)
    return arr
  }

  useEffect(() => {
    Updatefilters([])
  }, [])

  return (
    <>
      {!isEmpty(data) && !isEmpty(charts) ?
        <div className="container px-0">
          <div className="row">
            <Col lg={12} xl={6} className="chart-col">
              <ThirdChart
                subTitle={(!isEmpty(medicarePartA) ? differenceBetweenLargestAndSmallestElement(medicarePartA).toLocaleString() : 0) + ' Added'}
                fiscalYears={fiscalYearsByMedicarePartA}
                data={medicarePartA}
                description={getChart('patients-with-medicare-part-a')["description"]}
                title={getChart('patients-with-medicare-part-a')["name"]}
                colorHex={"#2A8B8C"}
                min={getSmallestValue(medicarePartA)}
                differenceArray={differenceArray(medicarePartA)}
                increaseChartNo={increaseChartNo}
                decreaseChartNo={decreaseChartNo}
                cancelLooping={cancelLooping}
                chartNo={chartNo}
                looping={looping}
                currentNo={1}
              />
            </Col>
            <Col lg={12} xl={6} className="chart-col">
              <ThirdChart
                subTitle={(!isEmpty(medicarePartB) ? differenceBetweenLargestAndSmallestElement(medicarePartB).toLocaleString() : 0) + ' Added'}
                fiscalYears={fiscalYears}
                data={medicarePartB}
                description={getChart('patients-with-medicare-part-b')["description"]}
                title={getChart('patients-with-medicare-part-b')["name"]}
                colorHex={"#733837"}
                min={getSmallestValue(medicarePartB)}
                differenceArray={differenceArray(medicarePartB)}
                increaseChartNo={increaseChartNo}
                decreaseChartNo={decreaseChartNo}
                cancelLooping={cancelLooping}
                chartNo={chartNo}
                looping={looping}
                currentNo={2}
              />
            </Col>
          </div>
          <div className="row">
            <Col lg={12} xl={6} className="chart-col">
              <ThirdChart
                subTitle={(!isEmpty(medicarePartD) ? differenceBetweenFirstAndLastElement(medicarePartD).toLocaleString() : 0) + ' Added'}
                fiscalYears={fiscalYears}
                data={medicarePartD}
                description={getChart('patients-with-medicare-part-d')["description"]}
                title={getChart('patients-with-medicare-part-d')["name"]}
                colorHex={"#e1a95b"}
                min={getSmallestValue(medicarePartD)}
                differenceArray={differenceArray(medicarePartD)}
                increaseChartNo={increaseChartNo}
                decreaseChartNo={decreaseChartNo}
                cancelLooping={cancelLooping}
                chartNo={chartNo}
                looping={looping}
                currentNo={3}
              />
            </Col>
            <Col lg={12} xl={6} className="chart-col">
              <ThirdChart
                subTitle={(!isEmpty(medicaid) ? differenceBetweenFirstAndLastElement(medicaid).toLocaleString() : 0) + ' Added'}
                fiscalYears={fiscalYears}
                data={medicaid}
                description={getChart('patients-with-medicaid')["description"]}
                title={getChart('patients-with-medicaid')["name"]}
                colorHex={"#6A6E71"}
                min={getSmallestValue(medicaid)}
                differenceArray={differenceArray(medicaid)}
                increaseChartNo={increaseChartNo}
                decreaseChartNo={decreaseChartNo}
                cancelLooping={cancelLooping}
                chartNo={chartNo}
                looping={looping}
                currentNo={4}
              />
            </Col>
          </div>
          <div className="row">
            <Col lg={12} xl={6} className="chart-col">
              <ThirdChart
                subTitle={(!isEmpty(privateInsurance) ? differenceBetweenFirstAndLastElement(privateInsurance).toLocaleString() : 0) + ' Added'}
                fiscalYears={fiscalYears}
                data={privateInsurance}
                description={getChart('patients-with-private-insurance')["description"]}
                title={getChart('patients-with-private-insurance')["name"]}
                colorHex={"#bc6541"}
                min={getSmallestValue(privateInsurance)}
                differenceArray={differenceArray(privateInsurance)}
                increaseChartNo={increaseChartNo}
                decreaseChartNo={decreaseChartNo}
                cancelLooping={cancelLooping}
                chartNo={chartNo}
                looping={looping}
                currentNo={5}
              />
            </Col>
          </div>
        </div>
        : null}
    </>
  )
}

export default ThirdPartyEligibility