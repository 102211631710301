import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isEmpty } from "lodash/fp";
import { SlQuestion } from "react-icons/sl";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { HiOutlineArrowsExpand } from "react-icons/hi";
import CardModal from "components/site/admin/dash/Modals/CardModal";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const ChsNumberOfAuthorizations = ({
  title,
  total,
  dental,
  hos,
  nonhos,
  dentalPercent,
  hosPercent,
  nonhosPercent,
  description,
  increaseChartNo, 
  decreaseChartNo, 
  cancelLooping, 
  chartNo, 
  looping, 
  currentNo
}) => {
  const options = { 
  
  credits: {
        enabled: false
    },

     credits: {
      enabled: false
  },

  chart: {
    type: "pie",
    },

    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: "%",
      },
    },

    title: {
      text: Math.round((total / 1000) * 10) / 10 + "K",
    },

    subtitle: {
      text: "Authorizations",
    },

    plotOptions: {
      pie: {
        shadow: false,
        center: ["50%", "50%"],
        showInLegend: true,
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:10px">Total</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0"></td>' +
        '<td style="padding:0"><b>{point.a:.1f} {point.y:.1f}%</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },

    series: [
      {
        name: "Total",
        colorByPoint: true,
        innerSize: "50%",
        dataLabels: {
          formatter: function () {
            return this.y > 1
              ? "<b>" + this.point.x + "K</b> " + this.y + "%"
              : null;
          },
        },
        data: [
          {
            name: "Dental",
            y: Math.round(dentalPercent * 10) / 10,
            x: Math.round((dental / 1000) * 10) / 10,
            a: (Math.round((dental / 1) * 1) / 1).toLocaleString(),
            color: "#2A8B8C",
          },
          {
            name: "Hospitalization",
            y: Math.round(hosPercent * 10) / 10,
            x: Math.round((hos / 1000) * 10) / 10,
            a: (Math.round((hos / 1) * 1) / 1).toLocaleString(),
            color: "#733837",
          },
          {
            name: "Non-Hospitalization",
            y: Math.round(nonhosPercent * 10) / 10,
            x: Math.round((nonhos / 1000) * 10) / 10,
            a: (Math.round((nonhos / 1) * 1) / 1).toLocaleString(),
            color: "#e1a95b",
          },
        ],
      },
    ],
  };


	const handleClose = () => {
    setShow(false);
    cancelLooping()
  }

	useEffect(() => {
		if (chartNo === 2 && looping){
			setShow(true)
		}
		if (chartNo !== 2){
			setShow(false)
		}
	  }, [chartNo]);  

    const [show, setShow] = useState(false);


  const ChartCard = () => {

    return (
      <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>PRC Number Of Authorizations</h5>
          <div className="d-flex gap-2">
              <div>
              <HiOutlineArrowsExpand onClick={() => setShow(true)} className="card-header__question-mark card-header__expand" />
              </div>
              <OverlayTrigger
                trigger="hover"
                placement="bottom-end"
                overlay={
                  <Popover
                    id={"popover-positioned-" + "bottom-end"}
                    arrow={false}
                    arrowProps={false}
                    style={{
                      marginTop: "0",
                      transition: "all 0.2s ease-in-out, transform 0s;",
                    }}
                  >
                    <Popover.Body>
                      {description}
                    </Popover.Body>
                  </Popover>
                }
              >
                <div>
                  <SlQuestion className="card-header__question-mark" />
                </div>
              </OverlayTrigger>
          </div> 
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </>
    )
  }

  return (
    <>
    <ChartCard />
    <CardModal 
      title={title} 
      subTitle={""} 
      highcharts={Highcharts} 
      options={options} 
      show={show} 
      handleClose={handleClose} 
      zoom={true} 
      increaseChartNo={increaseChartNo}
      decreaseChartNo={decreaseChartNo}
      currentNo={currentNo}
      />
    </>
  );
};

export default ChsNumberOfAuthorizations;
