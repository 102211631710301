import React, { useContext, useEffect, useState } from 'react';
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ChsExpenitures from "./charts/chs-expenitures";
import Expenditures from "./charts/chs-expenitures/expenitures-chart";
import ChsNumberOfAuthorizations from "./charts/chs-number-of-authorizations";
import Dashboard from "./../../../../../../api/dashboards";
import { isEmpty, getOr } from "lodash/fp";
import { DashContext } from 'context/Context';

const PurchaseReferredCare = () => {

  const [data, setData] = useState({})
  const [opt, setOpt] = useState("")
  const [charts, setCharts] = useState([])
  const [fiscalYears, setFiscalYears] = useState([])
  const [totalExpenditures, setTotalExpenditures] = useState(0)
  const [dentalExpenditures, setDentalExpenditures] = useState(0)
  const [hospitalExpenditures, setHospitalExpenditures] = useState(0)
  const [nonHospitalExpenditures, setNonHospitalExpenditures] = useState(0)

  const [totalNumbers, setTotalNumbers] = useState(0)
  const [dentalNumbers, setDentalNumbers] = useState(0)
  const [hospitalNumbers, setHospitalNumbers] = useState(0)
  const [nonHospitalNumbers, setNonHospitalNumbers] = useState(0)

  const [dentalExpendituresByYear, setDentalExpendituresByYear] = useState([])
  const [hospitalExpendituresByYear, setHospitalExpendituresByYear] = useState([])
  const [nonHospitalExpendituresByYear, setNonHospitalExpendituresByYear] = useState([])

  const {Updatefilters} = useContext(DashContext)

  const [chartNo, setChartNo] = useState(1)
  const [looping, setLooping] = useState(false)

  const increaseChartNo = (num) => {
    setLooping(true)
    if (num !== 5){
      setChartNo(num + 1)
    }
    else{
      setChartNo(1)
    }
  }

  const decreaseChartNo = (num) => {
    setLooping(true)
    if (num !== 1){
      setChartNo(num - 1)
    }
    else{
      setChartNo(5)
    }
  }

  const cancelLooping = () => {
    setChartNo(1)
    setLooping(false)
  }

  useEffect(() => {
    getChartInfo()
    getChartsData(opt, true)
  }, []);

  const getChart = (slug) => {
    if (!isEmpty(charts)) {
      if (charts.filter(obj => obj["slug"] === slug)) {
        return charts.filter(obj => obj["slug"] === slug)[0]
      }
    }
  }

  const getChartInfo = () => {
    let slug = window.location.href.split("/")[4]
    let dashboardName = localStorage.getItem('dashboardName')
    Dashboard.getChartsInfoBySlugAndTitle(dashboardName, slug).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setCharts(response.data)
      }
    });
  };

  const getChartsData = (option, pageLoad) => {
    let dashboardId = localStorage.getItem('dashboardId')
    Dashboard.getPurchaseReferredCareData(dashboardId, option, pageLoad).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        setData(response.data)
        setFiscalYears(getOr([], 'data.fiscal_years', response))

        setTotalExpenditures(getOr([], 'data.total_expenditures', response))
        setDentalExpenditures(getOr([], 'data.dental_expenditures', response))
        setHospitalExpenditures(getOr([], 'data.hospitalization_expenditures', response))
        setNonHospitalExpenditures(getOr([], 'data.non_hospitalization_expenditures', response))

        setTotalNumbers(getOr([], 'data.total_numbers', response))
        setDentalNumbers(getOr([], 'data.dental_numbers', response))
        setHospitalNumbers(getOr([], 'data.hospitalization_numbers', response))
        setNonHospitalNumbers(getOr([], 'data.non_hospitalization_numbers', response))
        setDentalExpendituresByYear(getOr([], 'data.stats_by_year', response).map(a => a.dental_expenditures))
        setHospitalExpendituresByYear(getOr([], 'data.stats_by_year', response).map(a => a.hospitalization_expenditures))
        setNonHospitalExpendituresByYear(getOr([], 'data.stats_by_year', response).map(a => a.non_hospitalization_expenditures))
      }
    });
  };

  useEffect(() => {
    const listenStorageChange = () => {
      const option = localStorage.getItem('option')
      if (option === '') {
        getChartsData(option, true)
      }
      else{
        getChartsData(option, false)
      }
    };
    window.addEventListener("storage", listenStorageChange);
    return () => window.removeEventListener("storage", listenStorageChange);
  }, []);

  const getSmallestValue = (array) => {
    return Math.min(...array);
  }

  const getLargestValue = (array) => {
    return Math.max(...array);
  }

  const differenceArray = (array) => {
    let arr = array.slice(1).map((v, i) => v - array[i])
    arr.unshift(0)
    return arr
  }

  
  useEffect(() => {
    Updatefilters(fiscalYears);
  }, [fiscalYears]);


  return (
    <>
    {!isEmpty(data) && !isEmpty(charts) ?
      <div className="container px-0">
{/*         <div className="card mb-3">
          <div className="card-body d-flex justify-content-between align-items-center gap-2">
            <h5 className="m-0 w500">Filters</h5>
            <Form>
              <Form.Select 
                size="sm" 
                onChange={(e) => handleFieldChange(e)} 
                value={opt}
              >
                <option value={""} key={"View-All"}>View All</option>
                {fiscalYears && fiscalYears.map(option => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form>
          </div>
        </div> */}
      <>
        <div className="row">
          <Col lg={12} xl={6} className="chart-col">
            <ChsExpenitures 
              total = {totalExpenditures}
              dental = {dentalExpenditures}
              hos = {hospitalExpenditures}
              nonhos = {nonHospitalExpenditures}
              dentalPercent = {totalExpenditures !== 0 ? ((dentalExpenditures/totalExpenditures)*100) : 0}
              hosPercent = {totalExpenditures !== 0 ? ((hospitalExpenditures/totalExpenditures)*100) : 0}
              nonhosPercent = {totalExpenditures !== 0 ? ((nonHospitalExpenditures/totalExpenditures)*100) : 0}
              description={getChart('prc-expenditures')["description"]}
              title={getChart('prc-expenditures')["name"]}
              increaseChartNo={increaseChartNo}
              decreaseChartNo={decreaseChartNo}
              cancelLooping={cancelLooping}
              chartNo={chartNo}
              looping={looping}
              currentNo={1}
            />
          </Col>
          <Col lg={12} xl={6} className="chart-col">
            <ChsNumberOfAuthorizations
              total = {totalNumbers}
              dental = {dentalNumbers}
              hos = {hospitalNumbers}
              nonhos = {nonHospitalNumbers}
              dentalPercent = {totalNumbers !== 0 ? ((dentalNumbers/totalNumbers)*100) : 0}
              hosPercent = {totalNumbers !== 0 ? ((hospitalNumbers/totalNumbers)*100) : 0}
              nonhosPercent = {totalNumbers !== 0 ? ((nonHospitalNumbers/totalNumbers)*100) : 0}
              description={getChart('prc-number-of-authorizations')["description"]}
              title={getChart('prc-number-of-authorizations')["name"]}
              increaseChartNo={increaseChartNo}
              decreaseChartNo={decreaseChartNo}
              cancelLooping={cancelLooping}
              chartNo={chartNo}
              looping={looping}
              currentNo={2}
             />
          </Col>
        </div>
        <div className="row">
        <Col lg={4} xl={6} xxl={4} className="chart-col">  
          <Expenditures 
            fiscalYears={opt === "" ? fiscalYears : fiscalYears}
            data={nonHospitalExpendituresByYear}
            description={getChart('prc-expenditures-non-hospitalization')["description"]}
            title={getChart('prc-expenditures-non-hospitalization')["name"]}
            colorHex={"#2A8B8C"}
            min={getSmallestValue([...nonHospitalExpendituresByYear, ...hospitalExpendituresByYear, ...dentalExpendituresByYear])}
            max={getLargestValue([...nonHospitalExpendituresByYear, ...hospitalExpendituresByYear, ...dentalExpendituresByYear])}
            differenceArray={differenceArray(nonHospitalExpendituresByYear)}
            option={opt}
            increaseChartNo={increaseChartNo}
            decreaseChartNo={decreaseChartNo}
            cancelLooping={cancelLooping}
            chartNo={chartNo}
            looping={looping}
            currentNo={3}
          />
        </Col>
        <Col lg={4} xl={6} xxl={4} className="chart-col">  
          <Expenditures 
            fiscalYears={opt === "" ? fiscalYears : fiscalYears}
            data={hospitalExpendituresByYear}
            description={getChart('prc-expenditures-hospitalization')["description"]}
            title={getChart('prc-expenditures-hospitalization')["name"]}
            colorHex={"#733837"}
            min={getSmallestValue([...nonHospitalExpendituresByYear, ...hospitalExpendituresByYear, ...dentalExpendituresByYear])}
            max={getLargestValue([...nonHospitalExpendituresByYear, ...hospitalExpendituresByYear, ...dentalExpendituresByYear])}
            differenceArray={differenceArray(hospitalExpendituresByYear)}
            option={opt}
            increaseChartNo={increaseChartNo}
            decreaseChartNo={decreaseChartNo}
            cancelLooping={cancelLooping}
            chartNo={chartNo}
            looping={looping}
            currentNo={4}
          />
        </Col>
        <Col lg={4} xl={6} xxl={4} className="chart-col">  
          <Expenditures 
            fiscalYears={opt === "" ? fiscalYears : fiscalYears}
            data={dentalExpendituresByYear}
            description={getChart('prc-expenditures-dental')["description"]}
            title={getChart('prc-expenditures-dental')["name"]}
            colorHex={"#e1a95b"}
            min={getSmallestValue([...nonHospitalExpendituresByYear, ...hospitalExpendituresByYear, ...dentalExpendituresByYear])}
            max={getLargestValue([...nonHospitalExpendituresByYear, ...hospitalExpendituresByYear, ...dentalExpendituresByYear])}
            differenceArray={differenceArray(dentalExpendituresByYear)}
            option={opt}
            increaseChartNo={increaseChartNo}
            decreaseChartNo={decreaseChartNo}
            cancelLooping={cancelLooping}
            chartNo={chartNo}
            looping={looping}
            currentNo={5}
          />
        </Col>
        </div>
      </>
      </div>
      : null}
    </>
  );
};

export default PurchaseReferredCare;
