import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdToggleOn, MdToggleOff } from "react-icons/md";
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import InvoiceReportsTableWrapper from './InvoiceReportsTableWrapper';
import InvoiceReportsTableMain from './InvoiceReportsTableMain';
import { recentPurchaseTableData } from 'data/dashboard/ecom';
import InvoiceReportsTableFooter from './InvoiceReportsTableFooter';
import { isEmpty, getOr } from "lodash/fp";
// Icons	
import Button from 'react-bootstrap/Button';
import { AutoComplete } from "primereact/autocomplete";
import { HiOutlineTrash } from 'react-icons/hi';
import { AiOutlineClose } from 'react-icons/ai';
import { BsCurrencyDollar } from 'react-icons/bs';
import { RiFlagLine } from 'react-icons/ri';
import ModalOrgInvoice from '../../modals/ModalOrgInvoice';

const InvoiceReportsTable = ({ invoices, showModal, setShowModal, setModalData }) => {

	const [filterBy, setFilterBy] = useState("All")

	const handleClick = () => {
	}

	const columns = [
		{
			accessor: 'name',
			Header: 'Organization Name',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: '',
			},
			
			Cell: rowData => {
					
				return (
					<ModalOrgInvoice print={true} orgName={rowData.value} />
				);
			}
		},
		{
			accessor: 'po',
			Header: 'PO #',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'fw-semi-bold',
			}
		},
		{
			accessor: 'invoice_id',
			Header: 'Invoice #',
			headerProps: { className: 'pe-4' },
			cellProps: {
				className: 'fw-semi-bold',
			}
		},
		{
			accessor: 'invoice_amount',
			Header: 'Invoice Amount',
			headerProps: { className: '' },
			cellProps: {
				className: 'fw-semi-bold',
			}
		},
		{
			accessor: 'invoice_date',
			Header: 'Invoice Date',
			headerProps: { className: '' },
			cellProps: {
				className: 'fw-semi-bold',
			}
		},
			{
				accessor: 'status',
				Header: 'Status',
				cellProps: {
					className: ''
				},
				Cell: rowData => {
					
					return (
						<div className={`${rowData.value == "Paid" ? "invoice--paid" : "invoice--unpaid" }`}>{rowData.value}</div>
					);
				}
			},
		{
			accessor: 'none',
			Header: 'Trash',
			disableSortBy: true,
			cellProps: {
				className: 'd-flex p-3'
			},
			Cell: rowData => {
				
				return (
					<HiOutlineTrash style={{ cursor: "pointer", }} size={25}/>
				);
			}
		}
	];

	const [value, setValue] = useState('');
    const [items, setItems] = useState([]);

	
    const search = (event) => {
        setItems([...Array(3).keys()].map(item => event.query + '-' + item));
    }

	const [formData, setFormData] = useState({
		filterYears: 'All Years',
		filterMonths: 'All Months',
	  });



	  const handleFieldChange = e => {
		setFormData({
		  ...formData,
		  [e.target.name]: e.target.value
		});
	  };
	  
	return (
		<InvoiceReportsTableWrapper
			columns={columns}
			data={invoices}
			selection
			sortable
			pagination
			perPage={7}
			rowCount={invoices.length}
		>
			<Card>
				<Card.Header>
					<div className='d-flex justify-content-between align-items-center'>
						<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Invoices</h5>
						<div className='d-flex gap-2'>
							<Button	className="btn--filter" variant={`${filterBy  == "All" ? "primary" : "transparent"}`} onClick={() => setFilterBy("All")} >All Invoices (1,234)</Button>
							<Button variant={`${filterBy  == "Unpaid" ? "primary" : "transparent"}`} className="btn--filter" onClick={() => setFilterBy("Unpaid")} >Unpaid (3)</Button>
							<Button variant={`${filterBy  == "Paid" ? "primary" : "transparent"}`} className="btn--filter" onClick={() => setFilterBy("Paid")} >Paid (512)</Button>
							<Button variant={`${filterBy  == "Trash" ? "primary" : "transparent"}`} className="btn--filter" onClick={() => setFilterBy("Trash")} >Trash (42)</Button>
						</div>
					</div>

					<div className='reports p-fluid'>
						<AutoComplete value={value} suggestions={items} completeMethod={search} className="reports__auto-complete" placeholder="Search" onChange={(e) => setValue(e.value)}  />
						<Button className='reports__cta'>Search</Button>
					</div>

					<div className='search-results'>
						<div className='search-results__result'>
							<div className='spacer'></div>
							<span>
								Rocky Boy Health Center
							</span>
							<AiOutlineClose className="icon" />
						</div>
						<div className='search-results__result'>
							<div className='spacer'></div>
							<span>
							Lorem Ipsum Health Center
							</span>
							<AiOutlineClose className="icon" />
						</div>
					</div>

					<div className="reports__filter">
						<div>
							<span>Mark selected as:</span>
							<div className='d-flex gap-2 mt-2'>
								<Button variant='secondary' className="btn btn-falcon-default reports__filter__cta"><BsCurrencyDollar size={20} /> <span>Paid</span></Button>
								<Button variant='secondary' className="btn btn-falcon-default reports__filter__cta"><RiFlagLine size={20} /> <span>UnPaid</span></Button>
								<Button variant='secondary' className="btn btn-falcon-default reports__filter__cta"><HiOutlineTrash size={20} /> <span>Trash</span></Button>
							</div>
						</div>
						<div>
							<span className='reports__filter__filterBy'>Filter By:</span>	
							<div>
							<Form.Group className="d-flex gap-2 mt-2">
								<Form.Select aria-label="Select Year" name="filterYears" onChange={handleFieldChange} value={formData.filterYears}>
								<option value="All Years">All Years</option>
								<option>2023</option>
								<option>2022</option>
								<option>2021</option>
								<option>2021</option>
								</Form.Select>
								<Form.Select aria-label="Select Month" name="filterMonths" onChange={handleFieldChange} value={formData.filterMonths}>
								<option value="All Months">All Months</option>
								<option>Jun</option>
								<option>Feb</option>
								<option>Jul</option>
								</Form.Select>
							</Form.Group>
							</div>
						</div>
					</div>
		
				</Card.Header>
				<Card.Body className="p-0">
					<InvoiceReportsTableMain
						table
						headerClassName="org-table__header text-900 text-nowrap align-middle"
						rowClassName="btn-reveal-trigger text-nowrap align-middle"
						tableProps={{
							size: 'sm',
							className: 'fs--1 mb-0 overflow-hidden'
						}}
						setShowModal={setShowModal}
						setModalData={setModalData}
					/>
				</Card.Body>
				<Card.Footer>
					<InvoiceReportsTableFooter rowCount={invoices.length} table rowInfo navButtons />
				</Card.Footer>
			</Card>
		</InvoiceReportsTableWrapper>
	);
};

export default InvoiceReportsTable;
