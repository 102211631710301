import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, Form } from "react-bootstrap";
import classNames from "classnames";
import AppContext, { DashContext, verticalNavContext } from "context/Context";
import PropTypes from 'prop-types';

import Logo from "components/common/Logo";
import NavbarTopDropDownMenus from "components/navbar/top/NavbarTopDropDownMenus";
import { navbarBreakPoint, topNavbarBreakpoint } from "config";
// import TopNavRightSideNavItem from "components/navbar/top/TopNavRightSideNavItem";
import { useLocation } from "react-router";
import TopNavRightSideNavItem from "components/site/admin/TopNavRightSideNavItem";
import ToggleButton from "components/navbar/vertical/ToggleButton";
import { isEmpty } from "lodash/fp";
import Organization from "./../../../api/organizations";
import Defender from "helpers/defender";
import { getOr } from "lodash/fp";
import { useNavigate } from "react-router-dom";
const SummaryNavbarTop = () => {
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig,
  } = useContext(AppContext);

  const {
    mainRoute, subRoute
  } = useContext(verticalNavContext);

  const { filters } = useContext(DashContext)

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const isChat = pathname.includes("chat");

  const [mainHeading, setMainHeading] = useState('');

  const [subHeading, setSubHeading] = useState('');

  const [dateHeading, setDateHeading] = useState('');

  const [startDate, setStartDate] = useState('');

  const [endDate, setEndDate] = useState('');

  const [dashboardStatic, setDashboardStatic] = useState(null);

  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    navbarPosition === "top" && setConfig("navbarCollapsed", !navbarCollapsed);
    (navbarPosition === "vertical" || navbarPosition === "combo") &&
      setConfig("showBurgerMenu", !showBurgerMenu);
  };

  const [opt, setOpt] = useState("");

  const [data, setData] = useState({})

  useEffect(() => {
    if (!isEmpty(Defender.currentUser())){
    Organization.getFirstOrganizationDashboard(Defender.currentUser().organization_id
      ? Defender.currentUser().organization_id
      : Defender.currentUser().organization).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          if (!isEmpty(response.data)) {
            localStorage.setItem('dashboardName', getOr({}, "data.dashboard.name", response))
            localStorage.setItem('dashboardCsvUploaded', getOr({}, "data.dashboard.csv_uploaded_format", response))
            setMainHeading(getOr({}, "data.dashboard.name", response))
            setDateHeading(getOr({}, "data.dashboard.csv_uploaded_format", response))
            setStartDate(getOr({}, "data.dashboard.starting_date_format", response))
            setEndDate(getOr({}, "data.dashboard.ending_date_format", response))
          }
        }
      })
    }
    else{
      navigate("/");
    }
  }, []);

  useEffect(() => {
    setMainHeading(localStorage.getItem('dashboardName'))
  }, [localStorage.getItem('dashboardName')]);
  useEffect(() => {
    setSubHeading(localStorage.getItem('dashboardSubPage'))
  }, [localStorage.getItem('dashboardSubPage')]);
  useEffect(() => {
    setDateHeading(localStorage.getItem('dashboardCsvUploaded'))
  }, [localStorage.getItem('dashboardCsvUploaded')]);
  useEffect(() => {
    setStartDate(localStorage.getItem('dashboardStartDate'))
  }, [localStorage.getItem('dashboardStartDate')]);
  useEffect(() => {
    setEndDate(localStorage.getItem('dashboardEndDate'))
  }, [localStorage.getItem('dashboardEndDate')]);
  useEffect(() => {
    const option = localStorage.getItem('option')
    setOpt(option)
  }, [localStorage.getItem('option')]);
  useEffect(() => {
    const dashboardStatic = localStorage.getItem('dashboardStatic')
    setDashboardStatic(dashboardStatic)
  }, [localStorage.getItem('dashboardStatic')]);


  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", setDropShadow);
    return () => window.removeEventListener("scroll", setDropShadow);
  }, []);

  const handleFieldChange = (e) => {
    setOpt(e.target.value)
    localStorage.setItem('option', e.target.value)
    window.dispatchEvent(new Event("storage"));
  }

  useEffect(() => {
    const listenStorageChange = () => {
      const option = localStorage.getItem('option')
      setOpt(option)
    };
    window.addEventListener("storage", listenStorageChange);
    return () => window.removeEventListener("storage", listenStorageChange);
  }, []);


  return (
    <Navbar
      style={{ background: "#f4f5f5" }}
      className={classNames("navbar-glass d-flex justify-content-between fs--1 navbar-top sticky-kit", {
        "navbar-glass-shadow": showDropShadow && !isChat,
      })}
      expand={
        navbarPosition === "vertical" || navbarPosition === "left"
          ? topNavbarBreakpoint
          : true
      }
    >
      <div className="vertical-navbar-options">
        <ToggleButton />
        <h5 className="dash-title">
          {!isEmpty(startDate) && !isEmpty(endDate) ?
            <>
              {subHeading ? ` ${subHeading}` : `${mainHeading}`} - {startDate} through {endDate}
            </>
            :
            <>{subHeading} {!isEmpty(subHeading) ? '-' : ''} {mainHeading}</>
          }

          {!isEmpty(dateHeading) ?
            <span>Data Updated: {dateHeading}</span>
            : null}
        </h5>
      </div>
      {!isEmpty(filters) && dashboardStatic && (
        <div>
          <Form>
            <Form.Select
              size="sm"
              onChange={handleFieldChange}
              value={opt}
            >
              <option value={""} key={"View-All"}>View All</option>
              {filters.map(option => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form>
        </div>
      )}
    </Navbar>
  );
};


SummaryNavbarTop.propTypes = { parent: PropTypes.string };


export default SummaryNavbarTop;
