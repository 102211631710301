import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import handleNavbarTransparency from 'helpers/handleNavbarTransparency';
import LandingRightSideNavItem from './LandingRightSideNavItem';
import { topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import Defender from "./../../../helpers/defender";
import SignOut from "./../../../api/auth/sign_out";
import User from "./../../../api/users";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Avatar from 'components/common/Avatar';
import team3 from 'assets/img/sp-images/default-avatar-sm.jpg';
import { Dropdown } from 'react-bootstrap';

const NavbarStandard = () => {

	const checkSessionTime = (e) => {
		User.checkSessionTime().then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 201) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				const flag = getOr(false, "data.flag", response)
				if (flag) {
					setModalShow(false)
					setModalShow(true)
				}
			}
		});
	}

  const increaseSessionTime = () => {
    if (!isEmpty(Defender.currentUser())) {
      User.fetch(Defender.currentUser().id).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 201) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          setModalShow(false)
        }
      });
    }
  }

	useEffect(() => {
    checkSessionTime();
    increaseSessionTime()

		// Set up the interval for subsequent API calls
		const intervalId = setInterval(() => {
			checkSessionTime();
		}, 60000); // 60000 milliseconds = 1 minute

		// Clean up the interval on component unmount
		return () => clearInterval(intervalId);
	}, []);

	const [modalShow, setModalShow] = useState(false)
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  const navigate = useNavigate();
  const logOut = (e) => {
    SignOut.destroy().then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 201) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        localStorage.removeItem('dashboardName')
        localStorage.removeItem('dashboardId')
        localStorage.removeItem('dashboardSubPage')
        localStorage.removeItem('dashboardCsvUploaded')
        localStorage.removeItem('dashboardStartDate')
        localStorage.removeItem('dashboardEndDate')
        localStorage.removeItem('dashboardStatic')
        localStorage.removeItem('option')
        Defender.logout();
        toast.success(`Successfully signed out`, {
          theme: 'colored'
        });
        navigate("/logout");
      }
    })
  }

  const {
    config: { isRTL },
    setConfig
  } = useContext(AppContext);

  return (
    <>
			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				size="md"
				aria-labelledby="remove-modal"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>Your session will be logged out in less than 5 minutes.</div>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => {
						increaseSessionTime()
					}
					} variant="btn-primary" className="btn btn-primary">STAY LOGGED IN</Button>
				</Modal.Footer>
			</Modal>
    <Navbar
      expand={topNavbarBreakpoint}
      className={classNames('navbar-front px-0')}
    >
      <Container>
        <Navbar.Brand className="" as={'div'} to="/">
          <Logo at="navbar-top" width={40} id="topLogo" />
        </Navbar.Brand>
        <div className="navbar-spacer"></div>
        <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        <Navbar.Collapse className="scrollbar">
          <LandingRightSideNavItem />
        </Navbar.Collapse>
        <ul className="header__access">
          {!isEmpty(Defender.currentUser()) ?
            <Dropdown navbar={true} as="li">
              <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to=""
                className="profile-dropdown pe-0 ps-3"
              >
                {!isEmpty(Defender.currentUser()) ?
                  <>
                    {!isEmpty(Defender.currentUser().main_image_link) ?
                      <img src={Defender.currentUser().main_image_link} height={25} />
                      : <Avatar src={team3} />
                    }
                  </>
                  : null}
              </Dropdown.Toggle>

              {!isEmpty(Defender.currentUser()) ?

                <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
                  <div className="bg-white rounded-2 py-2 dark__bg-1000">
                    {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ?
                      <Dropdown.Item as={Link} to="/admin">
                        Dashboard
                      </Dropdown.Item>
                      : <Dropdown.Item as={Link} to="/dash">
                        Dashboard
                      </Dropdown.Item>}
                    {Defender.currentUser()._r.includes("admin") ?
                      <Dropdown.Item as={Link} to="/upload">
                        Upload
                      </Dropdown.Item>
                      : null}
                    <Dropdown.Item as={Link} to="/settings">
                      Settings
                    </Dropdown.Item>
                    {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                      <Dropdown.Item as={Link} to="/tiles">
                        Tiles
                      </Dropdown.Item>
                    ) : null}
                    {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                      <Dropdown.Item as={Link} to="/plans">
                        Plans
                      </Dropdown.Item>
                    ) : null}
                    {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                      <Dropdown.Item as={Link} to="/invoice-reports">
                        Invoices Reports
                      </Dropdown.Item>
                    ) : null}
                    {Defender.currentUser()._r.includes("admin") || Defender.currentUser()._r.includes("sub_admin") ? (
                      <Dropdown.Item as={Link} to="/activity-log">
                        Activity Log
                      </Dropdown.Item>
                    ) : null}
                    {Defender.currentUser()._r.includes("admin") ? (
                      <Dropdown.Item as={Link} to="/superadmin">
                        SuperAdmin
                      </Dropdown.Item>
                    ) : null}
                    {Defender.currentUser()._r.includes("admin") ? (
                      <Dropdown.Item as={Link} to="/users">
                        Users
                      </Dropdown.Item>
                    ) : null}
                    <Dropdown.Item onClick={logOut}>
                      Logout
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
                :
                <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
                  <div className="bg-white rounded-2 py-2 dark__bg-1000">
                    <Dropdown.Item as={Link} to="/login">
                      Login
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              }
            </Dropdown>
            :
            <a className="nav-item" href="/login">Login</a>
          }
        </ul>
      </Container>
    </Navbar>
    </>
  );
};

export default NavbarStandard;
