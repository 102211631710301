import HighchartsReact from 'highcharts-react-official';
import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { HiZoomOut } from 'react-icons/hi';

function CardModal({ title, subTitle, highcharts, options, show, handleClose, zoom, increaseChartNo, decreaseChartNo, currentNo, disChart }) {
  const [fullscreen, setFullscreen] = useState(true);
  
  const newOptions = JSON.parse(JSON.stringify(options));

  const newNormalOptions = JSON.parse(JSON.stringify(options));

  newOptions.chart.height = "50%"
  newNormalOptions.chart.height = "47%"
   if (newNormalOptions.chart.type === 'column') {
    newNormalOptions.plotOptions.column.dataLabels.style = {
      fontSize: '1.5em'
    };
    newNormalOptions.yAxis.labels = {
      style: {
        fontSize: '1.3em'
      },
    };
    // newNormalOptions.xAxis.labels.style = {
    //     fontSize: '1.3em'
    // };
  } else if (newNormalOptions.chart.type === 'line') {
      newNormalOptions.plotOptions.series.dataLabels.style = {
        fontSize: '1.4em'
      };
      newNormalOptions.yAxis.labels = {
        style: {
          fontSize: '1.3em'
        },
      };
      newNormalOptions.xAxis.labels = {
        style: {
          fontSize: '1.2em'
        },
      };
  } else if (newNormalOptions.chart.type === 'bar') {

      newNormalOptions.plotOptions.series.dataLabels.style = {
        fontSize: '1.25em',
        border: 'none',
        outline: 'none',
      };
      newNormalOptions.plotOptions.series.dataLabels['inside'] = true
      newNormalOptions.plotOptions.series.dataLabels['verticalAlign'] = 'middle'
      newNormalOptions.plotOptions.series.dataLabels['y'] = 5
      newNormalOptions.yAxis.labels = {
        style: {
          fontSize: '1.15em'
        },
      };
      newNormalOptions.xAxis.labels = {
        style: {
          fontSize: '1.15em'
        },
      };

  } else if(newNormalOptions.chart.type === 'pie') {
    console.log(newNormalOptions.series[0])
    newNormalOptions.series[0].dataLabels['style'] = {
      fontSize: '1.4em',
    };
    newOptions.series[0].dataLabels['style'] = {
      fontSize: '1.4em',
    };
  } else {
    console.log(newNormalOptions.chart.type)
  }

  
 const chartRef = useRef();
  const handleResetZoom = () => {
    const chart = chartRef.current.chart;
    chart.zoom();
  };

  return (
    <>
      <Modal show={show} fullscreen={fullscreen} className="chart__modal" onHide={handleClose} animation={false}>
        <Modal.Header>
          <Modal.Title>{title} {subTitle ? `: ${subTitle}` : ''}</Modal.Title>
          <div className="modal-header__actions">
          <Button variant="falcon-default" onClick={() => decreaseChartNo(currentNo)}>
              <AiOutlineArrowLeft />
            </Button>
            {zoom && (
              <Button variant="falcon-default" onClick={() => handleResetZoom()}>
              <HiZoomOut/>
            </Button> 
            )}
            <Button variant="falcon-default" onClick={() =>increaseChartNo(currentNo)}>
              <AiOutlineArrowRight />
            </Button>
            <Button variant="falcon-default" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
        {disChart ? (
          <>
          <div className='chart__modal__container'>
          <HighchartsReact highcharts={highcharts} options={newOptions} ref={chartRef} />
          </div>
          </>
        ) : (
          <>
          <div className='chart__modal__container'>
          <HighchartsReact highcharts={highcharts} options={newNormalOptions} ref={chartRef} />
          </div>
          </>
        )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CardModal;
