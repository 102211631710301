import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";
import { HiOutlineArrowsExpand, HiZoomOut } from "react-icons/hi";
import CardModal from "../../Modals/CardModal";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const AgedTrialBalances = ({ till30, till60, till90, till120, over120, description, title, increaseChartNo, decreaseChartNo, cancelLooping, chartNo, looping, currentNo }) => {


	const chartRef = useRef();

	const handleResetZoom = () => {
	  const chart = chartRef.current.chart;
	  chart.zoom();
	};


  const options = { 
  
  credits: {
        enabled: false
    },

    title: {
      text: "",
    },
    chart: {
      type: "column",
      zoomType: "y",
      resetZoomButton: {
        position: {
          x: -3000,
          y: 0,
        },
      },
    },
    xAxis: {
      categories: ["0-30", "31-60", "61-90", "91-120", "120+"],
      crosshair: true,
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
      title: {
        useHTML: true,
        text: "",
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px"></span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0"></td>' +
        '<td style="padding:0">{point.name}<br/> <b>{point.a}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.0,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y}" + "M",
        },
      },
    },
    series: [
      {
        name: "Aged Trial Balances",
        colorByPoint: true,
        animation: false,
        data: [
          {
            name: "0-30",
            a: (Math.round(till30 * 1) / 1).toLocaleString(),
            y: Math.round((till30 / 1000000) * 10) / 10,
            color: "#2A8B8C",
          },
          {
            name: "31-60",
            a: (Math.round(till60 * 1) / 1).toLocaleString(),
            y: Math.round((till60 / 1000000) * 10) / 10,
            color: "#2A8B8C",
          },
          {
            name: "61-90",
            a: (Math.round(till90 * 1) / 1).toLocaleString(),
            y: Math.round((till90 / 1000000) * 10) / 10,
            color: "#2A8B8C",
          },
          {
            name: "91-120",
            a: (Math.round(till120 * 1) / 1).toLocaleString(),
            y: Math.round((till120 / 1000000) * 10) / 10,
            color: "#2A8B8C",
          },
          {
            name: "120+",
            a: (Math.round(over120 * 1) / 1).toLocaleString(),
            y: Math.round((over120 / 1000000) * 10) / 10,
            color: "#2A8B8C",
          },
        ],
      },
    ],
  };


  const [show, setShow] = useState(false);

	const handleClose = () => {
    setShow(false);
    cancelLooping()
  }

	useEffect(() => {
		if (chartNo === 3 && looping){
			setShow(true)
		}
		if (chartNo !== 3){
			setShow(false)
		}
	  }, [chartNo]);
  
	const ChartCard = () => {
  
	  return (
      <>
      <div className="card card--chart">
	    <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <div className="d-flex gap-2">
            <div>
              <HiZoomOut onClick={() => handleResetZoom()} className="card-header__question-mark" />
            </div>
            <HiOutlineArrowsExpand onClick={() => setShow(true)} className="card-header__question-mark card-header__expand" />
            <OverlayTrigger
              trigger="hover"
              placement="bottom-end"
              overlay={
              <Popover
                id={"popover-pos  itioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                marginTop: "0",
                transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                {description}
                </Popover.Body>
              </Popover>
              }
            >
              <div>
              <SlQuestion className="card-header__question-mark" />
              </div>
            </OverlayTrigger>
            </div>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
        </div>
      </div>
      </>
	    )
	};


		return (
	  <>
	  <ChartCard />
	  <CardModal 
      title={"Aged Trial Balances"} 
      subTitle={""} 
      highcharts={Highcharts} 
      options={options} 
      show={show} 
      handleClose={handleClose} 
      zoom={true} 
      increaseChartNo={increaseChartNo}
      decreaseChartNo={decreaseChartNo} 
      currentNo={currentNo}
    />
	  </>
	);

};

export default AgedTrialBalances;
