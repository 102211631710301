import API from './../index';

class OrganizationNote {

  static update(data, id) {
    return API.patch(`/api/v1/organization_notes/${id}.json`, data).catch(
      error => { return error; }
    );
  }

  static get(id) {
    return API.get(`/api/v1/organization_notes/${id}.json`).catch(
      error => { return error; }
    );
  }
  static getTotalSubscriptionAmount() {
    return API.get(`/api/v1/get_total_subscription_amount.json`).catch(
      error => { return error; }
    );
  }
}

export default OrganizationNote;
