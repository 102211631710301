import { useState } from "react";
import { Button, Col, Table } from "react-bootstrap";
import { MultiSelect  } from 'primereact/multiselect';
import { FaCalendarAlt } from "react-icons/fa";
import { GrPowerReset } from "react-icons/gr";
import Form from "react-bootstrap/Form";
import DataTable from "./table";

import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";                                         

const SumByProviderClinic = () => {

  const [opt, setOpt] = useState("")

  const [optProvider, setOptProvider] = useState("")

  const handleFieldChange = (e) => {
    setOpt(e.target.value)
  }
  const handleProviderFieldChange = (e) => {
    setOptProvider(e.target.value)
  }

  const providers = [
    {
      name: "ABRAHAM, FRED D"
    },
    {
      name: "Provider 2"
    },
    {
      name: "Provider 3"
    },
    {
      name: "Provider 4"
    },
    {
      name: "Provider 5"
    },
    {
      name: "Provider 6"
    },
    {
      name: "Provider 7"
    },
  ]

  return (
    <>
      <div className="container px-0">
        <div className="card mb-3">
          <div className="card-body d-flex justify-content-between align-items-center gap-2">
            <h5 className="m-0 w500">Filters</h5>
            <div className="d-flex gap-2 align-items-center" >
            <MultiSelect value={optProvider} onChange={(e) => setOptProvider(e.value)}  options={providers} optionLabel="name" 
    filter placeholder="Select Providers" display="chip"
 maxSelectedLabels={1337} className="multi-selection__filter" />
            <Form>
              <Form.Select 
                size="sm" 
                onChange={(e) => handleFieldChange(e)} 
                value={opt}
              >
                <option value={"All FYs"} key={"View-All"}>
                    All FYs
                </option>
                <option value={"FY2017"} key={"2017"}>
                  FY2017
                </option>
                <option value={"FY2018"} key={"2018"}>
                  FY2018
                </option>
                <option value={"FY2019"} key={"2019"}>
                  FY2019
                </option>
                <option value={"FY2020"} key={"2020"}>
                  FY2020
                </option>
                <option value={"FY2021"} key={"2021"}>
                  FY2021
                </option>
                <option value={"FY2022"} key={"2022"}>
                  FY2022
                </option>
                <option value={"FY2023"} key={"2023"}>
                  FY2023
                </option>
              </Form.Select>
            </Form>
            <Button variant="transparent" className="filter__reset_button">
            <GrPowerReset />
            </Button>
            </div>
          </div>
        </div>
      <>
        <div className="row">
          <Col lg={12} xl={12} className="chart-col">
            <DataTable
            title={"Summary By Clinic & Provider"}
             />            
          </Col>
        </div>
      </>
      </div>
    </>
  );
};

export default SumByProviderClinic;