import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isEmpty } from "lodash/fp";
import { SlQuestion } from "react-icons/sl";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const DChart = ({ type, total, typeData, showInLegendEnable }) => {
  let showInLegend = true;
  let showDataSeparately = false;
  if (showInLegendEnable != null) {
    showInLegend = showInLegendEnable;
    showDataSeparately = !showInLegendEnable
  }

  const options = {
     credits: {
      enabled: false
  },

  chart: {
    type: "pie",
      height: "70%"
    },

    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: "%",
      },
    },

    title: {
      text: showDataSeparately ? "" : Math.round(total / 1000) + "K",
    },

    subtitle: {
      text: showDataSeparately ? "  " : "",
    },

    plotOptions: {
      pie: {
        shadow: false,
        showInLegend: showInLegend,
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:10px">Total</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0"></td>' +
        '<td style="padding:0"><b>{point.a:.1f} {point.y:.1f}%</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },

    series: [
      {
        name: "Total",
        colorByPoint: true,
        innerSize: "50%",
        dataLabels: {
          formatter: function () {
            return this.y > 1
            ? this.point.name + "<br/>" + "<b>" + this.point.x + "</b> " + this.y + "%"
              : null;
          },
        },
        data: typeData,
      },
    ],
  };
  return (
    <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>ATB AMT by Provider</h5>
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
              <Popover
                id={"popover-positioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                  marginTop: "0",
                  transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                  Description of what this chart means and definitions of any
                  unfamililar or technical terms
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
        </div>
        <div className={`card-body ${showDataSeparately && "d-flex justify-content-between chart__card-body"} distribution-chart`}>
        { showDataSeparately && (<div></div>) }
        <div className="distribution-chart__container">
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
          { showDataSeparately && (
          <div className="chartdata">
            <h6 className="chartdata__title">Clinic</h6>
            <ul className="chartdata__ul">
              {typeData.map((data) => <li key={data.name} style={{ color: `${data.color}` }}>{data.name}</li>)}
            </ul>
          </div>
          )
        }
        </div>
      </div>
    </>
  );
};

export default DChart;