import { useState } from 'react';
import { Card, Dropdown, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { recentPurchaseTableData } from 'data/dashboard/ecom';
import { isEmpty, getOr } from "lodash/fp";
import { MdDashboard } from "react-icons/md";
import { MdPerson } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { RiCalendarFill } from 'react-icons/ri';
import { MdCode } from "react-icons/md";

import CardDropdown from 'components/common/CardDropdown';
import OrganizationTableWrapper from './OrganizationTableWrapper';
import OrganizationTableHeader from './OrganizationTableHeader';
import OrganizationTableMain from './OrganizationTableMain';
import OrganizationTableFooter from './OrganizationTableFooter';
import Defender from 'helpers/defender';
import Organization from "./../../../../api/organizations";

const OrganizationTable = ({ organizations, removeOrganization, updateStatus, statusList }) => {

	const navigate = useNavigate();

	const viewDashboard = (id, name, organization) => {
		let user = Defender.currentUser()
		user["organization_id"] = id
		user["organization_name"] = name
		user["organization"] = organization
		Defender.setCurrentUser(user)

		Organization.getFirstOrganizationDashboard(id).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				localStorage.removeItem('dashboardId')
				localStorage.removeItem('dashboardName')
				localStorage.removeItem('dashboardCsvUploaded')
				localStorage.removeItem('dashboardStartDate')
				localStorage.removeItem('dashboardEndDate')
				localStorage.removeItem('dashboardSubPage')
				localStorage.removeItem('option')
				localStorage.removeItem('dashboardStatic')
				localStorage.setItem('dashboardId', getOr({}, "data.dashboard.id", response))
				localStorage.setItem('dashboardName', getOr({}, "data.dashboard.name", response))
				localStorage.setItem('dashboardCsvUploaded', getOr({}, "data.dashboard.csv_uploaded_format", response))
				localStorage.setItem('dashboardStartDate', getOr({}, "data.dashboard.starting_date_format", response))
				localStorage.setItem('dashboardEndDate', getOr({}, "data.dashboard.ending_date_format", response))
				setTimeout(() => {
					window.location.href = "/dash"
				}, 1000);
			}
		})
	}
	const [data] = useState(recentPurchaseTableData);

	const [modalShow, setModalShow] = useState(false);

	const [RemoveOrganizationData, setRemoveOrganizationData] = useState({})

	const RemoveModal = ({ removeOrganization, RemoveOrganizationData }) => {
		return (
			<>
				<Modal
					show={modalShow}
					onHide={() => setModalShow(false)}
					size="md"
					aria-labelledby="remove-modal"
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>Delete Organization</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>Are you sure you want to delete organization <strong>{RemoveOrganizationData['name']}</strong>.</div>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => {
							setModalShow(false)
							removeOrganization(RemoveOrganizationData['id'], RemoveOrganizationData['e'])
						}
						} variant="btn-danger" className="btn-danger">Delete</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	const columns = [
		{
			accessor: 'customer',
			Header: 'Organizations',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'fw-semi-bold'
			},
			Cell: rowData => {
				const { name } = rowData.row.original;
				const { id } = rowData.row.original;
				let statusIndex = statusList.findIndex(x => x["id"] === id)
				return <>
					<div className="org-table__name" onClick={(e) => viewDashboard(id, name, rowData.row.original, e)}>{name}</div>
					<div className="org-table__meta org-table__meta--1"><MdCode className='org-code__icon' /> {getOr(0, 'csv_code', rowData.row.original)}<span></span><MdDashboard /> {getOr(0, 'row.original.dashboards_count', rowData)}<span></span><MdPerson /> {getOr(0, 'row.original.users_count', rowData)}<span></span><RiCalendarFill /> {getOr(0, 'created_at', rowData.row.original)}<span></span>

					{Defender.currentUser()._r.includes("admin") ? getOr(false, "status", statusList[statusIndex]) ? <div className="badge badge-soft-success"  onClick={(e) => updateStatus(getOr(0, 'id', rowData.row.original), false, e)}>Active</div> :	<div className="badge badge-soft-danger"   onClick={(e) => updateStatus(getOr(0, 'id', rowData.row.original), true, e)}>Inactive</div> : null}</div>
				</>;
			}
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end py-2'
			},
			Cell: rowData => {
				const { id } = rowData.row.original;
				const { name } = rowData.row.original;
				const organization = rowData.row.original
				return (
					<CardDropdown iconClassName="fs--1" drop="start">
						<div className="py-2">
							<Dropdown.Item onClick={(e) => viewDashboard(id, name, organization, e)} >View</Dropdown.Item>
							<Dropdown.Item href={`/organization/${id}`}>Edit</Dropdown.Item>
							{Defender.currentUser()._r.includes("admin") ?
								<Dropdown.Item onClick={(e) => {
									setRemoveOrganizationData({ id, e, name, organization })
									setModalShow(true)
								}} className="text-danger">Delete</Dropdown.Item>
								: null}
						</div>
					</CardDropdown>
				);
			}
		}
	];
	console.log(organizations)
	return (
		<OrganizationTableWrapper
			columns={columns}
			data={organizations}
			selection
			sortable
			pagination
			perPage={7}
			rowCount={organizations.length}
		>
			<Card>
				<Card.Header>
					<OrganizationTableHeader table data={organizations} />
					<RemoveModal removeOrganization={removeOrganization} RemoveOrganizationData={RemoveOrganizationData} />
				</Card.Header>
				<Card.Body className="p-0">
					<OrganizationTableMain
						table
						headerClassName="org-table__header text-900 text-nowrap align-middle"
						rowClassName="btn-reveal-trigger text-nowrap align-middle"
						tableProps={{
							size: 'sm',
							className: 'fs--1 mb-0 overflow-hidden'
						}}
					/>
				</Card.Body>
				<Card.Footer>
					<OrganizationTableFooter rowCount={organizations.length} table rowInfo navButtons />
				</Card.Footer>
			</Card>
		</OrganizationTableWrapper>
	);
};

export default OrganizationTable;
