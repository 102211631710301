import React, { useState, useEffect } from 'react';
import NavbarAdmin from './NavbarAdmin';
import OrganizationTable from './organization-table/OrganizationTable';
import StatisticsCards from './widgets/StatisticsCards';
import Organization from "./../../../api/organizations";
import OrganizationNote from "./../../../api/organization_notes";
import User from "./../../../api/users";
import SignOut from "./../../../api/auth/sign_out";
import { isEmpty, getOr } from "lodash/fp";
import Defender from 'helpers/defender';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const Site = ({ }) => {
	const navigate = useNavigate();
	const [organizations, setOrganizations] = useState([]);
	const [statusList, setStatusList] = useState([]);
	const [usersCount, setUsersCount] = useState(0);
	const [totalSubscriptionAmount, setTotalSubscriptionAmount] = useState(0);


	const getOrganizations = () => {
		Organization.getAll().then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				let statusListArray = []
				setOrganizations(response.data)
				for(let i = 0; i < response.data.length; i++){
					let obj = {}
					obj["id"] = response.data[i]["id"]
					obj["status"] = response.data[i]["status"]
					statusListArray.push(obj)
				}
				setStatusList(statusListArray)
			}
		})
	}

	const getUsersCount = () => {
		User.getUsersCount().then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				setUsersCount(response.data.users_count)
			}
		})
	}

	const getTotalSubscriptionAmount = () => {
		OrganizationNote.getTotalSubscriptionAmount().then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				setTotalSubscriptionAmount(response.data.subscription_amount)
			}
		})
	}

	useEffect(() => {
		if (!isEmpty(Defender.currentUser()) && (getOr([], "_r", Defender.currentUser()).includes("admin") || getOr([], "_r", Defender.currentUser()).includes("sub_admin"))){
			getOrganizations()
			getUsersCount()
			getTotalSubscriptionAmount()
		}
		else{
			SignOut.destroy().then((response) => {
				if (
					(getOr("", "status", response) === 200 ||
						getOr("", "status", response) === 201) &&
					isEmpty(getOr({}, "data.errors", response))
				) {
          localStorage.removeItem('dashboardName')
          localStorage.removeItem('dashboardId')
          localStorage.removeItem('dashboardSubPage')
          localStorage.removeItem('dashboardCsvUploaded')
          localStorage.removeItem('dashboardStartDate')
          localStorage.removeItem('dashboardEndDate')
					localStorage.removeItem('dashboardStatic')
					localStorage.removeItem('option')
					Defender.logout();
					toast.error(`Signed out`, {
						theme: 'colored'
					});
					navigate("/");
				}
			})
		}
	}, []);

  const removeOrganization = (id, e) => {
    e.preventDefault();
    Organization.remove(id).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
				toast.success(`Organization removed`, {
					theme: 'colored'
				});
				getOrganizations()
				getUsersCount()
				getTotalSubscriptionAmount()
      }
    })
  }

  const updateStatus = (id, status, e) => {
    e.preventDefault();
		let data = {}
		data["status"] = status
    Organization.update({organization: data}, id).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
				const newStatusList = [...statusList];
				let statusIndex =  newStatusList.findIndex(x => x["id"] === id)
				newStatusList[statusIndex]["status"] = newStatusList[statusIndex]["status"] === true ? false : true
				setStatusList(newStatusList);
				Organization.get(id).then((res) => {
					if (
						(getOr("", "status", res) === 200 ||
							getOr("", "status", res) === 204) &&
						isEmpty(getOr({}, "data.errors", res))
					) {
						toast.success(`Organization status updated`, {
							theme: 'colored'
						});
					}
				})
      }
    })
  }
	return (
		<>
			<NavbarAdmin />
			<div className="content">
				<section className="py-3">
					<div className="container">
						<div className="widgets">
							<StatisticsCards organizationsCount={organizations.length} usersCount={usersCount} totalSubscriptionAmount={totalSubscriptionAmount} />
						</div>
						<div className="org-table">
							<OrganizationTable organizations={organizations} removeOrganization={removeOrganization} updateStatus={updateStatus} statusList={statusList}/>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default Site;