import { createContext, useState } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);

export const EmailContext = createContext({ emails: [] });

export const ProductContext = createContext({ products: [] });

export const FeedContext = createContext({ feeds: [] });

export const AuthWizardContext = createContext({ user: {} });

export const ChatContext = createContext();

export const KanbanContext = createContext({
  KanbanColumns: [],
  kanbanTasks: []
});

export const verticalNavContext = createContext({
  mainRoute: 'Executive Summary',
  subRoute: false
});

export const DashContext = createContext();

export const DashProvider = ({ children }) => {
  const [filters, setFilters] = useState([]);

  const Updatefilters = (newState) => {
    setFilters(newState);
  };

  return (
    <DashContext.Provider value={{ filters, Updatefilters }}>
      {children}
    </DashContext.Provider>
  );
};

export default AppContext;
