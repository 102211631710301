import API from './../index';

class Dashboard {
  static create(data) {
    return API.post('/api/v1/dashboards.json', data).catch(
      error => { return error; }
    );
  }

  static update(data, id) {
    return API.patch(`/api/v1/dashboards/${id}.json`, data).catch(
      error => { return error; }
    );
  }

  static getAll(id) {
    return API.get(`/api/v1/get_organization_dashboards/${id}.json`).catch(
      error => { return error; }
    );
  }

  static get(id) {
    return API.get(`/api/v1/dashboards/${id}.json`).catch(
      error => { return error; }
    );
  }
  static remove(dashboard_id) {
    return API.delete(`/api/v1/dashboards/${dashboard_id}.json`);
  }

  static bulkOperate(data) {
    return API.post('/api/v1/bulk_operate_dashboards.json', data).catch(
      error => { return error; }
    );
  }
  static getData(id, option, pageLoad) {
    let data = {}
    data["option"] = option
    data["id"] = id
    data["page_load"] = pageLoad
    return API.post(`/api/v1/get_data.json`, data).catch(
      error => { return error; }
    );
  }
  static getChartsByDashboardTitle() {
    let data = {}
    data["id"] = 0
    return API.post(`/api/v1/get_charts_by_dashboard_title.json`, data).catch(
      error => { return error; }
    );
  }
  static updateChartInfo(data) {
    return API.post(`/api/v1/update_chart_info.json`, data).catch(
      error => { return error; }
    );
  }

  static getChartsInfo(title) {
    let data = {}
    data["title"] = title
    return API.post(`/api/v1/get_charts_info.json`, data).catch(
      error => { return error; }
    );
  }
  static getChartsInfoBySlugAndTitle(title, slug) {
    let data = {}
    data["title"] = title
    data["slug"] = slug
    return API.post(`/api/v1/get_charts_info_by_slug_and_title.json`, data).catch(
      error => { return error; }
    );
  }

  static getPatientRegistrationData(id) {
    let data = {}
    data["id"] = id
    return API.post(`/api/v1/get_patient_registration_data.json`, data).catch(
      error => { return error; }
    );
  }

  static getThirdPartyEligibilityData(id) {
    let data = {}
    data["id"] = id
    return API.post(`/api/v1/get_third_party_eligibility_data.json`, data).catch(
      error => { return error; }
    );
  }

  static getPurchaseReferredCareData(id, option, pageLoad) {
    let data = {}
    data["option"] = option
    data["id"] = id
    data["page_load"] = pageLoad
    return API.post(`/api/v1/get_purchase_referred_care_data.json`, data).catch(
      error => { return error; }
    );
  }
  static getAverageByType(id, option, pageLoad) {
    let data = {}
    data["option"] = option
    data["id"] = id
    data["page_load"] = pageLoad
    return API.post(`/api/v1/get_average_by_type_data.json`, data).catch(
      error => { return error; }
    );
  }

  static getAverageByLocation(id, option, pageLoad) {
    let data = {}
    data["option"] = option
    data["id"] = id
    data["page_load"] = pageLoad
    return API.post(`/api/v1/get_average_by_location_data.json`, data).catch(
      error => { return error; }
    );
  }

  static getAverageByServiceCategory(id, option, pageLoad) {
    let data = {}
    data["option"] = option
    data["id"] = id
    data["page_load"] = pageLoad
    return API.post(`/api/v1/get_average_by_service_category_data.json`, data).catch(
      error => { return error; }
    );
  }

  static getAverageByClinic(id, option, pageLoad) {
    let data = {}
    data["option"] = option
    data["id"] = id
    data["page_load"] = pageLoad
    return API.post(`/api/v1/get_average_by_clinic_data.json`, data).catch(
      error => { return error; }
    );
  }
  static getAverageByProviderType(id, option, pageLoad) {
    let data = {}
    data["option"] = option
    data["id"] = id
    data["page_load"] = pageLoad
    return API.post(`/api/v1/get_average_by_provider_type_data.json`, data).catch(
      error => { return error; }
    );
  }
  static getAverageByDiagnosis(id, option, pageLoad) {
    let data = {}
    data["option"] = option
    data["id"] = id
    data["page_load"] = pageLoad
    return API.post(`/api/v1/get_average_by_diagnosis_data.json`, data).catch(
      error => { return error; }
    );
  }

  static getInjuriesTotal(id) {
    let data = {}
    data["id"] = id
    return API.post(`/api/v1/get_injuries_total_data.json`, data).catch(
      error => { return error; }
    );
  }
  static getInjuriesType(id, option, pageLoad) {
    let data = {}
    data["option"] = option
    data["id"] = id
    data["page_load"] = pageLoad
    return API.post(`/api/v1/get_injuries_types_data.json`, data).catch(
      error => { return error; }
    );
  }
  static getDentalAppointmentsType(id, option, pageLoad) {
    let data = {}
    data["option"] = option
    data["id"] = id
    data["page_load"] = pageLoad
    return API.post(`/api/v1/get_dental_appointment_types_data.json`, data).catch(
      error => { return error; }
    );
  }
  static getDentalsTotal(id) {
    let data = {}
    data["id"] = id
    return API.post(`/api/v1/get_dental_totals_data.json`, data).catch(
      error => { return error; }
    );
  }
  static getPharmacyTotals(id) {
    let data = {}
    data["id"] = id
    return API.post(`/api/v1/get_pharmacy_totals_data.json`, data).catch(
      error => { return error; }
    );
  }

  static getYearsComparison(id) {
    let data = {}
    data["id"] = id
    return API.post(`/api/v1/get_years_comparison_data.json`, data).catch(
      error => { return error; }
    );
  }

  static getYearsComparisonByOption(id, option) {
    let data = {}
    data["id"] = id
    data["option"] = option
    return API.post(`/api/v1/get_years_comparison_data_by_option.json`, data).catch(
      error => { return error; }
    );
  }

  static getAtbSummaryByPayerAndInsurerAndVisitTypeAndVisitLocation(id) {
    let data = {}
    data["id"] = id
    return API.post(`/api/v1/get_atb_summary_by_payer_and_insurer_and_visit_type_and_visit_location.json`, data).catch(
      error => { return error; }
    );
  }

  static getAtbSummaryByInsurer(id) {
    let data = {}
    data["id"] = id
    return API.post(`/api/v1/get_atb_summary_by_insurer.json`, data).catch(
      error => { return error; }
    );
  }
}

export default Dashboard;
