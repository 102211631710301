import React, { useState, useEffect } from 'react';
import NavbarAdmin from './NavbarAdmin';
import Dropzone from './Dropzone';

const DataUpload = ({ }) => {
	return (
		<>
			<NavbarAdmin />
			<div className="content">
				<section className="py-3">
					<div className="container container--800">
						<h1 className="admin-title my-3">Data Upload</h1>
						<p>Drag and drop the .XLSX files into the dropzone area below. You can also click the dropzone area and select the files.</p>
						<Dropzone/>
						<p className="mt-4"><b>*WARNING:</b> Check to make sure the three character unique identifier for the .XLSX matches the dashboard because uploading the .CVS(s) will automatically overwrite any matching file and replace it with the new one. This is permanent and cannot be undone!</p>
					</div>
				</section>
			</div>
		</>
	);
};

export default DataUpload;