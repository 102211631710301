import API from '../../index';

class SignOut {
  static destroy() {
    return API.get("/api/v1/sign_out.json").catch( error => {
      return error.response.data
    });
  }
}

export default SignOut
