/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";
import { isEmpty, getOr } from "lodash/fp";
import { HiOutlineArrowsExpand, HiZoomOut } from "react-icons/hi";
import CardModal from "components/site/admin/dash/Modals/CardModal";
Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const Chart = ({ title, fiscalYears, data, description, colorHex, min, differenceArray, stacking, totalData, stackingColor, increaseChartNo, decreaseChartNo, cancelLooping, chartNo, looping, currentNo  }) => {
  let dataArray = []
  let filterArray = []
  let diffArray = []
  if (!isEmpty(data)) {
    for (let i = 0; i < fiscalYears.length; i++) {
      if (isEmpty(stacking)) {
        let obj = {}
        if (data[i] >= 1000) {
          obj["a"] = Math.round((data[i] / 1000) * 10) / 10 + "K"
        }
        else {
          obj["a"] = data[i]
        }
        obj["y"] = Math.round(data[i])
        obj["color"] = colorHex
        obj["name"] = fiscalYears[i]
        if (differenceArray[i] > 0) {
          obj["diff"] = "+" + Math.round(differenceArray[i]).toLocaleString()
        }
        else if (differenceArray[i] < 0) {
          obj["diff"] = "" + Math.round(differenceArray[i]).toLocaleString()
        }
        dataArray.push(obj)
      }
      else {
        filterArray.push(Math.round(data[i]))
        let difference = totalData[i] - data[i]
        diffArray.push(Math.round(difference))
      }
    }
  }

  const chartRef = useRef();

  const handleResetZoom = () => {
    const chart = chartRef.current.chart;
    chart.zoom();
  };

  const options = {
    credits: {
      enabled: false
    },
    title: {
      text: "",
    },
    chart: {
      type: "column",
      zoomType: "y",
      resetZoomButton: {
        position: {
          x: -3000,
          y: 0,
        },
      },
    },
    xAxis: {
      categories: fiscalYears,
      crosshair: true,
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
      min: min,
      title: {
        text: "",
      },
      labels: {
        format: '{value:,.0f}'
      }
    },
    tooltip: {
      headerFormat:
        `<span style="font-size:10px">${title}</span><table>`,
      pointFormat:
        '<tr><td style="color:{series.color};padding:0"></td>' +
        '<td style="padding:0"><b>{point.y}</b><br/>{point.diff}</td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.y > 1000000) {
              return Highcharts.numberFormat(this.y / 1000000, 1) + "M"
            } else if (this.y > 1000) {
              return Highcharts.numberFormat(this.y / 1000, 1) + "K";
            } else {
              return this.y
            }
          }
        },
      },
    },
    series: [
      {
        name: `${title}`,
        colorByPoint: true,
        data: dataArray,
        color: isEmpty(stacking) ? colorHex : "#ccc",
      },
    ],
  };

  const stackingOptions = {
   
    credits : {
      enabled: false,
    },
    
    chart: {
      type: 'column',
      zoomType: 'y',
      resetZoomButton: {
        position: {
          x: -3000,
          y: 0
        }
      }
    },

    tooltip: {
      headerFormat: 'Fiscal Year: <b>{point.x}</b><br/>',
      pointFormat: '{series.name}: <b>{point.y}</b><br/>'
    },

    plotOptions: {
      areaspline: {
        fillOpacity: 0.1
      },
      series: {
        stacking: 'normal',
        animation: false,
        color: stackingColor,
        showInLegend: false,
        dataLabels: {
          verticalAlign: 'top',
          enabled: true,
          formatter: function () {
            let total = this.stackTotal
            if (total > 1000000) {
              return Highcharts.numberFormat(total / 1000000, 1) + "M"
            } else if (total > 1000) {
              return Highcharts.numberFormat(total / 1000, 1) + "K";
            } else {
              return total
            }
          }
        },
      }
    },


    title: {
      text: ''
    },

    xAxis: {
      categories: fiscalYears
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      }
    },

    series: [{
      name: 'Others',
      data: diffArray,
      color: isEmpty(stacking) ? colorHex : "#ccc",
    }, {
      name: 'Highlighted',
      data: filterArray,
    }]
  };


	useEffect(() => {
    let x = currentNo
		if (chartNo === x && looping){
			setShow(true)
		}
		if (chartNo !== x){
			setShow(false)
		}
	  }, [chartNo]);

	const [show, setShow] = useState(false);

	const handleClose = () => {
    setShow(false);
    cancelLooping()
  }

  const ChartCard = () => {

    return (
      <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <div className="d-flex gap-2">
              <div>
                <HiZoomOut onClick={() => handleResetZoom()} className="card-header__question-mark" />
              </div>
              <div>
                <HiOutlineArrowsExpand onClick={() => setShow(true)} className="card-header__question-mark card-header__expand" />
              </div>
              <OverlayTrigger
                trigger="hover"
                placement="bottom-end"
                overlay={
                  <Popover
                    id={"popover-positioned-" + "bottom-end"}
                    arrow={false}
                    arrowProps={false}
                    style={{
                      marginTop: "0",
                      transition: "all 0.2s ease-in-out, transform 0s;",
                    }}
                  >
                    <Popover.Body>
                      {description}
                    </Popover.Body>
                  </Popover>
                }
              >
                <div>
                  <SlQuestion className="card-header__question-mark" />
                </div>
              </OverlayTrigger>
            </div>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={isEmpty(stacking) ? options : stackingOptions} ref={chartRef}/>
        </div>
      </div>
      </>
    )
  }

  return (
    <>
    <ChartCard />
    <CardModal 
      title={title} 
      subTitle={""} 
      highcharts={Highcharts} 
      options={options} 
      show={show} 
      handleClose={handleClose} 
      zoom={true} 
      increaseChartNo={increaseChartNo}
      decreaseChartNo={decreaseChartNo}
      currentNo={currentNo}
      />
    </>
  );
};

export default Chart;
