import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isEmpty } from "lodash/fp";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const Chart = ({
  type,
  data,
}) => {
  let colors = ['#2A8B8C', '#bc6541', '#e1a95b', '#6A6E71', '#bc6541' ]
  let atbAgeGroups = []
  let xAxis = []
  if (!isEmpty(data)) {
    xAxis = data[0][1].sort((a, b) => parseInt(a["min_age"]) - parseInt(b["min_age"])).map(a => a["atb_age_group"])
    for (let i = 0; i < data.length; i++) {
      let obj = {}
      obj["name"] = data[i][0]
      obj["color"] = colors[i]
      obj["data"] = data[i][1].sort((a, b) => parseInt(a["min_age"]) - parseInt(b["min_age"])).map(a => a["atb_amount"])
      atbAgeGroups.push(obj)
    }
  }

  const options = {
    chart: {
      type: "line",
      zoomType: "x",
      resetZoomButton: {
        position: {
          x: 0,
          y: 0,
        },
      },
    },


    credits: {
      enabled: false
    },

    title: {
      text: "",
    },

    xAxis: {
      categories: xAxis,
    },

    yAxis: {
      title: {
        text: "",
      },
    },

    series: atbAgeGroups,
  };
  return (
    <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>ATB AMT By ATB Age Group and {type}</h5>
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
              <Popover
                id={"popover-positioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                  marginTop: "0",
                  transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                  Description of what this chart means and definitions of any
                  unfamililar or technical terms
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </>
  );
};

export default Chart;