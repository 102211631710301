import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isEmpty } from "lodash/fp";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});
const Chart = ({
    categories,
    data,
    type
}) => {
  const options = {
    chart: {
      type: "bar",
      zoomType: "y",
      resetZoomButton: {
        position: {
          x: 0,
          y: 0,
        },
      },
    },

  
  credits: {
        enabled: false
    },

    title: {
      text: "",
    },

    xAxis: {
      categories: categories,
    },

    yAxis: {
      title: {
        text: "",
      },
    },
    series: data,

  };

  return (
    <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>Summary Of Accounts By {type}</h5>
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
              <Popover
                id={"popover-positioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                  marginTop: "0",
                  transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                  Description of what this chart means and definitions of any
                  unfamililar or technical terms
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </>
  );
};

export default Chart;
