import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/sp-images/email.png';

const ConfirmMailContent = ({ email, layout, titleTag: TitleTag }) => (
  <>
    <img
      className="d-block mx-auto mb-4"
      src={envelope}
      alt="sent"
      width={100}
    />
    <TitleTag>Please check your email!</TitleTag>
    <p>
      An email has been sent to <strong>{email}</strong>. Please click on the
      included link to reset your password.
    </p>
    <p>
      *If you don't see the email in your inbox, check your spam/junk folder and make sure that "info@bernieyazzieconsulting.com" is added to your safe sender's list.
    </p>
    <Button
      as={Link}
      color="primary "
      size="-rg"
      className="mt-3"
      to={`/login`}
    >
      Return to login
    </Button>
  </>
);

ConfirmMailContent.propTypes = {
  email: PropTypes.string.isRequired,
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

ConfirmMailContent.defaultProps = { layout: 'simple', titleTag: 'h4' };

export default ConfirmMailContent;
