import { React, useEffect, useState } from "react";
import { Button, Form, InputGroup, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";
import { SlQuestion } from "react-icons/sl";

import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";

const ParentRow = ({ data }) => {
  const [showChildren, setShowChildren] = useState(false);

  const handleRowChange = (e) => {
    e.target.parentElement.parentElement.parentElement.classList.toggle("summary-accounts__table__row__active")
  }
  return (
    <>
      <tr>
        <td className="px-0 pe-7 d-flex  align-items-center">
        <Form.Check className="px-2 table__checkbox" onChange={(e) => handleRowChange(e)}/>
          <Button
            variant="transparent"
            className="summary_accounts__table__colapse_button"
            onClick={() => setShowChildren(!showChildren)}
          >
            {showChildren ? <FiMinusSquare /> : <FiPlusSquare />}
          </Button>
          {data.clinic}
        </td>
        <td>{data.count}</td>
        <td>{data.billedamt}</td>
        <td>{data.gtbilled}</td>
        <td>{data.aveofexport}</td>
        <td>{data.tsrpay}</td>
        <td>{data.tsradjamt}</td>
        <td>{data.atbamt}</td>
        <td>{data.aveofatbage}</td>
        <td>{data.tsrppay}</td>
        <td>{data.paid}</td>
        <td>{data.adj}</td>
        <td>{data.atb}</td>
      </tr>
      {showChildren &&
        data.child.map((childRow) => (
          <ChildRow key={childRow.id} data={childRow} />
        ))}
    </>
  );
};

const ChildRow = ({ data }) => {
  const [showChildren, setShowChildren] = useState(false);
 
  const handleRowChange = (e) => {
    e.target.parentElement.parentElement.parentElement.classList.toggle("summary-accounts__table__row__active")
  }

  return (
    <>
      <tr> 
        <td className="d-flex  align-items-center">
        <Form.Check className="position-absolute table__checkbox" onChange={(e) => handleRowChange(e)}/>
        <div className="ps-5 d-flex align-items-center fw-bold">
          {data.title}
          </div>
        </td>
        <td>{data.count}</td>
        <td>{data.billedamt}</td>
        <td>{data.gtbilled}</td>
        <td>{data.aveofexport}</td>
        <td>{data.tsrpay}</td>
        <td>{data.tsradjamt}</td>
        <td>{data.atbamt}</td>
        <td>{data.aveofatbage}</td>
        <td>{data.tsrppay}</td>
        <td>{data.paid}</td>
        <td>{data.adj}</td>
        <td>{data.atb}</td>
      </tr>
    </>
  );
};


const DataTable = ({ title }) => {
  const [collapseData, setCollapseData] = useState({});

  const [tableRowData, setTableRowData] = useState([
    {
      id: 1,
      clinic: "Clinic",
      child: [
        {
          title: "Claim Count",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Charges",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Revenue",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Adjustments",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Aged Trial Balance",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
      ],
      count: "1,800",
      billedamt: "1,232,343",
      gtbilled: "84.23",
      aveofexport: "1,232,343",
      tsrpay: "84.23",
      tsradjamt: "1,232,343",
      atbamt: "84.23",
      aveofatbage: "1,232,343",
      tsrppay: "84.23",
      paid: "1,232,343",
      adj: "84.23",
      atb: "84.23",
    },
    {
      id: 1,
      clinic: "Clinic",
      child: [
        {
          title: "Claim Count",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Charges",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Revenue",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Adjustments",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Aged Trial Balance",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
      ],
      count: "1,800",
      billedamt: "1,232,343",
      gtbilled: "84.23",
      aveofexport: "1,232,343",
      tsrpay: "84.23",
      tsradjamt: "1,232,343",
      atbamt: "84.23",
      aveofatbage: "1,232,343",
      tsrppay: "84.23",
      paid: "1,232,343",
      adj: "84.23",
      atb: "84.23",
    },
    {
      id: 1,
      clinic: "Clinic",
      child: [
        {
          title: "Claim Count",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Charges",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Revenue",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Adjustments",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Aged Trial Balance",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
      ],
      count: "1,800",
      billedamt: "1,232,343",
      gtbilled: "84.23",
      aveofexport: "1,232,343",
      tsrpay: "84.23",
      tsradjamt: "1,232,343",
      atbamt: "84.23",
      aveofatbage: "1,232,343",
      tsrppay: "84.23",
      paid: "1,232,343",
      adj: "84.23",
      atb: "84.23",
    },
    {
      id: 1,
      clinic: "Clinic",
      child: [
        {
          title: "Claim Count",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Charges",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Revenue",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Adjustments",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Aged Trial Balance",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
      ],
      count: "1,800",
      billedamt: "1,232,343",
      gtbilled: "84.23",
      aveofexport: "1,232,343",
      tsrpay: "84.23",
      tsradjamt: "1,232,343",
      atbamt: "84.23",
      aveofatbage: "1,232,343",
      tsrppay: "84.23",
      paid: "1,232,343",
      adj: "84.23",
      atb: "84.23",
    },
    {
      id: 1,
      clinic: "Clinic",
      child: [
        {
          title: "Claim Count",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Charges",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Revenue",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Adjustments",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Aged Trial Balance",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
      ],
      count: "1,800",
      billedamt: "1,232,343",
      gtbilled: "84.23",
      aveofexport: "1,232,343",
      tsrpay: "84.23",
      tsradjamt: "1,232,343",
      atbamt: "84.23",
      aveofatbage: "1,232,343",
      tsrppay: "84.23",
      paid: "1,232,343",
      adj: "84.23",
      atb: "84.23",
    },
    {
      id: 1,
      clinic: "Clinic",
      child: [
        {
          title: "Claim Count",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Charges",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Revenue",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Adjustments",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Aged Trial Balance",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
      ],
      count: "1,800",
      billedamt: "1,232,343",
      gtbilled: "84.23",
      aveofexport: "1,232,343",
      tsrpay: "84.23",
      tsradjamt: "1,232,343",
      atbamt: "84.23",
      aveofatbage: "1,232,343",
      tsrppay: "84.23",
      paid: "1,232,343",
      adj: "84.23",
      atb: "84.23",
    },
        {
      id: 1,
      clinic: "Clinic",
      child: [
        {
          title: "Claim Count",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Charges",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Revenue",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Adjustments",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Aged Trial Balance",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
      ],
      count: "1,800",
      billedamt: "1,232,343",
      gtbilled: "84.23",
      aveofexport: "1,232,343",
      tsrpay: "84.23",
      tsradjamt: "1,232,343",
      atbamt: "84.23",
      aveofatbage: "1,232,343",
      tsrppay: "84.23",
      paid: "1,232,343",
      adj: "84.23",
      atb: "84.23",
    },
    {
      id: 1,
      clinic: "Clinic",
      child: [
        {
          title: "Claim Count",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Charges",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Revenue",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Adjustments",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Aged Trial Balance",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
      ],
      count: "1,800",
      billedamt: "1,232,343",
      gtbilled: "84.23",
      aveofexport: "1,232,343",
      tsrpay: "84.23",
      tsradjamt: "1,232,343",
      atbamt: "84.23",
      aveofatbage: "1,232,343",
      tsrppay: "84.23",
      paid: "1,232,343",
      adj: "84.23",
      atb: "84.23",
    },
    {
      id: 1,
      clinic: "Clinic",
      child: [
        {
          title: "Claim Count",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Charges",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Revenue",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Adjustments",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Aged Trial Balance",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
      ],
      count: "1,800",
      billedamt: "1,232,343",
      gtbilled: "84.23",
      aveofexport: "1,232,343",
      tsrpay: "84.23",
      tsradjamt: "1,232,343",
      atbamt: "84.23",
      aveofatbage: "1,232,343",
      tsrppay: "84.23",
      paid: "1,232,343",
      adj: "84.23",
      atb: "84.23",
    },
    {
      id: 1,
      clinic: "Clinic",
      child: [
        {
          title: "Claim Count",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Charges",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Revenue",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Adjustments",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Aged Trial Balance",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
      ],
      count: "1,800",
      billedamt: "1,232,343",
      gtbilled: "84.23",
      aveofexport: "1,232,343",
      tsrpay: "84.23",
      tsradjamt: "1,232,343",
      atbamt: "84.23",
      aveofatbage: "1,232,343",
      tsrppay: "84.23",
      paid: "1,232,343",
      adj: "84.23",
      atb: "84.23",
    },
    {
      id: 1,
      clinic: "Clinic",
      child: [
        {
          title: "Claim Count",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Charges",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Revenue",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Adjustments",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Aged Trial Balance",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
      ],
      count: "1,800",
      billedamt: "1,232,343",
      gtbilled: "84.23",
      aveofexport: "1,232,343",
      tsrpay: "84.23",
      tsradjamt: "1,232,343",
      atbamt: "84.23",
      aveofatbage: "1,232,343",
      tsrppay: "84.23",
      paid: "1,232,343",
      adj: "84.23",
      atb: "84.23",
    },
    {
      id: 1,
      clinic: "Clinic",
      child: [
        {
          title: "Claim Count",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Charges",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Revenue",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Adjustments",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
        {
          title: "Aged Trial Balance",
          count: "1,800",
          billedamt: "1,232,343",
          gtbilled: "84.23",
          aveofexport: "1,232,343",
          tsrpay: "84.23",
          tsradjamt: "1,232,343",
          atbamt: "84.23",
          aveofatbage: "1,232,343",
          tsrppay: "84.23",
          paid: "1,232,343",
          adj: "84.23",
          atb: "84.23",
        },
      ],
      count: "1,800",
      billedamt: "1,232,343",
      gtbilled: "84.23",
      aveofexport: "1,232,343",
      tsrpay: "84.23",
      tsradjamt: "1,232,343",
      atbamt: "84.23",
      aveofatbage: "1,232,343",
      tsrppay: "84.23",
      paid: "1,232,343",
      adj: "84.23",
      atb: "84.23",
    },
  ]);

  return (
    <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
              <Popover
                id={"popover-positioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                  marginTop: "0",
                  transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                  Description of what this chart means and definitions of any
                  unfamililar or technical terms
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
        </div>
        <div className="card-body">
          <Table responsive bordered className="summary-accounts__data-table">
            <thead className="summary-accounts__table__head">
              <tr>
                <th className="d-flex gap-2 align-items-center"> <Form.Check className="px-1 position-absolute table__checkbox"/> <span className="ps-4">Clinic</span></th>
                <th>JUL 2021</th>
                <th>AUG 2021</th>
                <th>SEP 2021</th>
                <th>OCT 2021</th>
                <th>JUL 2022</th>
                <th>AUG 2022</th>
                <th>SEP 2022</th>
                <th>OCT 2022</th>
                <th>JUL 2023</th>
                <th>AUG 2023</th>
                <th>SEP 2023</th>
                <th>OCT 2023</th>
              </tr>
            </thead>
            <tbody className="summary-accounts__table__body">
              {tableRowData.map((parentRow) => (
                <ParentRow key={parentRow.id} data={parentRow} />
              ))}
              <tr className="summary-accounts__table__row">
                <td className="summary-accounts__table__row-title">
                  Claim Count
                </td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
              </tr>
              <tr className="fw-bold">
                <td className="summary-accounts__table__row-title">
                  Charges
                </td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
              </tr>
              <tr className="fw-bold">
                <td className="summary-accounts__table__row-title">
                  Revenue
                </td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
              </tr>
              <tr className="fw-bold">
                <td className="summary-accounts__table__row-title">
                  Adjustments
                </td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
              </tr>
              <tr className="fw-bold">
                <td className="summary-accounts__table__row-title">
                  Aged Trial Balance
                </td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
                <td>1,800</td>
                <td>1,232,343</td>
                <td>84.23</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default DataTable;
