import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, Route, Routes } from 'react-router-dom';
import AppContext from 'context/Context';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Pages
import Site from 'components/site';
import SummaryNavbarTop from './SummaryNavbarTop';
import SummayNavbarVertical from './SummayNavbarVertical';

const SummaryLayout = ({ parent }) => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className='container-fluid'>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <SummayNavbarVertical parent={parent} />
      )}
      {/* <ProductProvider> */}
      <div className={classNames('content', { 'pb-0': isKanban })}>
        <SummaryNavbarTop parent={parent} />
        {/* ------ Main Routes ------*/}
        <Outlet />
      </div>
    </div>
  );
};

SummaryLayout.propTypes = { parent: PropTypes.string };

  
export default SummaryLayout;
