import API from './../index';

class Activity {

  static get(body) {
    return API.post(`/api/v1/get_activity_logs.json`, body).catch(
      error => { return error; }
    );
  }
  static getTags() {
    return API.get(`/api/v1/get_tags.json`).catch(
      error => { return error; }
    );
  }
  static removeTag(body) {
    return API.post(`/api/v1/remove_tag.json`, body).catch(
      error => { return error; }
    );
  }
  static getSuggestions(body) {
    return API.post(`/api/v1/get_suggestions.json`, body).catch(
      error => { return error; }
    );
  }
}

export default Activity;
