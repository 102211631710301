import React, { useState, useEffect } from "react";
import NavbarStandard from "./front/NavbarStandard";
import Footer from "./front/Footer";
import Button from "react-bootstrap/button";
import Modal from "react-bootstrap/Modal";

// Images
import bg3 from "assets/img/sp-images/home/bg3.png";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
} from "react-icons/ai";

const Team = ({}) => {
  const [modalShow, setModalShow] = useState(false);
  const [activeMember, setActiveMember] = useState(0)

  const teamMembers = [ 
    {
        name: "Bernie Yazzie",
        overline: "Navajo",
        position: "Chief Executive Officer",
        bio: `
        My name is Bernie B. Yazzie, and I am the Founder and Chief Executive Officer of Bernie Yazzie Consulting. I am Navajo and originally from Gallup, New Mexico. I currently reside in the Navajo Nation in Steamboat, Arizona.\nMy healthcare career began in 1995, at the Gallup Indian Medical Center. Later, I moved on to the Navajo Area Office-IHS as the Business Office Coordinator. I then became the Business Manager, and subsequently, the Director of Revenue Cycle Management at Tuba City Regional Healthcare Corporation. After Tuba City, I moved on to become the interim Revenue Cycle Director for Fort Defiance Indian Health Board, Inc. to be closer to home. Finally, I joined McManis and Monsalve Associates, based in Manassas, Virginia, as a Senior Associate consultant where I provided revenue cycle consultation to different Indian Health Service and Tribally-Operated facilities.\nI founded BYC LLC in 2018 with the goal of creating a people-centric dynamic and progressive healthcare consulting team, and support services, and to train and educate the “new wave” of new healthcare employees entering Revenue Cycle Management. My goal since my company’s inception is to continually provide strategic consulting services and support our tribal healthcare clients to maintain independence, self-determination, and self-sufficiency in their healthcare facilities.`
      },
      {
        name: "Amy Small",
        overline: "Navajo",
        position: "Interim Chief Operating Officer",
        bio: `
        My name is Amy Small and I am currently the Interim Chief Operating Officer for Bernie Yazzie Consulting. I am Navajo and originally from Steamboat, Arizona. Clan wise, I am Tsi’naajinii (Black Streaked Wood People) born for Táchii'nii (Red Running into the Water People).\nI hold a Bachelor’s Degree from Columbia University. I originally started my career in Public Health for a federally funded Urban Indian Organization bringing awareness of public health initiatives to Native Americans who lived in urban areas. It was during my public health career I learned how to manage multiple teams and projects effectively for local and national projects with tangible metrics of success.\nI first joined BYC as the Director of Communications and then transitioned into the interim Chief Operating Officer role, which I have held for the past 1.5 years. In this position I oversee the operations of BYC, ensuring smooth functioning internally with our employees and externally with our clients. I enjoy working with our clients and seeing their success translate into new healthcare services offered and overall better patient care.`
      },
      {
        name: "LouGene Jones",
        overline: "Navajo",
        position: "Financial Controller",
        bio: `
        I'm LouGene Jones, the Financial Controller for Bernie Yazzie Consulting. Originally from Houck, Arizona, I now call Flagstaff home. I am Honághááhnii (One-Walks-Around Clan), born for Kinyaa'áanii (The Towering House Clan). My maternal grandfathers are Tábaahí (Water’s Edge Clan), and my paternal grandfathers are Tótsohnii (Big Water Clan).\nI hold Bachelor's Degrees in Mathematics and Accountancy from Northern Arizona University.\nMy role at Bernie Yazzie Consulting encompasses a wide spectrum of financial responsibilities, including managing payroll, accounts receivable, accounts payable, and more. Prior to joining the BYC team, I spent nearly seven years in the hospitality industry, honing my skills in various front-line roles. This background has significantly contributed to my effective communication skills, which I leverage to benefit BYC and our affiliated partners.\nBeyond my professional endeavors, I find joy in attending live music shows at small venues and indulging in my passion for college football. These diverse experiences, both in and out of the financial realm, enrich my perspective and contribute to my holistic approach to the dynamic field of financial control.`
      },
      {
        name: "Nicholas Yazzie",
        overline: "Navajo",
        position: "Business Office Manager",
        bio: `
        My name is Nicholas Yazzie. I’m originally from and grew up in Steamboat, Arizona. I currently reside in Flagstaff, AZ. Clan wise, I am Tsi’naajinii born for Honághááhnii. My maternal grandfathers are Tsénjikini, and my paternal grandfathers are Bit’ahnii. I am currently pursuing a bachelor's degree from Northern Arizona in Business Management.\nI’ve been employed with BYC for nearly three years. I originally began as an Executive Assistant to the CEO where I helped assist in planning travel arrangements, assisted in running RPMS reports, and organized business-related documents. While in this position, I learned about the many different aspects of healthcare administration and the revenue cycle process of healthcare facilities. This allowed me to transition into my current position of Business Office Manager. Now, my duties include managing vendor relationships, overseeing office operations, and managing comprehensive benefits. My goal in mind as I continue my career to is expand the quality-of-care patients receive at Indian Health Service and Tribally-Operated healthcare facilities, while simultaneously increasing revenue.`
      },
      {
        name: "Harriet Begay",
        overline: "Navajo",
        position: "Senior Associate",
        bio: `
        My name is Harriet Begay, and I am currently a Senior Associate at BYC for two years now. I reside in the Navajo Nation with my family. Clan wise, I am One-Who-Walks-Around, born for Red Rock Cliff People (Honeycomb), maternal grandfathers are Red House and paternal grandfathers are Near Water.\nIn my current position as a Senior Associate, I provide Clinical Applications and Health Information consulting and technical services to our clients to assist them in areas such as RPMS template building, coding education and training, provider documentation review, etc. I am registered and certified with American Health Information Management Association (AHIMA) and the American Association of Professional Coders (AAPC) as a Registered Health Information Technician (RHIT). This includes being a Certified Coding Specialist (CCS), AHIMA-Approved ICD-10-CM/PCS Trainer, and Certified Professional Coder (CPC).\nPrior experience wise, I have worked in healthcare for over 20 years with the Navajo Nation, Urban, Tribal and Indian Health Service facilities. These facilities ranged from Navajo Nation programs, Tribal Clinics, Federally Qualified Healthcare Centers, and Acute Care Hospitals. The previous positions I’ve held over the years at these facilities were Certified Medical Assistant, Senior Community Health Worker, Coder, Coding Supervisor, Health Information Management Director, and Clinical Application Coordinator.`
      },
      {
        name: "Cheryl Lowery",
        overline: "Navajo",
        position: "Senior Associate",
        bio: "Yá’át’ééh (hello). I am a Senior Associate with Bernie Yazzie Consulting. I currently reside in Fort Defiance, Arizona. My clans are Tséńjíkiní nishłį́; Mą'ii deeshgiizhinii bashishchiin; Tódich’ii’nii dashicheii; Táchii’nii dashinalí. Outside of work, my focus is on family, and attending school. I am currently pursuing my master’s in health administration from the University of Phoenix.\n\nI joined the BYC team in January of 2021, and during these two years I have specialized in assisting facilities and individuals with training needs in Medical Third-Party Billing. I am dedicated to offering Medical Third-Party Billing services and trainings.\n\nPrior to BYC, I was the Patient Accounts Manager at the Fort Defiance Indian Hospital Board, Inc. for fifteen years. I am dedicated to supporting the growth of the business and to build professional working relationships with all clients. I am committed to contributing significantly to the overall success of each of our clients. With my background in medical billing, patient registration, and accounts receivable, I do bring a wealth of experience and knowledge to my current position. Ahéhee’ (thank you)."
      },
      {
        name: "Benjamal Small",
        overline: "Navajo",
        position: "Associate",
        bio: "My Name is Benjamal Small and I am currently a Patient Registration Associate with Bernie Yazzie Consulting. I originally began my career at BYC in early 2022 as an Executive Assistant to Mr. Bernie Yazzie, where I oversaw his day-to-day tasks and company-related items. I then began to receive training and graduated from my previous position to my current one of Associate for nearly one and a half years.\n\nIn my current position, I complete and assist clients with verifications of patient insurance and accounts. This includes the verification of Medicaid patients with full coverage/QMB/SLMB/HMK, CHIPS plans, Medicare Patients (Parts A, B, D), Medicaid Advantage, and Private Insurance plans. I also provide consulting, audits, and educational support to ensure our clients staff are confident in completing Patient Registration verifications accurately and in a timely manner. I thoroughly enjoy my position and helping our clients and their staff."
      },
      {
        name: "Vanessa Lalio",
        overline: "Zuni",
        position: "Associate",
        bio: "Keshi, my name is Vanessa Lalio and I am a member of the Zuni Tribe from Zuni, NM. My clans are Bit’chi’kwe (Crow) and Child of Tobacco Ana’kwe Awan Cha’le (Child of Tobacco). I am currently employed as an Associate with Bernie Yazzie Consulting as of April 2022, where I focus on providing Medical and Dental Coding consulting, technical support, and education and training to our clients.\n\nMy educational background consists of an Associate Degree in Medical Coding and Billing, as well as an Associate Degree in Health Information Technology. I have been a certified coder (CPC) with the American Association of Professional Coders (AAPC) since 2019 and have accumulated 8 years of experience in various areas of outpatient/ancillary medical coding. My specialization lies within Dental/Dentrix, OB/GYN, and infusions/injection coding.\n\nBefore joining Bernie Yazzie Consulting, LLC, I held a certified coder position at Gallup Indian Medical Center (GIMC), where I also served as an acting supervisor for the Outpatient Coding department. While at GIMC, I also worked as an MSA on the inpatient floor with the opportunity to be detailed in assisting with case management and as a Medical Records Technician in the file room. Additionally, I had the opportunity to work as a Billing Administrator in the private sector in Grants, NM for 3 years. All these vast experiences have helped me learn to work with different departments and tailor my assistance to their needs, which I also do with my work at BYC."
      },
      {
        name: "Renetta Yellowhair-Francisco",
        overline: "Navajo",
        position: "Managing Associate",
        bio: `
        My name is Renetta Yellowhair-Francisco, and I am the Managing Associate for Bernie Yazzie Consulting. I am from Pine Springs, Arizona, and currently reside in Vanderwagon, New Mexico. I am Todik’ozhi’ (Salt Water), born for Naaneesht’ezhi Tachii’nii (The Charcoal Streaked of the Red Running Into the Water). My maternal grandfathers are Honaghaahnii (One-walks-around), and my paternal grandfathers are Tsenjikini (Honey Combed Rock People).\nI have been working with BYC for 2 years. In my position, I oversee both our Senior Associates and Associates tasks and services that are provided to clients. In addition, when needed I provide consulting and technical services for different areas such as Compliance, Health Information Management, and Clinical Applications Coordination. This includes the training and educating of client staff. I have been a certified coder (CPC) with the American Association of Professional Coders (AAPC) since 2013.\nPrior to becoming a team manager for BYC, I worked for 22 years with the Indian Health Services. A majority of my healthcare experience was gained at Gallup Indian Medical Center (20 years), Santa Fe Indian Hospital (brief 4 months), and Fort Defiance Indian Hospital (2 years) before they converted to a 638 Tribal Facility. This experience is comprised of positions in Patient Registration, Medical Records, Inpatient and Outpatient coding (including Day Surgery and Observation), Billing, Accounts Receivable, Denial Management, Compliance Officer, and Supervisory oversight of Health Information Management (Medical Records Administrator, Deputy Director, and Outpatient Coding Supervisor).`
      },
      {
        name: "Chrysynthia McCabe-Wilson",
        overline: "Navajo",
        position: "Senior Associate",
        bio: "My name is Chrysynthia McCabe-Wilson and I am a Senior Associate with Bernie Yazzie Consulting. I have worked at BYC for a little over two years. In my current position, I provide Accounts Receivables consulting services and technical services, including education and training for clients. I encourage and emphasize teamwork and communication skills to support the changes in the healthcare industry to meet the elements needed for success.\n\nPrior to joining BYC, I have 24 years of experience in healthcare Revenue Cycle Management, and for the last 17 years, I have specialized in Accounts Receivable. I also have an extensive background in Patient Registration, Medical Billing, and Denial Management. Over the years, I have overseen Patient Registration, Medical Billing, and Accounts Receivable departments and have been successful in meeting annual collections. I have developed a thorough process of working with finance to ensure that audit and reconciliation elements are met each month and year. I have developed effective training presentations and workshops to develop the skill sets needed to have a successful Accounts Receivable team within Revenue Cycle Management."
      },
    
]



  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src = "https://links.bernieyazzieconsulting.com/js/form_embed.js";
    script.type = "text/javascript";

    // Append the script to the body
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleKeyDown = (e) => {
    if (e.keyCode === 37 && modalShow == true && activeMember != 0) {
      // Left arrow key
      setActiveMember((prev) => prev - 1);
    } else if (e.keyCode === 39 && modalShow == true && activeMember != teamMembers.length - 1) {
      // Right arrow key
      setActiveMember((prev) => prev + 1);
    }
  };

  useEffect(() => {

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }
  );
  

  return (
    <>
      <NavbarStandard />

      <div className="content content--front">
   
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="xl"
          className="team__modal"
          aria-labelledby="team-modal"
          centered
        >

          <Modal.Body className="team__modal__body">
          <div className="team__member__overline--modal">{teamMembers[activeMember].overline}</div>
          <div className="team__modal__header">
            <h2 className="team__member__modal__title">{ teamMembers[activeMember].name }</h2>
            <div className="team__modal__header__buttons">
              <Button variant="falcon-default" onClick={() => setActiveMember( (prev) => prev - 1 )} disabled={ activeMember == 0 ? true : false }>
                <AiOutlineArrowLeft />
              </Button>
              <Button
                variant="falcon-default"
                onClick={() => setModalShow(false)}
              >
                <AiOutlineClose />
              </Button>
              <Button variant="falcon-default" onClick={() => setActiveMember( (prev) => prev + 1 )} disabled={ activeMember == teamMembers.length - 1 ? true : false }>
                <AiOutlineArrowRight />
              </Button>
            </div>
          </div>
            <div className="team__member__modal__position">
            { teamMembers[activeMember].position }
            </div>
            <p className="team__member__modal__bio">
            {teamMembers[activeMember].bio.split("\n").map((line, index) => (
                <p key={index}>{line}</p>
            ))}
            </p>
            <div className="team__modal__header__buttons team__modal__header__buttons--mobile">
              <Button variant="falcon-default" onClick={() => setActiveMember( (prev) => prev - 1 )} disabled={ activeMember == 0 ? true : false }>
                <AiOutlineArrowLeft />
              </Button>
              <Button
                variant="falcon-default"
                onClick={() => setModalShow(false)}
              >
                <AiOutlineClose />
              </Button>
              <Button variant="falcon-default" onClick={() => setActiveMember( (prev) => prev + 1 )} disabled={ activeMember == teamMembers.length - 1 ? true : false }>
                <AiOutlineArrowRight />
              </Button>
            </div>
          </Modal.Body>
          {/*       <Modal.Footer>
        <Button onClick={() => setModalShow(false)}>Close</Button>
      </Modal.Footer> */}
        </Modal>

        <section className="py-0">
          <div
            className="heading bar3"
            style={{ backgroundImage: `url(${bg3})` }}
          >
            <div className="container">
              <div className="heading__overline">
                Bernie Yazzie Team
              </div>
              <h1 className="page-title">Meet The Team</h1>
              <div className="subtitle">
                The dedicated professionals guiding your journey.
              </div>
            </div>
          </div>

          <div className="container">
          <div className="team__img">
  {/*         <div className="bg-fill">
            <div className="bg-fill__img" style={{ backgroundImage: 'url("https://as1.ftcdn.net/v2/jpg/02/46/95/38/1000_F_246953805_6xJScGhHnaaH3iX27jbWtn7a1QyB1FVy.jpg")' }}></div>
          </div> */}
        </div>
            <div className="team">
            {
                teamMembers.map((member, index) => (
                    <div className="team__member" key={index} onClick={() => {
                        setActiveMember(index)
                        setModalShow(true)
                    }}>
                    <div className="team__member__overline">{member.overline}</div>
                    <h2 className="team__member__title">{member.name}</h2>
                    <div className="team__member__position">
                        {member.position}
                    </div>
                    <div className="team__member__bio">
                        {member.bio}
                    </div>
                    <a href="#" onClick={() => {
                        setActiveMember(index)
                        setModalShow(true)
                    }}>
                        View More
                    </a>
                    </div>
                ))
            }
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default Team;
