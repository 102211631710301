import React from 'react';
import LogoutContent from './LogoutContent';
import bgImg from 'assets/img/sp-images/access/access-logout.jpg';
import AuthSplitLayout from './AuthSplitLayout';

const Logout = () => {
  return (
    <div className="access">
      <AuthSplitLayout bgProps={{ image: bgImg }}>
        <div className="text-center">
          <LogoutContent layout="split" titleTag="h3" />
        </div>
      </AuthSplitLayout>
    </div>
  );
};

export default Logout;
