import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import logoInvoice from 'assets/img/logos/logo-invoice.png';
import IconButton from 'components/common/IconButton';
import SimpleBarReact from 'simplebar-react';
import Logo from 'components/common/Logo';

const Invoice = () => {
  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <Row className="align-items-center text-center mb-5  mt-4">
            <Col sm={6} className="text-sm-start">
            <Logo at="invoice" width={240} id="topInvoiceLogo" />

            </Col>
            <Col className="text-sm-end fs--1 mt-3">
              <div>
                <span className="fw-bold">Invoice </span>
                <span>#0001</span>
              </div>
              <div>
                <span className="fw-bold">PO </span>
                <span>#32434325423</span>
              </div>
              
            </Col>

          </Row>
          <Row className="align-items-center">
              <h5 className="my-2 fw-bold">Bernie Yazzie Consulting</h5>
            <Col lg="6">
              <h6 className="text-500 mt-4">From</h6>
              <p className="fw-regular fs--1">
                John Doe<br/>
                Bernie Yazzie Consulting<br/>
                1234 Main Street<br/>
                Fort Defiance, TX, 564562<br/>
                johndoe@email.com<br/>  
              </p>
            </Col>
            <Col lg="6">
              <h6 className="text-500 mt-4">To</h6>
                <p className="fw-regular fs--1">
                John Doe<br/>
                Bernie Yazzie Consulting<br/>
                1234 Main Street<br/>
                Fort Defiance, TX, 564562<br/>
                johndoe@email.com<br/>
                </p>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col lg="6">
              <h6 className="text-500 mt-4">Issued On</h6>
              <p className="fw-regular fs--1">
               September 3, 2023
              </p>
            </Col>
            <Col lg="6">
              <h6 className="text-500 mt-4">Due Date</h6>
                <p className="fw-regular fs--1">
                  October 3, 2023
                </p>
            </Col>
          </Row>

          <div className="mt-4 fs--1">
            <SimpleBarReact>
              <Table  className="border-bottom">
                <thead className="light">
                  <tr>
                    <th className="text-500 px-0">Items</th>
                    <th className="text-500 text-center">QTY</th>
                    <th className=" text-500 text-center">Price</th>
                    <th className="text-500 text-center">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="align-middle py-3 px-0 pr-4">
                      <div className="mb-0 text-nowrap text-start">
                       Custom Reports
                      </div>
                    </td>
                    <td className="align-middle text-center">2</td>
                    <td className="align-middle text-center">$1,000</td>
                    <td className="align-middle text-center">$10,000</td>
                  </tr>
                  <tr>
                    <td className="align-middle py-3 px-0">
                      <div className="mb-0 text-nowrap text-start">
                         Rate
                      </div>
                    </td>
                    <td className="align-middle text-center">10</td>
                    <td className="align-middle text-center">$5,000</td>
                    <td className="align-middle text-center">$50,000</td>
                  </tr>
                  <tr>
                    <td className="align-middle py-3 px-0 ">
                      <div className="mb-0 text-nowrap text-start">
                       Custom Reports
                      </div>
                    </td>
                    <td className="align-middle text-center">2</td>
                    <td className="align-middle text-center">$1,000</td>
                    <td className="align-middle text-center">$10,000</td>
                  </tr>

                </tbody>
              </Table>
            </SimpleBarReact>
          </div>

          <Row className="justify-content-end">
            <Col xs="auto" lg="12">
              <Table borderless size="lg" className="fs--1 ">
                <tbody>
                  <tr>
                    <th className="text-900 text-start px-0">Subtotal</th>
                    <td className="fw-semi-bold text-end">$60,000</td>
                  </tr>
                  <tr>
                    <th className="text-900 text-start px-0 ">Prorated Amount</th>
                    <td className="fw-semi-bold text-end">$-5,000</td>
                  </tr>
                  <tr className="">
                    <th className="text-900 text-start px-0 ">3,5% Tax</th>
                    <td className="fw-semi-bold text-end">$3,000</td>
                  </tr>
                  <tr className="mt-8">
                    <th className="text-900 text-start pt-4 px-0 pb-4 mt-8">Total Amount</th>
                    <td className="fw-bold text h5 pt-4 text-end">$58,000</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Invoice;
