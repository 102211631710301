import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdToggleOn, MdToggleOff } from "react-icons/md";
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { isEmpty, getOr } from "lodash/fp";
import ActivityLogTableWrapper from './ActivityLogTableWrapper';
import ActivityLogTableMain from './ActivityLogTableMain';
import { recentPurchaseTableData } from 'data/dashboard/ecom';
import ActivityLogTableFooter from './ActivityLogTableFooter';
// Icons	
import Button from 'react-bootstrap/Button';
import { AutoComplete } from "primereact/autocomplete";
import { HiOutlineTrash } from 'react-icons/hi';
import { AiOutlineClose } from 'react-icons/ai';
import { BsCurrencyDollar } from 'react-icons/bs';
import { RiFlagLine } from 'react-icons/ri';
import ModalOrgInvoice from '../../modals/ModalOrgInvoice';

const ActivityLogTable = ({ log, getLogs, setSuggestions, suggestions, getSuggestions }) => {

	const [filterBy, setFilterBy] = useState("All")

	const handleClick = () => {
	}

	const columns = [
		{
			accessor: 'activity',
			Header: 'Activity',
			headerProps: { className: 'activity__tr' },
			cellProps: {
				className: "w-100 text-wrap fw-semi-bold activity__td",
			},
		},
		{
			accessor: 'date',
			Header: 'Date',
			headerProps: { className: 'activity__tr' },
			cellProps: {
				className: 'fw-semi-bold activity__td',
			}
		},
		{
			accessor: 'time',
			Header: 'Time',
			headerProps: { className: 'activity__tr' },
			cellProps: {
				className: 'fw-semi-bold activity__td',
			}
		},
		{
			accessor: 'name',
			Header: 'Name',
			headerProps: { className: 'activity__tr' },
			cellProps: {
				className: 'fw-semi-bold activity__td',
			}
		},
		{
			accessor: 'role',
			Header: 'User Role',
			headerProps: { className: 'activity__tr' },
			cellProps: {
				className: 'fw-semi-bold activity__td',
			},
		},
		{
			accessor: 'ip',
			Header: 'IP',
			headerProps: { className: 'activity__tr' },
			cellProps: {
				className: 'fw-semi-bold activity__td',
			},
		}
	];

	const [tags, setTags] = useState([]);

	const [value, setValue] = useState('');
	const [dateFilter, setDateFilter] = useState('Last twelve');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

	const search = (event) => {
		if (event.query.length >= 2){
			getSuggestions(event.query)
		}
	}

	const searchLogs = () => {
		let body = {} 
		let tempTags = tags
		if (!tempTags.includes(value) && value !== ""){
			tempTags.push(value)
			setTags(tempTags)
		}
		if (isEmpty(tags)){
			body["value"] = value
		}
		else{
			body["value"] = tempTags.toString()
		}
		if (!isEmpty(dateFilter)){
			body["filter"] = dateFilter
			if (!isEmpty(startDate) && !isEmpty(endDate)){
				body["start_date"] = startDate
				body["end_date"] = endDate
			}
		}
		setValue("")
		getLogs(body)
	}

	const handleStartDate = e => {
		setStartDate(e.target.value)
		if (!isEmpty(endDate)){
			let body = {}
			body["value"] = value
			body["filter"] = dateFilter
			body["start_date"] = e.target.value
			body["end_date"] = endDate
			getLogs(body)
		}
	};

	const handleEndDate = e => {
		setEndDate(e.target.value)
		if (!isEmpty(startDate)){
			let body = {}
			body["value"] = value
			body["filter"] = dateFilter
			body["start_date"] = startDate
			body["end_date"] = e.target.value
			getLogs(body)
		}
	};

	const handledateFilter = (e) => {
		setDateFilter(e.target.value)
		setStartDate("")
		setEndDate("")
		if (e.target.value !== "Custom"){
			let body = {}
			body["value"] = value
			body["filter"] = e.target.value
			getLogs(body)
		}
	}

	const addToSearch = (name) => {
		if (!value.includes(name)){
			if (isEmpty(value)){
				setValue(value.concat(name))
			}
			else{
				let temp = " " + name
				setValue(value.concat(temp))
			}
		}
	}

	const removeFromSearch = (name) => {
		if (value.includes(name)){
			let temp = value.replace(name, "")
			setValue(temp)
		}
	}

	const handleSearchValue = (e) => {
		setValue(e.target.value)
		if (e.target.value === ""){
			let body = {}
			body["value"] = e.target.value
			body["filter"] = dateFilter
			body["start_date"] = startDate
			body["end_date"] = endDate
			getLogs(body)
		}
	}

	const handleSearchValueKey = (e) => {
		setValue(e.target.value)
		if (e.target.value === "" && e.key === "Backspace"){
			let body = {}
			body["value"] = e.target.value
			body["filter"] = dateFilter
			body["start_date"] = startDate
			body["end_date"] = endDate
			getLogs(body)
		}
	}

	const removeSearchTag = (v) => {
		let tempTags = tags
		if (tempTags.includes(v)){
			tempTags = tempTags.filter(x => x !== v)
		}
		setTags(tempTags)

		if (value.includes(v)){
			let temp = value
			temp = temp.replace(v, "")
			setValue(temp)
		}
	}


	return (
		<ActivityLogTableWrapper
			columns={columns}
			data={log}
			sortable
			pagination
			perPage={15}
			rowCount={log.length}
		>
			<Card>
				<Card.Header>
					<div className='d-flex justify-content-between align-items-center'>
						<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Activity Log</h5>
					</div>

					<div className='reports p-fluid'>
						<AutoComplete value={value} suggestions={suggestions} completeMethod={search} className="reports__auto-complete" placeholder="Search" onChange={(e) => handleSearchValue(e)} onKeyUp={(e) => handleSearchValueKey(e)} />
						<Button className='reports__cta' onClick={() => searchLogs()}>Search</Button>
					</div>

					<div className="reports__filter">
						<div className='search-results'>
							{tags && tags.map((name, index) => (
								<div className='search-results__result' key={index} style={{cursor: 'pointer'}}>
									<div className='spacer'></div>
									<span onClick={() => addToSearch(name)}>
										{name}
									</span>
									<AiOutlineClose className="icon" onClick={() => removeSearchTag(name)}/>
								</div>
							))}
						</div>
						<div>
							<div>
								<Form.Group className="d-flex gap-2 mt-2">
									<Form.Select aria-label="Last Month" name="lastMonth" className="activity__form" onChange={handledateFilter} value={dateFilter}>
										<option value="Today">Today</option>
										<option value="Last seven days">Last 7 Days</option>
										<option value="Last thirty days">Last 30 Days</option>
										<option value="Last three">Last 3 months</option>
										<option value="Last six">Last 6 months</option>
										<option value="Last twelve">Last 12 months</option>
										<option value="Custom">Custom</option>
									</Form.Select>
									{dateFilter === "Custom" ? (
										<>
											<Form.Control
												type="date"
												name="startDate"
												className="activity__form"
												onChange={handleStartDate} value={startDate}
											/>
											<Form.Control
												type="date"
												name="endDate"
												className="activity__form"
												onChange={handleEndDate} value={endDate}
											/>
										</>
									) : (
										<div></div>
									)}
								</Form.Group>
							</div>
						</div>
					</div>

				</Card.Header>
				<Card.Body className="p-0">
					<ActivityLogTableMain
						table
						headerClassName="org-table__header text-900 text-nowrap align-middle"
						rowClassName="btn-reveal-trigger text-nowrap align-middle"
						tableProps={{
							size: 'sm',
							className: 'fs--1 mb-0 overflow-hidden'
						}}
					/>
				</Card.Body>
				<Card.Footer>
					<ActivityLogTableFooter rowCount={log.length} table rowInfo navButtons />
				</Card.Footer>
			</Card>
		</ActivityLogTableWrapper>
	);
};

export default ActivityLogTable;
