import React, { useState } from 'react';
import { Card, Dropdown, Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { Link } from 'react-router-dom';
import DashboardTableWrapper from './DashboardTableWrapper';
import DashboardTableHeader from './DashboardTableHeader';
import DashboardTableMain from './DashboardTableMain';
import { recentPurchaseTableData } from 'data/dashboard/ecom';
import DashboardTableFooter from './DashboardTableFooter';
import { getOr } from "lodash/fp";
// Icons
import { MdPerson } from "react-icons/md";
import { MdToggleOn, MdToggleOff } from "react-icons/md";
import { MdCode } from "react-icons/md";
import { HiShieldCheck } from "react-icons/hi";
import { isEmpty } from "lodash/fp";

const DashboardTable = ({ dashboards, getDashboards, removeDashboard, updateDashboardStatus, getUsers, dashboardStatusList, title, showNew }) => {
	const [data] = useState(recentPurchaseTableData);
	const [showModal, setShowModal] = useState(false);
	const [selectedDashboard, setSelectedDashboard] = useState({});

	const openModal = (dashboard, e) => {
		e.preventDefault()
		setSelectedDashboard(dashboard)
		setShowModal(true)
	}

	const closeModal = (e) => {
		e.preventDefault()
		setSelectedDashboard({})
		setShowModal(false)
	}

	const [modalShow, setModalShow] = useState(false);

	const [RemoveDashboardData, setRemoveDashboardData] = useState({})

	const RemoveModal = ({ removeDashboard, RemoveDashboardData }) => {
		return (
			<>
				<Modal
					show={modalShow}
					onHide={() => setModalShow(false)}
					size="md"
					aria-labelledby="remove-modal"
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>Delete Dashboard</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>Are you sure you want to delete dashboard <strong>{RemoveDashboardData['name']}</strong> from your organization.</div>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => {
							setModalShow(false)
							removeDashboard(RemoveDashboardData['id'], RemoveDashboardData['e'])
						}
						} variant="btn-danger" className="btn-danger">Delete</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	const columns = [
		{
			accessor: 'dashboardr',
			Header: 'Dashboard',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'fw-semi-bold'
			},
			Cell: rowData => {
				const { name } = rowData.row.original;
				const { id } = rowData.row.original;
				return <div >
					<div className="org-table__name" onClick={(e) => openModal(rowData.row.original, e)}>{name}</div>
					{!showNew ?
						<>
							<div className="org-table__meta org-table__meta--1"><MdCode /> {getOr(0, 'csv_code', rowData.row.original)}<span></span></div>
							{!isEmpty(getOr('', 'csv_name', rowData.row.original)) ?
								<div className="settings-list__item">
									<label>CSV Status</label>
									<div className="settings-list__content"><HiShieldCheck className="csv-confirm" />{getOr('', 'csv_name', rowData.row.original)}<br /><span className="form-text-small">{getOr('', 'csv_uploaded_format', rowData.row.original)}, {getOr('', 'csv_size', rowData.row.original)} {name === "Operations Summary" ? "KB" : "MB"}</span><br />{getOr('', 'csv_upload_status', rowData.row.original)}</div>
								</div>
								: <div className="settings-list__item">
									<label>CSV Status</label>
									<div className="settings-list__content">None</div>
								</div>}
						</>
						: null}
				</div>
			}
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end py-2'
			},
			Cell: rowData => {
				const { id } = rowData.row.original;
				const { name } = rowData.row.original;
				return (
					<CardDropdown iconClassName="fs--1" drop="start">
						<div className="py-2">
							<Dropdown.Item onClick={(e) => openModal(rowData.row.original, e)}>Edit</Dropdown.Item>
							{showNew ?
								<>
									<Dropdown.Item onClick={(e) => {
										setRemoveDashboardData({ id, e, name })
										setModalShow(true)
									}} className="text-danger">Delete</Dropdown.Item>
								</>
								: null}
						</div>
					</CardDropdown>
				);
			}
		}
	];
	return (
		<DashboardTableWrapper
			columns={columns}
			data={dashboards}
			selection
			sortable
			pagination
			perPage={7}
			rowCount={dashboards.length}
			showNew={showNew}
		>
			<Card className="mb-3">
				<Card.Header>
					<DashboardTableHeader table getDashboards={getDashboards} data={dashboards} getUsers={getUsers} selectedDashboard={selectedDashboard} showModal={showModal} closeModal={closeModal} title={title} showNew={showNew} />
					<RemoveModal removeDashboard={removeDashboard} RemoveDashboardData={RemoveDashboardData} />
				</Card.Header>
				<Card.Body className="p-0">
					<DashboardTableMain
						table
						headerClassName="org-table__header text-900 text-nowrap align-middle"
						rowClassName="btn-reveal-trigger text-nowrap align-middle"
						tableProps={{
							size: 'sm',
							className: 'fs--1 mb-0 overflow-hidden'
						}}
					/>
				</Card.Body>
				<Card.Footer>
					<DashboardTableFooter rowCount={dashboards.length} table rowInfo navButtons />
				</Card.Footer>
			</Card>
		</DashboardTableWrapper>
	);
};

export default DashboardTable;
