import API from './../index';

class Organization {
  static create(data) {
    return API.post('/api/v1/organizations.json', data).catch(
      error => { return error; }
    );
  }

  static update(data, id) {
    return API.patch(`/api/v1/organizations/${id}.json`, data).catch(
      error => { return error; }
    );
  }

  static get(id) {
    return API.get(`/api/v1/organizations/${id}.json`).catch(
      error => { return error; }
    );
  }

  static getAll() {
    return API.get(`/api/v1/organizations.json`).catch(
      error => { return error; }
    );
  }
  static remove(organization_id) {
    return API.delete(`/api/v1/organizations/${organization_id}.json`);
  }

  static bulkOperate(data) {
    return API.post('/api/v1/bulk_operate_organizations.json', data).catch(
      error => { return error; }
    );
  }
  static uploadCSVS(data) {
    return API.post('/api/v1/upload_csvs.json', data).catch(
      error => { return error; }
    );
  }

  static sendContactEmail(data) {
    return API.post('/api/v1/send_contact_email.json', data).catch(
      error => { return error; }
    );
  }

  static getOrganizationDashboards(id) {
    return API.get(`/api/v1/get_organization_dashboards/${id}.json`).catch(
      error => { return error; }
    );
  }
  
  static getFirstOrganizationDashboard(id) {
    return API.get(`/api/v1/get_first_organization_dashboard/${id}.json`).catch(
      error => { return error; }
    );
  }
}

export default Organization;
