import { useState } from "react";
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Chart from "../chart";

const SumByMedicalCenter = () => {

  const [opt, setOpt] = useState("")

  const handleFieldChange = (e) => {
    setOpt(e.target.value)
    console.log(e.target.value)
  }

  let data = [
    {
        name: 'Charges',
        data: [
            {
                y: 100,
                color: "#2A8B8C",
              },
        ],
        color: '#2A8B8C'
    },
    {
        name: 'Revenue',
        data: [
            {
                y: 80
            },
        ],
        color: '#bc6541'
    },
    {
        name: 'Adjustments',
        data: [
            {
                y: 60
            },
        ],
        color: '#e1a95b'
    },
    {
        name: 'Aged Trial Balance',
        data: [
            {
                y: 40
            },
        ],
        color: '#90a9ac'
    },
]

let categories = ["Rockey Boy Health Center"]

  return (
    <>
      <div className="container px-0">
        <div className="card mb-3">
          <div className="card-body d-flex justify-content-between align-items-center gap-2">
            <h5 className="m-0 w500">Filters</h5>
            <Form>
              <Form.Select 
                size="sm" 
                onChange={(e) => handleFieldChange(e)} 
                value={opt}
              >
                <option value={"View All"} key={"View-All"}>
                  View All
                </option>
                <option value={"FY2017"} key={"2017"}>
                  FY2017
                </option>
                <option value={"FY2018"} key={"2018"}>
                  FY2018
                </option>
                <option value={"FY2019"} key={"2019"}>
                  FY2019
                </option>
                <option value={"FY2020"} key={"2020"}>
                  FY2020
                </option>
                <option value={"FY2021"} key={"2021"}>
                  FY2021
                </option>
                <option value={"FY2022"} key={"2022"}>
                  FY2022
                </option>
                <option value={"FY2023"} key={"2023"}>
                  FY2023
                </option>
              </Form.Select>
            </Form>
          </div>
        </div>
      <>
        <div className="row">
          <Col lg={12} xl={12} className="chart-col">
            <Chart 
            data={data}
            categories={categories}
            type={"Medical Center"}
            />
          </Col>
        </div>
      </>
      </div>
    </>
  );
};

export default SumByMedicalCenter;
