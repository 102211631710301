import { DashContext } from "context/Context";
import React, { useContext, useEffect } from "react";
import { Col } from "react-bootstrap";
import InjuriesChart from "./chart";

const InjuriesTotal = () => {

  const {Updatefilters} = useContext(DashContext)

  useEffect(() => {
    Updatefilters([])
  }, [])

  return (
    <>
      <div className="container px-0">
        <div className="row">
          <Col lg={12} xl={12} className="chart-col">
            <InjuriesChart />
          </Col>
        </div>
      </div>
    </>
  );
};

export default InjuriesTotal;
