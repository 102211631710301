import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isEmpty } from "lodash/fp";
import { SlQuestion } from "react-icons/sl";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { colorScheme } from "helpers/colors";
import { HiOutlineArrowsExpand } from "react-icons/hi";
import CardModal from "components/site/admin/dash/Modals/CardModal";


Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const DistributionChart = ({ title, type, total, typeData, showInLegendEnable, description, selectOption, increaseChartNo, decreaseChartNo, cancelLooping, chartNo, looping, currentNo, hideCustomList }) => {
  let showInLegend = true;
  let showDataSeparately = false;
  let selectedChartColor = "eee"
  if (showInLegendEnable != null) {
    showInLegend = showInLegendEnable;
    showDataSeparately = !showInLegendEnable
  }

  function getChartColorByName (name) {
    typeData.map((d,i) => {
      if (d.name == name) {
        selectedChartColor =  colorScheme[i];  
      }
    } )
  }

  const options = {

    credits: {
      enabled: false
    },

    chart: {
      type: "pie",
      height: hideCustomList ? "65%" : showDataSeparately ? "65%" : "",
    },

    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: "%",
      },
    },

    title: {
      text: showDataSeparately ? "" : "",
    },

    subtitle: {
      text: showDataSeparately ? "  " : "",
    },

    plotOptions: {
      pie: {
        shadow: false,
        showInLegend: hideCustomList ? false : showInLegend,
      },
      series: {
        point: {
          events: {
            click: function (event) {
              getChartColorByName (this.name)
              selectOption({
                name: this.name,
                color: selectedChartColor,
              })
            }
          }
        }
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:10px"></span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0"></td>' +
        '<td style="padding:0">{point.name}<br/><b>{point.a} {point.y:.1f}%</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },

    series: [
      {
        name: "Total",
        colorByPoint: true,
        innerSize: "50%",
        dataLabels: {
          formatter: function () {
            return this.y > 1
              ? this.point.name + "<br/>" + "<b>" + this.point.x + "</b> " + this.y + "%"
              : null;
          },
        },
        data: typeData,
      },
    ],
  };

	const [show, setShow] = useState(false);

	const handleClose = () => {
    setShow(false);
    cancelLooping()
  }

	useEffect(() => {
		if (chartNo === 3 && looping){
			setShow(true)
		}
		if (chartNo !== 3){
			setShow(false)
		}
	  }, [chartNo]);


  const ChartCard = () => {

    return (
      <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <div className="d-flex gap-2">
              <div>
              <HiOutlineArrowsExpand onClick={() => setShow(true)} className="card-header__question-mark card-header__expand" />
              </div>
              <OverlayTrigger
                trigger="hover"
                placement="bottom-end"
                overlay={
                  <Popover
                    id={"popover-positioned-" + "bottom-end"}
                    arrow={false}
                    arrowProps={false}
                    style={{
                      marginTop: "0",
                      transition: "all 0.2s ease-in-out, transform 0s;",
                    }}
                  >
                    <Popover.Body>
                      {description}
                    </Popover.Body>
                  </Popover>
                }
              >
                <div>
                  <SlQuestion className="card-header__question-mark" />
                </div>
              </OverlayTrigger>
            </div> 
        </div>
        <div className={`card-body ${showDataSeparately && "d-flex justify-content-between chart__card-body"} distribution-chart`}>
          {showDataSeparately && (<div></div>)}
          <div className={` ${showDataSeparately && "distribution-chart__container"}`}>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
          {showDataSeparately && (
            <div className="chartdata">
              <h6 className="chartdata__title">{type}</h6>
              <ul className="chartdata__ul">
                {typeData.map((data) => <li key={data.name} style={{ color: `${data.color}` }} onClick={(e) => {
                getChartColorByName (data.name)
                selectOption({
                name: data.name,
                color: selectedChartColor,
              })
                } }>{data.name}</li>)}
              </ul>
            </div>
          )
          }
        </div>
      </div>
      </>
    )
  }

  return (
    <>
    <ChartCard />
    <CardModal 
      title={`Distribution of Ambulatory Care Visits By ${type}`} 
      subTitle={""} 
      highcharts={Highcharts} 
      options={options} 
      show={show} 
      handleClose={handleClose}
      increaseChartNo={increaseChartNo}
      decreaseChartNo={decreaseChartNo}
      currentNo={currentNo}
      disChart={true} />
    </>
  );

};

export default DistributionChart;