import React, { useState, useEffect } from 'react';
import NavbarAdmin from './NavbarAdmin';
import PlansTable from './tables/plans-table/PlansTable';

const Plans = ({ }) => {

	const plans = [
		{
			name:"Lorem Ipsum Health Center",
			type: "Standard Monthly",
			fee: "$5,500",
			date: "01/02/2023",
		},
		{
			name:"Dolor Sit Amet Medicals",
			type: "Standard Annual",
			fee: "$60,000",
			date: "01/02/2023",
		},
		{
			name:"Consectetur Adipiscing Elit",
			type: "Premium Monthly",
			fee: "$7,500",
			date: "01/02/2023",
		},
		{
			name:"Sed Do Eiusmod Tempor",
			type: "Premium Annual",
			fee: "$80,000",
			date: "01/02/2023",
		}
	]

	return (
		<>
			<NavbarAdmin />
			<div className="content">
				<section className="py-3">
					<div className="container container-1000">
                        <PlansTable  plans={plans} />
					</div>
				</section>
			</div>
		</>
	);
};

export default Plans;