import API from './../index';

class User {
  static create(data) {
    return API.post(`/api/v1/users.json`, data).catch(
      error => { return error; }
    );
  }

  static fetch(id) {
    return API.get(`/api/v1/users/${id}.json`).catch(
      error => { return error; }
    );
  }
  static update(data) {
    return API.patch(`/api/v1/users/${0}.json`, data).catch(
      error => { return error; }
    );
  }
  static updateUser(data, id) {
    return API.patch(`/api/v1/users/${id}.json`, data).catch(
      error => { return error; }
    );
  }
  static updateImage(data, id) {
    return API.patch(`/api/v1/update_image/${id}.json`, data).catch(
      error => { return error; }
    );
  }
  static removeImage(id) {
    return API.post(`/api/v1/remove_image/${id}.json`).catch(
      error => { return error; }
    );
  }
  static removeUserOrganization() {
    return API.post(`/api/v1/remove_user_organization.json`).catch(
      error => { return error; }
    );
  }

  static updateOrganization(data) {
    return API.post(`/api/v1/update_organization.json`, data).catch(
      error => { return error; }
    );
  }
  static get(user_id) {
    return API.get(`/api/v1/users/${user_id}.json`).catch(
      error => { return error; }
    );
  }

  static getOrganizationUsers(id) {
    return API.get(`/api/v1/get_organization_users/${id}.json`).catch(
      error => { return error; }
    );
  }

  static getAdminUsers() {
    return API.get(`/api/v1/get_admin_users.json`).catch(
      error => { return error; }
    );
  }

  static getAllUsers(type) {
    return API.get(`/api/v1/get_all_users_without_distinction/${type}.json`).catch(
      error => { return error; }
    );
  }

  static getUsersSuggestions(body) {
    return API.post(`/api/v1/get_users_suggestions.json`, body).catch(
      error => { return error; }
    );
  }

  static searchUsers(body) {
    return API.post(`/api/v1/search_users.json`, body).catch(
      error => { return error; }
    );
  }

  static getUsersCount() {
    return API.get(`/api/v1/get_users_count.json`).catch(
      error => { return error; }
    );
  }

  static bulkOperate(data) {
    return API.post('/api/v1/bulk_operate_users.json', data).catch(
      error => { return error; }
    );
  }

  static inviteUser(data) {
    return API.post(`/api/v1/invite_user.json`, data);
  }

  static update_password(data) {
    return API.patch(`/api/v1/users/${0}.json`, data).catch(
      error => { return error; }
    );
  }
  static current() {
    return API.get('/api/v1/current_user/me.json').catch(
      error => { return error }
    );
  }

  static check_email(data) {
    return API.post(`/api/v1/check_email.json`, data).catch( error => {
      return error.response
    });
  }

  static check_user_role(data) {
    return API.post(`/api/v1/check_user_role.json`, data).catch( error => {
      return error.response
    });
  }
  static check_user(data) {
    return API.post(`/api/v1/check_user.json`, data).catch( error => {
      return error.response
    });
  }

  static get_user_email_by_token(data) {
    return API.post(`/api/v1/get_user_email_by_token.json`, data).catch( error => {
      return error.response
    });
  }

  static get_user(data) {
    return API.post(`/api/v1/get_user.json,`, data);
  }

  static get_online_users() {
    return API.get(`/api/v1/get_online_users.json`);
  }

  static remove(user_id) {
    return API.delete(`/api/v1/users/${user_id}.json`);
  }

  static checkSessionTime() {
    return API.get(`/api/v1/check_session_time.json`);
  }

  static invalidateExistingSessions(id) {
    return API.get(`/api/v1/invalidate_existing_sessions/${id}.json`);
  }
  static checkPasswordPawned(body) {
    return API.post(`/api/v1/password_pawned.json`, body);
  }
}

export default User
