import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import EditPassword from "./../../../api/auth/edit_password";
import User from "./../../../api/users";
import { isEmpty, getOr } from "lodash/fp";
import { useNavigate } from "react-router-dom";


const PasswordResetForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [isPwned, setIsPwned] = useState(false);

  const checkPasswordStrength = () => {
    // Define rules for password strength
    const minLength = 15;
    const hasUppercase = /[A-Z]/.test(formData.password);
    const hasLowercase = /[a-z]/.test(formData.password);
    const hasNumber = /\d/.test(formData.password);
    const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(formData.password);

    // Count the number of fulfilled rules
    const fulfilledRulesCount = [hasUppercase, hasLowercase, hasNumber, hasSpecialCharacter].filter(Boolean).length;

    // Assess the overall strength based on fulfilled rules
    if (formData.password.length >= minLength && fulfilledRulesCount === 4) {
      return 'Strong';
    } else if (formData.password.length >= minLength && fulfilledRulesCount >= 2) {
      return 'Moderate';
    } else {
      return 'Weak';
    }
  };

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true)
    if (formData.password === formData.confirmPassword) {
      if (checkPasswordStrength() === 'Strong' || checkPasswordStrength() === 'Moderate') {
        let urlToken = window.location.href.split("?")[1].split("=")[1];
        formData["reset_password_token"] = urlToken;
        User.checkPasswordPawned({ user: formData }).then((res) => {
          if (
            (getOr("", "status", res) === 200 ||
              getOr("", "status", res) === 204) &&
            isEmpty(getOr({}, "data.errors", res))){
              if (getOr({}, "data.success", res)){
            EditPassword.verify({ user: formData }).then((response) => {
              if (
                (getOr("", "status", response) === 200 ||
                  getOr("", "status", response) === 204) &&
                isEmpty(getOr({}, "data.errors", response))
              ) {
                setLoading(false)
                toast.success(`Login with your new password`, {
                  theme: 'colored'
                });
                navigate("/login");
              }
              else {
                setLoading(false)
                toast.error(`Invalid password token`, {
                  theme: 'colored'
                });
              }
            })
          }
          else{
            setLoading(false)
            toast.error(`The password you are trying to set is comprised. Please choose a different password`, {
              theme: 'colored'
            });
          }
          }
        })
      }
      else {
        setLoading(false)
        toast.error(`The password you are trying to set is weak. Please choose a different password`, {
          theme: 'colored'
        });
      }
    }
    else {
      setLoading(false)
      toast.error(`Password and Confirm Password do not match`, {
        theme: 'colored'
      });
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handlePasswordCheck = (result) => {
    console.log('Is password pwned?', result);
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <div className="mb-3">Minimum 15 characters with one number, one capital letter and one special character</div>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>
      {!isEmpty(formData.password) ?
        <div>
          <strong>Password Strength: </strong>
          {checkPasswordStrength()}
        </div>
        : null}
      <Button
        type="submit"
        className="mt-3 btn--rg w-100"
        disabled={loading}
      >
        Set password
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
