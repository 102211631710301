import React, { useState, useEffect } from 'react';
import NavbarStandard from './front/NavbarStandard';
import Footer from './front/Footer';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import Organization from "./../../api/organizations";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from 'react-toastify';

// Images
import bg3 from 'assets/img/sp-images/home/bg3.png';

const Schedule = ({ }) => {

	useEffect(() => {
		// Create a script element
		const script = document.createElement('script');
		script.src = 'https://links.bernieyazzieconsulting.com/js/form_embed.js';
		script.type = 'text/javascript';
	
		// Append the script to the body
		document.body.appendChild(script);
	
		// Clean up the script when the component unmounts
		return () => {
		  document.body.removeChild(script);
		};
	  }, []);

	  
	return (
		<>
			<NavbarStandard />

			<div className="content content--front" style={{ backgroundColor: '#fbfcfd' }}>
				<section className="py-0">
					<div 
						className="heading bar3"
						style={{ backgroundImage:`url(${bg3})` }} >
						<div className="container">
							<div className="heading__overline">TAKE THE FIRST STEP</div>
							<h1 className="page-title">Schedule My Conversation</h1>
							<div className="subtitle">Learn what is possible for your facility in as little as 15 minutes.</div>
						</div>
					</div>
					<div className="container ">
					<iframe
						src="https://links.bernieyazzieconsulting.com/widget/booking/7yExXRE2tUQGtdl0Bpwb"
						style={{ width: '100%', border: 'none', overflow: 'hidden' }}
						scrolling="no"
						id="7yExXRE2tUQGtdl0Bpwb_1716403348212"
						></iframe>
	</div>
				</section>
			</div>

			<Footer />
		</>
	);
};

export default Schedule;