import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Organization from "./../../../../api/organizations";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from 'react-toastify';
import Defender from 'helpers/defender';
import Invoice from 'components/app/e-commerce/Invoice';

function ModalOrgInvoice({ preview, previewHide, print, orgName }) {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [showInvioceConfirm, setShowInvioceConfirm] = useState(false)
  const [organization, setOrganization] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = (str) => {
    if(preview & str !== 'edit') {
      previewHide()
    }
    setShow(false)
  };
  const [formData, setFormData] = useState({
    name: '',
    organizationPhone: '',
    primaryAddress: '',
    secondaryAddress: '',
    city: '',
    state: '',
    zipcode: '',
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userPhone: '',
  });

  useEffect(() => {
    let id = window.location.href.split("/")[4]
    Organization.get(id).then((response) => {
      if (
        (getOr("", "status", response) === 200 ||
          getOr("", "status", response) === 204) &&
        isEmpty(getOr({}, "data.errors", response))
      ) {
        let organization = response.data.organization
        setOrganization(organization)
        setFormData({
          ...formData,
          name: getOr('', 'name', organization),
          website: getOr('', 'website', organization),
          organizationPhone: getOr('', 'phone', organization),
          primaryAddress: getOr('', 'primary_address', organization),
          secondaryAddress: getOr('', 'secondary_address', organization),
          state: getOr('', 'state', organization),
          zipcode: getOr('', 'zipcode', organization),
          city: getOr('', 'city', organization),
          userFirstName: getOr('', 'user_first_name', organization),
          userLastName: getOr('', 'user_last_name', organization),
          userEmail: getOr('', 'user_email', organization),
          userPhone: getOr('', 'user_phone', organization),
        });
      }
    })
  }, []);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const handleSubmit = e => {
    e.preventDefault();
  
    if(print) {
      // Print Functionality should be implemented here
      console.log("Print")
    } else {
      setShowInvioceConfirm(true);
    }

  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const confirmInvoiceSend = () => {
    return (
      <Modal
        show={showInvioceConfirm}
        onHide={() => setShowInvioceConfirm(false)}
        size="md"
        centered
        backdropClassName="custom-modal-backdrop"
      >
      { preview ? (
        <>
        <Modal.Header>
          <Modal.Title>Approve Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
						<div className='fw-medium link-dark'>You are approving the plan for this client and the invoice details. This invoice will be sent automatically on the first of the month, if you wish to send it now, click the checkbox below.</div>
						<Form.Group className="mt-4">
						<Form.Label>Enter your password to confirm</Form.Label>
						<Form.Control
						type="password"
						value={formData.passwordconfirm}
            name="passwordconfirm"
            onChange={handleFieldChange}
						/>
					</Form.Group>
					</Modal.Body>
					<Modal.Footer>
          <div className='d-flex w-100 justify-content-between align-items-center'>
          <div className='d-flex gap-2 align-items-center justify-content-center'>
            <Form.Check
              type="checkbox"
              id="send__invoice__now"
              name="send__invoice__now"
              checked={formData.send__invoice__now}
              onChange={handleFieldChange}
            />
            <Form.Label className='m-0'>Send new invoice now</Form.Label>
          </div>
                  <div className='d-flex gap-2'>
          <Button onClick={() => setShowInvioceConfirm(false)} variant="falcon-default">Cancel</Button> 
						<Button onClick={() => {
            setShowInvioceConfirm(false);
            toast.success(`Invoice has been sent`, {
              theme: 'colored'
            });
            handleClose();
						}
						} variant="primary" className='btn-primary'>Confirm</Button>
            </div>
            </div>
					</Modal.Footer>
          </>
      ) : (
        <>
        <Modal.Header>
          <Modal.Title>Resend invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to resend this invoice to the Invoice Recipients for this organization?</div>
          <div className='mt-2 d-flex gap-2 justify-content-end align-items-center'>
          <Button onClick={() => setShowInvioceConfirm(false)} variant="secondary">Cancel</Button>
          <Button onClick={() => {
            setShowInvioceConfirm(false);
            toast.success(`Invoice has been sent`, {
              theme: 'colored'
            });
            handleClose();
          }}>Confirm</Button>
          </div>
        </Modal.Body>
        </>
      ) }
      </Modal>
    );
  }

  return (
    <>
      {values.map((v, idx) => (
        print ? (
          <div key={idx} className="fw-semi-bold link-dark tile__name" onClick={() => handleShow(v)} >{orgName}</div>
        ) : (
          <Button key={idx} onClick={() => handleShow(v)} variant={`${preview ? "primary" : print ? "transparent" : "secondary"}`} size="md" className={`${preview ? "" : "btn btn-falcon-default"}`} >
          { preview ? "Save"  : "Resend Invoice" }
          {typeof v === 'string' && ` below ${v.split('-')[0]}`}
        </Button>
        )
      ))}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>Invoice Preview</Modal.Title>
          <div className="modal-header__actions">
            <Button variant="falcon-default" onClick={() => handleClose('edit')}>
            { preview ? "Edit" : print ? "Back" : "Cancel" }
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
            { preview ? "Approve" : print ? "Print" :  "Save" }
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row max-800 mx-auto">
            <Invoice />
          </div>
        </Modal.Body>
      </Modal>
      {showInvioceConfirm && confirmInvoiceSend()}
    </>
  );
}

export default ModalOrgInvoice;
