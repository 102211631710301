import React from 'react';
import NavbarStandard from './front/NavbarStandard';
import Footer from './front/Footer';

// Icon
import { AiOutlineBarChart } from "react-icons/ai";
import { GiDiscussion } from "react-icons/gi";
import { GrMoney } from "react-icons/gr";
import { RiHealthBookLine } from "react-icons/ri";
import { FaPeopleArrows } from "react-icons/fa";
import { GrDocumentUser } from "react-icons/gr";
import { TbShieldCheck } from "react-icons/tb";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { MdAppRegistration } from "react-icons/md";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { RiExchangeDollarLine } from "react-icons/ri";
import { MdOutlineSchool, MdOutlineDashboardCustomize } from "react-icons/md";
import { TbChartArrows } from "react-icons/tb";
import { TbReportAnalytics } from "react-icons/tb";
import { ImLab } from "react-icons/im";
import { TbRadioactive } from "react-icons/tb";
import { HiOutlineDatabase } from "react-icons/hi";
import { GiTeacher } from "react-icons/gi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";

// Images
import bg1 from 'assets/img/sp-images/home/bg1.png';

const About = ({}) => {
  return (
	<>
		<NavbarStandard />
		
		<div className="content content--front">
			<section className="py-0">
				<div 
					className="heading bar1"
					style={{ backgroundImage:`url(${bg1})` }} >
					<div className="container">
						<div className="heading__overline">About Solutions</div>
						<h1 className="page-title">Custom Solutions</h1>
						<div className="subtitle">We take an approach that considers your specific needs and goals.</div>
					</div>
				</div>
				<div className="container max-800">
					<h2 className="mb-3">How our Solutions works:</h2>
					<p>Our Solutions is based on the needs of your organization and we offer customized packages designed to deliver the results that your business needs to expeirence its necessary transformation.</p>
					<div className='mt-3 d-flex gap-3'>
					<a href="/contact" className="btn btn-primary btn--3d btn--rg">Get a Quote</a>
{/* 					<a href="https://calendly.com/bernieyazzie/datadx" className="btn btn-secondary btn--3d btn--rg btn--demo">Schedule My DataDx</a>
 */}

					</div>					
					<h2 className="mt-6 mb-3">Our capabilities include:</h2>
					
					<div className="services">
						<div className="services__item">
							<div className="services__img"><AiOutlineBarChart /></div>
							<div className="services__title">Healthcare Data Analytics</div>
						</div>
						<div className="services__item">
							<div className="services__img"><GiDiscussion /></div>
							<div className="services__title">Strategic Consulting</div>
						</div>
						<div className="services__item">
							<div className="services__img"><RiExchangeDollarLine /></div>
							<div className="services__title">Revenue Cycle Management</div>
						</div>
						<div className="services__item">
							<div className="services__img"><RiHealthBookLine /></div>
							<div className="services__title">Health Information Management</div>
						</div>
						<div className="services__item">
							<div className="services__img"><GrMoney /></div>
							<div className="services__title">Denial and Debt Management</div>
						</div>
						<div className="services__item">
							<div className="services__img"><FaPeopleArrows /></div>
							<div className="services__title">Patient Benefits Coordination</div>
						</div>
						<div className="services__item">
							<div className="services__img"><GrDocumentUser /></div>
							<div className="services__title">Clinical Applications Coodination (CAC)</div>
						</div>
						<div className="services__item">
							<div className="services__img"><TbShieldCheck /></div>
							<div className="services__title">Health Center Compliance Programming and Planning</div>
						</div>
						<div className="services__item">
							<div className="services__img"><HiOutlineDocumentReport /></div>
							<div className="services__title">Report Building and Training</div>
						</div>
						<div className="services__item">
							<div className="services__img"><MdAppRegistration /></div>
							<div className="services__title">Patient Registration and Access</div>
						</div>
						<div className="services__item">
							<div className="services__img"><MdOutlineHealthAndSafety /></div>
							<div className="services__title">Medical and Professional Coding</div>
						</div>
						<div className="services__item">
							<div className="services__img"><MdOutlineSchool /></div>
							<div className="services__title">Coding Compliance Education and Training</div>
						</div>
						<div className="services__item">
							<div className="services__img"><TbChartArrows /></div>
							<div className="services__title">Customized Reporting Services</div>
						</div>
						<div className="services__item">
							<div className="services__img"><TbReportAnalytics /></div>
							<div className="services__title">Provider Documentation Auditing and Training</div>
						</div>
						<div className="services__item">
							<div className="services__img"><ImLab /></div>
							<div className="services__title">Laboratory Information Systems</div>
						</div>
						<div className="services__item">
							<div className="services__img"><TbRadioactive /></div>
							<div className="services__title">Radiology</div>
						</div>
						<div className="services__item">
							<div className="services__img"><HiOutlineDatabase /></div>
							<div className="services__title">RPMS Support, Configuration, and Maintenance</div>
						</div>
						<div className="services__item">
							<div className="services__img"><GiTeacher /></div>
							<div className="services__title">Education and Training (on all listed services)</div>
						</div>
						<div className="services__item">
							<div className="services__img"><FaChalkboardTeacher /></div>
							<div className="services__title">Leadership and Management Mentoring</div>
						</div>
						<div className="services__item">
							<div className="services__img"><BsFillPeopleFill /></div>
							<div className="services__title">One-on-One Staff Mentoring</div>
						</div>
						<div className="services__item">
							<div className="services__img"><MdOutlineDashboardCustomize /></div>
							<div className="services__title">Interface Engineering</div>
						</div>
					</div>					

				</div>
			</section>
		</div>
		
		<Footer />
	</>
  );
};

export default About;