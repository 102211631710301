import React from 'react';
import ForgetPasswordForm from './ForgetPasswordForm';
import bgImg from 'assets/img/sp-images/access/access-forgot.jpg';
import AuthSplitLayout from './AuthSplitLayout';
import logo from 'assets/img/spearpoint-logo_1A.png';

const ForgetPassword = () => {
  return (
    <div className="access">
      <AuthSplitLayout
        bgProps={{ image: bgImg, position: '50% 76%', overlay: true }}
      >
        <div className="text-center">
          <div className="access__logo">
            <img className="me-2" src={logo} alt="Logo" />
          </div>
          <h3 className=""> Forgot your password?</h3>
          <p>Enter your email and we'll send you a reset link.</p>
          <ForgetPasswordForm layout="split" />
        </div>
      </AuthSplitLayout>
    </div>
  );
};

export default ForgetPassword;
