/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";
import { isEmpty, getOr } from "lodash/fp";
import { HiOutlineArrowsExpand, HiZoomOut } from "react-icons/hi";
import CardModal from "components/site/admin/dash/Modals/CardModal";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const PharmacyChart = ({ title, fiscalYears, data, description, colorHex, min, differenceArray, increaseChartNo, decreaseChartNo, cancelLooping, chartNo, looping, currentNo  }) => {
  let dataArray = []
  if (!isEmpty(data)) {
    for (let i = 0; i < fiscalYears.length; i++) {
      let obj = {}
      obj["a"] = Math.round(((data[i]) / 1) * 10) / 10
      obj["y"] = Math.round(((data[i]) / 1) * 10) / 10
      obj["color"] = colorHex
      obj["name"] = fiscalYears[i]
      if (differenceArray[i] > 0){
        obj["diff"] = "+" + (Math.round(differenceArray[i]*10)/1000).toLocaleString()
      }
      else if(differenceArray[i] < 0){
        obj["diff"] = "" + (Math.round(differenceArray[i]*10)/1000).toLocaleString()
      }
      dataArray.push(obj)
    }
  }

  const chartRef = useRef();

  const handleResetZoom = () => {
    const chart = chartRef.current.chart;
    chart.zoom();
  };

  const options = {
    credits: {
      enabled: false
    },

    title: {
      text: "",
    },
    chart: {
      type: "column",
      zoomType: "y",
      resetZoomButton: {
        position: {
          x: -3000,
          y: 0,
        },
      },
    },
    xAxis: {
      categories: fiscalYears,
      crosshair: true,
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
      min: min,
      title: {
        useHTML: true,
        text: "",
      },
    },
    tooltip: {
      headerFormat:
        `<span style="font-size:10px">${title}</span><table>`,
      pointFormat:
        '<tr><td style="color:{series.color};padding:0"></td>' +
        '<td style="padding:0"><b>{point.a:,.f}</b><br/>{point.diff}</td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.a:,.f}",
        },
      },
    },
    series: [
      {
        name: title,
        colorByPoint: true,
        data: dataArray
      },
    ],
  };

	useEffect(() => {
    let x = currentNo
		if (chartNo === x && looping){
			setShow(true)
		}
		if (chartNo !== x){
			setShow(false)
		}
	  }, [chartNo]);

	const [show, setShow] = useState(false);

	const handleClose = () => {
    setShow(false);
    cancelLooping()
  }


  const ChartCard = () => {

    return (
      <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <div className="d-flex gap-2">
          <div>
              <HiZoomOut onClick={() => handleResetZoom()} className="card-header__question-mark" />
          </div>
          <HiOutlineArrowsExpand onClick={() => setShow(true)} className="card-header__question-mark card-header__expand" />
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
              <Popover
                id={"popover-pos  itioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                  marginTop: "0",
                  transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                  {description}
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
          </div>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
      </>
    )
  }
  return (
    <>
    <ChartCard />
    <CardModal 
      title={title} 
      subTitle={""} 
      highcharts={Highcharts} 
      options={options} 
      show={show} 
      handleClose={handleClose} 
      zoom={true} 
      increaseChartNo={increaseChartNo}
      decreaseChartNo={decreaseChartNo}
      currentNo={currentNo}
      />
    </>
  );
};

export default PharmacyChart;
