/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";
import { isEmpty, getOr } from "lodash/fp";
import { HiOutlineArrowsExpand, HiZoomOut } from "react-icons/hi";
import CardModal from "components/site/admin/dash/Modals/CardModal";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const Expenditures = ({ title, fiscalYears, data, description, colorHex, min, max, differenceArray, option, increaseChartNo, decreaseChartNo, cancelLooping, chartNo, looping, currentNo }) => {
  function hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;

    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;

    return s;
}

function color(r, g, b) {
    return "#" + hex2(r) + hex2(g) + hex2(b);
}

function shade(col, light) {

    // TODO: Assert that col is good and that -1 < light < 1

    var r = parseInt(col.substr(1, 2), 16);
    var g = parseInt(col.substr(3, 2), 16);
    var b = parseInt(col.substr(5, 2), 16);

    if (light < 0) {
        r = (1 + light) * r;
        g = (1 + light) * g;
        b = (1 + light) * b;
    } else {
        r = (1 - light) * r + light * 255;
        g = (1 - light) * g + light * 255;
        b = (1 - light) * b + light * 255;
    }

    return color(r, g, b);
}
  let dataArray = []
  if (!isEmpty(data)) {
    for (let i = 0; i < fiscalYears.length; i++) {
      let obj = {}
      obj["a"] = Math.round((data[i] / 1000000) * 100) / 100 + "M"
      obj["y"] = Math.round(data[i])
      if (!isEmpty(option)){
        if (option !== fiscalYears[i]){
          obj["color"] = shade(colorHex, 0.731)
        }
        else{
          obj["color"] = colorHex
        }
      }
      else{
        obj["color"] = colorHex
      }
      obj["name"] = fiscalYears[i]
      if (differenceArray[i] > 0){
        obj["diff"] = "+" + differenceArray[i].toLocaleString()
      }
      else if(differenceArray[i] < 0){
        obj["diff"] = "" + differenceArray[i].toLocaleString()
      }
      dataArray.push(obj)
    }
  }

  const chartRef = useRef();

  const handleResetZoom = () => {
    const chart = chartRef.current.chart;
    chart.zoom();
  };


  const options = {
    credits: {
      enabled: false
    },

    title: {
      text: "",
    },
    chart: {
      type: "column",
      zoomType: "y",
      resetZoomButton: {
        position: {
          x: -3000,
          y: 0,
        },
      },
    },
    xAxis: {
      categories: fiscalYears,
      crosshair: true,
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
      max: max,
      min: min,
      title: {
        text: "",
      },
    },
    tooltip: {
      headerFormat:
        `<span style="font-size:10px">${title}</span><table>`,
      pointFormat:
        '<tr><td style="color:{series.color};padding:0"></td>' +
        '<td style="padding:0"><b>{point.y:,.f}</b><br/>{point.diff}</td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.a:,.f}",
        },
      },
    },
    series: [
      {
        name: "PRC Expenditures",
        colorByPoint: true,
        data: dataArray
      },
    ],
  };


  
	useEffect(() => {
    let x = currentNo
		if (chartNo === x && looping){
			setShow(true)
		}
		if (chartNo !== x){
			setShow(false)
		}
	  }, [chartNo]);

	const [show, setShow] = useState(false);

	const handleClose = () => {
    setShow(false);
    cancelLooping()
  }



  const ChartCard = () => {

    return (
      <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <div className="d-flex gap-2">
              <div>
              <HiZoomOut onClick={() => handleResetZoom()} className="card-header__question-mark" />
              </div>
              <div>
              <HiOutlineArrowsExpand onClick={() => setShow(true)} className="card-header__question-mark card-header__expand" />
              </div>
              <OverlayTrigger
                trigger="hover"
                placement="bottom-end"
                overlay={
                  <Popover
                    id={"popover-positioned-" + "bottom-end"}
                    arrow={false}
                    arrowProps={false}
                    style={{
                      marginTop: "0",
                      transition: "all 0.2s ease-in-out, transform 0s;",
                    }}
                  >
                    <Popover.Body>
                      {description}
                    </Popover.Body>
                  </Popover>
                }
              >
                <div>
                  <SlQuestion className="card-header__question-mark" />
                </div>
              </OverlayTrigger>
            </div> 
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
        </div>
      </div>
    </>
    )
  }

  return (
    <>
    <ChartCard />
    <CardModal 
      title={title} 
      subTitle={""} 
      highcharts={Highcharts} 
      options={options} 
      show={show} 
      handleClose={handleClose} 
      zoom={true} 
      increaseChartNo={increaseChartNo}
      decreaseChartNo={decreaseChartNo}
      currentNo={currentNo}
      />
    </>
  );
};

export default Expenditures;
