import { React, useEffect, useState } from "react";
import { Button, Form, InputGroup, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";
import { SlQuestion } from "react-icons/sl";

import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";

const ParentRow = ({ data }) => {
  const [showChildren, setShowChildren] = useState(false);

  const handleRowChange = (e) => {
    e.target.parentElement.parentElement.parentElement.classList.toggle("summary-accounts__table__row__active")
  }
  return (
    <>
      <tr>
        <td className="px-0 pe-7 d-flex  align-items-center">
        <Form.Check className="px-2 table__checkbox" onChange={(e) => handleRowChange(e)}/>
          <Button
            variant="transparent"
            className="summary_accounts__table__colapse_button"
            onClick={() => setShowChildren(!showChildren)}
          >
            {showChildren ? <FiMinusSquare /> : <FiPlusSquare />}
          </Button>
          {data.clinic}
        </td>
        <td>{data.firstRow}</td>
        <td>{data.secondRow}</td>
        <td>{data.thirdRow}</td>
        <td>{data.fourthRow}</td>
        <td>{data.totalRow}</td>

      </tr>
      {showChildren &&
        data.child.map((childRow) => (
          <GGrandchildRow key={childRow.id} data={childRow} />
        ))}
    </>
  );
};

const ChildRow = ({ data }) => {
  const [showChildren, setShowChildren] = useState(false);
 
  const handleRowChange = (e) => {
    e.target.parentElement.parentElement.parentElement.classList.toggle("summary-accounts__table__row__active")
  }

  return (
    <>
      <tr> 
        <td className="d-flex  align-items-center">
        <Form.Check className="position-absolute table__checkbox" onChange={(e) => handleRowChange(e)}/>
        <div className="ps-4">
          <Button
            variant="transparent"
            className="summary_accounts__table__colapse_button"
            onClick={() => setShowChildren(!showChildren)}
          >
          </Button>
          {data.title}
          </div>
        </td>
        <td>{data.firstRow}</td>
        <td>{data.secondRow}</td>
        <td>{data.thirdRow}</td>
        <td>{data.fourthRow}</td>
        <td>{data.totalRow}</td>

      </tr>
    </>
  );
};

const GrandchildRow = ({ data }) => {
  const [showChildren, setShowChildren] = useState(false);
  const handleRowChange = (e) => {
    e.target.parentElement.parentElement.parentElement.classList.toggle("summary-accounts__table__row__active")
  }
  return (
    <>
      <tr>
      <td className="ps-6 overflow-hidden d-flex align-items-center">
      <Form.Check className="position-absolute table__checkbox" onChange={(e) => handleRowChange(e)}/>
        <Button variant="transparent" className="summary_accounts__table__colapse_button" onClick={() => setShowChildren(!showChildren)}>
      {showChildren ? <FiMinusSquare  /> : <FiPlusSquare/> }
      </Button>
          {data.title}
        </td>
        <td>{data.firstRow}</td>
        <td>{data.secondRow}</td>
        <td>{data.thirdRow}</td>
        <td>{data.fourthRow}</td>
        <td>{data.totalRow}</td>

      </tr>
      {showChildren &&
        data.child.map((ggrandchildRow) => (
          <GGrandchildRow key={ggrandchildRow.id} data={ggrandchildRow} />
        ))}
    </>
  );
};

const GGrandchildRow = ({ data }) => {
  const handleRowChange = (e) => {
    e.target.parentElement.parentElement.parentElement.classList.toggle("summary-accounts__table__row__active")
  }
  return (
    <tr>
            <td className="ps-6 d-flex">
            <Form.Check className="position-absolute table__checkbox" onChange={(e) => handleRowChange(e)}/>
            {data.title}
            </td>
            <td>{data.firstRow}</td>
        <td>{data.secondRow}</td>
        <td>{data.thirdRow}</td>
        <td>{data.fourthRow}</td>
        <td>{data.totalRow}</td>
    </tr>
  );
};

const DataTable = ({ title }) => {
  const [collapseData, setCollapseData] = useState({});

  const [tableRowData, setTableRowData] = useState([
    {
      id: 1,
      clinic: "General",
      child: [
        {
            title: "ABRAHAM, FRED D",
            firstRow: '10',
            secondRow: '20',
            thirdRow: '30',
            fourthRow: '40',
            totalRow: '100',
          },
          {
            title: "ABRAHAM, FRED D",
            firstRow: '10',
            secondRow: '20',
            thirdRow: '30',
            fourthRow: '40',
            totalRow: '100',

          },
          {
            title: "ABRAHAM, FRED D",
            firstRow: '10',
            secondRow: '20',
            thirdRow: '30',
            fourthRow: '40',
            totalRow: '100',
          },
      ],
      firstRow: '10',
      secondRow: '20',
      thirdRow: '30',
      fourthRow: '40',
      totalRow: '100',
    },
    {
      id: 2,
      clinic: "General",
      child: [
        {
            title: "ABRAHAM, FRED D",
            firstRow: '10',
            secondRow: '20',
            thirdRow: '30',
            fourthRow: '40',
            totalRow: '100',
          },
          {
            title: "ABRAHAM, FRED D",
            firstRow: '10',
            secondRow: '20',
            thirdRow: '30',
            fourthRow: '40',
            totalRow: '100',

          },
          {
            title: "ABRAHAM, FRED D",
            firstRow: '10',
            secondRow: '20',
            thirdRow: '30',
            fourthRow: '40',
            totalRow: '100',
          },
      ],
      firstRow: '10',
      secondRow: '20',
      thirdRow: '30',
      fourthRow: '40',
      totalRow: '100',
    }, 
    {
        id: 3,
        clinic: "General",
        child: [
          {
              title: "ABRAHAM, FRED D",
              firstRow: '10',
              secondRow: '20',
              thirdRow: '30',
              fourthRow: '40',
              totalRow: '100',
            },
            {
              title: "ABRAHAM, FRED D",
              firstRow: '10',
              secondRow: '20',
              thirdRow: '30',
              fourthRow: '40',
              totalRow: '100',
  
            },
            {
              title: "ABRAHAM, FRED D",
              firstRow: '10',
              secondRow: '20',
              thirdRow: '30',
              fourthRow: '40',
              totalRow: '100',
            },
        ],
        firstRow: '10',
        secondRow: '20',
        thirdRow: '30',
        fourthRow: '40',
        totalRow: '100',
      }, 
      {
        id: 4,
        clinic: "General",
        child: [
          {
              title: "ABRAHAM, FRED D",
              firstRow: '10',
              secondRow: '20',
              thirdRow: '30',
              fourthRow: '40',
              totalRow: '100',
            },
            {
              title: "ABRAHAM, FRED D",
              firstRow: '10',
              secondRow: '20',
              thirdRow: '30',
              fourthRow: '40',
              totalRow: '100',
  
            },
            {
              title: "ABRAHAM, FRED D",
              firstRow: '10',
              secondRow: '20',
              thirdRow: '30',
              fourthRow: '40',
              totalRow: '100',
            },
        ],
        firstRow: '10',
        secondRow: '20',
        thirdRow: '30',
        fourthRow: '40',
        totalRow: '100',
      }, 
      {
        id: 5,
        clinic: "General",
        child: [
          {
              title: "ABRAHAM, FRED D",
              firstRow: '10',
              secondRow: '20',
              thirdRow: '30',
              fourthRow: '40',
              totalRow: '100',
            },
            {
              title: "ABRAHAM, FRED D",
              firstRow: '10',
              secondRow: '20',
              thirdRow: '30',
              fourthRow: '40',
              totalRow: '100',
  
            },
            {
              title: "ABRAHAM, FRED D",
              firstRow: '10',
              secondRow: '20',
              thirdRow: '30',
              fourthRow: '40',
              totalRow: '100',
            },
        ],
        firstRow: '10',
        secondRow: '20',
        thirdRow: '30',
        fourthRow: '40',
        totalRow: '100',
      }, 
  ]);

  return (
    <>
      <div className="card card--chart">
        <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
              <Popover
                id={"popover-positioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                  marginTop: "0",
                  transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                  Description of what this chart means and definitions of any
                  unfamililar or technical terms
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
        </div>
        <div className="card-body">
        <Table responsive bordered className="summary-accounts__data-table">
            <thead className="summary-accounts__table__head">
              <tr>
              <th className="d-flex gap-2 align-items-center"> <Form.Check className="px-1 position-absolute table__checkbox"/> <span className="ps-4">Insurer</span></th>
                <th>0-30</th>
                <th>31-60</th>
                <th>61-90</th>
                <th>120+</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody className="summary-accounts__table__body">
              {tableRowData.map((parentRow) => (
                <>
                <ParentRow key={parentRow.id} data={parentRow} />
              </>
              ))}
            </tbody>
            <tfoot>
            <tr className="summary-accounts__table__row">
                <td className="summary-accounts__table__row-title">
                  Count of insurer
                </td>
                <td>
                    4095
                </td>
                <td>
                    5441
                </td>
                <td>
                    6826
                </td>
                <td>
                    11684
                </td>
                 <td>
                    28046
                </td>
              </tr>
              <tr>
                <td className="summary-accounts__table__row-title">
                ATB AMT
                </td>
                <td className="summary-accounts__table__row-title">$796,131</td>
                <td className="summary-accounts__table__row-title">
                $703,597
                </td>
                <td className="summary-accounts__table__row-title">
                $559,996
                </td>
                <td className="summary-accounts__table__row-title">
                $1,305,100
                </td>
                <td className="summary-accounts__table__row-title">
                 $3,364,824
                </td>
              </tr>
              <tr>
                <td className="summary-accounts__table__row-title">
                %RT ATB AMT
                </td>
                <td className="summary-accounts__table__row-title">23.66%</td>
                <td className="summary-accounts__table__row-title">20.91%</td>
                <td className="summary-accounts__table__row-title">16.64%</td>
                <td className="summary-accounts__table__row-title">38.79%</td>
                <td className="summary-accounts__table__row-title">100%</td>
              </tr>

            </tfoot>
          </Table>
        </div>
      </div>
    </>
  );
};

export default DataTable;
