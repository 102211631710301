import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { MdAdd } from "react-icons/md";
import Dashboard from "./../../../../api/dashboards";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from 'react-toastify';
import { chart } from 'highcharts';

function ModalDashboard({ getDashboards, selectedDashboard, showModal, closeModal, showNew }) {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [formData, setFormData] = useState({
    name: '',
    embedCode: '',
    reportId: '',
    groupId: '',
  });

  useEffect(() => {
    setFormData({
      ...formData,
      name: '',
      embedCode: '',
      accessToken: '',
      reportId: '',
      groupId: '',
      description: ''
    });
    if (showModal === true) {
      let dashboard = selectedDashboard
      setFullscreen(true);
      setShow(true);
      setFormData({
        ...formData,
        name: getOr('', 'name', dashboard),
        embedCode: getOr('', 'embed_code', dashboard),
        accessToken: getOr('', 'access_token', dashboard),
        reportId: getOr('', 'report_id', dashboard),
        groupId: getOr('', 'group_id', dashboard),
        description: getOr('', 'description', dashboard),
      });

    }
    else {
      setFormData({
        ...formData,
        name: '',
        embedCode: '',
        reportId: '',
        groupId: '',
        description: '',
      });
    }
  }, [showModal]);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const ModalClose = (e) => {
    setShow(false);
    closeModal(e)
  }

  const handleSubmit = e => {
    e.preventDefault();
    let formDataDashboard = {}
    let id = window.location.href.split("/")[4]
    formDataDashboard["name"] = formData.name
    formDataDashboard["embed_code"] = formData.embedCode
    formDataDashboard["report_id"] = formData.reportId
    formDataDashboard["group_id"] = formData.groupId
    formDataDashboard["description"] = formData.description
    formDataDashboard["organization_id"] = id
    // if (!isEmpty(chartsData)){
    //   formDataDashboard["charts_data"] = chartsData
    // }
    if (isEmpty(selectedDashboard)) {
      Dashboard.create({ dashboard: formDataDashboard }).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          setFormData({
            ...formData,
            name: '',
            embedCode: '',
            accessToken: '',
            reportId: '',
            groupId: '',
            description: '',
          });
          toast.success(`Dashboard created`, {
            theme: 'colored'
          });
          handleClose()
          getDashboards()
        }
        else {
          toast.error(`Dashboard not created`, {
            theme: 'colored'
          });
        }
      })
    }
    else {
      Dashboard.update({ dashboard: formDataDashboard }, getOr(0, 'id', selectedDashboard)).then((response) => {
        if (
          (getOr("", "status", response) === 200 ||
            getOr("", "status", response) === 204) &&
          isEmpty(getOr({}, "data.errors", response))
        ) {
          setFormData({
            ...formData,
            name: '',
            embedCode: '',
            accessToken: '',
            reportId: '',
            groupId: '',
            description: '',
          });
          toast.success(`Dashboard Updated`, {
            theme: 'colored'
          });
          handleClose()
          getDashboards()
          setShow(false);
          closeModal(e)
        }
        else {
          toast.error(`Dashboard not updated`, {
            theme: 'colored'
          });
        }
      })
    }
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      {values.map((v, idx) => (
        <>
          {showNew ?
            <Button key={idx} className="btn btn-primary" onClick={() => handleShow(v)}>
              <MdAdd key={idx+2}/>
              <span className="d-none d-sm-inline-block ms-1" key={idx+1}>New</span>
              {typeof v === 'string' && `below ${v.split('-')[0]}`}
            </Button>
            : null}
        </>
      ))}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>Edit Dashboard</Modal.Title>
          <div className="modal-header__actions">
            <Button variant="falcon-default" onClick={ModalClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form className="modal-form max-800">
            <div className="row">
              {showNew ?
                <>
                  <Form.Group className="mb-3" controlId="DashboardName">
                    <Form.Label>Dashboard Name</Form.Label>
                    <Form.Control type="text" placeholder="" onChange={handleFieldChange} name="name" value={getOr('', 'name', formData)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="DashboardEmbedCode">
                    <Form.Label>Embed Code</Form.Label>
                    <Form.Control as="textarea" rows={7} placeholder="Paste your embed code here" onChange={handleFieldChange} name="embedCode" value={getOr('', 'embedCode', formData)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="DashboardReportId">
                    <Form.Label>Report ID</Form.Label>
                    <Form.Control type="text" placeholder="" onChange={handleFieldChange} name="reportId" value={getOr('', 'reportId', formData)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="DashboardReportId">
                    <Form.Label>Group ID</Form.Label>
                    <Form.Control type="text" placeholder="" onChange={handleFieldChange} name="groupId" value={getOr('', 'groupId', formData)} />
                  </Form.Group>
                </>
                : <>
                  <Form.Group className="mb-3" controlId="DashboardDescription">
                    <Form.Label>Dashboard Description</Form.Label>
                    <Form.Control as="textarea" rows={7} placeholder="" onChange={handleFieldChange} name="description" value={getOr('', 'description', formData)} />
                  </Form.Group>
                </>
              }
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalDashboard;