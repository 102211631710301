import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdToggleOn, MdToggleOff } from "react-icons/md";
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import PlansTableWrapper from './PlansTableWrapper';
import PlansTableMain from './PlansTableMain';
import { recentPurchaseTableData } from 'data/dashboard/ecom';
import PlansTableFooter from './PlansTableFooter';
import { isEmpty, getOr } from "lodash/fp";
// Icons
import { MdDashboard } from "react-icons/md";
import { MdPerson } from "react-icons/md";
import { MdCode } from "react-icons/md";
import { MdOutlineCalendarToday } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ModalOrgEditPlan from '../../modals/ModalOrgEditPlan';
import Button from 'react-bootstrap/Button';

const PlansTable = ({ plans, showModal, setShowModal, setModalData }) => {

	const navigate = useNavigate();

	const [showSelect, setShowSelect] = useState(false)

	const handleClick = () => {
	}

	const columns = [
		{
			accessor: 'name',
			Header: 'Organization Name	',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'fw-semi-bold link-dark tile__name',
			}
		},
		{
			accessor: 'type',
			Header: 'Plan Type',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'fw-semi-bold',
			}
		},
		{
			accessor: 'fee',
			Header: 'Plan Fee',
			headerProps: { className: 'pe-4' },
			cellProps: {
				className: 'fw-semi-bold',
			}
		},
		{
			accessor: 'date',
			Header: 'Start Date',
			headerProps: { className: '' },
			cellProps: {
				className: 'fw-semi-bold',
			}
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end p-4 text-black',
			}
		}
	];


	const EditSelected = () => {
		return (
			<>
				{showSelect ? (
					<div className='py-2 edit-selected'>
						<div>
							<Form.Label>Bulk Organization Edit</Form.Label>
							<div className='edit-selected__org-edit d-flex gap-2 flex-column'>
								<div className='edit-selected__org-edit__org'>
									<div className='edit-selected__org-edit__org__icon'>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.5C0 7.84784 1.05357 5.3043 2.92893 3.42893C4.8043 1.55357 7.34784 0.5 10 0.5C12.6522 0.5 15.1957 1.55357 17.0711 3.42893C18.9464 5.3043 20 7.84784 20 10.5C20 13.1522 18.9464 15.6957 17.0711 17.5711C15.1957 19.4464 12.6522 20.5 10 20.5C7.34784 20.5 4.8043 19.4464 2.92893 17.5711C1.05357 15.6957 0 13.1522 0 10.5ZM13.528 14.972L10 11.4427L6.472 14.972L5.528 14.0293L9.05733 10.5L5.528 6.972L6.472 6.028L10 9.55733L13.528 6.028L14.472 6.972L10.9427 10.5L14.472 14.028L13.5293 14.972H13.528Z" fill="#2A8B8C" />
										</svg>
									</div>
									<div>Lorem ipsum</div>
								</div>
								<div className='edit-selected__org-edit__org'>
									<div className='edit-selected__org-edit__org__icon'>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.5C0 7.84784 1.05357 5.3043 2.92893 3.42893C4.8043 1.55357 7.34784 0.5 10 0.5C12.6522 0.5 15.1957 1.55357 17.0711 3.42893C18.9464 5.3043 20 7.84784 20 10.5C20 13.1522 18.9464 15.6957 17.0711 17.5711C15.1957 19.4464 12.6522 20.5 10 20.5C7.34784 20.5 4.8043 19.4464 2.92893 17.5711C1.05357 15.6957 0 13.1522 0 10.5ZM13.528 14.972L10 11.4427L6.472 14.972L5.528 14.0293L9.05733 10.5L5.528 6.972L6.472 6.028L10 9.55733L13.528 6.028L14.472 6.972L10.9427 10.5L14.472 14.028L13.5293 14.972H13.528Z" fill="#2A8B8C" />
										</svg>
									</div>
									<div>Lorem ipsum</div>
								</div>
								<div className='edit-selected__org-edit__org'>
									<div className='edit-selected__org-edit__org__icon'>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.5C0 7.84784 1.05357 5.3043 2.92893 3.42893C4.8043 1.55357 7.34784 0.5 10 0.5C12.6522 0.5 15.1957 1.55357 17.0711 3.42893C18.9464 5.3043 20 7.84784 20 10.5C20 13.1522 18.9464 15.6957 17.0711 17.5711C15.1957 19.4464 12.6522 20.5 10 20.5C7.34784 20.5 4.8043 19.4464 2.92893 17.5711C1.05357 15.6957 0 13.1522 0 10.5ZM13.528 14.972L10 11.4427L6.472 14.972L5.528 14.0293L9.05733 10.5L5.528 6.972L6.472 6.028L10 9.55733L13.528 6.028L14.472 6.972L10.9427 10.5L14.472 14.028L13.5293 14.972H13.528Z" fill="#2A8B8C" />
										</svg>
									</div>
									<div>Lorem ipsum</div>
								</div>

							</div>

						</div>
						<div >
							<Form.Group className="mb-3" controlId="Start_Date">
								<Form.Label>Plans</Form.Label>
								<Form.Select aria-label="Contract Start Date" name="month" onChange={() => { }} value={""}>
									<option value="no_change">No Change</option>
									<option value="Feb">Feb</option>
								</Form.Select>
								<Form.Label>Plan Fee</Form.Label>
								<Form.Control type="text" onChange={(e) => { }} value={"5,500"} />

								<div className='w-100 d-flex gap-4' >
									<div>
										<Form.Label>Custom Report Fee</Form.Label>
										<Form.Control type="text" onChange={(e) => { }} value={"50"} />
									</div>
									<div>
										<Form.Label>Tax Rate</Form.Label>
										<Form.Control type="text" onChange={(e) => { }} value={"0.1%"} />
									</div>
								</div>


							</Form.Group>

						</div>
					</div>) : (<div className='d-none'>no bb</div>)}
			</>
		)
	}




	return (
		<PlansTableWrapper
			columns={columns}
			data={plans}
			selection
			sortable
			pagination
			perPage={7}
			rowCount={plans.length}
		>
			<Card>
				<Card.Header>
					<div className='d-flex justify-content-between align-items-center'>
						<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Plans</h5>
						<div className='d-flex gap-2'>
							<ModalOrgEditPlan />
							<Button onClick={() => setShowSelect(prevShowSelect => !prevShowSelect)}>Edit Selected</Button>
						</div>
					</div>
					<EditSelected />
				</Card.Header>
				<Card.Body className="p-0">
					<PlansTableMain
						table
						headerClassName="org-table__header text-900 text-nowrap align-middle"
						rowClassName="btn-reveal-trigger text-nowrap align-middle"
						tableProps={{
							size: 'sm',
							className: 'fs--1 mb-0 overflow-hidden'
						}}
						setShowModal={setShowModal}
						setModalData={setModalData}
					/>
				</Card.Body>
				<Card.Footer>
					<PlansTableFooter rowCount={plans.length} table rowInfo navButtons />
				</Card.Footer>
			</Card>
		</PlansTableWrapper>
	);
};

export default PlansTable;
