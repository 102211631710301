/* eslint-disable */
import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SlQuestion } from "react-icons/sl";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const Chart = ({ data, row1, row2, row3, row4, row5, row6, row7, row8, row9, row10, row11, row12, row13, row14,row15, row16, row17, row18, row19, colorHex }) => {
  const options = {
  
  credits: {
        enabled: false
    },

    title: {
      text: "",
    },
    chart: {
      type: "column",
      zoomType: "x",
      resetZoomButton: {
        position: {
          x: 0,
          y: 0,
        },
      },
    },
    xAxis: {
      categories: ["CROSSOVER (OUTPT)","CROSSOVER (PROF)","DENTAL","GROUP THERAPY","IMMUNIZATION","LABORATORY","MEDICAL/SURGICAL","MENTAL HEALTH","OPTICAL","OPTOMETRY","OTHER","OUTPATIENT","Pharmacy POS","PHYSICAL THERAPY","PROFESSIONAL COMPONENT","PROFESSIONAL TELEPHONE SVS","RADIOLOGY","REHAB/SUBSTANCE","TELEMEDICINE"],
      crosshair: true,
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
      labels: {
        format: '{value}%'
    },
    title: {
      text: ''
    },
      },
    tooltip: {
      headerFormat:
        `<span style="font-size:10px">Provider</span><table>`,
      pointFormat:
        '<tr><td style="color:{series.color};padding:0"></td>' +
        '<td style="padding:0"><b>{point.a}%</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.a}%",
        },
      },
    },
    series: [
      {
        name: "CHS Chart",
        colorByPoint: true,
        data: [
          {
            name: "CHS Chart",
            a: row1,
            y: row1,
            color: colorHex || "#733837",
          },
          {
            name: "Safari",
            a: row2,
            y: row2,
            color: colorHex || "#733837",
          },
          {
            name: "Firefox",
            a: row3,
            y: row3,
            color: colorHex || "#733837",
          },
          {
            name: "Edge",
            a: row4,
            y: row4,
            color: colorHex || "#733837",
          },
          {
            name: "Opera",
            a: row5,
            y: row5,
            color: colorHex || "#733837",
          },
          {
            name: "Opera",
            a: row6,
            y: row6,
            color: colorHex || "#733837",
          },
          {
            name: "Opera",
            a: row7,
            y: row7,
            color: colorHex || "#733837",
          },
          {
            name: "CHS Chart",
            a: row8,
            y: row8,
            color: colorHex || "#733837",
          },
          {
            name: "Safari",
            a: row9,
            y: row9,
            color: colorHex || "#733837",
          },
          {
            name: "Firefox",
            a: row10,
            y: row10,
            color: colorHex || "#733837",
          },
          {
            name: "Edge",
            a: row11,
            y: row11,
            color: colorHex || "#733837",
          },
          {
            name: "Opera",
            a: row12,
            y: row12,
            color: colorHex || "#733837",
          },
          {
            name: "Opera",
            a: row13,
            y: row13,
            color: colorHex || "#733837",
          },
          {
            name: "Opera",
            a: row14,
            y: row14,
            color: colorHex || "#733837",
          },
          {
            name: "CHS Chart",
            a: row15,
            y: row15,
            color: colorHex || "#733837",
          },
          {
            name: "Safari",
            a: row16,
            y: row16,
            color: colorHex || "#733837",
          },
          {
            name: "Firefox",
            a: row17,
            y: row17,
            color: colorHex || "#733837",
          },
          {
            name: "Edge",
            a: row18,
            y: row18,
            color: colorHex || "#733837",
          },
          {
            name: "Opera",
            a: row19,
            y: row19,
            color: colorHex || "#733837",
          },
        ],
      },
    ],
  };
  return (
    <>
      <div className="card card--chart">
	  <div className="card-header border-200 border-bottom d-flex justify-content-between align-items-center">
          <h5>Payment & Adjustment as a percent of Billed Amount by Provider</h5>
          <OverlayTrigger
            trigger="hover"
            placement="bottom-end"
            overlay={
              <Popover
                id={"popover-pos  itioned-" + "bottom-end"}
                arrow={false}
                arrowProps={false}
                style={{
                  marginTop: "0",
                  transition: "all 0.2s ease-in-out, transform 0s;",
                }}
              >
                <Popover.Body>
                  Description of what this chart means and definitions of any
                  unfamililar or technical terms
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <SlQuestion className="card-header__question-mark" />
            </div>
          </OverlayTrigger>
        </div>
        <div className="card-body">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </>
  );
};

export default Chart;
