import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import is from 'is_js';
import MainLayout from './MainLayout';
import SettingsPanel from 'components/settings-panel/SettingsPanel';

import ErrorLayout from './ErrorLayout';
import WizardAuth from 'components/authentication/wizard/WizardAuth';
import Landing from 'components/pages/landing/Landing';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import FontAwesome from 'components/doc-components/FontAwesome';
import ReactIcons from 'components/doc-components/ReactIcons';
import AppContext from 'context/Context';
// Pages
import Site from 'components/site';
import Pricing from 'components/site/pricing';
import About from 'components/site/about';
import Contact from 'components/site/contact';

import AccessLogin from 'components/site/access';
import AccessForgetPassword from 'components/site/access/ForgetPassword';
import AccessPasswordReset from 'components/site/access/PasswordReset';
import AccessLogout from 'components/site/access/Logout';
import AccessConfirmMail from 'components/site/access/ConfirmMail';
import AccessTwoFactor from 'components/site/access/TwoFactor';

import AdminNewOrganization from 'components/site/admin/NewOrganization';
import AdminSpearpoint from 'components/site/admin';
import UserDashboard from 'components/site/user';
import UserSettings from 'components/site/admin/settings';
import DataUpload from 'components/site/admin/DataUpload';
import Plans from 'components/site/admin/Plans';
import InvoiceReports from 'components/site/admin/Invoice-reports';

import Summary from 'components/site/admin/dash';
import SummaryLayout from 'components/spearpoint-layout/summary-layout/SummaryLayout';
import SummaryDetails from 'components/site/admin/dash/summary-component/SummaryDetails';
import Settings from 'components/site/admin/dash/summary-component/Settings';

import {  PatientRegistration, ThirdPartyEligibility, PurchaseReferredCare, ByType, ByLocation, ByServiceCategory, ByClinic, ByProviderType, ByTOP10DiagnosisDX, InjuriesTotal, TOP5InjuriesTypes, DentalTotals, TOP7DentalApptTypes, PharmacyTotals, AmbulatoryCareVisits, } from 'components/site/admin/dash/operations-summary';
import Tiles from 'components/site/admin/tiles';


import { AtbSumByPayer, SumByLocation, SumByMedicalCenter, SumByProviderClinic, SumByPayer, HRNCounts, PayerByChargesReveune, ByInsurers, ByVisitType, SumByVisitTypeClinic, SumByPayerInsurer, SumByInsurerVisitType, SumByMOSAccts, SumByMOSClinic, SumByMOSVisitType, SumByVisitTypePayer } from 'components/site/admin/dash/summary-of-accounts';
import Demo from 'components/site/demo';
import SuperAdmin from 'components/site/admin/super-admins';
import AllUsers from 'components/site/admin/all-users';
import EmptyDash from 'components/site/admin/dash/empty-dash';
import ActivityLog from 'components/site/admin/activity-log';
import Schedule from 'components/site/schedule';
import Team from 'components/site/team';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        {/*- ------------- Frontend ---------------------------  */}
        <Route path="/" element={<Site />} />
        <Route path="solutions" element={<Pricing />} />
        <Route path="about" element={<About />} />
        <Route path="demo" element={<Demo />} />
        <Route path="contact" element={<Contact />} />
        <Route path="schedule" element={<Schedule />} />
        <Route path="team" element={<Team />} />

        {/*- ------------- Access ---------------------------  */}
        <Route path="login" element={<AccessLogin />} />
        <Route path="forgot-password" element={<AccessForgetPassword />} />
        <Route path="reset-password" element={<AccessPasswordReset />} />
        <Route path="confirm-mail" element={<AccessConfirmMail />} />

        {/*- ------------- Admin ---------------------------  */}
        <Route path="admin" element={<AdminSpearpoint />} />
        <Route path="settings" element={<UserSettings />} />
        <Route path="tiles" element={<Tiles />} />
        <Route path="superadmin" element={<SuperAdmin />} />
        <Route path="users" element={<AllUsers />} />
        <Route path="user" element={<UserDashboard />} />
        <Route path="upload" element={<DataUpload />} />
        <Route path="plans" element={<Plans />} />
        <Route path="invoice-reports" element={<InvoiceReports />} />
        <Route path="activity-log" element={<ActivityLog />} />
        <Route path="organization/:id" element={<AdminNewOrganization />} />
        <Route path="organization" element={<AdminNewOrganization />} />

        {/*- ------------- Dashboards ---------------------------  */}
        <Route path='dash' element={<SummaryLayout />}>
          <Route index element={<Summary />} />
          <Route path='details/:id' element={<SummaryDetails />} />
          <Route path='settings' element={<Settings />} />
        </Route>
        {/*- ------------- Empty Dashboard ---------------------------  */}
        <Route path='empty-dash' element={<EmptyDash />}>
        </Route>
        {/*- ------------- Opreations Summary ---------------------------  */}
        <Route path='operations-summary' element={<SummaryLayout />}>
          <Route path='patient-registration' element={<PatientRegistration />} />
          <Route path='third-party-eligibility' element={<ThirdPartyEligibility />} />
          <Route path='purchase-referred-care' element={<PurchaseReferredCare />} />
          <Route path='av-by-type' element={<ByType />} />
          <Route path='av-by-location' element={<ByLocation />} />
          <Route path='av-by-service-category' element={<ByServiceCategory />} />
          <Route path='av-by-clinic' element={<ByClinic />} />
          <Route path='av-by-provider-type' element={<ByProviderType />} />
          <Route path='av-by-top-10-diagnosis-dx' element={<ByTOP10DiagnosisDX />} />
          <Route path='injuries-total' element={<InjuriesTotal />} />
          <Route path='top-5-injuries-types' element={<TOP5InjuriesTypes />} />
          <Route path='dental-totals' element={<DentalTotals />} />
          <Route path='top-7-dental-appt-types' element={<TOP7DentalApptTypes />} />
          <Route path='pharmacy-totals' element={<PharmacyTotals />} />
          <Route path='years-comparison' element={<AmbulatoryCareVisits />} />
        </Route>
        {/*- ------------- Summary Of Accounts ---------------------------  */}
        <Route path='summary-of-accounts' element={<SummaryLayout />}>
          <Route path='atb-sum-by-payer' element={<AtbSumByPayer />} />
          <Route path='atb-sum-by-insurers' element={<ByInsurers />} />
          <Route path='atb-sum-by-visit-type' element={<ByVisitType />} />
          <Route path='sum-by-clinic-visit-type' element={<SumByVisitTypeClinic />} />
          <Route path='sum-by-clinic-provider' element={<SumByProviderClinic />} />
          <Route path='sum-by-payer' element={<SumByPayer />} />
          <Route path='sum-by-location' element={<SumByLocation />} />
          <Route path='sum-by-medical-center' element={<SumByMedicalCenter />} />
          <Route path='hrn-counts' element={<HRNCounts />} />
          <Route path='payer-charges-revenue' element={<PayerByChargesReveune />} />
          <Route path='sum-by-payer-insurer' element={<SumByPayerInsurer />} />
          <Route path='sum-by-insurer-visit-type' element={<SumByInsurerVisitType />} />
          <Route path='sum-by-mos-accts' element={<SumByMOSAccts />} />
          <Route path='sum-by-mos-clinic' element={<SumByMOSClinic />} />
          <Route path='sum-by-mos-visit-type' element={<SumByMOSVisitType />} />
          <Route path='sum-by-visit-type-payer' element={<SumByVisitTypePayer />} />
          </Route>
        {/* <Route path="forgot-password" element={<AccessForgetPassword />} /> */}
        <Route path="access" element={<AccessLogin />} />
        <Route path="logout" element={<AccessLogout />} />
        <Route path="two-factor" element={<AccessTwoFactor />} />

        {/*- ------------- Landing and Errors ---------------------------  */}
        <Route path="landing" element={<Landing />} />
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <SettingsPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
