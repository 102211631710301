import { useState } from "react";
import { Button, Col, Table } from "react-bootstrap";
import { MultiSelect  } from 'primereact/multiselect';
import { FaCalendarAlt } from "react-icons/fa";
import { GrPowerReset } from "react-icons/gr";
import Form from "react-bootstrap/Form";

import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";                                         
import Chart from "./chart";
import DataTable from "./table";

const PayerByChargesReveune = () => {

  const [opt, setOpt] = useState("")

  const [optProvider, setOptProvider] = useState("")

  const [optClinic, setOptClinic] = useState("")

  const [optVisit, setOptVisit] = useState("")

  const handleFieldChange = (e) => {
    setOpt(e.target.value)
  }
  const handleProviderFieldChange = (e) => {
    setOptProvider(e.target.value)
  }

  const providers = [
    {
      name: "ABRAHAM, FRED D"
    },
    {
      name: "Provider 2"
    },
    {
      name: "Provider 3"
    },
    {
      name: "Provider 4"
    },
    {
      name: "Provider 5"
    },
    {
      name: "Provider 6"
    },
    {
      name: "Provider 7"
    },
  ]
    const clinics = [
    {
      name: "Clinic 1"
    },
    {
      name: "Clinic 2"
    },
    {
      name: "Clinic 3"
    },
    {
      name: "Clinic 4"
    },
    {
      name: "Clinic 5"
    },
    {
      name: "Clinic 6"
    },
    {
      name: "Clinic 7"
    },
  ]
  const visitType = [
    {
      name: "Visit Type 1"
    },
    {
      name: "Visit Type 2"
    },
    {
      name: "Visit Type 3"
    },
    {
      name: "Visit Type 4"
    },
    {
      name: "Visit Type 5"
    },
    {
      name: "Visit Type 6"
    },
    {
      name: "Visit Type 7"
    },
  ]

  return (
    <>
      <div className="container px-0">
        <div className="card mb-3">
          <div className="card-body d-flex justify-content-between align-items-center gap-2">
            <h5 className="m-0 w500">Filters</h5>
            <div className="d-flex gap-2 align-items-center" >
            <MultiSelect value={optVisit} onChange={(e) => setOptVisit(e.value)}  options={visitType} optionLabel="name" 
    filter placeholder="Select Visit Type" display="chip" maxSelectedLabels={1337} className="multi-selection__filter" />
    <MultiSelect value={optClinic} onChange={(e) => setOptClinic(e.value)}  options={clinics} optionLabel="name" 
    filter placeholder="Select Clinic" display="chip" maxSelectedLabels={1337} className="multi-selection__filter" />
                <MultiSelect value={optProvider} onChange={(e) => setOptProvider(e.value)}  options={providers} optionLabel="name" 
    filter placeholder="Select Providers" display="chip" maxSelectedLabels={1337} className="multi-selection__filter" />
            <Form>
              <Form.Select 
                size="sm" 
                onChange={(e) => handleFieldChange(e)} 
                value={opt}
              >
                <option value={"All FYs"} key={"View-All"}>
                    All FYs
                </option>
                <option value={"FY2022"} key={"2022"}>
                  FY2022
                </option>
                <option value={"FY2023"} key={"2023"}>
                  FY2023
                </option>
                <option value={"FY2024"} key={"2024"}>
                  FY2024
                </option>
              </Form.Select>
            </Form>
            <Button variant="transparent" className="filter__reset_button">
            <GrPowerReset />
            </Button>
            </div>
          </div>
        </div>
      <>
        <div className="row">
          <Col lg={6} xl={6} className="chart-col">  
            <Chart
              title={"Charges By Payer"}
              total={5000000}
              medicid={1000000}
              pi={1000000}
              medicare={1000000}
              mcrp={1000000}
              medicidPercent={20}
              piPercent={20}
              medicarePercent={20}
              mcrpPercent={20}
             />        
          </Col>
          <Col lg={6} xl={6} className="chart-col">         
          <Chart 
            title={"Revenue By Payer"}
             total={10000000}
              medicid={2000000}
              pi={2000000}
              medicare={2000000}
              mcrp={2000000}
              medicidPercent={20}
              piPercent={20}
              medicarePercent={20}
              mcrpPercent={20}
          />        
          </Col>
        </div>
        <div className="row">
        <Col lg={12} xl={12} className="chart-col">
            <DataTable
            title={"Charges By Payer"}
             />            
          </Col>
        </div>
      </>
      </div>
    </>
  );
};

export default PayerByChargesReveune;