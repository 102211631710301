import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import OrganizationNote from "./../../../../api/organization_notes";
import { isEmpty, getOr } from "lodash/fp";
import { toast } from 'react-toastify';
import { MdAdd } from "react-icons/md";
import CurrencyInput from 'react-currency-input-field';

function ModalNotes({ organizationNote, getOrganization }) {
	const values = [true];
	const [fullscreen, setFullscreen] = useState(true);
	const [subscriptionAmount, setSubscriptionAmount] = useState(0);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [formData, setFormData] = useState({
		note: '',
		subscriptionAmount: '',
	});

	useEffect(() => {
		setSubscriptionAmount(getOr(0, 'subscription_amount', organizationNote))
		setFormData({
			...formData,
			note: getOr('', 'note', organizationNote),
		});
	}, [organizationNote]);

	const handleSubmit = e => {
		e.preventDefault();
		let formDataOrganizationNote = {}
		formDataOrganizationNote["note"] = formData.note
		formDataOrganizationNote["subscription_amount"] = parseFloat(subscriptionAmount)
		OrganizationNote.update({ organization_note: formDataOrganizationNote }, getOr(0, 'id', organizationNote)).then((response) => {
			if (
				(getOr("", "status", response) === 200 ||
					getOr("", "status", response) === 204) &&
				isEmpty(getOr({}, "data.errors", response))
			) {
				toast.success(`Note updated`, {
					theme: 'colored'
				});
				handleClose()
				getOrganization()
			}
			else {
				toast.error(`Note not updated`, {
					theme: 'colored'
				});
			}
		})
	}

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleAmountChange = (value) => {
		setSubscriptionAmount(value)
	}


	function handleShow(breakpoint) {
		setFullscreen(breakpoint);
		setShow(true);
	}

	return (
		<>
			{values.map((v, idx) => (
				<Button key={idx} className="btn btn-primary" onClick={() => handleShow(v)}>
					Edit
					{typeof v === 'string' && `below ${v.split('-')[0]}`}
				</Button>
			))}
			<Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
				<Modal.Header>
					<Modal.Title>Edit Notes</Modal.Title>
					<div className="modal-header__actions">
						<Button variant="falcon-default" onClick={handleClose}>
							Cancel
						</Button>
						<Button variant="primary" onClick={handleSubmit}>
							Save
						</Button>
					</div>
				</Modal.Header>
				<Modal.Body>
					<Form className="modal-form max-800">
						<div className="row">
							<Form.Group className="mb-3" controlId="ContactFirstName">
								<Form.Label>Notes</Form.Label>
								<Form.Control as="textarea" rows={3} placeholder="Your notes" name="note" onChange={handleFieldChange} value={formData.note} />
							</Form.Group>
							<Form.Group className="mb-3 col-sm-6" controlId="ContactLastName" >
								<Form.Label>Subscription amount</Form.Label>
								<CurrencyInput
									name="subscriptionAmount"
									placeholder="$"
									className="form-control"
									defaultValue={subscriptionAmount}
									decimalsLimit={2}
									onValueChange={(value, name) => handleAmountChange(value)}
								/>
							</Form.Group>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default ModalNotes;