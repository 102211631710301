import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdToggleOn, MdToggleOff } from "react-icons/md";
import { Link } from 'react-router-dom';
import TilesTableWrapper from './TilesTableWrapper';
import TilesTableMain from './TilesTableMain';
import { recentPurchaseTableData } from 'data/dashboard/ecom';
import TilesTableFooter from './TilesTableFooter';
import { isEmpty, getOr } from "lodash/fp";
// Icons
import { MdDashboard } from "react-icons/md";
import { MdPerson } from "react-icons/md";
import { MdCode } from "react-icons/md";
import { MdOutlineCalendarToday } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Defender from 'helpers/defender';
import Organization from "../../../../api/organizations";

const TilesTable = ({ sections, showModal, setShowModal, setModalData }) => {

	const navigate = useNavigate();

	const handleClick = () => {
	}
	
	const columns = [
		{
			accessor: 'name',
			Header: 'Tiles',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'fw-semi-bold link-dark tile__name',
			}
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end p-4 text-black',
			}
		}
	];
	return (
		<TilesTableWrapper
			columns={columns}
			data={sections}
			sortable
			pagination
			perPage={7}
			rowCount={sections.length}
		>
			<Card>
				<Card.Header>
				<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Sections</h5>
				</Card.Header>
				<Card.Body className="p-0">
					<TilesTableMain
						table
						headerClassName="org-table__header text-900 text-nowrap align-middle"
						rowClassName="btn-reveal-trigger text-nowrap align-middle"
						tableProps={{
							size: 'sm',
							className: 'fs--1 mb-0 overflow-hidden'
						}}
						setShowModal={setShowModal}
						setModalData={setModalData}
					/>
				</Card.Body>
				<Card.Footer>
					<TilesTableFooter rowCount={sections.length} table rowInfo navButtons />
				</Card.Footer>
			</Card>
		</TilesTableWrapper>
	);
};

export default TilesTable;
