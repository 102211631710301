import { useState } from "react";
import { Button, Col, Table } from "react-bootstrap";
import { MultiSelect  } from 'primereact/multiselect';
import { FaCalendarAlt } from "react-icons/fa";
import { GrPowerReset } from "react-icons/gr";
import Form from "react-bootstrap/Form";
import DataTable from "./table";
import SDataTable from "./sTable";

import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";                                         
import Chart from "./chart";
import InsurerChart from "./insurer-chart";

const SumByMOSClinic = () => {

  const [opt, setOpt] = useState("")

  const handleFieldChange = (e) => {
    setOpt(e.target.value)
  }


  let data = [
    {
      name: "Aged Trial Balance",
      data: [100000,60000,154245,645420,2525259,3252992,9981848,100000,60000,154245,645420,2525259,3252992,9981848,100000,60000,154245,645420,2525259,3252992,9981848,100000,60000,154245,645420,2525259,3252992,9981848,100000,60000,154245,645420,2525259,3252992,9981848,100000],
      color: "#2A8B8C",
    },
    {
      name: "Revenue",
      data: [0,100000,645420,454245,2525259,9981848,3252992,0,100000,645420,454245,2525259,9981848,3252992,0,100000,645420,454245,2525259,9981848,3252992,0,100000,645420,454245,2525259,9981848,3252992,0,100000,645420,454245,2525259,9981848,3252992,0],
      color: "#bc6541",
    },
    {
      name: "Adjustments",
      data: [800000,5446,254245,845420,5525259,2252992,7581848,800000,5446,254245,845420,5525259,2252992,7581848,800000,5446,254245,845420,5525259,2252992,7581848,800000,5446,254245,845420,5525259,2252992,7581848,800000,5446,254245,845420,5525259,2252992,7581848,800000],
        color: "#e1a95b",
    },
    {
      name: "Charges",
      data: [800000,5446,254245,845420,5525259,2252992,7581848,800000,5446,254245,845420,5525259,2252992,7581848,800000,5446,254245,845420,5525259,2252992,7581848,800000,5446,254245,845420,5525259,2252992,7581848,800000,5446,254245,845420,5525259,2252992,7581848,800000],
        color: "#6A6E71",
    }
  ]

  return (
    <>
      <div className="container px-0">
        <div className="card mb-3">
          <div className="card-body d-flex justify-content-between align-items-center gap-2">
            <h5 className="m-0 w500">Filters</h5>
            <div className="d-flex gap-2 align-items-center" >
            <Form>
              <Form.Select 
                size="sm" 
                onChange={(e) => handleFieldChange(e)} 
                value={opt}
              >
                <option value={"All FYs"} key={"View-All"}>
                    All FYs
                </option>
                <option value={"FY2017"} key={"2017"}>
                  FY2017
                </option>
                <option value={"FY2018"} key={"2018"}>
                  FY2018
                </option>
                <option value={"FY2019"} key={"2019"}>
                  FY2019
                </option>
                <option value={"FY2020"} key={"2020"}>
                  FY2020
                </option>
                <option value={"FY2021"} key={"2021"}>
                  FY2021
                </option>
                <option value={"FY2022"} key={"2022"}>
                  FY2022
                </option>
                <option value={"FY2023"} key={"2023"}>
                  FY2023
                </option>
              </Form.Select>
            </Form>
            <Button variant="transparent" className="filter__reset_button">
            <GrPowerReset />
            </Button>
            </div>
          </div>
        </div>
      <>
        <div className="row">
          <Col lg={12} xl={12} className="chart-col">
            <DataTable
            title={"Summary By MOS & Clinic"}
             />            
          </Col>
        </div>
        <div className="row">
          <Col lg={4} xl={4} className="chart-col">
            <SDataTable
            title={"Summary By MOS & Clinic"}
            type={'Visit Location'}
             />            
          </Col>
          <Col lg={4} xl={4} className="chart-col">
            <SDataTable
            title={"Summary By MOS & Clinic"}
            type={'Clinic'}
             />            
          </Col>
          <Col lg={4} xl={4} className="chart-col">
            <SDataTable
            title={"Summary By MOS & Clinic"}
            type={'Visit Type'}
             />            
          </Col>
        </div>
        <div className="row">
            <Col lg={12} xl={12} className="chart-col">
            <Chart
              data={data}
              />
              </Col>
        </div>
 
      </>
      </div>
    </>
  );
};

export default SumByMOSClinic;